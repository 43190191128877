import { useQuery } from "@apollo/client";
import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { theme } from "core/theme";
import { PRACTITIONER_TYPES_QUERY } from "graphql/accounts";
import { ReactComponent as FileIcon } from "images/file-icon.svg";
import { PanelBoxV2 } from "tpo/Boxes";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ProfessionalBodiesField from "tpo/partnerDashboard/practitioner/Application/ui/ProfessionalBodiesField";
import ButtonV2 from "v2/Buttons";

function ReadOnlyExpertRelatedFields({ documents, practitionerTypeValue }) {
  const { data: { practitionerTypes = [] } = {} } = useQuery(PRACTITIONER_TYPES_QUERY);

  return (
    <>
      {!!practitionerTypeValue && (
        <FormControl
          label={
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="26px">
              What role describes you best?
            </Box>
          }
          spaceAboveHelpText={2}
          helpText={
            <Box fontFamily="gilroyMedium" fontSize={16}>
              Your selected primary professional focus. If you wish to change your role please
              contact{" "}
              <ExternalTextLink
                href={`mailto:support@omnos.me`}
                target="_blank"
                rel="noopener noreferrer"
                noWrap
              >
                support@omnos.me
              </ExternalTextLink>
            </Box>
          }
          spaceAboveChildren={[20, 20, 40]}
        >
          <Stack alignItems="flex-start" gap={[20, 20, 40]}>
            {practitionerTypeValue && (
              <ButtonV2
                color="green"
                variant="filled"
                py={[2, 2, 16]}
                px={[16, 16, 20]}
                fontSize={[10, 10, 12]}
                borderRadius="100px"
                borderWidth="2px"
                gap={10}
                height={[27, 27, 40]}
              >
                {practitionerTypes.find(({ id }) => id === practitionerTypeValue)?.role}
              </ButtonV2>
            )}
          </Stack>
        </FormControl>
      )}
      {!!documents?.length && (
        <FormControl
          label={
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="26px">
              Qualification proof
            </Box>
          }
          spaceAboveHelpText={2}
          spaceAboveChildren={[20, 20, 40]}
        >
          <Stack gap={20}>
            {documents.map(d => (
              <Group
                alignItems="center"
                justifyContent="space-between"
                key={d.key}
                gap={20}
                flexWrap="wrap"
                maxWidth="100%"
              >
                <Group
                  gap={20}
                  alignItems="center"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                  }}
                >
                  <FileIcon />
                  <Box fontFamily="gilroyBold" fontSize={12} letterSpacing="2.8px">
                    {d.name}
                  </Box>
                </Group>
              </Group>
            ))}
          </Stack>
        </FormControl>
      )}
    </>
  );
}

export default function OtherDetails({ partnerUserProfile }) {
  return (
    <PanelBoxV2
      maxWidth={1532}
      outer={{
        pt: theme.spacing.section.pt,
        pb: theme.spacing.section.pb,
        px: [20],
        bg: "white",
        "data-component-name": "OtherDetailsSection"
      }}
    >
      <PanelBoxV2 maxWidth={760} stacked gap={[20, 20, 40]}>
        <ReadOnlyExpertRelatedFields
          documents={partnerUserProfile.documents?.map(d => ({
            key: d.fileKey,
            name: d.fileName
          }))}
          practitionerTypeValue={partnerUserProfile.practitionerType?.id}
        />
        <FormControl
          label={
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="26px">
              Professional Bodies
            </Box>
          }
          spaceAboveHelpText={2}
          helpText={
            <Box fontFamily="gilroyMedium" fontSize={16}>
              Please give details of any professional bodies you are registered with
            </Box>
          }
          spaceAboveChildren={[20, 20, 40]}
        >
          <ProfessionalBodiesField name="otherDetailsProfessionalBodies" />
        </FormControl>
        <FormControl
          label={
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]} lineHeight="26px">
              Social media
            </Box>
          }
          spaceAboveHelpText={2}
          spaceAboveChildren={[20, 20, 40]}
          helpText={
            <Box fontFamily="gilroyMedium" fontSize={16}>
              Please enter at least one of your social media links.
            </Box>
          }
        >
          <Stack gap={20}>
            <ControlledFormField
              name="otherDetailsInstagram"
              label="Instagram"
              Component={FloatingLabelInput}
            />
            <ControlledFormField
              name="otherDetailsLinkedin"
              label="Linkedin"
              Component={FloatingLabelInput}
            />
            <ControlledFormField
              name="otherDetailsYoutube"
              label="Youtube"
              Component={FloatingLabelInput}
            />
            <ControlledFormField
              name="otherDetailsWebsiteBlog"
              label="Website / Blog"
              Component={FloatingLabelInput}
            />
          </Stack>
        </FormControl>
      </PanelBoxV2>
    </PanelBoxV2>
  );
}
