export default function CirclePlayIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10025_6146)">
        <path
          d="M15.1543 11.867C15.4876 12.0594 15.4876 12.5406 15.1543 12.733L10.8043 15.2445C10.471 15.4369 10.0543 15.1964 10.0543 14.8115L10.0543 9.78853C10.0543 9.40363 10.471 9.16307 10.8043 9.35552L15.1543 11.867Z"
          fill="#1A1A1A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1133 22.2C17.7466 22.2 22.3133 17.6333 22.3133 12C22.3133 6.3667 17.7466 1.8 12.1133 1.8C6.47998 1.8 1.91328 6.3667 1.91328 12C1.91328 17.6333 6.47998 22.2 12.1133 22.2ZM12.1133 24C18.7407 24 24.1133 18.6274 24.1133 12C24.1133 5.37258 18.7407 0 12.1133 0C5.48586 0 0.113281 5.37258 0.113281 12C0.113281 18.6274 5.48586 24 12.1133 24Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10025_6146">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
