import Modal from "./Modal";

function ChartModal({ children, show, close, bg }) {
  return (
    <Modal
      mode={["fullScreen", "fullScreen", "centered"]}
      maxWidth={1020}
      closeButton
      bg={bg}
      headerProps={{
        p: [20, 20, 40]
      }}
      show={show}
      close={close}
    >
      {children}
    </Modal>
  );
}

ChartModal.defaultProps = {
  bg: "white"
};

export default ChartModal;
