import React from "react";

import { FormProvider } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";

import { CustomerAccountPage } from "components/CustomerPage";
import { PARTNERS_RESET_PASSWORD_COMPLETE_URL, RESET_PASSWORD_COMPLETE_URL } from "core/urls";
import { RESET_PASSWORD_MUTATION } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import { Error } from "tpo/InputWrapper";
import FloatingLabelPasswordInput from "tpo/PasswordInput";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function ResetFormPage({ partners }) {
  useDocTitle("Reset password");
  const history = useHistory();
  const match = useRouteMatch();

  const api = useDjangoGraphqlForm({
    mutation: RESET_PASSWORD_MUTATION,
    mutationName: "resetPasswordMutation",
    defaultValues: {
      password: "",
      confirmPassword: ""
    },
    transformer: data => ({
      ...data,
      userId: match.params.userId,
      token: match.params.token
    }),
    handleSuccess: () => {
      history.push(partners ? PARTNERS_RESET_PASSWORD_COMPLETE_URL : RESET_PASSWORD_COMPLETE_URL);
    }
  });

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Password reset",
        bg: partners ? "partners" : "purple",
        color: partners ? "dark" : "white"
      }}
    >
      <FormProvider {...api.methods}>
        <Stack
          gap={20}
          as="form"
          onSubmit={api.methods.handleSubmit(api.onSubmit)}
          maxWidth={600}
          mx="auto"
        >
          <ControlledFormField
            label="Password"
            name="password"
            Component={FloatingLabelPasswordInput}
          />
          <ControlledFormField
            label="Confirm Password"
            name="confirmPassword"
            Component={FloatingLabelPasswordInput}
          />
          <ButtonV2
            color="green"
            rightIcon={<ChevronComponent />}
            size={["sm", "sm", "md"]}
            mx="auto"
            mt={20}
          >
            Submit
          </ButtonV2>
          {!!api.nonFieldError && <Error mx="auto" error={api.nonFieldError} />}
        </Stack>
      </FormProvider>
    </CustomerAccountPage>
  );
}
