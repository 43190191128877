import { theme } from "core/theme";
import ButtonV2 from "v2/Buttons";

import ChevronComponent from "./Chevron";

export function SubmitButton({ onClick, text }) {
  return (
    <ButtonV2
      onClick={onClick}
      rightIcon={<ChevronComponent fill={theme.colors.dark} />}
      color="white"
      size={["sm", "sm", "md"]}
      sx={{
        color: "dark"
      }}
    >
      {text}
    </ButtonV2>
  );
}
