import { useLocation, useParams, Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import DataLoader from "components/DataLoader";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import ParamsContext from "contexts/ParamsContext";
import useFirstAvailableOrganisation from "hooks/organisations/useFirstAvailableOrganisation";
import { useDidUpdate } from "hooks/useDidUpdate";
import Params from "tpo/Params";
import RecommendationPage from "tpo/RecommendationPage";
import UserDatapointPage from "tpo/UserDatapoint";
import UserResultPage from "tpo/UserResult";
import { ViewerContext } from "tpo/Viewer";

import { ERROR_404_URL, getClientManagementTabUrl } from "../../core/urls";
import Dashboard, { DASHBOARD_QUERY } from "./Dashboard";
import FoodListRoute from "./FoodLists";
import ProfileTab from "./Profile";
import RecommendationsRoute from "./Recommendations";
import SelfAssessmentRoute from "./SelfAssessment";
import Wellness from "./Wellness";
import Template from "./shared/Template";
import Test from "./tests/TestTab";
import TestsRoute from "./tests/TestsRoute";

function PatientView() {
  const match = useRouteMatch();
  const { patientId } = useParams();

  const { includeExpiredMarkers } = useChangeOverTimeContext();

  return (
    <ViewerContext.Provider
      value={{
        userId: patientId
      }}
    >
      <Switch>
        <Route path={match.path} exact>
          <Redirect to={getClientManagementTabUrl(patientId, "profile")} />
        </Route>
        <Route path={`${match.path}/profile`} exact>
          <ProfileTab />
        </Route>
        <Route path={`${match.path}/dashboard`} exact>
          <Template selectedTab="dashboard" docTitle="Patient's Dashboard">
            <DataLoader
              query={DASHBOARD_QUERY}
              variables={{
                userId: patientId
              }}
              render={({ user }) => {
                const snapshot = includeExpiredMarkers
                  ? user.patientDashboard.perSnapshot
                  : user.patientDashboard.perFreshSnapshot;

                return (
                  <Dashboard
                    wellnessScorePanel={snapshot?.wellnessScorePanel}
                    latestOrderPanel={user.patientDashboard.latestOrder}
                    selfAssessmentPanel={snapshot?.selfAssessment}
                    expiredMarkersPanel={snapshot?.expiredMarkersPanel}
                    latestTestResultsPanel={snapshot?.latestUserTest}
                  />
                );
              }}
            />
          </Template>
        </Route>
        <Route path={`${match.path}/tests`} exact>
          <TestsRoute />
        </Route>
        <Route path={`${match.path}/tests/:id`} exact>
          <Test />
        </Route>
        <Route path={`${match.path}/wellness`}>
          <Wellness />
        </Route>
        <Route path={`${match.path}/self_assessment`} exact>
          <SelfAssessmentRoute />
        </Route>
        <Route path={`${match.path}/suggestions`} exact>
          <RecommendationsRoute />
        </Route>
        <Route path={`${match.path}/food_list`} exact>
          <FoodListRoute />
        </Route>
        <Redirect to={ERROR_404_URL} />
      </Switch>
    </ViewerContext.Provider>
  );
}

const IS_CONNECTED_TO_PATIENT = gql`
  query IsConnectedToPatient($patientId: ID!) {
    isConnectedToPatient(patientId: $patientId)
  }
`;

export default function PatientViewRoutes() {
  const match = useRouteMatch();
  const location = useLocation();
  const { patientId } = useParams();
  const { data, refetch } = useQuery(IS_CONNECTED_TO_PATIENT, {
    variables: {
      patientId
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  useDidUpdate(() => {
    refetch();
  }, [location.pathname, refetch]);

  const { loadingElement } = useFirstAvailableOrganisation();

  if (loadingElement) return loadingElement;
  if (data && !data?.isConnectedToPatient) {
    return <Redirect to={ERROR_404_URL} />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/result/:userResultId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <UserResultPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/biomarker/:userDatapointId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <UserDatapointPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/suggestions/:recommendationId`}>
        {/* Not sure it's possible through the UI to get here but this is in case any old links
        exist pointing to the old /partner ... urls.  For these we'd redirect to here */}
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <RecommendationPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}`} component={PatientView} />
    </Switch>
  );
}
