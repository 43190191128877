import { useCallback, useRef } from "react";

import { FormProvider } from "react-hook-form";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { INVITE_YOUR_PATIENT_MUTATION } from "graphql/organisations/mutations";
import { CONNECTED_USERS_QUERY } from "graphql/organisations/queries/patients";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import { first } from "lodash";
import { PanelBoxV2 } from "tpo/Boxes";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import { Error } from "tpo/InputWrapper";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { PROFILE_QUERY } from "tpo/partnerDashboard/common/graphql/query";
import { IndicativeButton } from "v2/Buttons";

export default function InviteYourPatients() {
  const { organisation } = useOrganisationContext();
  const { data, loading, error } = useQuery(PROFILE_QUERY);
  const partnerLinkURL = `${window.location.origin}/partners/patient_invite/organisation/${organisation.hashId}/partner/${data?.partnerUserProfile?.user?.hashId}`;

  const submitButtonRef = useRef();

  const { methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: INVITE_YOUR_PATIENT_MUTATION,
    mutationName: "inviteYourPatientMutation",
    mutationOptions: {
      refetchQueries: [
        {
          query: CONNECTED_USERS_QUERY,
          variables: {
            page: 1,
            orderBy: "-created",
            search: "",
            organisationId: organisation?.id
          }
        }
      ]
    },
    defaultValues: {
      email: "",
      firstName: "",
      lastName: ""
    },
    handleSuccess: ({ api: { reset } }) => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
        reset();
      }
    },
    handleFailure: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
    }
  });

  const handleSubmitPatient = useCallback(
    input => {
      if (!organisation) return;
      onSubmit({
        organisation: parseInt(organisation.id),
        ...input
      });
    },
    [organisation, onSubmit]
  );

  return (
    <FormProvider {...methods}>
      <PanelBoxV2 maxWidth={1280}>
        <PanelBoxV2
          maxWidth={1020}
          outer={{
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            bg: "white",
            px: 20,
            borderRadius: 5,
            "data-component-name": "InviteYourPatients"
          }}
        >
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
            Invite your patients
          </Box>
          <Spacer py={[20]} />
          <Box fontFamily="gilroyMedium" fontSize={16} lineHeight="150%">
            Fill in their email address below and we will send an invitation to join Omnos and allow
            you access to their results.
          </Box>
          <Spacer py={[20]} />
          <form onSubmit={methods.handleSubmit(handleSubmitPatient)}>
            {nonFieldError && <Error data-testid="nonFieldError" error={nonFieldError} />}
            <Stack gap={20}>
              <Group flexDirection={["column", "column", "row"]} gap={20} flexWrap="wrap">
                <ControlledFormField
                  name="firstName"
                  label="First Name"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="lastName"
                  label="Last Name"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField name="email" label="Email" Component={FloatingLabelInput} />
              </Group>
              <IndicativeButton
                ref={submitButtonRef}
                defaultColor="green"
                pendingColor="#0cf970"
                successColor="#2ecc71"
                failureColor="error"
                size={["sm", "sm", "md"]}
                sx={{
                  mr: "auto"
                }}
              >
                send
              </IndicativeButton>
            </Stack>
          </form>
          <Spacer py={20} />
          <Box fontFamily="gilroyMedium" fontSize={16} lineHeight="150%">
            Alternatively you can send them this unique link{" "}
            <Box as="span" color="anchorBlue" wordWrap="break-word">
              {partnerLinkURL}
            </Box>
          </Box>
        </PanelBoxV2>
      </PanelBoxV2>
    </FormProvider>
  );
}
