import { Redirect, useHistory, useLocation } from "react-router-dom";

import { theme } from "core/theme";
import ButtonV2 from "v2/Buttons";

import Box from "../components/Box";
import Text from "../components/Text";
import { DASHBOARD_HOME_URL, getQuestionnaireIntroUrl, getTestUrl } from "../core/urls";
import Section from "../deprecated/Section";
import useDocTitle from "../hooks/use-doc-title";
import ChevronComponent from "./Chevron";
import { HeadingExtraSmall } from "./Headings";
import { useTestProduct } from "./QuestionnaireHooks";
import { LoadingResults, Results, useOutro } from "./QuestionnaireOutro";
import Page from "./QuestionnairePages";
import { usedAppBackButton, usedQuestionnaireNavigation } from "./QuestionnaireUtilities";
import { BestMatchedTest } from "./TestRecommendations";

function TestProductQuestionnaireResults({ userTestRecommendation }) {
  useDocTitle("Questionnaire Results");
  const history = useHistory();

  return (
    <Page
      header={userTestRecommendation.product.name}
      Main={
        <Box px={20} pb={theme.spacing.section.pb}>
          <Box
            bg="white"
            pt={[30, 30, 70]}
            pb={[40, 40, 70]}
            maxWidth={1020}
            mx="auto"
            style={{
              borderRadius: 5
            }}
            px={20}
          >
            <Box maxWidth={760} mx="auto">
              <Box display="flex" flexDirection={["column", "column", "row"]}>
                <Box
                  position="relative"
                  width={["100%", "100%", "50%"]}
                  display="flex"
                  justifyContent="flex-end"
                  pr={[0, 0, 20]}
                  height={["300px", "300px", "auto"]}
                  backgroundColor="#F2F2F2"
                  style={{
                    backgroundImage: `url(${userTestRecommendation.product.content.image1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain"
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width={["100%", "100%", "50%"]}
                  pl={[0, 0, 20]}
                  mt={[50, 50, 0]}
                >
                  <BestMatchedTest.TestMatchDegree
                    userTestRecommendation={userTestRecommendation}
                  />
                  <BestMatchedTest.SymptomList userTestRecommendation={userTestRecommendation} />
                  <ButtonV2
                    color="green"
                    mr="auto"
                    onClick={() =>
                      history.push({
                        pathname: getTestUrl(
                          userTestRecommendation.product.slug,
                          userTestRecommendation.product.productFamily.slug
                        )
                      })
                    }
                    mt={[20, 20, 40]}
                    sx={{
                      color: "white"
                    }}
                    rightIcon={<ChevronComponent />}
                    size={["sm", "sm", "md"]}
                  >
                    Buy now
                  </ButtonV2>
                </Box>
              </Box>
              <Box mt={40}>
                <Section.Header>
                  <HeadingExtraSmall fontSize={[18, 18, 24]}>Next steps</HeadingExtraSmall>
                </Section.Header>
                <Text fontSize={[14, 14, 16]}>
                  Complete our free symptoms questionnaire and we’ll recommend the most appropriate
                  test for you across our full suite of available tests. We’ll also provide an
                  overall wellness score and lifestyle suggestions.
                </Text>
                <ButtonV2
                  color="green"
                  mr="auto"
                  mt={4}
                  onClick={() => history.push(getQuestionnaireIntroUrl("symptoms"))}
                  rightIcon={<ChevronComponent />}
                  size={["sm", "sm", "md"]}
                >
                  self assessment
                </ButtonV2>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
}

function TestProductQuestionnaireOutroComponent({ product }) {
  const { bestMatchedTest } = useOutro(product, true);

  if (!bestMatchedTest?.show) {
    return <LoadingResults />;
  }

  return (
    <Results
      threshold={0}
      userTestRecommendation={bestMatchedTest.test}
      Component={TestProductQuestionnaireResults}
    />
  );
}

function TestProductQuestionnaireOutro() {
  const history = useHistory();
  const location = useLocation();
  const testProductData = useTestProduct();

  if (!usedQuestionnaireNavigation(location) || usedAppBackButton(history)) {
    return <Redirect to={DASHBOARD_HOME_URL} />;
  }

  if (!testProductData) {
    return <LoadingResults />;
  }

  return <TestProductQuestionnaireOutroComponent product={testProductData.testProduct.id} />;
}

export default TestProductQuestionnaireOutro;
