import { useContext } from "react";

import { InternalTextLink } from "../components/Links";
import { ListBox } from "../components/List";
import { getPatientTestResultUrl, getTestResultUrl } from "../core/urls";
import { ViewerContext } from "./Viewer";

function RecommendationWhy({ backgroundColor, relatedUserResults, heading, text }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  return (
    <ListBox
      data-component-name="RecommendationWhy"
      backgroundColor={backgroundColor}
      heading={heading}
      text={text}
      headingSize="s"
      items={relatedUserResults.map((relatedUserResult, index) => {
        const url = clientId
          ? getPatientTestResultUrl(clientId, relatedUserResult.id)
          : getTestResultUrl(relatedUserResult.id);
        return (
          <InternalTextLink href={url} key={index}>
            {relatedUserResult.result.name}
          </InternalTextLink>
        );
      })}
    />
  );
}

RecommendationWhy.defaultProps = {
  heading: "Why are we making this suggestion?",
  text: "This suggestion relates to the following results:"
};

export default RecommendationWhy;
