import { useCallback, useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { usePersistentState } from "contexts/LocalStorage";
import { UPDATE_ORGANISATION_DETAILS_MUTATION } from "graphql/organisations/mutations";
import {
  ORGANISATION_LIST_QUERY,
  ORGANISATION_ROLES_QUERY
} from "graphql/organisations/queries/organisation";

export default function useOrganisation() {
  const [showCreateOrganisationModal, setShowCreateOrganisationModal] = useState(false);
  const [showUpdateOrganisationNameModal, setShowUpdateOrganisationNameModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [organisationId, setOrganisationId] = usePersistentState("selectedOrganisationId", null);
  const [organisation, setOrganisation] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const {
    loading: listLoading,
    data: { organisations: availableOrganisations } = {},
    refetch
  } = useQuery(ORGANISATION_LIST_QUERY);

  const { data: { organisationRoles } = {} } = useQuery(ORGANISATION_ROLES_QUERY);

  const [_updateOrganisationDetails] = useMutation(UPDATE_ORGANISATION_DETAILS_MUTATION, {
    refetchQueries: [
      {
        query: ORGANISATION_LIST_QUERY
      }
    ]
  });

  const updateOrganisationDetails = useCallback(
    async variables => {
      if (!organisationId) {
        return;
      }
      setLoading(true);
      try {
        await _updateOrganisationDetails({
          variables: {
            input: {
              id: parseInt(organisationId),
              ...variables
            }
          }
        });
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    },
    [_updateOrganisationDetails, organisationId]
  );

  useEffect(() => {
    if (listLoading) {
      return;
    }
    if (!organisationId) {
      setOrganisation(null);
    }
    let organisation = availableOrganisations?.find(({ id }) => id === organisationId);

    if (!organisation) {
      organisation = availableOrganisations?.[0];
      if (organisation) {
        setOrganisationId(organisation.id);
      } else {
        setOrganisationId(null);
      }
    }
    setOrganisation(organisation);
    setPermissions(organisation?.permissions || []);
    setLoading(false);
  }, [availableOrganisations, organisationId, setOrganisationId, setOrganisation, listLoading]);

  return {
    loading,
    error,
    organisation,
    organisationRoles,
    availableOrganisations,
    setOrganisationId,
    organisationId,
    permissions,
    updateOrganisationDetails,
    refetchOrganisations: refetch,
    showCreateOrganisationModal,
    setShowCreateOrganisationModal,
    showUpdateOrganisationNameModal,
    setShowUpdateOrganisationNameModal
  };
}
