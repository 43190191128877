import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import Grid from "components/Grid";
import { useBasketContext } from "contexts/BasketContext";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useTestProductOptionContext } from "contexts/TestProductOptionContext";
import { theme } from "core/theme";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import BiomarkersAndSymptomsList from "tpo/shop/BiomarkersAndSymptomsList";
import Carousel from "tpo/shop/Carousel";
import DiscoverOtherTestsPanel from "tpo/shop/DiscoverOtherTestsPanel";
import HowItWorksPanel from "tpo/shop/HowItWorksPanel";
import MainDetails from "tpo/shop/MainDetails";
import RelatedPdfs from "tpo/shop/RelatedFiles";
import SecurityPanel from "tpo/shop/SecurityPanel";
import SelfAssessmentInvitationPanel from "tpo/shop/SelfAssessmentInvitationPanel";
import TestProductInformationPanels from "tpo/shop/TestProductInformationPanels";
import TestProductOptions from "tpo/shop/TestProductOptions";
import TestRecommendationPanel from "tpo/shop/TestRecommendationPanel";
import YourResultsPanel from "tpo/shop/YourResultsPanel";
import { DISPLAY_CONTEXT_ALWAYS_VISIBLE, DISPLAY_CONTEXT_B2C_ONLY } from "tpo/shop/constants";

import RelatedFileClasses from "../../RelatedFiles.module.css";
import getCarouselImages from "../utils/getCarouselImages";

export default function Full() {
  const { testProduct, userTestRecommendation, selectTestProductId } = useTestProductModalContext();
  const { selectTestProductOptionId } = useTestProductOptionContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  const carouselImages = getCarouselImages(testProduct);

  const practitionerSupportRequired = testProduct.productAudience === "B2B";

  const options = testProduct.options.length ? (
    <TestProductOptions>
      {testProduct.options.map((option, index) => {
        const hasClinics = !!option.consumerClinicsInfo?.clinics?.length;
        return (
          <TestProductOptions.Option
            key={option.id}
            id={option.id}
            status={testProduct.status}
            compositeId={option.compositeId}
            name={option.testProductOptionFamily.name}
            description={option.testProductOptionFamily.description}
            price={
              hasClinics ? (
                <Stack gap={0}>
                  <Currency
                    prefix="FROM"
                    prefixFontFamily="gilroyBold"
                    prefixFontSize={16}
                    value={option.consumerClinicsInfo.fromPrice}
                    stacked
                    fontSize={44}
                  />
                  <Box fontFamily="gilroyMedium" fontSize={14}>
                    Includes clinic fee
                  </Box>
                </Stack>
              ) : (
                option.rrpCurrentPrice
              )
            }
            practitionerSupportRequired={testProduct.productAudience === "B2B"}
            lastOption={index === testProduct.options.length - 1}
            hasClinics={hasClinics}
            onFindNearestClinic={() => {
              selectTestProductOptionId({
                id: option.id,
                paymentIsoCode: option.paymentIsoCode,
                organisation: option.organisation
              });
            }}
            onAddToBasket={() => {
              setBasketOpen(true);
              selectTestProductId(undefined);
              addItemToBasket({
                compositeId: option.compositeId
              });
            }}
          />
        );
      })}
    </TestProductOptions>
  ) : null;

  const showHowItWorksPanel =
    testProduct.content.howItWorksPanel &&
    (testProduct.content.collectingYourSampleImage ||
      testProduct.content.collectingYourSampleVideo);

  const informationPanels = testProduct.content.informationPanels.filter(
    panel =>
      panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
      panel.displayContext === DISPLAY_CONTEXT_B2C_ONLY
  );

  return (
    <>
      <Box display="none" data-component-name="ProductDetail" />
      <PanelBoxV2
        maxWidth={768}
        outer={{
          bg: "white",
          pt: carouselImages.length ? 0 : theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40]
        }}
        stacked
        gap={[20, 20, 40]}
      >
        {!!carouselImages.length && (
          // parent is a flexbox with gap defined so mb takes this into account
          <Box bg="haze" p={20} mb={[20]}>
            <Carousel images={carouselImages} bg="haze" />
          </Box>
        )}
        <Box>
          <MainDetails
            status={testProduct.status}
            name={testProduct.name}
            description={testProduct.content.description}
            categories={testProduct.content.categories.map(cat => cat.name)}
            sampleTypes={testProduct.sampleTypes}
            numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
            processingTime={testProduct.content.processingTime}
            options={options}
            priceElement={
              <Group gap={10}>
                {testProduct.rrpFullPrice !== undefined &&
                  testProduct.rrpFullPrice > testProduct.rrpCurrentPrice && (
                    <Currency
                      fontSize={[44]}
                      value={testProduct.rrpFullPrice}
                      color="red"
                      strikethrough
                    />
                  )}
                <Currency fontSize={[44]} value={testProduct.rrpCurrentPrice} />
              </Group>
            }
            productOptionCardStackBg="white"
            testType={testProduct.slug}
            practitionerSupportRequired={practitionerSupportRequired}
            onAddToBasket={() => {
              addItemToBasket({
                compositeId: testProduct.compositeId
              });
              setBasketOpen(true);
              selectTestProductId(undefined);
            }}
          />
        </Box>
        <TestRecommendationPanel userTestRecommendation={userTestRecommendation} />
        {(!userTestRecommendation || userTestRecommendation.rank > 5) && (
          <SelfAssessmentInvitationPanel productSlug={testProduct.slug} />
        )}
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={768}
        outer={{
          bg: "haze",
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40]
        }}
        stacked
        gap={40}
      >
        <TestProductInformationPanels panels={informationPanels} />
        {(!!testProduct.instructions?.length || !!testProduct.sampleLabReports?.length) && (
          <Box
            bg="white"
            borderRadius={5}
            className={RelatedFileClasses.container}
            px={[20, 20, 40]}
            py={[30, 30, 60]}
          >
            {!!testProduct.sampleLabReports?.length && (
              <RelatedPdfs sectionName="Sample Reports" pdfs={testProduct.sampleLabReports} />
            )}
            {!!testProduct.instructions?.length && (
              <RelatedPdfs sectionName="Instructions" pdfs={testProduct.instructions} />
            )}
          </Box>
        )}
      </PanelBoxV2>
      {!!(
        testProduct.content.biomarkersTested?.length || testProduct.content.relatedSymptoms?.length
      ) && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "partners",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40]
          }}
        >
          <BiomarkersAndSymptomsList
            relatedSymptoms={testProduct.content.relatedSymptoms}
            biomarkersTested={testProduct.content.biomarkersTested}
          />
        </PanelBoxV2>
      )}
      {showHowItWorksPanel && (
        <PanelBoxV2
          maxWidth={768}
          bg="white"
          outer={{
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, 40]
          }}
          stacked
          gap={40}
        >
          <HowItWorksPanel
            title={testProduct.content.howItWorksPanel.title}
            body={testProduct.content.howItWorksPanel.body}
            collectingYourSampleImage={testProduct.content.collectingYourSampleImage}
            collectingYourSampleVideo={testProduct.content.collectingYourSampleVideo}
          />
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={768}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, 40],
          bg: "haze"
        }}
        stacked
        gap={40}
      >
        <YourResultsPanel.Title />
        <Grid gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr"]}>
          <YourResultsPanel.ResultsCard />
          <YourResultsPanel.RecommendationsCard />
          <YourResultsPanel.SupportCard
            gridColumnStart="1"
            gridColumnEnd={["1", "3", "3"]}
            maxHeight={276}
          />
        </Grid>
      </PanelBoxV2>
      <PanelBoxV2
        maxWidth={1280}
        outer={{
          pt: theme.spacing.section.pt,
          px: [20, 20, 40],
          bg: "white"
        }}
        stacked
        gap={40}
      >
        <Grid gridTemplateColumns={["1fr"]} gridRowGap={0} gridColumnGap={0}>
          <SecurityPanel.DataSecurityImage minHeight={[400, null]} />
          <SecurityPanel.DataSecurity py={[60, 60, 120]} px={[50, 50, 80]} />
        </Grid>
        <Grid gridTemplateColumns={["1fr"]} gridRowGap={0} gridColumnGap={0}>
          <SecurityPanel.LabsYouCanTrustImage minHeight={[400, null]} gridRowStart={["1", null]} />
          <SecurityPanel.LabsYouCanTrust py={[60, 60, 120]} px={[50, 50, 80]} />
        </Grid>
      </PanelBoxV2>
      <Stack gap={40} pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} bg="white">
        <DiscoverOtherTestsPanel />
      </Stack>
    </>
  );
}
