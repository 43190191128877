import React from "react";

import { useSwipeable } from "react-swipeable";

import propTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";

import ArrowCircle from "./ArrowCircle";
import Box from "./Box";

function SliderButton({ handleClick, bg, ...props }) {
  return (
    <ArrowCircle
      bg={bg}
      {...props}
      as="button"
      onClick={e => {
        e.preventDefault();
        handleClick();
      }}
    />
  );
}

SliderButton.propTypes = {
  handleClick: PropTypes.func,
  bg: propTypes.color.bg
};

SliderButton.defaultProps = {
  bg: "white",
  size: [40, 80]
};

export default function Slider({
  currentIndex,
  children,
  handleNext,
  handlePrev,
  width = null,
  inset = null,
  buttonInset,
  buttonBg,
  showArrowButtons = true
}) {
  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev
  });

  width = width || [
    "calc(100% - 30px)",
    "calc(100% - 60px)",
    "calc(100% - 200px)",
    "calc(100% - 200px)"
  ];
  inset = inset || ["5px", "10px", 60, 60];
  return (
    <Box overflow="hidden" {...handlers}>
      <Box data-component-name="Slider" position="relative" mx="auto" width={width}>
        <Box
          data-component-name="Tray"
          display="flex"
          style={{
            transition: "transform 300ms",
            transform: `translate3d(${(100 / children.length) * -currentIndex}%,0,0)`
          }}
          width={`${children.length * 100}%`}
          height="100%"
        >
          {children.map((child, index) => {
            return (
              <Box key={index} width={`${100 / children.length}%`}>
                <Box mx={inset} position="relative">
                  {child}
                </Box>
              </Box>
            );
          })}
        </Box>
        {handlePrev && showArrowButtons && (
          <SliderButton
            direction="left"
            handleClick={handlePrev}
            position="absolute"
            top="50%"
            left={buttonInset}
            transform="translateY(-50%)"
            bg={buttonBg}
          />
        )}
        {handleNext && showArrowButtons && (
          <SliderButton
            direction="right"
            handleClick={handleNext}
            position="absolute"
            top="50%"
            right={buttonInset}
            transform="translateY(-50%)"
            bg={buttonBg}
          />
        )}
      </Box>
    </Box>
  );
}

Slider.propTypes = {
  currentIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  width: propTypes.layout.width,
  inset: propTypes.space.mx
};

Slider.defaultProps = {
  buttonBg: "white"
};
