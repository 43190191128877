export default function PenIcon({ fill, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10025_6152)">
        <path
          d="M18.0223 1.6C18.484 1.6 18.916 1.776 19.2406 2.096L19.2452 2.10057C19.5697 2.42286 19.7412 2.83429 19.7412 3.28914C19.7412 3.744 19.5675 4.15314 19.2429 4.47772L18.0223 5.68457L15.604 3.28914L16.7995 2.10286C17.124 1.78057 17.5583 1.60229 18.02 1.60229H18.0223M18.0223 7.10599e-07H18.02C17.1309 7.10599e-07 16.2989 0.342858 15.6726 0.964572L13.332 3.28914L18.02 7.93371L20.3675 5.616C20.996 4.99429 21.3435 4.16914 21.3435 3.28914C21.3435 2.40914 20.9983 1.584 20.372 0.962286C20.372 0.962286 20.3697 0.960001 20.3675 0.957715C19.7412 0.338286 18.9092 -0.002285 18.0246 -0.002285L18.0223 7.10599e-07Z"
          fill="inherit"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.45898 18.704L7.4487 17.6274L3.5447 13.7623L2.45898 18.704Z"
          fill="inherit"
        />
        <path
          d="M12.4206 6.45028L14.8298 8.83657L8.74751 14.8457L6.35208 12.4731L12.4206 6.448M12.4184 4.19886L4.08008 12.4777L8.74522 17.0994L17.1041 8.84114L12.4184 4.19886Z"
          fill="inherit"
        />
        <path
          d="M23.2 24.3977H0.8C0.358857 24.3977 0 24.0389 0 23.5977C0 23.1566 0.358857 22.7977 0.8 22.7977H23.2C23.6411 22.7977 24 23.1566 24 23.5977C24 24.0389 23.6411 24.3977 23.2 24.3977Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_10025_6152">
          <rect width="24" height="24.3977" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
