import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import Box from "components/Box";
import { ORGANISATION_LIST_QUERY } from "graphql/organisations/queries/organisation";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import ShippingCountrySelect from "tpo/ShippingCountrySelect";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const CREATE_ORGANISATION_MUTATION = gql`
  mutation CreateOrganisationMutation($input: CreateOrganisationMutationInput!) {
    createOrganisationMutation(input: $input) {
      organisation {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default function CreateOrganisationForm({ onSuccess }) {
  const api = useDjangoGraphqlForm({
    mutation: CREATE_ORGANISATION_MUTATION,
    mutationName: "createOrganisationMutation",
    mutationOptions: {
      refetchQueries: [
        {
          query: ORGANISATION_LIST_QUERY
        }
      ]
    },
    handleSuccess: data => {
      onSuccess(data);
    },
    defaultValues: {
      contactEmail: "",
      name: "",
      country: ""
    }
  });

  return (
    <FormProvider {...api.methods}>
      <Stack
        as="form"
        gap={40}
        onSubmit={api.methods.handleSubmit(api.onSubmit)}
        mx="auto"
        maxWidth={720}
        width="100%"
        pb={[60, 60, 80]}
        px={20}
      >
        <Stack gap={20}>
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Create a new organisation
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            You can belong to or own more than 1 organisation to help manage your various patients.
          </Box>
        </Stack>
        <Stack gap={20}>
          <ControlledFormField
            name="name"
            Component={FloatingLabelInput}
            label="Organisation name"
          />
          <ControlledFormField
            name="contactEmail"
            Component={FloatingLabelInput}
            label="Organisation email"
          />
          <ControlledFormField
            name="country"
            valueField="id"
            placeholder="Organisation country"
            fullWidth
            mt={2}
            Component={ShippingCountrySelect}
          />
        </Stack>
        <ButtonV2 mx="auto" size={["sm", "sm", "md"]} color="green">
          Create
        </ButtonV2>
      </Stack>
    </FormProvider>
  );
}
