import { forwardRef } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import { EXPERT_AVAILABILITY_IN_PERSON } from "core/constants";
import { theme } from "core/theme";
import { getExpertsDetailUrl } from "core/urls";
import { EXPERT_FIELDS } from "graphql/accounts";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import PartnersHeaderImage from "images/partners-header.jpg";
import Badge from "tpo/Badge";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import LinkWrapper from "tpo/LinkWrapper";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";
import useProductFilters from "tpo/shop/hooks/useProductFilters";
import ButtonV2 from "v2/Buttons";

import Avatar from "./Avatar";
import Box from "./Box";
import Grid from "./Grid";
import Page from "./Page";
import ProductFilters from "./ProductFilters";

const EXPERT_CONNECTIONS_QUERY = gql`
  query ExpertConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $consultationTypes: String
    $practitionerTypes: String
    $specialisms: String
  ) {
    expertConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      consultationTypes: $consultationTypes
      practitionerTypes: $practitionerTypes
      specialisms: $specialisms
    ) {
      edges {
        node {
          ...ExpertFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${EXPERT_FIELDS}
`;

const FILTERS_QUERY = gql`
  query FiltersQuery($includeFilters: [IncludeFilters]) {
    consultationTypes: expertConsultationTypes
    practitionerTypes {
      id
      role
    }
    specialisms: areasOfInterest(includeFilters: $includeFilters) {
      id
      name
    }
  }
`;

const ExpertCard = forwardRef(
  ({ id, name, practitionerType, availability, location, avatarUrl, firstName, lastName }, ref) => (
    <Stack gap={0} ref={ref} borderRadius={5}>
      <Center bg="partners" py={[20, 20, 40]}>
        <Avatar
          placeholder={`${firstName?.[0] || ""}${lastName?.[0] || ""}`}
          bg="white"
          size={160}
          src={avatarUrl}
        />
      </Center>
      <Stack pt={[30]} pb={[30, 30, 60]} px={20} bg="white" gap={30}>
        <Box fontFamily="gilroyBold" fontSize={24}>
          {name}
        </Box>
        <Stack gap={10}>
          {!!practitionerType && (
            <Group gap={10}>
              <Box fontFamily="gilroyBold" fontSize={[15]}>
                Practitioner Type:
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={[15]}>
                {practitionerType.role}
              </Box>
            </Group>
          )}
          <Group gap={10} alignItems="center" flexWrap="wrap">
            <Box fontFamily="gilroyBold" fontSize={[15]}>
              Availability:
            </Box>
            <Group
              alignItems="center"
              gap={10}
              fontFamily="gilroyMedium"
              fontSize={[15]}
              flexWrap="wrap"
            >
              {availability.map(a => (
                <Badge bg="green" color="white" px={16} py="8px" key={a}>
                  {a}
                </Badge>
              ))}
            </Group>
          </Group>
          {availability.includes(EXPERT_AVAILABILITY_IN_PERSON) && (
            <Group gap={10}>
              <Box fontFamily="gilroyBold" fontSize={[15]}>
                Location:
              </Box>
              <Box fontFamily="gilroyMedium" fontSize={[15]}>
                {location}
              </Box>
            </Group>
          )}
        </Stack>
        <Center mt={[-2, -2, 2]}>
          <ButtonV2
            color="dark"
            rightIcon={<ChevronComponent />}
            as={LinkWrapper}
            to={getExpertsDetailUrl(id)}
          >
            see profile
          </ButtonV2>
        </Center>
      </Stack>
    </Stack>
  )
);

export default function Experts() {
  const {
    data: { consultationTypes = [], practitionerTypes = [], specialisms = [] } = {}
  } = useQuery(FILTERS_QUERY, {
    variables: {
      includeFilters: ["Both", "B2C"]
    }
  });

  const history = useHistory();
  const location = useLocation();

  const { toggleFilter, allFilters, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      consultationType: [],
      practitionerType: [],
      specialisms: []
    },
    allFilters: {
      consultationType: [...consultationTypes].sort(),
      practitionerType: [...practitionerTypes.map(pt => pt.role)].sort(),
      specialisms: [...specialisms.map(s => s.name)].sort()
    }
  });

  const controls = useListControls({
    defaultSort: "-id",
    sortOptions: [
      {
        label: "A - Z",
        value: "id"
      },
      {
        label: "Z - A",
        value: "-id"
      }
    ]
  });

  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const { data, loading, fetchMore } = useQuery(EXPERT_CONNECTIONS_QUERY, {
    variables: {
      orderBy: controls.sort,
      search: debouncedSearch,
      consultationTypes: validatedFilters.consultationType.join(","),
      practitionerTypes: validatedFilters.practitionerType.join(","),
      specialisms: validatedFilters.specialisms.join(","),
      first: 10,
      after: ""
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });

  const connectionsFieldName = "expertConnections";

  const experts = data?.[connectionsFieldName]?.edges.map(edge => edge.node);
  const hasMore = data?.[connectionsFieldName]?.pageInfo?.hasNextPage;
  const endCursor = data?.[connectionsFieldName]?.pageInfo?.endCursor;

  return (
    <Page>
      <Stack
        height={[294, 294, 526]}
        style={{
          backgroundImage: `url(${PartnersHeaderImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
        pb={[48, 48, 140]}
        px={20}
      >
        <Box
          color="white"
          maxWidth={1580}
          mx="auto"
          mt="auto"
          width="100%"
          fontSize={[36, 36, 68]}
          fontFamily="gilroyBold"
        >
          Find a practitioner
        </Box>
      </Stack>
      <Center pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} bg="haze">
        <Stack px={[20, 20, "5.5vw"]} width="100%" gap={[20, 20, 40]} maxWidth={1280}>
          <Box maxWidth={760} mx="auto">
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
              Welcome to our experts area
            </Box>
            <Spacer py={[2, 2, 15]} />
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              Below you’ll find a list of our qualified expert practitioners. They are here to help
              you on your path towards optinal health.
            </Box>
          </Box>
          <Stack gap={[20]}>
            <Box
              display="flex"
              flexDirection={["column", "column", "row"]}
              gap={20}
              justifyContent={[null, null, "space-between"]}
              flexWrap="wrap"
            >
              <Group gap={10}>
                <ProductFilters
                  allFilters={allFilters}
                  validatedFilters={validatedFilters}
                  drawerBg="haze"
                  borderColor="dark"
                  color={theme.colors.dark}
                  filtersTitle="Practitioner"
                  toggleFilter={toggleFilter}
                />
              </Group>
              <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
                <Search
                  placeholder="Search"
                  value={search}
                  onChange={s => {
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.set("search", s);
                    history.push({
                      path: location.path,
                      search: searchParams.toString()
                    });
                  }}
                  maxWidth={[null, null, 350]}
                  minWidth={310}
                  width="100%"
                />
                <Box display="flex" justifyContent="flex-end">
                  <SortMenu
                    value={controls.sort}
                    setValue={controls.setSort}
                    open={controls.sortMenuOpen}
                    setOpen={controls.setSortMenuOpen}
                    options={controls.sortOptions}
                    placeholder="Sort By"
                  />
                </Box>
              </Box>
            </Box>
            {selectedFilters}
          </Stack>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}>
            <InfiniteScroll
              hasMore={hasMore}
              next={() =>
                fetchMore({
                  variables: {
                    after: endCursor,
                    first: 10
                  }
                })
              }
              loading={loading}
            >
              <InfiniteScrollContext.Consumer>
                {({ setBottomElement }) => (
                  <>
                    {experts?.slice(0, -1)?.map(expert => (
                      <ExpertCard
                        key={expert.id}
                        id={expert.id}
                        avatarUrl={expert.resizedAvatarUrl}
                        name={expert.partnerUserProfile.user.fullName}
                        availability={expert.consultationType}
                        practitionerType={expert.partnerUserProfile.practitionerType}
                        location={expert.partnerUserProfile.location}
                        firstName={expert.partnerUserProfile.user.firstName}
                        lastName={expert.partnerUserProfile.user.lastName}
                      />
                    ))}
                    {experts?.slice(-1)?.map(expert => (
                      <ExpertCard
                        key={expert.id}
                        id={expert.id}
                        avatarUrl={expert.resizedAvatarUrl}
                        ref={setBottomElement}
                        name={expert.partnerUserProfile.user.fullName}
                        availability={expert.consultationType}
                        practitionerType={expert.partnerUserProfile.practitionerType}
                        location={expert.partnerUserProfile.location}
                        firstName={expert.partnerUserProfile.user.firstName}
                        lastName={expert.partnerUserProfile.user.lastName}
                      />
                    ))}
                  </>
                )}
              </InfiniteScrollContext.Consumer>
            </InfiniteScroll>
          </Grid>
        </Stack>
      </Center>
    </Page>
  );
}
