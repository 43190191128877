export default function CpdIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3423_155442)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 37C29.3888 37 37 29.3888 37 20C37 10.6112 29.3888 3 20 3C10.6112 3 3 10.6112 3 20C3 29.3888 10.6112 37 20 37ZM20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#1A1A1A"
        />
        <path
          d="M27.3148 21.168C29.6308 21.168 31.3588 23.004 31.3588 25.368C31.3588 27.732 29.6308 29.568 27.3148 29.568H23.9788V21.168H27.3148ZM27.3148 27.984C28.7668 27.984 29.7748 26.916 29.7748 25.368C29.7748 23.82 28.7668 22.752 27.3148 22.752H25.6348V27.984H27.3148Z"
          fill="#1A1A1A"
        />
        <path
          d="M20.0326 21.168C21.6526 21.168 22.9006 22.416 22.9006 23.988C22.9006 25.56 21.6526 26.808 20.0326 26.808H18.5566V29.568H16.9006V21.168H20.0326ZM20.0326 25.26C20.7406 25.26 21.2566 24.708 21.2566 23.988C21.2566 23.256 20.7406 22.716 20.0326 22.716H18.5566V25.26H20.0326Z"
          fill="#1A1A1A"
        />
        <path
          d="M12.2713 29.736C9.72732 29.736 7.90332 27.816 7.90332 25.368C7.90332 22.908 9.72732 21 12.2713 21C13.8073 21 15.1633 21.78 15.8833 22.98L14.4553 23.808C14.0473 23.064 13.2313 22.608 12.2713 22.608C10.6273 22.608 9.54732 23.748 9.54732 25.368C9.54732 26.976 10.6273 28.116 12.2713 28.116C13.2313 28.116 14.0593 27.66 14.4553 26.928L15.8833 27.756C15.1753 28.956 13.8193 29.736 12.2713 29.736Z"
          fill="#1A1A1A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1116 9.35615C26.6196 8.88128 25.8367 8.88128 25.3619 9.35615L19.2442 15.4738L16.6175 12.8471C16.1255 12.3722 15.3383 12.3722 14.8635 12.8471C14.3886 13.339 14.3886 14.1219 14.8635 14.6011L18.3501 18.0877C18.8421 18.5626 19.625 18.5626 20.0998 18.0877L27.0902 11.0973C27.5651 10.6225 27.5651 9.8353 27.1073 9.36043L27.1116 9.35615Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3423_155442">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

CpdIcon.defaultProps = {
  size: 40
};
