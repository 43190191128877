export default function FolderIcon({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10025_6160)">
        <path
          d="M22.8866 4.51134H20.2887V1.9134C20.2887 1.29979 19.7889 0.799999 19.1753 0.799999H1.1134C0.499794 0.799999 0 1.29979 0 1.9134V18.1196C0 19.6214 1.21979 20.8412 2.72165 20.8412H22.8866C23.5002 20.8412 24 20.3414 24 19.7278V5.62474C24 5.01113 23.5002 4.51134 22.8866 4.51134ZM3.71134 5.62474V18.1196C3.71134 18.6664 3.26845 19.1093 2.72165 19.1093C2.17485 19.1093 1.73196 18.6664 1.73196 18.1196V2.53196H18.5567V4.51134H4.82474C4.21113 4.51134 3.71134 5.01113 3.71134 5.62474ZM22.268 19.1093H5.25773C5.37897 18.8025 5.4433 18.4685 5.4433 18.1196V6.2433H22.268V19.1093Z"
          fill="#1A1A1A"
        />
        <path
          d="M8.78395 10.2021H19.1757C19.6532 10.2021 20.0417 9.81361 20.0417 9.33608C20.0417 8.85856 19.6532 8.4701 19.1757 8.4701H8.78395C8.30642 8.4701 7.91797 8.85856 7.91797 9.33608C7.91797 9.81361 8.30642 10.2021 8.78395 10.2021Z"
          fill="#1A1A1A"
        />
        <path
          d="M8.78395 13.4186H19.1757C19.6532 13.4186 20.0417 13.0301 20.0417 12.5526C20.0417 12.075 19.6532 11.6866 19.1757 11.6866H8.78395C8.30642 11.6866 7.91797 12.075 7.91797 12.5526C7.91797 13.0301 8.30642 13.4186 8.78395 13.4186Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10025_6160">
          <rect width="24" height="20.0412" fill="white" transform="translate(0 0.799999)" />
        </clipPath>
      </defs>
    </svg>
  );
}

FolderIcon.defaultProps = {
  width: 24,
  height: 21
};
