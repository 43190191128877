import { Children, cloneElement, useEffect, useRef, useState, isValidElement } from "react";

import { Wrapper } from "@googlemaps/react-wrapper";

import { createCustomEqual } from "fast-equals";

/**
 * Source - https://developers.google.com/maps/documentation/javascript/react-map#javascript
 */

const render = status => {
  return <h1>{status}</h1>;
};

function Marker(options) {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
}

const deepCompareEqualsForMaps = createCustomEqual(deepEqual => (a, b) => {
  if (a instanceof window.google.maps.LatLng || b instanceof window.google.maps.LatLng) {
    return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
  }

  // TODO extend to other types

  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize)); // eslint-disable-line react-hooks/exhaustive-deps
}

function Map({ onClick, onIdle, children, style, ...options }) {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach(
        eventName => new window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, child => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </>
  );
}

function GoogleMap({ places, lat, lng, zoom: initialZoom }) {
  const [zoom, setZoom] = useState(initialZoom);
  const [center, setCenter] = useState({
    lat,
    lng
  });

  useEffect(() => {
    if (lat && lng) {
      setCenter({ lat, lng });
    }
  }, [lat, lng, setCenter]);

  const onIdle = m => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  return (
    <Wrapper apiKey={"AIzaSyBLLUIacl1zimipmDguybQdb-3U35-a5jw"} render={render}>
      <Map center={center} onIdle={onIdle} zoom={zoom} style={{ height: "442px" }}>
        {places.map((place, i) => (
          <Marker key={i} position={place.pos} />
        ))}
      </Map>
    </Wrapper>
  );
}

GoogleMap.defaultProps = {
  zoom: 10
};

export default GoogleMap;
