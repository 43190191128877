import gql from "graphql-tag";

// Queries

export const PLATFORM_USER_PROFILE_FIELDS = gql`
  fragment PlatformUserProfileFields on PlatformUserProfileType {
    id
    pk
    height
    weight
    dateOfBirth
    gender
    isComplete
    stripeCustomerId
    stripeCardPaymentMethodId
    stripeCardExpMonth
    stripeCardExpYear
    stripeCardLast4
    stripeCardBrand
    user {
      id
      firstName
      lastName
      email
    }
    goals {
      id
    }
    dietPreferences {
      id
    }
    marketingConsent
  }
`;

export const PLATFORM_USER_PROFILE_QUERY = gql`
  query PlatformUserProfileQuery {
    platformUserProfile {
      ...PlatformUserProfileFields
    }
  }
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

export const PRACTITIONER_TYPE_FIELDS = gql`
  fragment PractitionerTypeFields on PractitionerTypeType {
    id
    role
    qualificationTypes
  }
`;

export const PRACTITIONER_TYPES_QUERY = gql`
  query PractitionerTypesQuery {
    practitionerTypes {
      ...PractitionerTypeFields
    }
  }
  ${PRACTITIONER_TYPE_FIELDS}
`;

export const EXPERT_TESTIMONIAL_FIELDS = gql`
  fragment ExpertTestimonialFields on TestimonialType {
    id
    detail
    name
    order
  }
`;

export const EXPERT_FIELDS = gql`
  fragment ExpertFields on ExpertType {
    id
    partnerUserProfile {
      id
      user {
        id
        fullName
        firstName
        lastName
      }
      businessLocationCity
      practitionerType {
        id
        role
        qualificationTypes
      }
      organisationSet {
        id
        name
      }
      professionalBodies
      documents {
        id
        type
        qualificationType
      }
    }
    public
    avatar
    avatarUrl
    resizedAvatarUrl
    bio
    consultationType
    supportedTests {
      id
      name
      paymentIsoCode
      organisation
    }
    specialisms {
      id
      name
      description
    }
    testimonials {
      ...ExpertTestimonialFields
    }
  }
  ${EXPERT_TESTIMONIAL_FIELDS}
`;

export const PARTNER_USER_PROFILE_FIELDS = gql`
  fragment PartnerUserProfileFields on PartnerUserProfileType {
    id
    pk
    tier
    approved
    user {
      id
      firstName
      lastName
      hashId
    }
    discountSet {
      id
      code
      testPercentage
      commissionPercentage
    }
    jobRole
    phoneNumber
    description
    descriptionOther
    qualifications
    sources
    sourcesOther
    specialisms
    specialismsOther
    instagram
    tiktok
    youtube
    linkedin
    websiteBlog
    isApprovedToBeExpert
    practitionerType {
      id
      role
    }
    professionalBodies
    documents {
      id
      fileUrl
      fileName
      fileKey
    }
    expert {
      ...ExpertFields
    }
    businessName
    businessCountry {
      id
      name
    }
    businessLocationCity
  }
  ${EXPERT_FIELDS}
`;

export const PARTNER_USER_PROFILE_QUERY = gql`
  query PartnerUserProfileQuery {
    partnerUserProfile {
      ...PartnerUserProfileFields
    }
  }
  ${PARTNER_USER_PROFILE_FIELDS}
`;

export const GENERIC_USER_PROFILE_QUERY = gql`
  query GenericUserProfileQuery {
    user {
      id
      email
      dateJoined
      fullName
      platformUserProfile {
        ...PlatformUserProfileFields
      }
      partnerUserProfile {
        ...PartnerUserProfileFields
      }
    }
  }
  ${PLATFORM_USER_PROFILE_FIELDS}
  ${PARTNER_USER_PROFILE_FIELDS}
`;

export const RESULTS_ACCESS_REQUEST_QUERY = gql`
  query ResultAccessRequestQuery($id: String!) {
    resultsAccessLink(id: $id) {
      id
      partnerUserProfile {
        id
        user {
          id
          identifier
        }
      }
      organisation {
        id
        name
      }
    }
  }
`;

export const AFFILIATE_LINK_FIELDS = gql`
  fragment AffiliateLinkFields on AffiliateLinkType {
    id
    created
    orderTestItemsTotal
    orderSupplementItemsTotal
    orderTestItemsCommission
    orderSupplementItemsCommission
    partnerUserProfile {
      id
      tier
    }
    resultsAccessLink {
      id
      granted
    }
    platformUserEmail
    platformUserProfile {
      id
      user {
        id
        email
      }
    }
  }
`;
export const USER_PURCHASE_COMMISSION_FIELDS = gql`
  fragment UserPurchaseCommissionFields on CommissionType {
    orderDescription
    discount
    commission
    orderDate
    orderId
  }
`;

export const AFFILIATE_LINKS_QUERY = gql`
  query AffiliateLinksQuery {
    affiliateLinks {
      ...AffiliateLinkFields
    }
  }
  ${AFFILIATE_LINK_FIELDS}
`;

export const PAYOUT_FIELDS = gql`
  fragment PayoutFields on PayoutType {
    id
    created
    amount
    status
  }
`;

export const PAYOUTS_QUERY = gql`
  query PayoutsQuery {
    payouts {
      ...PayoutFields
    }
  }
  ${PAYOUT_FIELDS}
`;

// Mutations

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation($input: SignupMutationInput!) {
    signupMutation(input: $input) {
      user {
        id
        email
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const KIT_SIGNUP_MUTATION = gql`
  mutation KitSignupMutation($input: KitSignupMutationInput!) {
    kitSignupMutation(input: $input) {
      user {
        id
        fullName
        platformUserProfile {
          id
          dateOfBirth
          gender
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_PATIENT_INVITE_MUTATION = gql`
  mutation AcceptPatientInviteMutation($input: AcceptPatientInviteMutationInput!) {
    acceptPatientInviteMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation ActivateAccountMutation($input: ActivateAccountMutationInput!) {
    activateAccountMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const LOG_IN_MUTATION = gql`
  mutation LogInMutation($input: LogInMutationInput!) {
    logInMutation(input: $input) {
      user {
        id
        email
        dateJoined
        fullName
        platformUserProfile {
          ...PlatformUserProfileFields
        }
        partnerUserProfile {
          ...PartnerUserProfileFields
        }
      }
      errors {
        field
        messages
      }
      csrfToken
    }
  }
  ${PARTNER_USER_PROFILE_FIELDS}
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

export const LOG_OUT_MUTATION = gql`
  mutation LogOutMutation {
    logOutMutation {
      result
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation($input: ChangePasswordMutationInput!) {
    changePasswordMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordResetMutation($input: RequestPasswordResetMutationInput!) {
    requestPasswordResetMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmailMutation($input: ResendVerificationEmailMutationInput!) {
    resendVerificationEmailMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($input: ResetPasswordMutationInput!) {
    resetPasswordMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_PROFESSIONAL_LINK_INVITATION_MUTATION = gql`
  mutation AcceptProfessionalLinkInvitationMutation(
    $input: AcceptProfessionalLinkInvitationMutationInput!
  ) {
    acceptProfessionalLinkInvitationMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION = gql`
  mutation AcceptResultsAccessRequestMutation($input: AcceptResultsAccessRequestMutationInput!) {
    acceptResultsAccessRequestMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const PLATFORM_USER_PROFILE_MUTATION = gql`
  mutation PlatformUserProfileMutation($input: PlatformUserProfileMutationInput!) {
    platformUserProfileMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const GOALS_MUTATION = gql`
  mutation GoalsMutation($input: GoalsMutationInput!) {
    goalsMutation(input: $input) {
      platformUserProfile {
        id
        pk
        goals {
          id
          text
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DIET_PREFERENCES_MUTATION = gql`
  mutation DietPreferencesMutation($input: DietPreferencesMutationInput!) {
    dietPreferencesMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PARTNER_USER_PROFILE_MUTATION = gql`
  mutation CreatePartnerUserProfileMutation($input: CreatePartnerUserProfileMutationInput!) {
    createPartnerUserProfileMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const MIGRATE_AFFILIATE_TO_PRACTITIONER_MUTATION = gql`
  mutation MigrateAffiliateToPractitionerMutation(
    $input: MigrateAffiliateToPractitionerMutationInput!
  ) {
    migrateAffiliateToPractitionerMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PARTNER_INVITATION_MUTATION = gql`
  mutation CreatePartnerInvitationMutation($input: CreatePartnerInvitationInput!) {
    createPartnerInvitationMutation(input: $input) {
      createdAffiliateLink
      createdResultsAccessLink
      errors {
        field
        messages
      }
    }
  }
`;

export const PARTNER_LINK_CODE_QUERY = gql`
  query PartnerShareLinkRedirectPageQuery($partnerLinkCode: String!) {
    checkIncomingPartnerLinkCode(partnerLinkCode: $partnerLinkCode) {
      hashId
      identifier
      type
      tier
    }
  }
`;

export const ACKNOWLEDGE_NOTIFICATION_MUTATION = gql`
  mutation AcknowledgeNotificationMutation($input: AcknowledgeNotificationMutationInput!) {
    acknowledgeNotificationMutation(input: $input) {
      ok
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      id
      args
    }
  }
`;

export const CREATE_OR_EDIT_EXPERT_PROFILE_MUTATION = gql`
  mutation CreateOrEditExpertProfileMutation($input: CreateOrEditExpertProfileMutationInput!) {
    createOrEditExpertProfileMutation(input: $input) {
      expert {
        ...ExpertFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${EXPERT_FIELDS}
`;

export const GENERATE_PRESIGNED_URL_FOR_USER_UPLOAD_MUTATION = gql`
  mutation GeneratePresignedUrlForUserUploadMutation($fileName: String, $public: Boolean) {
    generatePresignedUrlForUserUploadMutation(fileName: $fileName, public: $public) {
      fields
      url
      fileUrl
    }
  }
`;

export const SAVE_REVIEW = gql`
  mutation SaveReview($input: SaveReviewMutationInput!) {
    saveReviewMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const CONTACT_EXPERT_FIELDS = gql`
  fragment ContactExpertFields on ContactExpertType {
    id
    firstName
    lastName
    email
    phoneNumber
    message
    preferredMethodOfContact
    consultationType
    created
    responded
  }
`;

export const CALLBACK_REQUESTS_QUERY = gql`
  query CallbackRequestsQuery($page: Int, $orderBy: String, $search: String) {
    contactExpertsPagination(page: $page, orderBy: $orderBy, search: $search) {
      items {
        ...ContactExpertFields
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
      }
    }
  }
  ${CONTACT_EXPERT_FIELDS}
`;

export const TOGGLE_RESPONDED_TO_CALLBACK_REQUEST_MUTATION = gql`
  mutation ToggleRespondedToCallbackRequestMutation($id: ID!) {
    toggleRespondedToCallbackRequestMutation(id: $id) {
      contactExpert {
        ...ContactExpertFields
      }
    }
  }
  ${CONTACT_EXPERT_FIELDS}
`;

export const ARCHIVE_CALLBACK_REQUEST_MUTATION = gql`
  mutation ArchiveCallbackRequestMutation($id: ID!) {
    archiveContactExpertMutation(id: $id) {
      ok
    }
  }
`;
