export default function ThinPlus({ color, size, style }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.8182L11.8182 11.8182L11.8182 20L8.18182 20L8.18182 11.8182L-1.11264e-06 11.8182L-9.53685e-07 8.18182L8.18365 8.18182L8.18182 -5.16589e-07L11.8182 -3.57639e-07L11.82 8.18182L20 8.18182L20 11.8182Z"
        fill={color}
      />
    </svg>
  );
}

ThinPlus.defaultProps = {
  size: 20,
  color: "black"
};
