import { createContext, useContext, useMemo } from "react";

import { FormProvider, useWatch } from "react-hook-form";

import Box from "components/Box";
import { CHECKOUT_MUTATION, OPEN_ORDER_QUERY, ORDER_QUERY } from "graphql/shop";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ControlledFormField from "tpo/ControlledFormField";
import ControlledRadioGroup from "tpo/ControlledRadioGroup";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import { Error } from "tpo/InputWrapper";
import ShippingCountrySelect from "tpo/ShippingCountrySelect";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

function initializeGuestCheckoutForm({ order }) {
  return {
    id: order.id,

    // shipping fields
    shippingFirstName: "",
    shippingLastName: "",
    email: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingTownCity: "",
    shippingPostalCode: "",
    shippingPhone: "",
    shippingCountry: "",

    // decides whether to show billing fields
    useShippingForBilling: true,

    // billing fields
    billingFirstName: "",
    billingLastName: "",
    billingAddress: "",
    billingTownCity: "",
    billingPostalCode: "",
    billingCountry: "",

    // depends on value of shippingCountry
    internationalShippingConsent: false,

    ageConfirmation: false,
    // TODO - add marketing consent field to the server side form
    // marketingConsent: false,

    whereDidYouHearAboutUs: "",
    // old checkout form conditionally shows this field based on answer to above but this one is always available
    whereDidYouHearAboutUsFurther: ""
  };
}

function initializeAuthenticatedCheckoutForm({ order }) {
  return {
    id: order.id,

    // shipping fields
    shippingFirstName: "",
    shippingLastName: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingTownCity: "",
    shippingPostalCode: "",
    shippingPhone: "",
    shippingCountry: "",

    // decides whether to show billing fields
    useShippingForBilling: true,

    // billing fields
    billingFirstName: "",
    billingLastName: "",
    billingAddress: "",
    billingTownCity: "",
    billingPostalCode: "",
    billingCountry: "",

    // depends on value of shippingCountry
    internationalShippingConsent: false,

    ageConfirmation: false,
    // TODO - add marketing consent field to the server side form
    // marketingConsent: false,

    whereDidYouHearAboutUs: "",
    // old checkout form conditionally shows this field based on answer to above but this one is always available
    whereDidYouHearAboutUsFurther: ""
  };
}

function initializeGuestChargelessCheckoutForm({ order }) {
  return {
    id: order.id,

    // shipping fields
    shippingFirstName: "",
    shippingLastName: "",
    email: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingTownCity: "",
    shippingPostalCode: "",
    shippingPhone: "",
    shippingCountry: "",

    // depends on value of shippingCountry
    internationalShippingConsent: false,

    ageConfirmation: false,
    // TODO - add marketing consent field to the server side form
    // marketingConsent: false,

    whereDidYouHearAboutUs: "",
    // old checkout form conditionally shows this field based on answer to above but this one is always available
    whereDidYouHearAboutUsFurther: ""
  };
}

function initializeAuthenticatedChargelessCheckoutForm({ order }) {
  return {
    id: order.id,

    // shipping fields
    shippingFirstName: "",
    shippingLastName: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingTownCity: "",
    shippingPostalCode: "",
    shippingPhone: "",
    shippingCountry: "",

    // depends on value of shippingCountry
    internationalShippingConsent: false,

    ageConfirmation: false,
    // TODO - add marketing consent field to the server side form
    // marketingConsent: false,

    whereDidYouHearAboutUs: "",
    // old checkout form conditionally shows this field based on answer to above but this one is always available
    whereDidYouHearAboutUsFurther: ""
  };
}

export const CheckoutFormContext = createContext();

export function useCheckoutFormContext() {
  return useContext(CheckoutFormContext);
}

function InternationalShippingConsent() {
  const {
    methods: { control }
  } = useCheckoutFormContext();

  const shippingCountry = useWatch({
    control,
    name: "shippingCountry"
  });

  if (!shippingCountry || shippingCountry === "United Kingdom") return null;

  return (
    <ControlledFormField
      name="internationalShippingConsent"
      Component={Checkbox}
      label="I understand I am responsible for any cost related to local custom clearance regulation"
    />
  );
}

function BillingFieldset() {
  const {
    methods: { control }
  } = useCheckoutFormContext();

  const useShippingForBilling = useWatch({
    control,
    name: "useShippingForBilling"
  });

  if (useShippingForBilling) return null;

  return (
    <FormControl
      as="fieldset"
      label={
        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
          Billing details
        </Box>
      }
      fields={
        <>
          <ControlledFormField
            name="billingFirstName"
            label="Billing First Name"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="billingLastName"
            label="Billing Last Name"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="billingAddress"
            label="Billing Address"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="billingTownCity"
            label="Billing Town / City"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="billingPostalCode"
            label="Billing Postal Code"
            Component={FloatingLabelInput}
          />
          <ControlledFormField name="billingCountry" Component={ShippingCountrySelect} />
        </>
      }
    />
  );
}

function NonUkShippingNotice() {
  const {
    methods: { control }
  } = useCheckoutFormContext();

  const shippingCountry = useWatch({
    control,
    name: "shippingCountry"
  });

  if (!shippingCountry || shippingCountry === "United Kingdom") return null;

  return (
    <Box fontFamily="gilroyBold" fontSize={16}>
      All our products are shipped from the UK and will be sent “DAP – Delivery at place”. We will
      arrange for our courier to deliver to your specified address, but please be aware that you as
      the buyer will be responsible for any cost related to local custom clearance regulations.
    </Box>
  );
}

function ShippingFieldset({ billingFieldsetSupported, includeEmailField }) {
  return (
    <FormControl
      as="fieldset"
      label={
        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
          Shipping details
        </Box>
      }
    >
      <Stack gap={40}>
        <Stack gap={20}>
          <ControlledFormField
            name="shippingFirstName"
            label="First name"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="shippingLastName"
            label="Last name"
            Component={FloatingLabelInput}
          />
          {includeEmailField && (
            <ControlledFormField name="email" label="Email" Component={FloatingLabelInput} />
          )}
          <ControlledFormField
            name="shippingAddress1"
            label="Address Line 1"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="shippingAddress2"
            label="Address Line 2"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="shippingTownCity"
            label="Town"
            Component={FloatingLabelInput}
          />
          <ControlledFormField
            name="shippingPostalCode"
            label="Postcode"
            Component={FloatingLabelInput}
          />
          <ControlledFormField name="shippingPhone" label="Phone" Component={FloatingLabelInput} />
        </Stack>
        <ControlledFormField name="shippingCountry" Component={ShippingCountrySelect} />
        <NonUkShippingNotice />
        <InternationalShippingConsent />
        {billingFieldsetSupported && (
          <ControlledFormField
            name="useShippingForBilling"
            Component={Checkbox}
            label="Use shipping details for billing address"
          />
        )}
        <ControlledFormField
          name="ageConfirmation"
          Component={Checkbox}
          label="I confirm that the recipient of this test is over 18"
        />
      </Stack>
    </FormControl>
  );
}

function WhereDidYouHearAboutUsFieldset() {
  return (
    <FormControl
      as="fieldset"
      label={
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Where did you hear about us?
        </Box>
      }
      fields={
        <>
          <ControlledRadioGroup
            name="whereDidYouHearAboutUs"
            values={[
              {
                label: "Social Media",
                value: "Social Media"
              },
              {
                label: "Campaign",
                value: "Campaign"
              },
              {
                label: "Search",
                value: "Search"
              },
              {
                label: "Word Of Mouth",
                value: "Word Of Mouth"
              },
              {
                label: "Practitioner",
                value: "Practitioner"
              },
              {
                label: "Influencer",
                value: "Influencer"
              }
            ]}
            Container={Stack}
            containerProps={{
              gap: 20
            }}
          />
          <ControlledFormField
            name="whereDidYouHearAboutUsFurther"
            label="Please provide additional details"
            Component={FloatingLabelInput}
          />
        </>
      }
    />
  );
}

function SubmitButtonWithNonFieldError() {
  const { nonFieldError } = useCheckoutFormContext();

  return (
    <Center stacked gap={20}>
      <ButtonV2 color="green" type="submit" size={["sm", "sm", "md"]}>
        continue to checkout
      </ButtonV2>
      {!!nonFieldError && <Error className="nonFieldError" error={nonFieldError} />}
    </Center>
  );
}

export function GuestCheckoutForm() {
  const { methods, onSubmit } = useCheckoutFormContext();
  return (
    <FormProvider {...methods}>
      <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)} gap={40}>
        <ShippingFieldset billingFieldsetSupported includeEmailField />
        <BillingFieldset />
        <WhereDidYouHearAboutUsFieldset />
        <SubmitButtonWithNonFieldError />
      </Stack>
    </FormProvider>
  );
}

export function AuthenticatedCheckoutForm() {
  const { methods, onSubmit } = useCheckoutFormContext();
  return (
    <FormProvider {...methods}>
      <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)} gap={40}>
        <ShippingFieldset billingFieldsetSupported />
        <BillingFieldset />
        <WhereDidYouHearAboutUsFieldset />
        <SubmitButtonWithNonFieldError />
      </Stack>
    </FormProvider>
  );
}

export function GuestChargelessCheckoutForm() {
  const { methods, onSubmit } = useCheckoutFormContext();
  return (
    <FormProvider {...methods}>
      <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)} gap={40}>
        <ShippingFieldset billingFieldsetSupported={false} includeEmailField />
        <WhereDidYouHearAboutUsFieldset />
        <SubmitButtonWithNonFieldError />
      </Stack>
    </FormProvider>
  );
}

export function AuthenticatedChargelessCheckoutForm() {
  const { methods, onSubmit } = useCheckoutFormContext();
  return (
    <FormProvider {...methods}>
      <Stack as="form" onSubmit={methods.handleSubmit(onSubmit)} gap={40}>
        <ShippingFieldset billingFieldsetSupported={false} />
        <WhereDidYouHearAboutUsFieldset />
        <SubmitButtonWithNonFieldError />
      </Stack>
    </FormProvider>
  );
}

function useCheckoutForm({ order, init }) {
  const mutationOptions = useMemo(
    () => ({
      refetchQueries: [
        { query: OPEN_ORDER_QUERY },
        {
          query: ORDER_QUERY,
          variables: {
            id: order.id
          }
        }
      ]
    }),
    [order.id]
  );

  const api = useDjangoGraphqlForm({
    mutation: CHECKOUT_MUTATION,
    mutationOptions,
    mutationName: "checkoutMutation",
    defaultValues: init({ order })
  });

  return api;
}

export function GuestCheckoutFormProvider({ children, order }) {
  const api = useCheckoutForm({ order, init: initializeGuestCheckoutForm });
  return <CheckoutFormContext.Provider value={api}>{children}</CheckoutFormContext.Provider>;
}

export function AuthenticatedUserCheckoutFormProvider({ children, order }) {
  const api = useCheckoutForm({ order, init: initializeAuthenticatedCheckoutForm });
  return <CheckoutFormContext.Provider value={api}>{children}</CheckoutFormContext.Provider>;
}

export function GuestChargelessCheckoutFormProvider({ children, order }) {
  const api = useCheckoutForm({ order, init: initializeGuestChargelessCheckoutForm });
  return <CheckoutFormContext.Provider value={api}>{children}</CheckoutFormContext.Provider>;
}

export function AuthenticatedUserChargelessCheckoutFormProvider({ children, order }) {
  const api = useCheckoutForm({ order, init: initializeAuthenticatedChargelessCheckoutForm });
  return <CheckoutFormContext.Provider value={api}>{children}</CheckoutFormContext.Provider>;
}
