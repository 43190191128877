import Box from "components/Box";
import Image from "components/Image";
import { theme } from "core/theme";
import padlockImage from "images/tpo/padlock.svg";
import Center from "tpo/Center";
import Spacer from "tpo/Spacer";

export default function UnapprovedPanel() {
  return (
    <Center
      bg="supplements"
      pt={theme.spacing.section.pt}
      pb={theme.spacing.section.pb}
      px={20}
      data-component-name="UnlockNewAudiences"
    >
      <Center maxWidth={760} stacked gap={40}>
        <Image src={padlockImage} height={70} width={70} />
        <Box>
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Unlock new audiences
          </Box>
          <Spacer py={30 / 2} />
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            If you would like to become a recommended practitioner and featured within the Omnos
            platform for new patients to discover, please reach out to{" "}
            <span
              style={{
                whiteSpace: "nowrap"
              }}
            >
              support@omnos.me
            </span>{" "}
            to proceed.
          </Box>
        </Box>
      </Center>
    </Center>
  );
}
