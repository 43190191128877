import Box from "./Box";

const Divider = ({ color, ...props }) => <Box {...props} bg={color} />;

Divider.defaultProps = {
  color: "rgba(0,0,0,0.1)",
  width: "100%",
  height: "1px"
};

export default Divider;
