import Box from "./Box";
import { CustomerAccountPage } from "./CustomerPage";

const textProps = {
  fontFamily: "gilroyMedium",
  fontSize: [14, 14, 16]
};

export default function CannotFindInvite({ color, headerBg, title, message }) {
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title,
        bg: headerBg,
        color
      }}
    >
      <Box {...textProps} pt={40} data-testid="cannotFindInvite">
        {message}
      </Box>
    </CustomerAccountPage>
  );
}

CannotFindInvite.defaultProps = {
  color: "dark",
  headerBg: "partners",
  title: "Organisation invite not found"
};
