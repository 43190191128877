import { useParams } from "react-router-dom";

import { theme } from "core/theme";
import Stack from "tpo/Stack";

import OrganisationTabs from "../OrganisationTabs";
import OrganisationOrderDetails from "./shared/OrganisationOrderDetails";

export default function OrganisationViewOrder() {
  // get the orderId url param and fetch the order
  const { orderId } = useParams();

  return (
    <>
      <OrganisationTabs selectedTab="orders" />
      <Stack
        maxWidth={1538}
        mx="auto"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, "5.5vw"]}
        gap={[20, 20, 40]}
      >
        {orderId && <OrganisationOrderDetails id={orderId} />}
      </Stack>
    </>
  );
}
