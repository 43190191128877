import { useCallback, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { SAVE_SHIPPING_ADDRESS_AT_CHECKOUT_MUTATION } from "graphql/organisations/mutations";
import { NON_FIELD_ERROR_KEY } from "hooks/form/useDjangoGraphqlForm";
import useScrollToError from "hooks/form/useScrollToError";
import useShippingCountries from "hooks/useShippingCountries";

const SHIPPING_ADDRESS_PREFIX = "shippingAddress";
// At the time of writing these fields are unique to the shipping address form
const SHIPPING_ADDRESS_FIELDS = ["id", "name", "line_1", "line_2", "city", "postcode", "country"];

export default function useShippingAddressAndFinalForm({ formApi, mutation, submitMutation }) {
  const [scrollToError, setScrollToError] = useState(0);

  const { basket } = useOrganisationBasketContext();

  const shippingCountries = useShippingCountries();

  const [shippingAddressMutation, { loading }] = useMutation(
    SAVE_SHIPPING_ADDRESS_AT_CHECKOUT_MUTATION
  );

  const shippingAddressId = formApi.watch("shippingAddress.id");

  const haveExistingShippingAddress = !!basket?.shippingAddress && !!shippingAddressId;
  const [editShippingAddress, setEditShippingAddress] = useState(false);

  const showButtonsForShippingAddressForm = haveExistingShippingAddress && editShippingAddress;
  const shippingAddressFormEditable =
    showButtonsForShippingAddressForm || !haveExistingShippingAddress;

  const shippingAddressSubmitButtonRef = useRef();
  const checkoutSubmitButtonRef = useRef();

  const history = useHistory();

  const { setError, reset, watch } = formApi;

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (type === "change" && name === "shippingAddress.country" && !data.shippingAddress.id) {
        // If the shipping address country changes and we don't have a id we need to create the shipping address
        // now, on the fly, and store against the basket, so that the locale prices update correctly.
        // removed check for basket.payee as we need to update regardless for shipping address.

        const input = {
          ...data.shippingAddress,
          line1: data.shippingAddress.line_1,
          line2: data.shippingAddress.line_2,
          organisation: data.organisation,
          basket: basket.id
        };

        delete input.line_1;
        delete input.line_2;

        shippingAddressMutation({
          variables: {
            input
          }
        })
          .then(resp => {
            const errors = resp.data.saveShippingAddressAtCheckoutMutation.errors;
            if (errors.length) {
              errors.forEach(error => {
                setError(
                  error.field === "__all__"
                    ? NON_FIELD_ERROR_KEY
                    : `${SHIPPING_ADDRESS_PREFIX}.${error.field}`,
                  {
                    type: "custom",
                    message: error.messages[0]
                  }
                );
              });
            } else {
              // a successful mutation means the basket has a new shipping address
              // so the component using this hook should have a useEffect watching the basket
              // which will reset the form
              setEditShippingAddress(true);
            }
          })
          .catch(e => {
            console.log("Error updating shipping address", e);
          });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, basket?.id, shippingAddressMutation, reset, setError, basket?.payee]);

  const onSubmit = useCallback(
    formValues => {
      if (haveExistingShippingAddress && editShippingAddress) {
        const input = {
          ...formValues.shippingAddress,
          line1: formValues.shippingAddress.line_1,
          line2: formValues.shippingAddress.line_2,
          organisation: formValues.organisation,
          basket: basket.id
        };

        delete input.line_1;
        delete input.line_2;

        shippingAddressMutation({
          variables: {
            input
          }
        })
          .then(resp => {
            const errors = resp.data.saveShippingAddressAtCheckoutMutation.errors;
            if (errors.length) {
              errors.forEach(error => {
                formApi.setError(
                  error.field === "_All__"
                    ? NON_FIELD_ERROR_KEY
                    : `${SHIPPING_ADDRESS_PREFIX}.${error.field}`,
                  {
                    type: "custom",
                    message: error.messages[0]
                  }
                );
              });
              shippingAddressSubmitButtonRef.current?.setSuccessful(false);
              shippingAddressSubmitButtonRef.current?.setPending(false);
            } else {
              shippingAddressSubmitButtonRef.current?.setSuccessful(true);
              shippingAddressSubmitButtonRef.current?.setPending(false);
              formApi.reset({
                ...formValues
              });
              setEditShippingAddress(false);
            }
          })
          .catch(e => {
            console.log("Error updating shipping address", e);
          });
      } else {
        const input = {
          ...formValues,
          shippingAddress: JSON.stringify({
            ...formValues[SHIPPING_ADDRESS_PREFIX]
          })
        };

        delete input["acknowledge"]; // sometimes present, sometimes not

        submitMutation({
          variables: {
            input
          }
        })
          .then(resp => {
            const errors = resp.data[mutation.mutationName].errors;
            if (errors.length) {
              errors.forEach(error => {
                formApi.setError(
                  error.field === "_All__"
                    ? NON_FIELD_ERROR_KEY
                    : SHIPPING_ADDRESS_FIELDS.includes(error.field)
                    ? `${SHIPPING_ADDRESS_PREFIX}.${error.field}`
                    : error.field,
                  {
                    type: "custom",
                    message: error.messages[0]
                  }
                );

                checkoutSubmitButtonRef.current?.setSuccessful(false);
                checkoutSubmitButtonRef.current?.setPending(false);
                setScrollToError(v => v + 1);
              });
            } else {
              checkoutSubmitButtonRef.current?.setSuccessful(true);
              checkoutSubmitButtonRef.current?.setPending(false);
              mutation.handleSuccess?.({
                formResponseRelated: resp,
                history,
                setScrollToError
              });
            }
          })
          .catch(e => {
            console.log("Error checking out", e);
          });
      }
    },
    [
      setEditShippingAddress,
      shippingAddressMutation,
      haveExistingShippingAddress,
      editShippingAddress,
      mutation,
      submitMutation,
      formApi,
      history,
      basket,
      setScrollToError
    ]
  );

  useScrollToError({
    scrollToError
  });

  return {
    haveExistingShippingAddress,
    editShippingAddress,
    setEditShippingAddress,
    showButtonsForShippingAddressForm,
    shippingAddressFormEditable,
    shippingAddressSubmitButtonRef,
    onSubmit,
    shippingCountries,
    SHIPPING_ADDRESS_PREFIX,
    loading
  };
}
