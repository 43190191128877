import { FormProvider } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import { useAuthContext } from "components/AuthProvider";
import { CustomerAccountPage } from "components/CustomerPage";
import DataLoader from "components/DataLoader";
import {
  DASHBOARD_HOME_URL,
  LOGIN_URL,
  SIGNUP_COMPLETE_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL
} from "core/urls";
import {
  ACCEPT_PROFESSIONAL_LINK_INVITATION_MUTATION,
  PARTNER_LINK_CODE_QUERY
} from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import Checkbox from "tpo/Checkbox";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import { Error } from "tpo/InputWrapper";
import LinkWrapper from "tpo/LinkWrapper";
import FloatingLabelPasswordInput from "tpo/PasswordInput";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import Box from "./Box";
import { ExternalTextLink } from "./Links";
import { SpanText } from "./Text";

const textProps = {
  fontFamily: "gilroyMedium",
  fontSize: [14, 14, 16]
};

function CannotFindInvite() {
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Invitation not found"
      }}
    >
      <Box {...textProps} pt={40}>
        I'm sorry, we can't seem to find that invite. Please contact support@omnos.me.
      </Box>
    </CustomerAccountPage>
  );
}

function InviteFound({ organisation, partnerLinkCode, identifier }) {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthContext();

  const api = useDjangoGraphqlForm({
    mutation: ACCEPT_PROFESSIONAL_LINK_INVITATION_MUTATION,
    mutationName: "acceptProfessionalLinkInvitationMutation",
    handleSuccess: () => {
      history.push(user ? DASHBOARD_HOME_URL : SIGNUP_COMPLETE_URL);
    },
    defaultValues: {
      platformUserFirstName: "",
      platformUserLastName: "",
      platformUserEmail: "",
      password: "",
      resultsAccessGranted: false,
      acceptTerms: false
    },
    transformer: data => ({
      ...data,
      partnerLinkCode,
      organisation
    })
  });

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: user ? "Results access request" : "Welcome"
      }}
    >
      {user && (
        <Box {...textProps}>
          You've been invited to share your results with an Omnos Practitioner.
        </Box>
      )}
      {!user && (
        <Box {...textProps}>
          You've been invited to join the Omnos platform. Please set a password to begin your
          journey.
        </Box>
      )}
      {!user && (
        <Box {...textProps} pt={20}>
          Already a user? Click{" "}
          <SpanText
            as={LinkWrapper}
            // todo - check next param is working
            to={`${LOGIN_URL}?next=${location.pathname}`}
            color="purple"
            underline
          >
            here
          </SpanText>{" "}
          to login
        </Box>
      )}
      <Box pt={user ? 20 : 40}>
        <FormProvider {...api.methods}>
          <Stack
            mx="auto"
            maxWidth={760}
            as="form"
            onSubmit={api.methods.handleSubmit(api.onSubmit)}
            gap={20}
          >
            {!user && (
              <ControlledFormField
                Component={FloatingLabelInput}
                name="platformUserEmail"
                label="Email"
              />
            )}
            {!user && (
              <ControlledFormField
                Component={FloatingLabelPasswordInput}
                name="password"
                label="Password"
              />
            )}
            {!user && (
              <ControlledFormField
                Component={FloatingLabelInput}
                name="platformUserFirstName"
                label="First name"
              />
            )}
            {!user && (
              <ControlledFormField
                Component={FloatingLabelInput}
                name="platformUserLastName"
                label="Last name"
              />
            )}
            <ControlledFormField
              Component={Checkbox}
              name="resultsAccessGranted"
              label={`I'm happy for ${identifier} to have access to my results`}
              styles={{
                inputWrapperProps: {
                  mt: 20
                }
              }}
            />
            {!user && (
              <ControlledFormField
                Component={Checkbox}
                name="acceptTerms"
                label={
                  <span>
                    I have read and agree to the{" "}
                    <ExternalTextLink href={TERMS_OF_SERVICE_URL}>
                      terms of service
                    </ExternalTextLink>
                    {" and the "}
                    <ExternalTextLink href={TERMS_OF_USE_URL}>terms of use</ExternalTextLink>
                  </span>
                }
              />
            )}
            <ButtonV2 color="green" sizes={["sm", "sm", "md"]} mx="auto" mt={20}>
              submit
            </ButtonV2>
            {!!api.nonFieldError && <Error error={api.nonFieldError} />}
          </Stack>
        </FormProvider>
      </Box>
    </CustomerAccountPage>
  );
}

export default function AcceptPartnerLinkInvite(props) {
  useDocTitle("Accept partner invite");

  return (
    <DataLoader
      query={PARTNER_LINK_CODE_QUERY}
      variables={{
        partnerLinkCode: props.match.params.partnerLinkCode
      }}
      render={({ checkIncomingPartnerLinkCode }) => {
        if (checkIncomingPartnerLinkCode.hashId === null) {
          return <CannotFindInvite />;
        }

        return (
          <InviteFound
            partnerLinkCode={props.match.params.partnerLinkCode}
            identifier={checkIncomingPartnerLinkCode.identifier}
            organisation={props.match.params.organisation}
          />
        );
      }}
    />
  );
}
