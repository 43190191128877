import { forwardRef } from "react";

import { useQuery } from "@apollo/client";
import { SHIPPING_COUNTRIES_QUERY } from "graphql/shop";

import NativeSelect from "./NativeSelect";

const ShippingCountrySelect = forwardRef(
  (
    {
      placeholder,
      onChange,
      value,
      error,
      label,
      name,
      getOptions,
      fontSize,
      valueField,
      fullWidth,
      ...wrapperStyles
    },
    ref
  ) => {
    const { data, loading } = useQuery(SHIPPING_COUNTRIES_QUERY);

    if (loading) {
      return (
        <NativeSelect
          name={name}
          ref={ref}
          onChange={onChange}
          value={value}
          error={error}
          label={label}
          fontSize={fontSize}
          styles={{
            select: {
              fontSize: [14, 14, 16]
            },
            wrapper: {
              maxWidth: fullWidth ? null : 350,
              ...wrapperStyles
            }
          }}
        />
      );
    }

    let options;
    if (getOptions) {
      options = getOptions(data?.shippingCountries);
    } else {
      const shippingCountries = data?.shippingCountries.map(item => ({
        value: item[valueField],
        label: item.name
      }));

      const ukOnly = shippingCountries.filter(item => item.label === "United Kingdom");
      const rest = shippingCountries.filter(item => item.label !== "United Kingdom");

      options = [...ukOnly, ...rest.sort(({ value: v1 }, { value: v2 }) => v1.localeCompare(v2))];
    }

    return (
      <NativeSelect
        name={name}
        ref={ref}
        onChange={onChange}
        value={value}
        error={error}
        label={label}
        styles={{
          select: {
            fontSize: [14, 14, 16]
          },
          wrapper: {
            maxWidth: fullWidth ? null : 350,
            ...wrapperStyles
          }
        }}
      >
        <option value="">{placeholder}</option>
        {options?.map((item, idx) => (
          <option key={`${item.value}-${idx}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </NativeSelect>
    );
  }
);

ShippingCountrySelect.defaultProps = {
  placeholder: "Select a country",
  valueField: "name"
};

export default ShippingCountrySelect;
