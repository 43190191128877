import { useContext } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import DataLoader from "components/DataLoader";
import { REVIEW_STATUS_APPROVED } from "core/constants";
import { theme } from "core/theme";
import { getClientManagementUserTestUrl } from "core/urls";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import Stack from "tpo/Stack";
import TestStatuses from "tpo/TestStatuses";
import { GenesTestContent, GET_DISPLAY_GROUPS, NonGenesTestContent } from "tpo/UserTest";
import { ViewerContext } from "tpo/Viewer";

function GenesTest({ userTest }) {
  const { userId } = useContext(ViewerContext);

  return (
    <DataLoader
      query={GET_DISPLAY_GROUPS}
      variables={{
        userId,
        userTestId: userTest.id
      }}
      render={({ userTest: { resultDisplayGroups } }) => (
        <GenesTestContent
          clientId={userId}
          userTest={userTest}
          resultDisplayGroups={resultDisplayGroups}
        />
      )}
    />
  );
}

export default function Tests({ userTests, userTest }) {
  const { userId } = useContext(ViewerContext);
  const history = useHistory();

  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: theme.spacing.section.pt,
          px: 20,
          pb: theme.spacing.section.pb
        }}
      >
        <Stack gap={40}>
          <PanelBoxV2 maxWidth={[464, 464, 464, 500]}>
            {userTests.length ? (
              <TestStatuses
                heading="Available tests"
                ordersInProgress={[]}
                testResults={userTests}
                handleSelect={selected => {
                  return history.push(getClientManagementUserTestUrl(userId, selected.id));
                }}
                defaultOpen={false}
              />
            ) : (
              <TestStatuses heading="No tests" />
            )}
          </PanelBoxV2>
          {!!userTest && (
            <Center>
              <Box>
                <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]} textAlign="center">
                  {userTest?.name}
                </Box>
                <Box textAlign="center">
                  <Box as="span" fontFamily="gilroyBold">
                    Sample taken:
                  </Box>
                  <Box as="span" fontFamily="gilroyMedium" fontSize={16} ml={1}>
                    {userTest?.sampleCollectedDateFormatted}
                  </Box>
                </Box>
                <Box textAlign="center">
                  <Box as="span" fontFamily="gilroyBold">
                    Released to client:
                  </Box>
                  <Box as="span" fontFamily="gilroyMedium" fontSize={16} ml={1}>
                    {userTest?.reviewStatus === REVIEW_STATUS_APPROVED ? (
                      <Box as="span" color="green">
                        ✓
                      </Box>
                    ) : (
                      <Box as="span" color="red">
                        ⨯
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Center>
          )}
        </Stack>
        <Box py={[15, 15, 20]} />
        {!!userTest &&
          (userTest.isGenesTest ? (
            <GenesTest userTest={userTest} />
          ) : (
            <NonGenesTestContent
              userTest={userTest}
              showFullExplanation={false}
              clientId={userId}
            />
          ))}
      </PanelBoxV2>
    </>
  );
}
