import { createContext, useCallback, useContext, useMemo, useState } from "react";

import { useApolloClient } from "@apollo/client";

import { useOrganisationBasketContext } from "./organisations/OrganisationBasketContext";

export const TestProductOptionContext = createContext();

export function TestProductOptionProvider({
  children,
  testProductOptionFragment,
  testProductOptionFragmentName,
  testProductOptionFragmentType
}) {
  const [selectedTestProductOptionId, setSelectedTestProductOptionId] = useState();

  /**
   * Read from cache because the detail view query should have all the product option
   * details on it.  Product option can't be chosen without this detail query running first.
   */

  const selectTestProductOptionId = useCallback(
    value => {
      setSelectedTestProductOptionId(
        value?.id && value?.paymentIsoCode
          ? JSON.stringify({
              id: value.id,
              paymentIsoCode: value.paymentIsoCode,
              organisation: value.organisation // this could be null in the case of the consumer shop
            })
          : value
      );
    },
    [setSelectedTestProductOptionId]
  );

  const client = useApolloClient();

  const testProductOption = client.readFragment({
    fragment: testProductOptionFragment,
    id: `${testProductOptionFragmentType}:${selectedTestProductOptionId}`,
    fragmentName: testProductOptionFragmentName
  });

  const api = useMemo(
    () => ({
      selectedTestProductOptionId,
      selectTestProductOptionId,
      testProductOption
    }),
    [testProductOption, selectTestProductOptionId, selectedTestProductOptionId]
  );

  return (
    <TestProductOptionContext.Provider value={api}>{children}</TestProductOptionContext.Provider>
  );
}

export function useTestProductOptionContext() {
  return useContext(TestProductOptionContext);
}
