import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ERROR_404_URL, PARTNERS_ORGANISATIONS_ROOT_URL } from "core/urls";

import Payment from "./Payment";
import SetUp from "./SetUp";
import Settings from "./Settings";
import TeamMembers from "./TeamMembers";

export default function Routes() {
  const match = useRouteMatch();

  const { organisation } = useOrganisationContext();

  return (
    <Switch>
      <Route exact path={match.path}>
        {organisation?.onboardingStatus === "complete" ? (
          <Redirect to={`${match.path}/payment`} />
        ) : (
          <SetUp />
        )}
      </Route>
      <Route exact path={`${match.path}/payment`}>
        {organisation?.onboardingStatus !== "complete" ? (
          <Redirect to={PARTNERS_ORGANISATIONS_ROOT_URL} />
        ) : (
          <Payment />
        )}
      </Route>
      <Route exact path={`${match.path}/settings`}>
        {organisation?.onboardingStatus !== "complete" ? (
          <Redirect to={PARTNERS_ORGANISATIONS_ROOT_URL} />
        ) : (
          <Settings />
        )}
      </Route>
      <Route exact path={`${match.path}/team_members`}>
        {organisation?.onboardingStatus !== "complete" ? (
          <Redirect to={PARTNERS_ORGANISATIONS_ROOT_URL} />
        ) : (
          <TeamMembers />
        )}
      </Route>
      <Redirect to={ERROR_404_URL} />
    </Switch>
  );
}
