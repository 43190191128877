import { forwardRef } from "react";

import InputWrapper from "tpo/InputWrapper";

import Box from "./Box";

const Textarea = forwardRef(
  ({ name, error, label, value, onChange, rows, placeholder, style, maxLength, ...props }, ref) => (
    <InputWrapper
      label={label}
      input={
        <Box
          as="textarea"
          ref={ref}
          name={name}
          onChange={onChange}
          value={value}
          rows={rows}
          placeholder={placeholder}
          style={style}
          maxLength={maxLength}
          {...props}
        />
      }
      error={error}
    />
  )
);

export default Textarea;
