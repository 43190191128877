import { useEffect, useRef, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ORGANISATION_BASKET_DETAIL_QUERY } from "graphql/organisations/queries/organisation";
import useShippingAddressAndFinalForm from "hooks/organisations/useShippingAddressAndFinalForm";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import NativeSelect from "tpo/NativeSelect";
import Stack from "tpo/Stack";
import ButtonV2, { IndicativeButton } from "v2/Buttons";

import CHECKOUT_MUTATIONS from "./CheckoutMutations";

const SHIPPING_ADDRESS_PREFIX = "shippingAddress";

function ShippingAddressAndFinalForm() {
  const { organisation } = useOrganisationContext();
  const { basket, updateBasketDetails } = useOrganisationBasketContext();

  const address1 = organisation?.primaryAddress;
  const address2 = organisation?.secondaryAddress;

  const addressesRef = useRef({
    address1: address1?.id,
    address2: address2?.id
  });

  addressesRef.current = {
    address1: address1?.id,
    address2: address2?.id
  };

  const [shippingAddressSelectValue, setShippingAddressSelectValue] = useState("address1");

  useEffect(() => {
    const foundShippingAddress = Object.entries(addressesRef.current).find(
      ([k, v]) => v === basket?.shippingAddress?.id
    )?.[0];

    if (foundShippingAddress) {
      setShippingAddressSelectValue(foundShippingAddress);
    }
  }, [setShippingAddressSelectValue, basket?.shippingAddress?.id]);

  const mutation = CHECKOUT_MUTATIONS.paymentInvoice;

  const [submitMutation, { loading }] = useMutation(mutation.mutation, {
    refetchQueries: [
      {
        // ensure that the client gets the new basket id after a successful checkout
        query: ORGANISATION_BASKET_DETAIL_QUERY,
        variables: {
          organisation: parseInt(organisation?.id)
        }
      }
    ]
  });

  const formApi = useForm({
    defaultValues: {
      [SHIPPING_ADDRESS_PREFIX]: {
        id: basket?.shippingAddress?.id || "",
        name: basket?.shippingAddress?.name || "",
        line_1: basket?.shippingAddress?.line1 || "",
        line_2: basket?.shippingAddress?.line2 || "",
        city: basket?.shippingAddress?.city || "",
        postcode: basket?.shippingAddress?.postcode || "",
        country: basket?.shippingAddress?.country?.isoCode || ""
      },
      organisation: organisation?.id || "",
      basket: basket?.id || "",
      addressForPatient: false,
      addressForOrganisation: true,
      addressForOrganisationPrimaryAddress: shippingAddressSelectValue === "address1"
    }
  });

  const {
    haveExistingShippingAddress,
    editShippingAddress,
    setEditShippingAddress,
    showButtonsForShippingAddressForm,
    shippingAddressFormEditable,
    shippingAddressSubmitButtonRef,
    onSubmit,
    shippingCountries,
    loading: shippingAddressLoading
  } = useShippingAddressAndFinalForm({
    formApi,
    mutation,
    submitMutation
  });

  useEffect(() => {
    if (basket?.id && organisation?.id) {
      formApi.reset({
        [SHIPPING_ADDRESS_PREFIX]: {
          id: basket?.shippingAddress?.id || "",
          name: basket?.shippingAddress?.name || "",
          line_1: basket?.shippingAddress?.line1 || "",
          line_2: basket?.shippingAddress?.line2 || "",
          city: basket?.shippingAddress?.city || "",
          postcode: basket?.shippingAddress?.postcode || "",
          country: basket?.shippingAddress?.country?.isoCode || ""
        },
        organisation: organisation?.id || "",
        basket: basket?.id || "",
        addressForPatient: false,
        addressForOrganisation: true,
        addressForOrganisationPrimaryAddress: shippingAddressSelectValue === "address1"
      });
    }
  }, [
    formApi,
    basket?.id,
    basket?.shippingAddress?.id,
    basket?.shippingAddress?.name,
    basket?.shippingAddress?.line1,
    basket?.shippingAddress?.line2,
    basket?.shippingAddress?.city,
    basket?.shippingAddress?.postcode,
    basket?.shippingAddress?.country?.isoCode,
    organisation?.id,
    shippingAddressSelectValue
  ]);

  return (
    <FormProvider {...formApi}>
      <Stack as="form" gap={[20, 20, 40]} onSubmit={formApi.handleSubmit(onSubmit)}>
        <PartnerDashboardBox data-component-name="ShippingAddressForm">
          <Stack gap={[24, 24, 28]}>
            <Group alignItems="flex-start" justifyContent="space-between">
              <Stack gap={20}>
                <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
                  Organisation shipping address
                </Box>
                <NativeSelect
                  onChange={e => {
                    setShippingAddressSelectValue(e.target.value);
                    updateBasketDetails({
                      shippingAddress:
                        Object.entries(addressesRef.current).find(
                          ([k, v]) => k === e.target.value
                        )?.[1] || ""
                    });
                  }}
                  value={shippingAddressSelectValue}
                  data-component-name="OrganisationAddressSelect"
                >
                  <option value={"address1"}>Address 1</option>
                  <option value={"address2"}>Address 2</option>
                </NativeSelect>
              </Stack>
              {haveExistingShippingAddress && (
                <ButtonV2
                  color="dark"
                  size={["xs", "xs", "sm"]}
                  sx={{
                    color: "white"
                  }}
                  onClick={() => {
                    setEditShippingAddress(!editShippingAddress);
                  }}
                  type="button"
                >
                  edit address
                </ButtonV2>
              )}
            </Group>
          </Stack>
          <Stack gap={20}>
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.name`}
              Component={FloatingLabelInput}
              label="Name"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.line_1`}
              Component={FloatingLabelInput}
              label="Address line 1"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.line_2`}
              Component={FloatingLabelInput}
              label="Address line 2"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.city`}
              Component={FloatingLabelInput}
              label="City"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.postcode`}
              Component={FloatingLabelInput}
              label="Postcode"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.country`}
              Component={NativeSelect}
              label=""
              readOnly={!shippingAddressFormEditable}
            >
              <option value="">Select country</option>
              {shippingCountries.map((country, idx) => (
                <option key={`${country.isoCode}-${idx}`} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </ControlledFormField>
          </Stack>
          {showButtonsForShippingAddressForm ? (
            <Center gap={20}>
              <ButtonV2
                type="button"
                color="red"
                rightIcon={<ChevronComponent />}
                onClick={() => {
                  formApi.reset();
                  setEditShippingAddress(false);
                }}
                size={["sm", "sm", "md"]}
              >
                Cancel
              </ButtonV2>
              <IndicativeButton
                rightIcon={<ChevronComponent />}
                ref={shippingAddressSubmitButtonRef}
                defaultColor="green"
                pendingColor="#0cf970"
                successColor="#2ecc71"
                failureColor="error"
                size={["sm", "sm", "md"]}
                disabled={loading || shippingAddressLoading}
              >
                save
              </IndicativeButton>
            </Center>
          ) : null}
        </PartnerDashboardBox>
        {mutation.renderFields({
          loading,
          canSubmit:
            !(haveExistingShippingAddress && editShippingAddress) &&
            (basket?.productLineItems.length > 0 || basket?.supplementLineItems.length > 0)
        })}
      </Stack>
    </FormProvider>
  );
}

export default function StockCheckoutForm() {
  return (
    <>
      <ShippingAddressAndFinalForm />
    </>
  );
}
