import { useHistory, useLocation, matchPath } from "react-router-dom";

import Box from "components/Box";
import { useHeaderContext } from "contexts/HeaderContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import {
  DASHBOARD_HOME_URL,
  PARTNERS_COMMISSIONS_URL,
  PARTNERS_ORDERS_URL,
  PARTNERS_ORGANISATIONS_ROOT_URL,
  PARTNERS_ORGANISATIONS_URL,
  PARTNERS_PROFILE_URL,
  PARTNERS_SHOP_URL,
  getPartnerShopTabUrl
} from "core/urls";
import useLogOut from "hooks/header/useLogOut";
import { ReactComponent as UserIcon } from "images/user.svg";
import Drawer from "tpo/Drawer";
import Group from "tpo/Group";
import Modal from "tpo/Modal";
import NavLink from "tpo/NavLink";
import { CloseButton } from "v2/Buttons";

export default function PractitionerDrawer({ bg }) {
  const { opened, close, onClick } = useHeaderContext();
  const { availableOrganisations, organisationId, setOrganisationId } = useOrganisationContext();
  const location = useLocation();
  const history = useHistory();

  const logout = useLogOut();

  return (
    <Modal
      maxWidth={700}
      bg="dark"
      show={opened}
      close={close}
      mode={[
        "fullScreen",
        "fullScreen",
        {
          topRightPosition: {
            x: [20, 20, "max(calc((100% - 1538px) / 2), 20px)"],
            y: 104
          }
        }
      ]}
      maxHeight="calc(100vh - 104px - 20px)"
      borderRadius={[0, 0, 10]}
      data-component-name="drawer"
      withOverlay={false}
    >
      <Box pr={[24, 24, 40]} pl={[40, 40, 60]} pt={[40, 40, 60]} pb={[60, 60, 80]}>
        <Group
          position="sticky"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          justifyContent="flex-end"
          height={60}
        >
          <CloseButton color="white" onClick={close} size={null} />
        </Group>
        <Box mt={[60]}>
          <NavLink
            fontSize={[18, 18, 24]}
            color="white"
            label="Account"
            leftIcon={<UserIcon fill="white" width={20} />}
          >
            <NavLink
              color="white"
              fontSize={[14, 14, 16]}
              label="Personal"
              onClick={() => {
                onClick();
                history.push(DASHBOARD_HOME_URL);
              }}
            />
            {availableOrganisations?.map(organisation => (
              <NavLink
                key={`organisation-${organisation.id}`}
                color="white"
                fontSize={[14, 14, 16]}
                // Rest of the code
                label={organisation.name}
                active={organisationId === organisation.id}
                onClick={() => {
                  setOrganisationId(organisation.id);
                  onClick();
                  history.push(PARTNERS_ORGANISATIONS_URL);
                }}
              />
            ))}
          </NavLink>
          <NavLink
            color="white"
            label="Profile"
            fontSize={[18, 18, 24]}
            active={location.pathname === PARTNERS_PROFILE_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_PROFILE_URL);
            }}
          />
          <NavLink
            color="white"
            label="Organisation"
            fontSize={[18, 18, 24]}
            active={location.pathname === PARTNERS_ORGANISATIONS_ROOT_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_ORGANISATIONS_ROOT_URL);
            }}
          />
          <NavLink
            color="white"
            label="Practitioner shop"
            fontSize={[18, 18, 24]}
            active={matchPath(location.pathname, {
              path: PARTNERS_SHOP_URL,
              exact: true
            })}
            onClick={() => {
              onClick();
              history.push(getPartnerShopTabUrl("tests"));
            }}
          />
          <NavLink
            color="white"
            label="Orders"
            fontSize={[18, 18, 24]}
            active={location.pathname === PARTNERS_ORDERS_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_ORDERS_URL);
            }}
          />
          <NavLink
            color="white"
            label="Commission"
            fontSize={[18, 18, 24]}
            active={location.pathname === PARTNERS_COMMISSIONS_URL}
            onClick={() => {
              onClick();
              history.push(PARTNERS_COMMISSIONS_URL);
            }}
          />
          <NavLink color="red" label="Logout" onClick={logout} fontSize={[18, 18, 24]} />
        </Box>
      </Box>
    </Modal>
  );
}
