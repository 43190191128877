import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";

import BasketErrorModal from "./BasketErrorModal";

export default function MultipleProductsErrorModal() {
  const { hasMultipleProductsError } = useOrganisationBasketContext();

  return (
    <BasketErrorModal
      explanation={`Oops! It seems you've added multiple quantities or options of the same product to your
      basket. To return to a patient basket, please remove the duplicate quantities or options.`}
      show={hasMultipleProductsError}
    />
  );
}
