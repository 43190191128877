import Box from "components/Box";
import { ICONS } from "contexts/Locale";
import Group from "tpo/Group";

export default function PatientLocation({ country, mt }) {
  const Icon = ICONS[country.isoCode];

  return (
    <Group gap={10} fontSize={[14, 14, 16]} alignItems="center" mt={mt}>
      {Icon && <Icon height={20} width={20} />}
      <Box fontFamily="gilroyMedium" fontSize="inherit">
        Patient location:
      </Box>
      <Box fontFamily="gilroyRegular" fontSize="inherit">
        {country.name}
      </Box>
    </Group>
  );
}
