import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";
import { useQuery } from "@apollo/client";
import Box from "components/Box";
import Loading from "components/Loading";
import { getOrderUrl } from "core/urls";
import { ORDER_QUERY } from "graphql/shop";
import useDocTitle from "hooks/use-doc-title";
import CheckoutTemplate from "tpo/shop/CheckoutTemplate";

export default function OrderProcessingPage({
  match: {
    params: { id }
  }
}) {
  useDocTitle("Order processing");
  const history = useHistory();
  const apolloClient = useApolloClient();
  const { data } = useQuery(ORDER_QUERY, {
    client: apolloClient,
    variables: { id },
    pollInterval: 1000
  });

  useEffect(() => {
    if (data !== undefined) {
      if (data.order !== undefined) {
        if (data.order.status === "Payment succeeded") {
          if (typeof window.gtag !== typeof undefined) {
            // https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce

            const testItemsData = data.order.testItems.map(item => {
              return {
                id: item.id,
                name: item.product.name,
                brand: "Google",
                category: "Tests",
                quantity: 1,
                price: item?.productOption?.rrpCurrentPrice || item?.product?.rrpCurrentPrice
              };
            });
            const supplementItemsData = data.order.supplementItems.map(item => {
              return {
                id: item.id,
                name: item.product.name,
                brand: "Google",
                category: "Supplements",
                quantity: 1,
                price: item.product.price
              };
            });

            const trackingData = {
              transaction_id: data.order.id,
              value: data.order.discountedTotal,
              currency: "GBP",
              shipping: data.order.postageCosts,
              items: [...testItemsData, ...supplementItemsData]
            };
            window.gtag("event", "purchase", trackingData);
          }
          history.push(getOrderUrl(id));
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <CheckoutTemplate title="Order processing">
      <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
        Payment processing, please wait...
      </Box>
      <Loading minHeight={50} />
    </CheckoutTemplate>
  );
}
