function convertOrderedLists(html) {
  // NB - this does not support nested ordered lists
  // Before addressing that we have the problem of how to even indent them with our custom boxes in the design

  return html.replace(
    /(?<opening><ol.*?>)(?<content>.*?)(?<closing><\/ol>)/g,
    function (matched, opening, content, closing) {
      return `${opening}${content.replace(
        /(?<opening><li.*?>)(?<content>.*?)(?<closing><\/li>)/g,
        "$1<div>$2</div>$3"
      )}${closing}`;
    }
  );
}

export default convertOrderedLists;
