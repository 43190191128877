import { forwardRef } from "react";

import Box from "../components/Box";
import Text, { SpanText } from "../components/Text";
import Section from "../deprecated/Section";
import { PanelBoxV2 } from "./Boxes";
import { HeadingExtraExtraSmall } from "./Headings";
import SelectCircle from "./QuestionnaireSelectCircle";

const getAnswers = answerConfig => {
  /**
   * Sometimes we have only 2 options, other times
   * more.
   */

  let answers = {};
  for (let key in answerConfig) {
    if (key.startsWith("answer")) {
      let m = key.match(/^answer([\d])+$/);
      let answerKey = m[1];
      let value = answerConfig[key];
      if (value) {
        answers[+answerKey] = value;
      }
    }
  }
  return answers;
};

export function Meta({ leftText, rightText }) {
  return (
    <Box display="flex" justifyContent={leftText ? "space-between" : "flex-end"} py={4}>
      {!!leftText && (
        <Box>
          <SpanText color="white" fontSize={[14, 14, 16]}>
            {leftText}
          </SpanText>
        </Box>
      )}
      <Box>
        <SpanText color="white" fontSize={[14, 14, 16]}>
          {rightText}
        </SpanText>
      </Box>
    </Box>
  );
}

const Question = forwardRef(({ question, index, answer, onSelect, showError }, ref) => {
  const answers = Object.entries(getAnswers(question.answerConfig));

  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        borderColor: showError && "red",
        borderStyle: "solid",
        borderWidth: showError ? 2 : 0,
        "data-component-name": "Question",
        borderRadius: 5,
        bg: "white",
        pt: [40, 40, 60],
        pb: [60, 60, 80],
        px: [20, 20, 0]
      }}
      ref={ref}
    >
      <Section.Header>
        <HeadingExtraExtraSmall fontSize={[18, 18, 24]}>
          {`Question ${index + 1}`}
        </HeadingExtraExtraSmall>
      </Section.Header>
      <Section.BodyCopy fontSize={[14, 14, 16]}>{question.text}</Section.BodyCopy>
      <Box>
        {answers.map(([answerIndex, answerValue], index) => (
          <Box
            display="flex"
            alignItems="center"
            key={answerIndex}
            mb={index !== answers.length - 1 ? 4 : 0}
          >
            <SelectCircle
              onClick={() => onSelect(question.id, +answerIndex)}
              selected={answer === +answerIndex}
              dataComponentName="Option"
            />
            <Text fontSize={[14, 14, 16]}>{answerValue}</Text>
          </Box>
        ))}
      </Box>
      {showError && (
        <Text color="red" mt={50}>
          <SpanText>*</SpanText>
          This question is required
        </Text>
      )}
    </PanelBoxV2>
  );
});

export default Question;
