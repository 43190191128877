import Box from "components/Box";
import { getTestProductQuestionnaireIntroUrl } from "core/urls";
import LinkWrapper from "tpo/LinkWrapper";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function SelfAssessmentInvitationPanel({ productSlug }) {
  return (
    <Stack bg="purple" borderRadius={5} gap={40} p={40} alignItems="flex-start">
      <Stack gap={20} color="white">
        <Box fontFamily="gilroyBold" fontSize={28} color="inherit">
          Is this test right for you?
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} color="inherit">
          Take our free 5 minute self assesment and discover if this tests is a good match for your
          current symptoms
        </Box>
      </Stack>
      <ButtonV2
        color="green"
        as={LinkWrapper}
        to={getTestProductQuestionnaireIntroUrl(productSlug, "symptoms")}
        size="xs"
        sx={{
          fontSize: 12,
          px: 20
        }}
      >
        take free assessment now
      </ButtonV2>
    </Stack>
  );
}
