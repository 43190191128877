import { useFormContext, useWatch } from "react-hook-form";

import Box from "components/Box";
import Loading from "components/Loading";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function PayOrderLaterFields({ canSubmit, loading }) {
  const { control } = useFormContext();

  const acknowledge = useWatch({
    name: "acknowledge",
    control
  });

  return (
    <PartnerDashboardBox>
      <Stack gap={[24, 24, 28]}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Payment Details
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          By placing this order, a payment link will be created that can be sent to your patient.
          They will be able to pay using this link at a later date. The order will not be processed
          until payment has been received.
        </Box>
      </Stack>
      <ControlledFormField
        name="acknowledge"
        label="I acknowledge that this order will be created and I will receive a payment link to be sent to my patient."
        Component={Checkbox}
      />
      <Center>
        <ButtonV2
          color="green"
          rightIcon={
            loading ? (
              <Loading
                minHeight={20}
                size={20}
                color="white"
                style={{
                  marginLeft: 10
                }}
              />
            ) : (
              <ChevronComponent />
            )
          }
          type="submit"
          disabled={!canSubmit || !acknowledge || loading}
        >
          place order
        </ButtonV2>
      </Center>
    </PartnerDashboardBox>
  );
}
