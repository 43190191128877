import { Fragment } from "react";

import { Controller, useController, useFormContext } from "react-hook-form";

import InputWrapper from "./InputWrapper";
import Radio from "./Radio";

export default function ControlledRadioGroup({
  Container,
  containerProps,
  name,
  values,
  labelProps,
  label,
  disabled
}) {
  const { control } = useFormContext();
  const {
    fieldState: { error }
  } = useController({ name, control });

  // NB -
  // values prop is the list of values
  // field.value below is the array of chosen values for the checkbox group form field

  let ContainerComponent = Container || Fragment;

  return (
    <InputWrapper
      labelProps={labelProps}
      label={label}
      error={error?.message}
      input={
        <ContainerComponent {...containerProps}>
          {values?.map(({ label, value, labelProps, helpText }) => (
            <Controller
              key={value}
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <Radio
                    {...field}
                    value={value}
                    label={label}
                    helpText={helpText}
                    labelProps={labelProps}
                    onChange={val => {
                      if (field.value !== val) {
                        field.onChange(val);
                      }
                    }}
                    checked={field.value === value}
                    disabled={disabled}
                  />
                );
              }}
            />
          ))}
        </ContainerComponent>
      }
    />
  );
}
