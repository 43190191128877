import Modal from "tpo/Modal";

/**
 * We really need a "Modal Manager" system like the react component library
 * mantine providers so we can ensure only one modal shows at a time.
 * As it stands these notification modals should ever show on top of
 * other modals.
 */

export default function NotificationModal({ children, onClose }) {
  return (
    <Modal
      mode={["fullScreen", "fullScreen", "centered"]}
      maxWidth={1020}
      bg="white"
      closeButton
      headerProps={{
        p: [20, 20, 40]
      }}
      show
      close={onClose}
      data-component-name="NotificationModal"
    >
      {children}
    </Modal>
  );
}
