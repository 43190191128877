import Box from "components/Box";
import { getTestProductQuestionnaireIntroUrl } from "core/urls";
import Group from "tpo/Group";
import LinkWrapper from "tpo/LinkWrapper";
import Tooltip from "tpo/Tooltip";
import ButtonV2, { QuestionButton } from "v2/Buttons";

export default function IsThisTestRightForYou({ slug }) {
  return (
    <Group gap={5}>
      <ButtonV2
        as={LinkWrapper}
        color="dark"
        to={getTestProductQuestionnaireIntroUrl(slug, "symptoms")}
        size="xs"
        sx={{
          fontSize: 12,
          px: 20
        }}
      >
        is this test right for you?
      </ButtonV2>
      <Tooltip
        content={
          <Box bg="haze" maxWidth={130} p={2} pb={20} borderRadius={5}>
            <Box fontSize={12}>
              Assess how well your symptoms align with this test and gain valuable insights
            </Box>
          </Box>
        }
      >
        <QuestionButton bg="dark" color="white" />
      </Tooltip>
    </Group>
  );
}
