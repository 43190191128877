import { getPartnerDashboardOrderUrl } from "core/urls";
import RefetchEvent from "events/refetch";
import {
  CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION,
  CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION,
  CREATE_PAYMENT_INVOICE_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import { ORGANISATION_ORDERS_LIST_QUERY } from "graphql/organisations/queries/orders";
import { ORGANISATION_STOCK_LIST_QUERY } from "graphql/organisations/queries/stock";

import CreateOrderInvoiceFields from "./CreateOrderInvoiceFields";
import PayOrderLaterFields from "./PayOrderLaterFields";
import PayOrderNowFields from "./PayOrderNowFields";

const CHECKOUT_MUTATIONS = {
  paymentCheckoutSession: {
    mutation: CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION,
    mutationName: "createPaymentCheckoutSessionMutation",
    renderFields: ({ canSubmit, loading }) => (
      <PayOrderNowFields canSubmit={canSubmit} loading={loading} />
    ),
    handleSuccess: ({ formResponseRelated: { data }, setScrollToError }) => {
      if (data?.createPaymentCheckoutSessionMutation?.paymentCheckoutSession?.url) {
        RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
        window.location.href = data.createPaymentCheckoutSessionMutation.paymentCheckoutSession.url;
      } else if (data?.createPaymentCheckoutSessionMutation?.basket) {
        // If a basket got returned the checkout wasn't successful
        // This might seem a bit odd to be returning errors within a success handler
        // but baskets can have invalid states (for example a line item can't be shipped to the country
        // on the shipping address)
        setScrollToError(v => v + 1);
      }
    }
  },
  paymentInvoice: {
    mutation: CREATE_PAYMENT_INVOICE_MUTATION,
    mutationName: "createPaymentInvoiceMutation",
    renderFields: ({ canSubmit, loading }) => (
      <CreateOrderInvoiceFields canSubmit={canSubmit} loading={loading} />
    ),
    handleSuccess: ({ formResponseRelated: { data }, history, setScrollToError }) => {
      if (data?.createPaymentInvoiceMutation?.order?.id) {
        RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
        return history.push(
          getPartnerDashboardOrderUrl(data.createPaymentInvoiceMutation.order.id)
        );
      } else if (data?.createPaymentInvoiceMutation?.basket) {
        // If a basket got returned the checkout wasn't successful
        // This might seem a bit odd to be returning errors within a success handler
        // but baskets can have invalid states (for example a line item can't be shipped to the country
        // on the shipping address)
        setScrollToError(v => v + 1);
      }
    }
  },
  patientOrder: {
    mutation: CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION,
    mutationName: "createPatientOrderFromBasketMutation",
    renderFields: ({ canSubmit, loading }) => (
      <PayOrderLaterFields canSubmit={canSubmit} loading={loading} />
    ),
    handleSuccess: ({ formResponseRelated: { data }, history, setScrollToError }) => {
      if (data?.createPatientOrderFromBasketMutation?.order?.id) {
        RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
        history.push(
          getPartnerDashboardOrderUrl(data.createPatientOrderFromBasketMutation.order.id)
        );
      } else if (data?.createPatientOrderFromBasketMutation?.basket) {
        // If a basket got returned the checkout wasn't successful
        // This might seem a bit odd to be returning errors within a success handler
        // but baskets can have invalid states (for example a line item can't be shipped to the country
        // on the shipping address)
        setScrollToError(v => v + 1);
      }
    }
  }
};

export default CHECKOUT_MUTATIONS;
