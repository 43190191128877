import Box from "components/Box";
import Badge from "tpo/Badge";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";

import ClinicBookingOptions from "./ClinicBookingOptions";
import OrderTestItemStatusBadges from "./OrderTestItemStatusBadges";

export default function OrderListItem({
  name,
  registrationId,
  price,
  clinicLocation,
  clinicBooking,
  order,
  orderTestItem,
  isAddOn,
  currencySymbol = "£"
}) {
  return (
    <Stack
      gap={20}
      pb={20}
      borderBottomColor="midGrey"
      borderBottomStyle="solid"
      borderBottomWidth={1}
    >
      <Stack gap={20}>
        <Group alignItems="center" gap={10} flexWrap="wrap" justifyContent="space-between">
          <Group alignItems="center" gap={10} flexWrap="wrap">
            {isAddOn && (
              <Badge bg="dark" color="white" size="md" fontSize={10}>
                add-on
              </Badge>
            )}
            <Box>
              <Box fontFamily="gilroyBold" fontSize={18}>
                {name}
              </Box>
              {registrationId && (
                <Box fontFamily="gilroyMedium" fontSize={14}>
                  Kit registration ID: {registrationId}
                </Box>
              )}
            </Box>
          </Group>
          <Currency
            fontSize={28}
            value={clinicLocation ? price - clinicLocation.fee : price}
            symbol={currencySymbol}
          />
        </Group>
        <OrderTestItemStatusBadges orderTestItem={orderTestItem} ml="auto" />
      </Stack>
      {clinicLocation && (
        <>
          <Box>
            <Box fontFamily="gilroyBold" fontSize={16}>
              Venous draw clinic:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={14}>
              <Box>{clinicLocation.clinic.name}</Box>
              <Box>{clinicLocation.address}</Box>
              <Box>{clinicLocation.postCode}</Box>
            </Box>
            <Group justifyContent="space-between">
              <Box fontFamily="gilroyBold" fontSize={16}>
                Clinic fee
              </Box>
              <Currency value={clinicLocation.fee} fontSize={18} symbol={currencySymbol} />
            </Group>
          </Box>
          {clinicBooking && <ClinicBookingOptions order={order} orderTestItem={orderTestItem} />}
        </>
      )}
    </Stack>
  );
}
