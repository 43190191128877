import { useContext, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { Spinner } from "components/Spinner";
import { FadeGrowTransition } from "components/animations/FadeTransition";
import { DiscreteAxis, GradientAxis, SegmentedAxis } from "components/cot/AxisCharts";
import { GraphTemplate } from "components/cot/GraphTemplate";
import { ResultsLineGraph } from "components/cot/LineGraph";
import { USER_RESULTS_QUERY } from "graphql/cot";
import { last } from "lodash";
import "styles/cot/result-graph.css";
import Center from "tpo/Center";
import Spacer from "tpo/Spacer";
import Tooltip from "tpo/Tooltip";
import { ViewerContext } from "tpo/Viewer";
import { buildDiscreteGraphConfiguration } from "utils/cot/discreteGraphs";
import { buildGradientGraphConfiguration } from "utils/cot/gradientGraphs";
import { buildSegmentedGraphConfiguration } from "utils/cot/segmentedGraphs";
import { QuestionButton } from "v2/Buttons";

function useResultGraphConfiguration({
  chartId,
  chartConfiguration,
  freshResults,
  historicalResults,
  builderFunction
}) {
  const [freshConfig, setFreshConfig] = useState(null);
  const [historicalConfig, setHistoricalConfig] = useState(null);
  const [productCodes, setProductCodes] = useState([]);

  const ensureRecentPoint = config => {
    const lastPoint = last(config.points);
    const lastPointTime = lastPoint[0];
    const lastPointValue = lastPoint[1];
    const now = new Date().getTime();
    const twoDays = 2 * 24 * 60 * 60 * 1000;
    if (now - lastPointTime > twoDays) {
      config.points.push([now, lastPointValue]);
    }
  };

  useEffect(() => {
    if (!builderFunction || !chartConfiguration) return;

    let config = builderFunction({
      chartConfiguration,
      userResults: freshResults
    });

    if (config.points.length > 0) {
      ensureRecentPoint(config);
    }
    setFreshConfig(config);

    config = builderFunction({
      chartConfiguration,
      userResults: historicalResults
    });

    if (config.points.length > 0) {
      ensureRecentPoint(config);
    }
    setHistoricalConfig(config);
  }, [chartConfiguration, freshResults, historicalResults, builderFunction]);

  useEffect(() => {
    if (!historicalResults) return;

    // Now lets get the unique product codes in all the results
    let productCodes = [];
    historicalResults.forEach(userResult =>
      userResult.result.testproductSet.forEach(testProduct => {
        if (!productCodes.includes(testProduct.productCode)) {
          productCodes.push(testProduct.productCode);
        }
      })
    );

    setProductCodes(productCodes);
  }, [historicalResults]);

  return { freshConfig, historicalConfig, productCodes };
}

function LegendItem({ color, label, tooltipText, tooltipHref }) {
  return (
    <Box display="flex" alignItems="center" gap={5} mr={2}>
      <Box width={20} height={4} backgroundColor={color} />
      <Box
        fontSize={"10px"}
        style={{ textTransform: "upperCase", fontWeight: "bold" }}
        overflow="hidden"
      >
        {label}
      </Box>
      <Tooltip
        content={
          <Box bg="dark" maxWidth={200} p={2} pb={20} borderRadius={5}>
            <Box color="white" fontFamily="gilroyRegular" fontSize={12}>
              {tooltipText}
            </Box>
            <Spacer py={1} />
            <ExternalTextLink fontSize={12} underline color="white" href={tooltipHref}>
              Read more
            </ExternalTextLink>
          </Box>
        }
        placement="bottom-end"
      >
        <QuestionButton />
      </Tooltip>
    </Box>
  );
}

function ResultsGraphLegend({ showFresh = true, showHistorical = true }) {
  return (
    <Box
      width="100%"
      display="flex"
      paddingLeft={50}
      position="absolute"
      top={[0, 3]}
      left={[-25, 0]}
      // flex to column if mobile
      flexDirection={["column", "row"]}
    >
      {showFresh && (
        <LegendItem
          color="#4b2ed4"
          label="Live - Excludes Expired Markers"
          tooltipText="This view excludes the impact of markers that are over 6 months old and
          represents the most accurate view of your current overall health."
          tooltipHref="https://www.omnos.me/articles/change-over-time"
        />
      )}
      {showHistorical && (
        <LegendItem
          color="#6a6a6a"
          label="Historical - Includes Expired Markers"
          tooltipText="This historical view includes all new markers as they became available. It
          doesn't adjust for expired markers."
          tooltipHref="https://www.omnos.me/articles/change-over-time"
        />
      )}
    </Box>
  );
}

function ResultGraphContent({
  chartId,
  chartConfiguration,
  freshResults,
  historicalResults,
  builderFunction,
  boxProps,
  axisComponent: AxisComponent,
  ...props
}) {
  const { freshConfig, historicalConfig, productCodes } = useResultGraphConfiguration({
    chartId,
    chartConfiguration,
    freshResults,
    historicalResults,
    builderFunction
  });

  if (!freshConfig && !historicalConfig) return null;

  return (
    <GraphTemplate
      chart={
        <ResultsLineGraph
          chartId={chartId}
          fullRange={freshConfig.fullRange}
          points={[freshConfig.points, historicalConfig.points]}
          tooltips={[freshConfig.tooltips, historicalConfig.tooltips]}
          colors={freshConfig.colors}
          productCodes={productCodes}
        />
      }
      yAxisWidth={30}
      yAxis={
        <Box position="absolute" width={290} {...boxProps}>
          <AxisComponent
            chartConfiguration={chartConfiguration}
            background={freshConfig.background}
            labels={freshConfig.fullRange.map(point => point.label)}
          />
        </Box>
      }
      {...props}
    />
  );
}

export default function ResultGraph({
  chartId,
  userResult: {
    result: { id: resultId },
    chartConfiguration
  }
}) {
  const viewerContext = useContext(ViewerContext);

  const { data } = useQuery(USER_RESULTS_QUERY, {
    variables: {
      resultId,
      cot: true,
      userId: viewerContext?.userId
    },
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-first"
  });
  const [graphProps, setGraphProps] = useState({});

  useEffect(() => {
    if (data) {
      const userResults = data?.userResults;
      const freshResults = userResults.filter(
        userResult => userResult.userSnapshot.snapshotType === "FRESH"
      );
      const historicalResults = userResults.filter(
        userResult => userResult.userSnapshot.snapshotType === "HISTORICAL"
      );
      let graphProps = {
        freshResults,
        historicalResults,
        chartConfiguration
      };

      if (chartConfiguration.chartType === "GRADIENT_CHART") {
        graphProps.builderFunction = buildGradientGraphConfiguration;
        graphProps.boxProps = {
          top: "50px"
        };
        graphProps.axisComponent = GradientAxis;
      } else if (chartConfiguration.chartType === "DISCRETE_CHART") {
        graphProps.builderFunction = buildDiscreteGraphConfiguration;
        graphProps.boxProps = {
          transform: "scale(1, -1) rotate(-270deg)",
          top: 178,
          left: -125
        };
        graphProps.axisComponent = DiscreteAxis;
      } else if (chartConfiguration.chartType === "SEGMENTED_PROGRESS_CHART") {
        graphProps.builderFunction = buildSegmentedGraphConfiguration;
        graphProps.boxProps = {
          transform: "rotate(-90deg)",
          top: 178,
          left: -125
        };
        graphProps.axisComponent = SegmentedAxis;
      }

      setGraphProps(graphProps);
    }
  }, [data, chartConfiguration]);

  if (!data) {
    return (
      <FadeGrowTransition in={true} appear timeout={100} heights={[123, 405]}>
        <Center minHeight="405px">
          <Spinner />
        </Center>
      </FadeGrowTransition>
    );
  }

  return (
    <Box style={{ position: "relative" }} marginTop={[2, 0]}>
      <ResultGraphContent chartId={chartId} {...graphProps} />
      <ResultsGraphLegend />
    </Box>
  );
}
