import { useState } from "react";

import Box from "components/Box";
import Grid from "components/Grid";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function AddOnDetail({ description, name, state, onRemove, onAdd, price }) {
  const { loading, basket } = useOrganisationBasketContext();

  const [show, setShow] = useState(false);

  let bg = "white";
  let button = (
    <ButtonV2 color="green" onClick={onAdd} disabled={loading} type="button" size="sm">
      add
    </ButtonV2>
  );
  if (state === "unavailable") {
    bg = "#E5E5E5";
    button = (
      <ButtonV2
        color="midGrey"
        disabled
        sx={{
          opacity: 1
        }}
        type="button"
        size="sm"
      >
        add
      </ButtonV2>
    );
  } else if (state === "selected") {
    button = (
      <ButtonV2
        color="red"
        style={{
          lineHeight: "70%"
        }}
        onClick={onRemove}
        disabled={loading}
        type="button"
        size="sm"
      >
        remove
      </ButtonV2>
    );
  }

  return (
    <Grid p={4} gridTemplateColumns="1fr 1fr" gridRowGap={20} bg={bg} data-component-name="Addon">
      <Box
        fontFamily="gilroyBold"
        fontSize={[16, 16, 18]}
        alignSelf="center"
        data-testid="addOn:name"
      >
        {name}
      </Box>
      <Stack gap={20} alignItems="flex-end">
        <Group alignItems="center" gap={20}>
          <Box>
            <Currency fontSize={[28]} value={price} color="dark" symbol={basket?.currencySymbol} />
          </Box>
          {button}
        </Group>
      </Stack>
      <ButtonV2
        variant="transparent"
        rightIcon={<ChevronComponent direction={show ? "top" : "bottom"} fill="black" />}
        onClick={() => setShow(!show)}
        p={0}
        size={null}
        ml="auto"
        sx={{
          fontFamily: "gilroyBold",
          fontSize: 10
        }}
        style={{
          gridColumnStart: 2,
          gridColumnEnd: 3
        }}
        type="button"
      >
        details
      </ButtonV2>
      {show && (
        <Box
          fontFamily="gilroyMedium"
          fontSize={[16, 16, 18]}
          py={[15, 15, 30]}
          px={[20, 20, 40]}
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 3
          }}
        >
          {description}
        </Box>
      )}
    </Grid>
  );
}
