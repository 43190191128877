import { forwardRef } from "react";

import Box from "../components/Box";

const Group = forwardRef(({ children, ...props }, ref) => {
  return (
    <Box {...props} ref={ref}>
      {children}
    </Box>
  );
});

Group.defaultProps = {
  display: "flex",
  flexDirection: "row"
};

export default Group;
