import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import Modal from "tpo/Modal";

import CreateOrganisationForm from "../forms/CreateOrganisationForm";

export default function CreateOrganisationModal() {
  const { showCreateOrganisationModal, setShowCreateOrganisationModal } = useOrganisationContext();

  return (
    <Modal
      mode={["fullScreen", "fullScreen", "centered"]}
      maxWidth={1000}
      bg="white"
      closeButton
      headerProps={{
        p: [20, 20, 40]
      }}
      show={showCreateOrganisationModal}
      close={() => setShowCreateOrganisationModal(false)}
      data-component-name="CreateOrganisationModal"
    >
      <CreateOrganisationForm
        onSuccess={() => {
          setShowCreateOrganisationModal(false);
        }}
      />
    </Modal>
  );
}
