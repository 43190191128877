import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { get } from "lodash";
import ChevronComponent from "tpo/Chevron";
import ButtonV2 from "v2/Buttons";

import { SIGNUP_DONE_URL } from "../core/urls";
import { RESEND_VERIFICATION_EMAIL } from "../graphql/accounts";

const EMAIL_NOT_VERIFIED_ERROR_MESSAGE =
  "We notice your account is not yet fully activated. Please complete email verification step.";

export default function ResendVerificationEmailButton({ errorField }) {
  const {
    formState: { errors, isSubmitting },
    getValues
  } = useFormContext();

  const error = get(errors, errorField)?.message;

  const history = useHistory();
  const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL);

  if (error === EMAIL_NOT_VERIFIED_ERROR_MESSAGE) {
    return (
      <ButtonV2
        size={["sm", "sm", "md"]}
        color="green"
        type="button"
        onClick={() =>
          resendVerificationEmail({
            variables: {
              input: {
                email: getValues("email")
              }
            }
          }).then(response => {
            if (
              response?.data?.resendVerificationEmailMutation &&
              !response?.data?.resendVerificationEmailMutation?.errors?.length
            ) {
              return history.push(SIGNUP_DONE_URL);
            }
          })
        }
      >
        resend verification email
      </ButtonV2>
    );
  }

  return (
    <ButtonV2
      rightIcon={<ChevronComponent />}
      color="green"
      submitting={isSubmitting}
      data-component-name="Submit button"
      size={["sm", "sm", "md"]}
    >
      submit
    </ButtonV2>
  );
}
