// We are no longer accepting new affiliates
// So redirect to the practitioner sign up page
import Box from "components/Box";
import Page from "components/Page";
import { theme } from "core/theme";
import Center from "tpo/Center";
import Jumbotron from "tpo/Jumbotron";
import LinkWrapper from "tpo/LinkWrapper";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

export default function AffiliateSignupRedirect() {
  return (
    <Page bg="haze">
      <Jumbotron bg="partners" color="dark" />
      <Box pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={[20, 20, "5.5vw"]}>
        <Box maxWidth={760} mx="auto">
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Affiliate application
          </Box>
          <Spacer py={2} />
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            We are no longer accepting applications for affiliate partners. If you are a
            practitioner and would like to take advantage of trade prices please apply below
          </Box>
          <Spacer py={20} />
          <Center>
            <ButtonV2 color="dark" as={LinkWrapper} to="/partners/practitioner">
              practitioner application
            </ButtonV2>
          </Center>
        </Box>
      </Box>
    </Page>
  );
}
