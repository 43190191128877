import { FoodList } from "tpo/FoodsPage";

import Template from "./shared/Template";

export default function FoodListRoute() {
  return (
    <Template selectedTab="food_list" docTitle="Patient's Food list">
      <FoodList />
    </Template>
  );
}
