import { Fragment, useState } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import Circle from "components/Circle";
import { useActionRequired } from "contexts/ActionRequiredContext";
import { getPartnerPatientTabUrl } from "core/urls";
import Group from "tpo/Group";
import LinkWrapper from "tpo/LinkWrapper";
import Menu, { Button } from "tpo/Menu";
import ButtonV2 from "v2/Buttons";

export default function Switcher({ active }) {
  const { callbackRequestsCount, newResultsCount } = useActionRequired();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const options = {
    manage_patients: "Manage patients",
    callback_requests: "Callback requests",
    new_results: "New results"
  };

  const showRedCircle = {
    callback_requests: !!callbackRequestsCount,
    new_results: !!newResultsCount
  };

  return (
    <>
      <Menu opened={open} onChange={setOpen} trigger="hover">
        <Menu.Target>
          <Button open={open} minWidth={320} display={["flex", "none", "none"]}>
            {options[active]}
          </Button>
        </Menu.Target>
        <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={200} height={200} overflow="auto">
          {Object.entries(options).map(([value, label]) => (
            <Menu.Item
              key={value}
              onClick={() => history.push(getPartnerPatientTabUrl(value))}
              selected={value === active}
              fontSize={14}
              lineHeight={1.7}
              py={16}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={10}
            >
              {active === value && <Circle bg="green" size={5} />}
              {label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
      <Group
        gap={20}
        bg="white"
        style={{ borderRadius: "100px" }}
        px={40}
        py={20}
        alignItems="center"
        display={["none", "none", "flex"]}
      >
        {Object.entries(options).map(([value, label], idx) => (
          <Fragment key={value}>
            <ButtonV2
              as={LinkWrapper}
              variant="link"
              size={null}
              p={0}
              sx={
                active === value
                  ? {
                      color: "purple"
                    }
                  : {}
              }
              to={getPartnerPatientTabUrl(value)}
            >
              {label}
              {showRedCircle[value] && <Circle bg="red" size={5} position="relative" top={-5} />}
            </ButtonV2>
            {idx !== Object.entries(options).length - 1 && (
              <Box width={"1px"} height={10} bg="black" />
            )}
          </Fragment>
        ))}
      </Group>
    </>
  );
}
