import styled from "styled-components";

import { spinAnimation } from "./Loading";

export const Spinner = styled.span`
  height: 16px;
  left: ${props => {
    return props.right && "10px";
  }};
  position: relative;
  right: ${props => {
    return props.left && "25px";
  }};
  width: 0;

  &::after {
    animation: ${spinAnimation} 1000ms linear infinite;
    border-bottom: 2px solid transparent;
    border-left: 2px solid
      ${props => {
        return props.color;
      }};
    border-radius: 50%;
    border-right: 2px solid
      ${props => {
        return props.color;
      }};
    border-top: 2px solid transparent;
    content: "";
    height: 16px;
    position: absolute;
    width: 16px;
  }
`;
