import { useEffect, useRef, useState } from "react";

import Box from "components/Box";

import ChevronComponent from "./Chevron";
import Menu from "./Menu";
import TextInput from "./TextInput";

export default function Autocomplete({
  placeholder,
  options,
  value,
  onChange,
  maxWidth,
  searchProp = "label",
  noOptionsFoundMessage = "No options found"
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [focused, setFocused] = useState(false);

  const filteredOptions = options?.filter(option => {
    return option[searchProp].toLowerCase().includes(search.toLowerCase());
  });

  const chosenOption = options?.find(option => option.value === value);

  return (
    <>
      <Menu opened={open} onChange={setOpen} trigger="click" yOffset={10}>
        <Menu.Target
          onFocus={() => {
            setFocused(true);
            setOpen(true);
          }}
          onBlur={() => setFocused(false)}
        >
          <TextInput
            placeholder={placeholder}
            value={chosenOption?.label || search || ""}
            onChange={e => {
              if (value) {
                onChange("");
                setSearch("");
              } else {
                onChange("");
                setSearch(e.target.value);
              }
            }}
            styles={{
              input: {
                borderColor: focused ? "#228be6" : "haze",
                borderStyle: "solid",
                borderWidth: 1
              }
            }}
            py={15}
            px={20}
            maxWidth={maxWidth}
            borderRadius={5}
          />
        </Menu.Target>
        <Menu.Dropdown maxHeight={300} overflowY="auto" py={2}>
          {filteredOptions?.length ? (
            filteredOptions.map(option => (
              <Menu.Item
                key={option.label}
                onClick={() => {
                  setSearch(option.label);
                  onChange(option.value);
                  setOpen(false);
                }}
              >
                {option.label}
              </Menu.Item>
            ))
          ) : (
            <Menu.Item>{noOptionsFoundMessage}</Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
