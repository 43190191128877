import { useState } from "react";

import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";

import AddTeamMemberForm from "./forms/AddTeamMemberForm";
import BusinessTemplate from "./ui/BusinessTemplate";
import TeamMembersList from "./ui/TeamMembersList";

export default function TeamMembers() {
  const { organisation } = useOrganisationContext();

  const [membersRefetch, setMembersRefetch] = useState(0);
  return (
    <BusinessTemplate active="team_members">
      {organisation.teamMembersEnabled && (
        <>
          <PartnerDashboardBox gap={0}>
            <TeamMembersList refetch={membersRefetch} />
          </PartnerDashboardBox>
          <PartnerDashboardBox>
            <AddTeamMemberForm onSuccess={() => setMembersRefetch(Math.random())} />
          </PartnerDashboardBox>
        </>
      )}
    </BusinessTemplate>
  );
}
