import { useAppState } from "./AppStateProvider";
import SliderTabs from "./SliderTabs";

export default function CustomerTabs({ selectedTab }) {
  const { userInfo } = useAppState();
  const user = userInfo?.user;

  const tabs = [
    {
      label: "dashboard",
      slug: "/dashboard",
      disabled: false
    },
    {
      label: "shop",
      slug: "/shop",
      disabled: false
    },
    {
      label: "test results",
      slug: "/test-results",
      disabled: !user?.pageAvailability.testResultsAvailable
    },
    {
      label: "wellness scores",
      slug: "/theme/health",
      disabled: !user?.pageAvailability.wellnessScoresAvailable
    },
    {
      label: "suggestions",
      slug: "/suggestions",
      disabled: !user?.pageAvailability.personalisedRecommendationsAvailable
    },
    {
      label: "food list",
      slug: "/food-list",
      disabled: !user?.pageAvailability.foodListAvailable
    }
  ];

  return (
    <SliderTabs
      data-component-name="CustomerTabs"
      tabs={tabs}
      selectedTab={selectedTab}
      withBorder
    />
  );
}
