import { useRef } from "react";

import styled from "styled-components";
import getResponsiveProps from "utils/getResponsiveProps";

import Box from "../components/Box";
import useWindowSize from "../hooks/use-window-size";

const SegmentedControlContainer = styled(Box)`
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  position: relative;

  div {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  div.item:not(.item ~ .item) {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  div.item:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
`;

export default function SegmentedControl({
  bg,
  borderRadius,
  data,
  onChange,
  height,
  value,
  ml,
  mr,
  size,
  "data-testid": dataTestId
}) {
  const refs = useRef({});

  useWindowSize();
  // otherwise the widths calculated for the "transform" below are wrong
  // if the user resizes the browser

  // strip trailing slash
  value = value.replace(/\/$/, "");

  const sizes = {
    xs: {
      fontSize: 12,
      px: 20,
      height: 40
    },
    sm: {
      fontSize: 12,
      px: 32,
      height: 48
    }
  };

  const sizeProps = getResponsiveProps(sizes, size);

  return (
    <Box
      data-testid={dataTestId}
      data-component-name="SegmentedControl"
      display="flex"
      ml={ml}
      mr={mr}
    >
      <SegmentedControlContainer bg={bg} borderRadius={borderRadius}>
        <Box
          height="100%"
          top={0}
          left={0}
          width={`${100 / data.length}%`}
          position="absolute"
          style={{
            transition: "transform 200ms ease 0s, width 100ms ease 0s",
            transform: `translate(${(function () {
              const index = data.findIndex(item => item.value === value);
              const itemsBefore = Object.values(refs.current).slice(0, index);
              const width = itemsBefore.reduce((accumulatedWidth, item) => {
                const elem = item.current;
                const { width } = elem.getBoundingClientRect();
                return accumulatedWidth + width;
              }, 0);
              return `${width}px`;
            })()})`
          }}
        />
        {data.map((item, index) => (
          <Box
            className="item"
            key={item.value}
            onClick={() => {
              if (item.value !== value) {
                onChange(item.value);
              }
            }}
            data-value={item.value}
            data-component-name="segment"
            data-is-selected={item.value === value}
            bg={item.value === value ? "dark" : "inherit"}
            px={sizeProps.px}
            color={
              item.value === value ? item.selectedColor || "white" : item.unSelectedColor || "dark"
            }
            borderRadius={item.value === value ? "2rem" : null}
            height={sizeProps.height}
            style={{
              transition:
                item.value === value ? "border-left-color 200ms ease 0s" : "color 200ms ease 0s"
            }}
            ref={elem => {
              if (!(index in refs.current)) {
                refs.current[index] = {
                  current: elem
                };
              } else {
                refs.current[index].current = elem;
              }
            }}
            fontFamily="gilroyBold"
            fontSize={sizeProps.fontSize}
            letterSpacing="2.8px"
          >
            {item.label}
          </Box>
        ))}
      </SegmentedControlContainer>
    </Box>
  );
}

SegmentedControl.defaultProps = {
  bg: "white",
  borderRadius: "2rem",
  size: ["xs", "xs", "sm"]
};
