import OrganisationDashboard from "components/organisations/OrganisationDashboard";
import CreateOrganisationModal from "components/organisations/business/ui/CreateOrganisationModal";
import AddOnErrorModal from "components/organisations/checkout/ui/AddOnErrorModal";
import MultipleProductsErrorModal from "components/organisations/checkout/ui/MultipleProductsErrorModal";
import ProductNotSoldInLocaleErrorModal from "components/organisations/checkout/ui/ProductNotSoldInLocaleErrorModal";
import StockPatientErrorModal from "components/organisations/checkout/ui/StockPatientErrorModal";
import { TestProductModalProvider } from "contexts/TestProductModalContext";
import { TestProductOptionProvider } from "contexts/TestProductOptionContext";
import { useUserProfileContext } from "contexts/UserProfileContext";
import {
  PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY,
  PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS
} from "graphql/shop";

import AffiliateDashboard from "./affiliate";
import Basket from "./practitioner/Basket";
import TestProductModal from "./practitioner/TestProductModal";

function PartnerDashboard() {
  const { isPartnerUser, partnerUserProfile } = useUserProfileContext();
  if (!isPartnerUser) {
    return null;
  }

  if (partnerUserProfile.tier === "Practitioner") {
    return (
      <>
        <TestProductOptionProvider
          testProductOptionFragment={PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS}
          testProductOptionFragmentName="PractitionerTestProductOptionFields"
          testProductOptionFragmentType="TestProductOptionType"
        >
          <TestProductModalProvider
            testProductQuery={PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY}
            testProductFieldName="practitionerTestProduct"
            testProductOptionFragment={PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS}
            testProductOptionFragmentName="practitionerTestProductOption"
            testProductOptionFragmentType="PractitionerTestProductOptionType"
          >
            <Basket />
            <OrganisationDashboard />
            <CreateOrganisationModal />
            <TestProductModal bg="white" />
            <AddOnErrorModal />
            <MultipleProductsErrorModal />
            <StockPatientErrorModal />
            <ProductNotSoldInLocaleErrorModal />
          </TestProductModalProvider>
        </TestProductOptionProvider>
      </>
    );
  } else if (partnerUserProfile.tier === "Affiliate") {
    return <AffiliateDashboard />;
  }
  return null;
}

export default PartnerDashboard;
