// This corresponds to the design file: https://www.figma.com/design/zAqxrAYPx8DaaiGP2bPO2X/Omnos-|-Component-Library?node-id=1-8010&m=dev
import { fontSize } from "styled-system";
import getResponsiveProps from "utils/getResponsiveProps";

const { forwardRef } = require("react");
const { default: ButtonV2 } = require("v2/Buttons");

// We have another Tab.js file.
// Possibly look to create a single Tab component which takes a variant prop
// so that the RoundedTab and Tab components can be combined into one.

// In the design a RoundedTab is sometimes used where in code we have a Badge
// Badge is just a div component, so not interactive, whereas RoundedTab is a button

const RoundedTab = forwardRef(
  ({ children, color, withBorder, selected, variant, size, ...rest }, ref) => {
    let props = {};

    if (withBorder) {
      props = {
        borderColor: color,
        borderWidth: 2,
        borderStyle: "solid"
      };
    }

    if (variant === "filled") {
      if (selected) {
        props = {
          ...props,
          bg: color,
          color: "white"
        };
      } else {
        props = {
          ...props,
          bg: "transparent",
          color: "midGrey"
        };
      }
    }
    if (variant === "outline") {
      if (selected) {
        props = {
          ...props,
          bg: color,
          color: "white"
        };
      } else {
        props = {
          ...props,
          bg: "transparent",
          color: color
        };
      }
    }

    const sizes = {
      xs: {
        height: 28,
        maxHeight: 28,
        py: 12,
        px: 18,
        fontSize: 10
      },
      sm: {
        height: 40,
        maxHeight: 40,
        py: 16,
        px: 20,
        fontSize: 12
      }
    };

    const sizeProps = getResponsiveProps(sizes, size);

    return (
      <ButtonV2
        color={color}
        ref={ref}
        variant="pill"
        sx={{
          ...props,
          ...sizeProps,
          ...rest
        }}
      >
        {children}
      </ButtonV2>
    );
  }
);

RoundedTab.defaultProps = {
  selected: false,
  withBorder: true,
  size: "sm"
};

export default RoundedTab;
