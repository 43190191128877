import Box from "components/Box";
import Grid from "components/Grid";

import PaginationWrapper from "./Pagination";
import SortMenu from "./SortMenu";
import Spacer from "./Spacer";
import { Search } from "./TextInput";

export default function SimpleTable({
  search,
  setSearch,
  sort,
  setSort,
  sortMenuOpen,
  setSortMenuOpen,
  table,
  pageInfo,
  setPage,
  sortingOptions,
  controls
}) {
  return (
    <>
      <Grid gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}>
        {controls}
        <Grid
          gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}
          gridColumnStart={["unset", "unset", "2"]}
          gridColumnEnd={["unset", "unset", "2"]}
        >
          <SortMenu
            value={sort}
            setValue={setSort}
            open={sortMenuOpen}
            setOpen={setSortMenuOpen}
            options={sortingOptions}
            styles={{
              button: {
                color: "dark",
                marginRight: ["auto", "auto", 0],
                order: ["1", "1", "2"],
                sx: {
                  color: "white"
                }
              }
            }}
            placeholder="Sort By"
          />
          <Search
            value={search}
            onChange={setSearch}
            maxWidth={[null, null, 350]}
            minWidth={310}
            width="100%"
            bg="haze"
            styles={{
              wrapper: {
                order: ["2", "2", "1"]
              }
            }}
          />
        </Grid>
      </Grid>
      <Spacer py={[2, 2, 20]} />
      {table}
      <Box py={20} />
      <PaginationWrapper
        variant={["mobile", "mobile", "default"]}
        pageInfo={pageInfo}
        onChange={setPage}
      />
    </>
  );
}
