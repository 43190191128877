import { useState } from "react";

import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import PlusIcon from "images/thin-plus";
import Center from "tpo/Center";
import Group from "tpo/Group";
import Menu, { Button } from "tpo/Menu";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import UpdateOrganisationNameModal from "./UpdateOrganisationNameModal";

export default function OrganisationSwitcher() {
  const {
    availableOrganisations,
    organisation,
    setOrganisationId,
    setShowCreateOrganisationModal,
    setShowUpdateOrganisationNameModal
  } = useOrganisationContext();
  const [showOrgMenu, setShowOrgMenu] = useState(false);

  return (
    <>
      <Center stacked gap={[20, 20, 40]}>
        <Menu opened={showOrgMenu} onChange={setShowOrgMenu} trigger="hover">
          <Menu.Target>
            <Button
              data-component-name="OrganisationMenuButton"
              open={showOrgMenu}
              color="dark"
              sx={{
                color: "white"
              }}
            >
              Organisation
            </Button>
          </Menu.Target>
          <Menu.Dropdown
            mt={2}
            py={2}
            zIndex={2}
            minWidth={200}
            maxHeight={500}
            overflow="auto"
            bg="dark"
            data-component-name="OrganisationMenuDropdown"
          >
            {availableOrganisations
              .map(({ id: value, name: label }) => ({
                label: label,
                value
              }))
              .map(sortOption => (
                <Menu.Item
                  onClick={() => setOrganisationId(sortOption.value)}
                  selected={sortOption.value === organisation?.id || ""}
                  key={sortOption.value}
                  fontSize={14}
                  lineHeight={1.7}
                  py={16}
                  color="white"
                  selectedColor="rgba(255, 255, 255, 0.10)"
                >
                  {sortOption.label}
                </Menu.Item>
              ))}
            <Menu.Item
              onClick={() => {
                setShowCreateOrganisationModal(true);
              }}
              fontSize={14}
              lineHeight={1.7}
              py={16}
              color="white"
              selectedColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              alignItems="center"
            >
              <PlusIcon color="white" style={{ marginRight: 10 }} size={16} />
              Add new organisation
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Stack gap={10} alignItems="center">
          {organisation?.name && (
            <Box
              fontFamily="gilroyBold"
              fontSize={[24, 24, 28]}
              data-component-name="OrganisationName"
            >
              {organisation?.name}
            </Box>
          )}
          <ButtonV2
            color="green"
            size="xs"
            onClick={() => {
              setShowUpdateOrganisationNameModal(true);
            }}
            data-component-name="EditOrganisationNameButton"
          >
            edit
          </ButtonV2>
        </Stack>
      </Center>
      <UpdateOrganisationNameModal />
    </>
  );
}
