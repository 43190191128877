import { FormProvider, useFormContext } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { useAuthContext } from "components/AuthProvider";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import { InternalTextLink } from "components/Links";
import { PRACTITIONER } from "core/constants";
import { theme } from "core/theme";
import {
  DASHBOARD_HOME_URL,
  DEFAULT_PARTNERS_URL,
  PARTNERS_AFFILIATE_URL,
  PARTNERS_DASHBOARD_URL,
  REQUEST_PASSWORD_RESET_URL,
  SIGNUP_DONE_URL
} from "core/urls";
import { LOG_IN_MUTATION, RESEND_VERIFICATION_EMAIL } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import ButtonV2 from "v2/Buttons";

import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ChevronComponent from "./Chevron";
import ControlledFormField from "./ControlledFormField";
import FloatingLabelInput from "./FloatingLabelInput";
import FloatingLabelPasswordInput from "./PasswordInput";
import Stack from "./Stack";

const EMAIL_NOT_VERIFIED_ERROR_MESSAGE =
  "We notice your account is not yet fully activated. Please complete email verification step.";

function FormButton({ nonFieldError }) {
  const { getValues } = useFormContext();
  const history = useHistory();
  const [resendVerificationEmailMutation] = useMutation(RESEND_VERIFICATION_EMAIL);

  if (nonFieldError !== EMAIL_NOT_VERIFIED_ERROR_MESSAGE) {
    return (
      <ButtonV2
        color="green"
        rightIcon={<ChevronComponent />}
        type="submit"
        size={["sm", "sm", "md"]}
      >
        submit
      </ButtonV2>
    );
  }

  return (
    <ButtonV2
      color="green"
      size={["sm", "sm", "md"]}
      onClick={() => {
        resendVerificationEmailMutation({
          variables: {
            input: {
              email: getValues("email")
            }
          }
        })
          .then(response => {
            if (
              response?.data?.resendVerificationEmailMutation &&
              !response?.data?.resendVerificationEmailMutation?.errors?.length
            ) {
              return history.push(SIGNUP_DONE_URL);
            }
          })
          .catch(error => {
            console.log(
              "Error encountered performing the resend verification email mutation",
              error
            );
          });
      }}
      rightIcon={<ChevronComponent />}
      type="button"
    >
      resend verification email
    </ButtonV2>
  );
}

function NonFieldError({ error }) {
  if (!error) return null;

  return (
    <Box color="red" py={20}>
      {error}
    </Box>
  );
}

export function LoginForm({ bottomLinks, ...props }) {
  const history = useHistory();
  const location = useLocation();
  const { setCsrfToken, setUser } = useAuthContext();

  const { methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: LOG_IN_MUTATION,
    mutationName: "logInMutation",
    defaultValues: {
      email: "",
      password: ""
    },
    handleSuccess: ({ data }) => {
      setCsrfToken(data.logInMutation.csrfToken);
      setUser(data.logInMutation.user);
      let defaultUrl = DASHBOARD_HOME_URL;
      if (
        data.logInMutation.user.partnerUserProfile?.id &&
        data.logInMutation.user.partnerUserProfile?.approved
      ) {
        if (data.logInMutation.user.partnerUserProfile.tier === PRACTITIONER) {
          defaultUrl = DEFAULT_PARTNERS_URL;
        } else {
          defaultUrl = PARTNERS_AFFILIATE_URL;
        }
      }
      history.push(new URLSearchParams(location.search).get("next") || defaultUrl);
    }
  });

  return (
    <FormProvider {...methods}>
      <PanelBoxV2
        maxWidth={600}
        stacked
        gap={40}
        outer={{
          as: "form",
          onSubmit: methods.handleSubmit(onSubmit),
          ...props
        }}
      >
        <Stack gap={20}>
          <NonFieldError error={nonFieldError} />
          <ControlledFormField name="email" label="Email address" Component={FloatingLabelInput} />
          <ControlledFormField
            name="password"
            label="Password"
            Component={FloatingLabelPasswordInput}
          />
        </Stack>
        <Center gap={20} stacked>
          <FormButton nonFieldError={nonFieldError} />
        </Center>
        <Center gap={20} stacked>
          {bottomLinks}
        </Center>
      </PanelBoxV2>
    </FormProvider>
  );
}

export default function Login({ bg, color, title, bottomLinks }) {
  useDocTitle("Log in");
  return (
    <CustomerPage
      jumbotronProps={{
        title,
        bg,
        color
      }}
    >
      <LoginForm
        bottomLinks={bottomLinks}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={20}
      />
    </CustomerPage>
  );
}

Login.defaultProps = {
  title: "Log In"
};
