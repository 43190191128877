import Box from "components/Box";
import NothingToShow from "tpo/NothingToShow";

export default function NoTests() {
  return (
    <NothingToShow
      jumbotron={
        <>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            No purchased tests
          </Box>
          <Box py={[2, 2, 20]} />
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            Your patient currently has no purchased tests. Details will be available here once an
            order has been placed.
          </Box>
        </>
      }
    />
  );
}
