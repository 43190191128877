import Box from "components/Box";
import { DownloadCrossOriginFile } from "components/Links";
import { endsWith } from "lodash";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import RelatedFileClasses from "./RelatedFiles.module.css";

export default function RelatedPdfs({ sectionName, pdfs }) {
  return (
    <Stack className={RelatedFileClasses.relatedPdfs} gap={20}>
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
        {sectionName}
      </Box>
      <Stack gap={20}>
        {pdfs.map(pdf => (
          <Group key={pdf.id} gap={20} flexWrap="wrap" justifyContent="space-between">
            <Box fontFamily="gilroyBold">{pdf.name}</Box>
            <DownloadCrossOriginFile
              fileUrl={pdf.pdf}
              fileName={endsWith(pdf.filename, ".pdf") ? pdf.filename : `${pdf.filename}.pdf`}
              contentType="application/pdf"
              trigger={
                <ButtonV2
                  color="green"
                  rightIcon={<ChevronComponent direction="bottom" />}
                  size="xs"
                >
                  Download
                </ButtonV2>
              }
            />
          </Group>
        ))}
      </Stack>
    </Stack>
  );
}
