import { useState } from "react";

import { FormProvider } from "react-hook-form";

import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import { InternalTextLink } from "components/Links";
import { theme } from "core/theme";
import { LOGIN_URL } from "core/urls";
import { KIT_SIGNUP_MUTATION } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Asterisk from "tpo/Asterisk";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import ControlledRadioGroup from "tpo/ControlledRadioGroup";
import DateInput from "tpo/DateInput";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import FloatingLabelPasswordInput from "tpo/PasswordInput";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import RegisterKitPageContent from "./RegisterKitPageContent";

export default function SignUp({ products }) {
  const [signedUp, setSignedUp] = useState(false);

  const api = useDjangoGraphqlForm({
    mutation: KIT_SIGNUP_MUTATION,
    mutationName: "kitSignupMutation",
    defaultValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      gender: "",
      password: "",
      confirmPassword: "",
      marketingConsent: true
    },
    handleSuccess: ({ data }) => {
      setSignedUp(data.kitSignupMutation.user);
    }
  });

  if (!signedUp) {
    return (
      <CustomerPage
        jumbotronProps={{
          title: "Kit registration"
        }}
      >
        <Center
          pt={theme.spacing.section.pt}
          pb={theme.spacing.section.pb}
          px={[20]}
          stacked
          gap={40}
        >
          <Box maxWidth={760} width="100%">
            <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
              Register your kit
            </Box>
            <Spacer py={[2, 2, 15]} />
            <Box fontFamily="gilroyMedium" fontSize={16} maxWidth={760} width="100%">
              Create an account to register your kit. If you already have an account{" "}
              <InternalTextLink href={LOGIN_URL}>sign in</InternalTextLink>.
            </Box>
          </Box>
          <Box as="form" maxWidth={600} onSubmit={api.methods.handleSubmit(api.onSubmit)}>
            <FormProvider {...api.methods}>
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%" mb={40}>
                Your details
                <Asterisk />
              </Box>
              <Stack gap={40}>
                <Stack gap={20}>
                  <ControlledFormField
                    name="firstName"
                    Component={FloatingLabelInput}
                    label="First name"
                  />
                  <ControlledFormField
                    name="lastName"
                    Component={FloatingLabelInput}
                    label="Last name"
                  />
                  <ControlledFormField
                    name="email"
                    Component={FloatingLabelInput}
                    label="Email address"
                  />
                </Stack>
                <FormControl
                  label={
                    <Box fontFamily="gilroyBold" fontSize={18}>
                      Password
                    </Box>
                  }
                  fields={
                    <>
                      <ControlledFormField
                        name="password"
                        Component={FloatingLabelPasswordInput}
                        label="Password"
                      />
                      <ControlledFormField
                        name="confirmPassword"
                        Component={FloatingLabelPasswordInput}
                        label="Confirm Password"
                      />
                    </>
                  }
                />
                <ControlledFormField
                  name="dateOfBirth"
                  Component={DateInput}
                  getDay={dateStr => (dateStr ? dateStr.split("-")[2] : "")}
                  getMonth={dateStr => (dateStr ? dateStr.split("-")[1] : "")}
                  getYear={dateStr => (dateStr ? dateStr.split("-")[0] : "")}
                  constructDate={({ day, month, year }) => {
                    return `${year}-${month}-${day}`;
                  }}
                  label="Date of birth"
                  labelProps={{
                    fontFamily: "gilroyBold",
                    fontSize: 18,
                    mb: 20
                  }}
                />
                <ControlledRadioGroup
                  Container={Stack}
                  containerProps={{
                    gap: 20
                  }}
                  name="gender"
                  label="Gender"
                  labelProps={{
                    fontFamily: "gilroyBold",
                    fontSize: 18,
                    mb: 20
                  }}
                  values={[
                    {
                      label: "Male",
                      value: "Male"
                    },
                    {
                      label: "Female",
                      value: "Female"
                    }
                  ]}
                />
                <Stack gap={20}>
                  <ControlledFormField
                    name="marketingConsent"
                    Component={Checkbox}
                    label="By submitting this form, you indicate your consent to receiving our latest health news, exclusive promotions and product updates. Uncheck this box if you would not like to receive this information"
                    styles={{
                      wrapperProps: {
                        alignItems: "flex-start"
                      }
                    }}
                  />
                </Stack>
                <ButtonV2
                  color="green"
                  rightIcon={<ChevronComponent />}
                  mx="auto"
                  size={["sm", "sm", "md"]}
                >
                  submit
                </ButtonV2>
              </Stack>
            </FormProvider>
          </Box>
        </Center>
      </CustomerPage>
    );
  }

  return (
    <RegisterKitPageContent
      products={products}
      gender={api.methods.getValues("gender")}
      userId={signedUp.id}
      confirmationField={{
        fullName: signedUp.fullName,
        dateOfBirth: signedUp.platformUserProfile.dateOfBirth
      }}
    />
  );
}
