import React from "react";

import { useHistory } from "react-router-dom";

import { HOME_URL, TESTS_URL } from "../core/urls";
import { PARTNER_LINK_CODE_QUERY } from "../graphql/accounts";
import { CustomerAccountPage } from "./CustomerPage";
import DataLoader from "./DataLoader";

function PartnerShareLinkRedirectPage({
  match: {
    params: { partnerLinkCode }
  }
}) {
  const history = useHistory();

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Share code"
      }}
    >
      <DataLoader
        query={PARTNER_LINK_CODE_QUERY}
        variables={{
          partnerLinkCode
        }}
        render={({ checkIncomingPartnerLinkCode }) => {
          if (checkIncomingPartnerLinkCode.hashId) {
            const url = `${window.location.origin}${TESTS_URL}?utm_source=${checkIncomingPartnerLinkCode.hashId}&utm_medium=${checkIncomingPartnerLinkCode.tier}&utm_campaign=partners&utm_term=${checkIncomingPartnerLinkCode.type}`;
            window.location.href = url;
          } else {
            history.push(HOME_URL);
          }
          return <></>;
        }}
      />
    </CustomerAccountPage>
  );
}

export default PartnerShareLinkRedirectPage;
