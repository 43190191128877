import React from "react";

import propTypes from "@styled-system/prop-types";
import Footer from "components/Footer";
import PropTypes from "prop-types";

import Box from "./Box";

const NAV_BAR_HEIGHT = 94;

function Page({
  header,
  children,
  backgroundColor,
  addNavBarHeight,
  includeFooter,
  bodyProps,
  ...rest
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      backgroundColor={backgroundColor}
      // pt={addNavBarHeight ? NAV_BAR_HEIGHT : null}
      {...rest}
    >
      {header}
      <Box flex={1} {...bodyProps}>
        {children}
      </Box>
      {includeFooter && <Footer />}
    </Box>
  );
}

Page.defaultProps = {
  addNavBarHeight: true,
  includeFooter: true
};

Page.propTypes = {
  header: PropTypes.element,
  children: PropTypes.node,
  backgroundColor: propTypes.color.backgroundColor,
  addNavBarHeight: PropTypes.bool,
  includeFooter: PropTypes.bool
};

export default Page;
