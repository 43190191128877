import React from "react";

import * as Sentry from "@sentry/browser";

import useDocTitle from "../hooks/use-doc-title";
import Box from "./Box";
import { CustomerAccountPage } from "./CustomerPage";
import Text from "./Text";

function Error500Page(props) {
  useDocTitle("Error");
  if (window.location.search === "?error") {
    throw new Error("Test throw new Error");
  }
  if (window.location.search === "?capture") {
    console.log("Testing Sentry.captureException");
    Sentry.captureException("Test captureException");
  }
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Error"
      }}
    >
      <Box pt={40}>
        <Text>I'm sorry, there's a problem with that page</Text>
      </Box>
    </CustomerAccountPage>
  );
}

export default Error500Page;
