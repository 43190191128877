import { forwardRef } from "react";

import Box from "components/Box";
import { OUT_OF_STOCK } from "core/constants";
import styled from "styled-components";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2, { ActionIcon } from "v2/Buttons";

const Card = styled(Stack)`
  :hover {
    box-shadow: 2px 2px 4px rgba(26, 26, 26, 0.2);
  }

  cursor: pointer;
`;

const SupplementCard = forwardRef(
  ({ subtitle, title, previousPrice, price, pills, onSelect, addText, onAdd, status }, ref) => (
    <Card
      data-component-name="SupplementCard"
      bg="white"
      borderRadius="5px"
      py={20}
      px={[20, 30]}
      gap={20}
      ref={ref}
      onClick={onSelect}
    >
      <Box
        display={["grid", "grid"]}
        gridTemplateColumns={["1fr auto", "1fr auto auto"]}
        justifyContent={[null, "space-between"]}
        alignItems={[null, "center"]}
        gap={[null, 40]}
        gridRowGap={[20, null]}
      >
        <Stack gap={0} gridColumnStart="1" gridColumnEnd="2" alignSelf="flex-start">
          <Box
            fontFamily="gilroyBold"
            lineHeight="130%"
            fontSize={[24, 24, 28]}
            data-testid="supplementCard:title"
          >
            {title}
          </Box>
          {subtitle}
        </Stack>
        <Stack ml={[null, "auto"]} alignItems="flex-end" gridColumnStart="2" gridColumnEnd="3">
          <Currency fontSize={[24, 44]} value={price} decimalFontSize={[16, 28]} />
          {!!previousPrice && previousPrice > price && (
            <Currency prefix="RRP" color="midGrey" fontSize={18} value={previousPrice} />
          )}
        </Stack>
        <ButtonV2
          onClick={onAdd}
          color={status === OUT_OF_STOCK ? "midGrey" : "green"}
          rightIcon={<ChevronComponent />}
          sx={{
            gridColumnStart: ["2", "3"],
            gridColumnEnd: ["3", "4"]
          }}
          disabled={status === OUT_OF_STOCK}
          size={["sm", "sm", "md"]}
        >
          {status === OUT_OF_STOCK ? "Out of stock" : addText}
        </ButtonV2>
        <Group
          flexWrap="wrap"
          gap={2}
          alignSelf={["flex-start", "flex-start", "flex-end"]}
          gridColumnStart={["1"]}
          gridColumnEnd={["3", "2"]}
          gridRowStart={["4", "unset"]}
          gridRowEnd={["5", "unset"]}
        >
          {pills}
        </Group>
        <ActionIcon
          bg="haze"
          variant="circle"
          ml={["auto"]}
          onClick={onSelect}
          gridColumnStart={["2", "3"]}
          gridColumnEnd={["3", "4"]}
          gridRowStart={["3", "unset"]}
          gridRowEnd={["4", "unset"]}
          alignSelf="flex-end"
        >
          <ChevronComponent
            fill="dark"
            style={{
              margin: 0
            }}
          />
        </ActionIcon>
      </Box>
    </Card>
  )
);

export default SupplementCard;
