export default function OmnosLogoMobile({ fill, size, style }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5345 1.93286C18.8695 1.93286 24.0767 7.03852 24.536 13.4931C22.9943 8.15492 18.2211 4.40218 12.7464 4.40218C9.52048 4.40218 6.47132 5.68799 4.16063 8.02291C2.15961 10.0449 0.901168 12.661 0.544059 15.4954C0.514805 15.1388 0.5 14.7803 0.5 14.4219C0.5 7.53539 5.89873 1.93286 12.5345 1.93286Z"
        fill={fill || "#00BBBF"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8825 5.05957C14.67 5.05957 16.4254 5.46972 18.0235 6.25389C17.1405 6.04854 16.2355 5.94477 15.3202 5.94477C8.56422 5.94477 3.06791 11.6197 3.06791 18.5953C3.06791 20.8092 3.62998 22.988 4.69328 24.8957C5.52184 26.3824 6.64008 27.6842 7.96488 28.7131C3.6958 26.7339 0.90332 22.3435 0.90332 17.4294C0.90332 10.6087 6.27726 5.05957 12.8825 5.05957Z"
        fill={fill || "#17C6E9"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.6567 8.64404C6.65835 10.9489 5.53334 13.9334 5.53334 17.019C5.53334 24.0257 11.1827 29.7262 18.1267 29.7262C19.9522 29.7262 21.7486 29.3278 23.3913 28.565C21.2795 30.1431 18.7232 30.9999 16.0652 30.9999C9.27174 30.9999 3.74512 25.4252 3.74512 18.5732C3.74512 16.384 4.31717 14.2319 5.39955 12.3491C6.22865 10.9073 7.341 9.64493 8.6567 8.64404Z"
        fill={fill || "#CCBAFF"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.4032 14.9988C30.4674 15.5239 30.5001 16.0543 30.5001 16.5858C30.5001 23.5184 25.0129 29.1585 18.2683 29.1585C15.006 29.1585 11.9376 27.854 9.6283 25.4851C7.60516 23.41 6.37561 20.7341 6.09717 17.8484C6.85277 20.1574 8.24963 22.2287 10.1108 23.7719C12.3227 25.6056 15.1087 26.6154 17.956 26.6154C24.4455 26.6154 29.7962 21.5081 30.4032 14.9988Z"
        fill={fill || "#FF5D6D"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.1339 2.39551C24.9662 3.29904 26.5425 4.66351 27.7378 6.39082C29.1949 8.49692 29.9654 10.982 29.9654 13.5773C29.9654 20.4126 24.5666 25.9735 17.9309 25.9735C15.9842 25.9735 14.0874 25.4945 12.3809 24.5792C13.3787 24.8432 14.4067 24.9767 15.4504 24.9767C22.2367 24.9767 27.7578 19.2898 27.7578 12.2997C27.7578 8.41185 26.0493 4.79277 23.1339 2.39551Z"
        fill={fill || "#FF905F"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0691 0C21.7048 0 27.1034 5.53231 27.1034 12.3325C27.1034 16.5368 25.0415 20.3922 21.6519 22.6589C23.8411 20.3198 25.0761 17.201 25.0761 13.9294C25.0761 6.97451 19.5549 1.31625 12.7687 1.31625C11.1922 1.31625 9.62307 1.62922 8.16895 2.22803C10.1819 0.784174 12.5912 0 15.0691 0Z"
        fill={fill || "#FFC647"}
      />
    </svg>
  );
}
