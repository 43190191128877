import { gql } from "@apollo/client";

const GET_REGISTRABLE_PRODUCTS = gql`
  query GetRegistrableProducts {
    user {
      id
      fullName
      platformUserProfile {
        id
        dateOfBirth
        gender
      }
    }
    registrableProducts {
      id
      testProduct {
        id
        truncatedName
        paymentIsoCode
        organisation
      }
      instructions
      finalInstructions
      label
      consent
      questionsHeader
      questions {
        id
        question
        gender
        answers {
          id
          answer
          order
        }
      }
    }
  }
`;

export default GET_REGISTRABLE_PRODUCTS;
