import React, { useState } from "react";

import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { DASHBOARD_HOME_URL, DEFAULT_PARTNERS_URL, PARTNERS_DASHBOARD_URL } from "core/urls";
import PractitionerApplication from "tpo/partnerDashboard/practitioner/Application";
import ApplicationSubmitted from "tpo/partnerDashboard/practitioner/ApplicationSubmitted";

import { AFFILIATE } from "../core/constants";
import { PARTNER_USER_PROFILE_QUERY } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import { useAuthContext } from "./AuthProvider";
import DataLoader from "./DataLoader";

function PartnersPage() {
  useDocTitle("Partners");

  const location = useLocation();

  const { user } = useAuthContext();
  const [formSubmitted, setFormSubmitted] = useState(false);
  if (formSubmitted) {
    return <ApplicationSubmitted />;
  }
  if (user) {
    if (!user.partnerUserProfile) {
      return <PractitionerApplication setFormSubmitted={setFormSubmitted} />;
    }

    if (user.partnerUserProfile.tier === AFFILIATE) {
      return (
        <DataLoader
          query={PARTNER_USER_PROFILE_QUERY}
          fetchPolicy="no-cache"
          nextFetchPolicy="no-cache"
          render={({ partnerUserProfile }) => (
            <PractitionerApplication
              setFormSubmitted={setFormSubmitted}
              isAffiliateCurrently
              affiliateProfile={partnerUserProfile}
            />
          )}
        />
      );
    }

    if (!user.partnerUserProfile.approved) {
      return <ApplicationSubmitted />;
    }

    return <Redirect to={DEFAULT_PARTNERS_URL} />;
  }

  return <PractitionerApplication setFormSubmitted={setFormSubmitted} />;
}

export default PartnersPage;
