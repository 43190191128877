import { useHistory } from "react-router-dom";

import Box from "components/Box";
import ProductFilters from "components/ProductFilters";
import { theme } from "core/theme";
import { ThemeProvider as BaseThemeProvider } from "styled-components";
import EmptySearch from "tpo/EmptySearch";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import SortMenu from "tpo/SortMenu";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";

import OrganisationTabs from "../OrganisationTabs";
import NavigationInstance from "./Navigation";
import useList from "./useList";

export function ListTemplateTabs({ selectedTab }) {
  return <OrganisationTabs selectedTab={selectedTab} />;
}

export function ListTemplateInfiniteScrollObjectList({
  Container,
  containerProps,
  loading,
  objects,
  objectsPlural,
  hasMore,
  fetchMore,
  endCursor,
  Card
}) {
  if (!loading && !objects?.length) {
    return <EmptySearch message={`No ${objectsPlural} found`} mt={[40, 40, 60]} />;
  }

  return (
    <Container {...containerProps}>
      <InfiniteScroll
        hasMore={hasMore}
        next={() =>
          fetchMore({
            variables: {
              after: endCursor,
              first: 10
            }
          })
        }
        loading={loading}
      >
        <InfiniteScrollContext.Consumer>
          {({ setBottomElement }) => {
            function renderCard(obj, ref) {
              return <Card {...obj} key={obj.id} ref={ref} />;
            }

            return (
              <>
                {objects?.slice(0, -1)?.map(obj => renderCard(obj))}
                {objects?.slice(-1)?.map(obj => renderCard(obj, setBottomElement))}
              </>
            );
          }}
        </InfiniteScrollContext.Consumer>
      </InfiniteScroll>
    </Container>
  );
}

const breakpoints = ["640px", "1100px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export function ListTemplateThemeProvider({ children }) {
  return (
    <BaseThemeProvider
      theme={{
        ...theme,
        disableStyledSystemCache: true,
        breakpoints,
        mediaQueries: {
          small: `@media screen and (min-width: ${breakpoints.sm})`,
          medium: `@media screen and (min-width: ${breakpoints.md})`,
          large: `@media screen and (min-width: ${breakpoints.lg})`
        }
      }}
    >
      {children}
    </BaseThemeProvider>
  );
}

export default function ListTemplate({
  icon,
  pageTitle,
  pageDescription,
  query,
  connectionName,
  objectsPlural,
  Card,
  filtersTitle,
  Container,
  containerProps,
  defaultSort,
  sortOptions
}) {
  const {
    allFilters,
    selectedFilters,
    validatedFilters,
    toggleFilter,
    controls,
    location,
    fetchMore,
    loading,
    objects,
    hasMore,
    endCursor,
    search
  } = useList({
    defaultSort,
    query,
    connectionName,
    sortOptions
  });

  const history = useHistory();

  return (
    <ListTemplateThemeProvider>
      <OrganisationTabs selectedTab="learning_hub" />
      <Group
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20, 20, 40]}
        maxWidth={1538}
        mx="auto"
        gap={80}
      >
        <NavigationInstance />
        <Stack gap={[40]} maxWidth={1138} flexGrow={1}>
          <Box>
            <Stack gap={20}>
              <Group gap={20} alignItems="center">
                {icon}
                <Box fontFamily="gilroyBold" fontSize={[36, 36, 68]}>
                  {pageTitle}
                </Box>
              </Group>
              {pageDescription && (
                <Box fontFamily="gilroyMedium" fontSize={18}>
                  {pageDescription}
                </Box>
              )}
            </Stack>
            <Stack gap={20} pt={[40, 40, 80]}>
              <Box
                display="flex"
                flexDirection={["column", "column", "row"]}
                gap={20}
                justifyContent={[null, null, "space-between"]}
                flexWrap="wrap"
              >
                <Group gap={10}>
                  <ProductFilters
                    allFilters={allFilters}
                    filtersTitle={filtersTitle}
                    drawerBg="dark"
                    validatedFilters={validatedFilters}
                    toggleFilter={toggleFilter}
                  />
                </Group>
                <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
                  <Search
                    value={search}
                    onChange={s => {
                      const searchParams = new URLSearchParams(location.search);
                      searchParams.set("search", s);
                      history.push({
                        path: location.path,
                        search: searchParams.toString()
                      });
                    }}
                    maxWidth={[null, null, 350]}
                    minWidth={310}
                    width="100%"
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <SortMenu
                      value={controls.sort}
                      setValue={controls.setSort}
                      open={controls.sortMenuOpen}
                      setOpen={controls.setSortMenuOpen}
                      options={controls.sortOptions}
                      placeholder="Sort By"
                    />
                  </Box>
                </Box>
              </Box>
              {selectedFilters}
            </Stack>
            <ListTemplateInfiniteScrollObjectList
              Container={Container}
              containerProps={containerProps}
              loading={loading}
              objects={objects}
              objectsPlural={objectsPlural}
              hasMore={hasMore}
              fetchMore={fetchMore}
              endCursor={endCursor}
              Card={Card}
            />
          </Box>
        </Stack>
      </Group>
    </ListTemplateThemeProvider>
  );
}

ListTemplate.defaultProps = {
  Container: Stack,
  containerProps: {
    gap: 16,
    pt: 40,
    pb: theme.spacing.section.pb
  }
};

ListTemplate.Tabs = ListTemplateTabs;
ListTemplate.InfiniteScrollObjectList = ListTemplateInfiniteScrollObjectList;
ListTemplate.ThemeProvider = ListTemplateThemeProvider;
