import Badge from "tpo/Badge";

export default function StatusBadge({ variant, children, style, ...otherProps }) {
  let props = undefined;
  let combinedStyles = {};

  const VARIANTS = {
    unfilled: {
      color: "black",
      bg: "#F2F2F2",
      withBorder: true
    },
    filled: {
      bg: "black",
      color: "white"
    },
    success: {
      bg: "green",
      color: "white"
    },
    failure: {
      bg: "red",
      color: "white"
    },
    notify: {
      bg: "purple",
      color: "white"
    }
  };

  if (variant) {
    props = VARIANTS[variant];
  }

  if (style) {
    combinedStyles = {
      cursor: "default",
      ...style
    };
  }

  return (
    <Badge size="xs" {...props} {...combinedStyles}>
      {children}
    </Badge>
  );
}
