import Box from "components/Box";
import { CMP_PERMISSION_ADMIN } from "components/organisations/constants";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import { Error } from "tpo/InputWrapper";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export function getSavePatientEditableFields(organisationPatient) {
  let editableFields = [];

  if (organisationPatient.platformUserProfile.user.firstName === "") {
    editableFields.push("firstName");
  }
  if (organisationPatient.platformUserProfile.user.lastName === "") {
    editableFields.push("lastName");
  }
  if (organisationPatient.platformUserProfile.dateOfBirth === null) {
    editableFields.push("dateOfBirth");
    editableFields.push("requiresGuardianConsent");
    editableFields.push("guardianFirstName");
    editableFields.push("guardianLastName");
    editableFields.push("guardianEmail");
  }

  if (organisationPatient.platformUserProfile.gender === "") {
    editableFields.push("sex");
  }

  return editableFields;
}

export function haveAllPatientDetails(organisationPatient) {
  return getSavePatientEditableFields(organisationPatient).length === 0;
}

export default function PatientDetails({ patient, permissions, onEdit }) {
  return (
    <Stack gap={20} data-component-name="PatientDetails">
      <Box fontFamily="gilroyBold" fontSize={18}>
        Patient details
      </Box>
      <Box>
        <Group gap={5}>
          <span>Name:</span>
          <span data-component-name="PatientDetails:name">
            {patient.platformUserProfile.user.firstName} {patient.platformUserProfile.user.lastName}
          </span>
        </Group>
        <Group gap={5}>
          <span>Date of birth:</span>
          <span data-component-name="PatientDetails:dateOfBirth">
            {patient.platformUserProfile.dateOfBirth}
          </span>
        </Group>
        <Group gap={5}>
          <span>Sex:</span>
          <span data-component-name="PatientDetails:sex">{patient.platformUserProfile.gender}</span>
        </Group>
        <Group>
          <span>Email:</span>
          <span data-component-name="PatientDetails:email">
            {patient.platformUserProfile.user.email}
          </span>
        </Group>
      </Box>
      {permissions &&
        permissions.includes(CMP_PERMISSION_ADMIN) &&
        !haveAllPatientDetails(patient) && (
          <ButtonV2
            color="dark"
            onClick={() => {
              onEdit(true);
            }}
            rightIcon={<ChevronComponent />}
            size={["sm"]}
            mr="auto"
            type="button"
          >
            Edit
          </ButtonV2>
        )}
      {!haveAllPatientDetails(patient) && (
        <Error error="Please complete the missing patient information" />
      )}
    </Stack>
  );
}
