import React from "react";

import useDocTitle from "../hooks/use-doc-title";
import Box from "./Box";
import { CustomerAccountPage } from "./CustomerPage";
import Text from "./Text";

function Error404Page() {
  useDocTitle("Page not found");
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Page not found"
      }}
    >
      <Box pt={40}>
        <Text>I'm sorry, that page cannot be found</Text>
      </Box>
    </CustomerAccountPage>
  );
}

export default Error404Page;
