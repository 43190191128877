import CustomerPage from "components/CustomerPage";
import { theme } from "core/theme";

import Box from "../components/Box";
import Loading from "../components/Loading";
import { HeadingExtraSmall } from "./Headings";

function Page({ children, Header, header = "Questionnaire", Main, Footer }) {
  return (
    <CustomerPage bg="blue" includeFooter={false}>
      {Header || (
        <Box pt={theme.spacing.section.pt} pb={40} mt={94}>
          <HeadingExtraSmall color="white" fontSize={[28, 28, 36]} textAlign="center">
            {header}
          </HeadingExtraSmall>
        </Box>
      )}
      {Main}
      {Footer}
      {children}
    </CustomerPage>
  );
}

export function LoadingPage() {
  return (
    <Page>
      <Loading color="white" />
    </Page>
  );
}

export default Page;
