import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";

import BusinessTemplate from "./ui/BusinessTemplate";
import StripeConnectSetupPanel from "./ui/StripeConnectSetupPanel";

export default function SetUp() {
  return (
    <BusinessTemplate active="payment" includeSubnav={false}>
      <PartnerDashboardBox>
        <StripeConnectSetupPanel />
      </PartnerDashboardBox>
    </BusinessTemplate>
  );
}
