import Input from "tpo/Input";

export default function ProductQtyInput({ disabled, value, onChange, ...rest }) {
  return (
    <Input
      data-component-name="ProductQtyInput"
      height={48}
      width={48}
      p="8px"
      value={value}
      disabled={disabled}
      bg={disabled ? "#F5F5F5" : "#FFFFFF"}
      mr="auto"
      borderColor="#E5E5E5"
      borderWidth={1}
      borderStyle="solid"
      borderRadius={5}
      textAlign="center"
      text="number"
      onChange={e => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
          onChange(e.target.value);
        }
      }}
      {...rest}
    />
  );
}
