import { cloneElement, createContext, forwardRef, useContext, useMemo } from "react";

import {
  useFloating,
  autoUpdate,
  offset,
  size,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  useClick,
  safePolygon
} from "@floating-ui/react";
import Box from "components/Box";
import styled from "styled-components";
import { flexbox, space } from "styled-system";
import getResponsiveProps from "utils/getResponsiveProps";
import ButtonV2 from "v2/Buttons";

import { theme } from "../core/theme";
import ChevronComponent from "./Chevron";
import { ModalInstanceContext } from "./Modal";

const MenuContext = createContext();

function Target({ children, onBlur, onFocus }) {
  const { refs, getReferenceProps } = useContext(MenuContext);

  const { onBlur: internalOnBlur, onFocus: internalOnFocus, ...refProps } = getReferenceProps();
  // Anything coming from getReferenceProps will override anything on the element which is the target / trigger
  // for the menu to open
  // Only workaround i can see is to pass the prop to the Target component here

  return cloneElement(children, {
    ref: refs.setReference,
    onBlur: e => {
      if (onBlur) {
        onBlur(e);
      }
      if (internalOnBlur) {
        internalOnBlur(e);
      }
    },
    onFocus: e => {
      if (onFocus) {
        onFocus(e);
      }
      if (internalOnFocus) {
        internalOnFocus(e);
      }
    },
    ...refProps
  });
}

function Dropdown({ children, ...rest }) {
  const { refs, opened, floatingStyles, getFloatingProps } = useContext(MenuContext);
  const modalInstanceContext = useContext(ModalInstanceContext);

  if (opened) {
    return (
      <FloatingPortal>
        {cloneElement(
          <Box
            backgroundColor="white"
            boxShadow="2px 2px 4px rgba(26, 26, 26, 0.2)"
            zIndex={modalInstanceContext ? modalInstanceContext.zIndex + 1 : 4}
            data-component-name="MenuDropdown"
            {...rest}
          >
            {children}
          </Box>,
          {
            ref: refs.setFloating,
            style: floatingStyles,
            ...getFloatingProps()
          }
        )}
      </FloatingPortal>
    );
  }

  return null;
}

Dropdown.defaultProps = {
  borderRadius: 4
};

const MenuItemContainer = styled(Box)`
  ${space}
  ${flexbox}
  background-color: ${props => (props.selected ? props.selectedColor : "inherit")};
  &:hover {
    background-color: ${props => props.selectedColor};
  }
  cursor: pointer;
`;

MenuItemContainer.defaultProps = {
  selectedColor: "#f2f2f2"
};

function Item({ children, onClick, ...props }) {
  const { onChange } = useContext(MenuContext);
  return (
    <MenuItemContainer
      onClick={() => {
        onChange(false);
        if (onClick) {
          onClick();
        }
      }}
      {...props}
      data-component-name="menu-item"
      data-selected={props.selected}
    >
      {children}
    </MenuItemContainer>
  );
}

Item.defaultProps = {
  px: 4,
  py: 2
};

function Menu({ children, opened, onChange, placement, trigger, yOffset, xOffset }) {
  const { refs, floatingStyles, context } = useFloating({
    open: opened,
    onOpenChange: onChange,
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,

    middleware: [
      offset({
        mainAxis: yOffset,
        crossAxis: xOffset
      }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`
          });
        }
      }),
      // flip({
      //   fallbackAxisSideDirection: "start"
      // }),
      shift()
    ]
  });

  // Event listeners to change the open state
  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon({
      requireIntent: false
    }),
    enabled: trigger === "hover"
  });
  const focus = useFocus(context);
  const click = useClick(context);

  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    hover,
    dismiss,
    role,
    focus
  ]);

  const api = useMemo(
    () => ({
      refs,
      floatingStyles,
      getFloatingProps,
      getReferenceProps,
      opened,
      onChange
    }),
    [floatingStyles, getFloatingProps, getReferenceProps, refs, opened, onChange]
  );

  return <MenuContext.Provider value={api}>{children}</MenuContext.Provider>;
}

Menu.defaultProps = {
  trigger: "click",
  xOffset: 0,
  yOffset: 0
};

Menu.Dropdown = Dropdown;
Menu.Item = Item;
Menu.Target = Target;

export default Menu;

export const Button = forwardRef(
  ({ children, open, fullWidth, color, chevronColor, size, sx, ...rest }, ref) => {
    const sizes = {
      xs: {
        fontSize: 12,
        height: 40,
        px: 40,
        py: "16px"
      },
      sm: {
        fontSize: 12,
        height: 48,
        px: 40,
        py: 16
      }
    };

    const sizeProps = getResponsiveProps(sizes, size);

    return (
      <ButtonV2
        rightIcon={
          <ChevronComponent
            fill={color === "white" ? theme.colors.dark : "white"}
            direction={open ? "bottom" : "top"}
          />
        }
        color={color}
        borderRadius="100px"
        gap={10}
        ref={ref}
        {...rest}
        sx={{
          width: fullWidth ? "100%" : null,
          color: "dark",
          ...sizeProps,
          ...sx
        }}
        type="type"
      >
        {children}
      </ButtonV2>
    );
  }
);

Button.defaultProps = {
  color: "white",
  size: ["xs", "xs", "sm"]
};
