import { ExternalTextLink } from "components/Links";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import { QuestionButton } from "v2/Buttons";

import Box from "../components/Box";
import { PanelBoxV2 } from "./Boxes";
import Group from "./Group";
import Spacer from "./Spacer";
import ToggleSwitch from "./ToggleSwitch";
import Tooltip from "./Tooltip";

export function ExpiredMarkersToggleSwitch() {
  const { toggleExpiredMarkers, includeExpiredMarkers } = useChangeOverTimeContext();

  return (
    <PanelBoxV2
      maxWidth={1538}
      outer={{
        pt: 2
      }}
    >
      <Group alignItems="center" gap={10} justifyContent="flex-end">
        <Tooltip
          content={
            <Box bg="haze" maxWidth={200} p={2} pb={20} borderRadius={5}>
              <Box fontFamily="gilroyBold" fontSize={15}>
                Expired markers
              </Box>
              <Spacer py={1} />
              <Box fontFamily="gilroyRegular" fontSize={12}>
                For the best understanding of your current condition you should exclude expired
                tests.
              </Box>
              <Spacer py={1} />
              <ExternalTextLink
                fontSize={12}
                underline
                href="https://www.omnos.me/articles/change-over-time"
              >
                Read more
              </ExternalTextLink>
            </Box>
          }
          placement="bottom-end"
        >
          <QuestionButton bg="white" color="midGrey" />
        </Tooltip>
        <Box color="white" fontFamily="gilroyBold" fontSize={15} lineHeight="24px">
          Include expired markers
        </Box>
        <ToggleSwitch
          id="expired-markers"
          onChange={() => {
            console.log("on change");
            toggleExpiredMarkers();
          }}
          value={includeExpiredMarkers}
        />
      </Group>
    </PanelBoxV2>
  );
}
