import { useRef, useState } from "react";

import Flicking, { FlickingError, ERROR_CODE } from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { FormProvider, useFormContext } from "react-hook-form";
import { Redirect, useParams } from "react-router-dom";

import { gql } from "@apollo/client";
import { EXPERT_AVAILABILITY_IN_PERSON } from "core/constants";
import { ERROR_404_URL } from "core/urls";
import { EXPERT_FIELDS } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import { ReactComponent as InstagramIcon } from "images/instagram.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";
import { ReactComponent as Pencil } from "images/pencil.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube.svg";
import { createGlobalStyle } from "styled-components";
import Accordion from "tpo/Accordion";
import Badge from "tpo/Badge";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import { CollapseableText } from "tpo/CollapseableText";
import ControlledCheckboxGroup from "tpo/ControlledCheckboxGroup";
import ControlledFormField from "tpo/ControlledFormField";
import FormControl from "tpo/FormControl";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import TextInput from "tpo/TextInput";
import ButtonV2, { ActionIcon } from "v2/Buttons";

import Avatar from "./Avatar";
import Box from "./Box";
import DataLoader from "./DataLoader";
import Divider from "./Divider";
import { ExternalTextLink } from "./Links";
import Page from "./Page";
import Textarea from "./Textarea";

const GlobalStyle = createGlobalStyle`
  .PoweredByOmnosBanner {
    z-index: 1;
  }
`;

const GET_EXPERT = gql`
  query GetExpert($id: ID!) {
    expert(id: $id) {
      ...ExpertFields
    }
  }
  ${EXPERT_FIELDS}
`;

const CONTACT_EXPERT_MUTATION = gql`
  mutation ContactExpert($input: ContactExpertMutationInput!) {
    contactExpertMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

function EmailField() {
  const { watch } = useFormContext();

  const preferredMethodOfContact = watch("preferredMethodOfContact");

  if (!preferredMethodOfContact?.includes("Email")) return null;

  return (
    <ControlledFormField
      placeholder="Email address"
      Component={TextInput}
      name="email"
      rightIcon={<Pencil fill="black" width={16} />}
      px={18}
      py={20}
      bg="white"
      styles={{
        input: {
          border: "1px solid black",
          pl: 2
        }
      }}
    />
  );
}

function PhoneNumberField() {
  const { watch } = useFormContext();

  const preferredMethodOfContact = watch("preferredMethodOfContact");

  if (!preferredMethodOfContact?.includes("Phone")) return null;

  return (
    <ControlledFormField
      placeholder="Phone number"
      Component={TextInput}
      name="phoneNumber"
      rightIcon={<Pencil fill="black" width={16} />}
      px={18}
      py={20}
      bg="white"
      styles={{
        input: {
          border: "1px solid black",
          pl: 2
        }
      }}
    />
  );
}

function ExpertDetail({
  id,
  bio,
  fullName,
  availability,
  location,
  organisations,
  practitionerTypes,
  specialisms,
  qualifications,
  associations,
  supportedTests,
  instagram,
  youtube,
  linkedin,
  websiteBlog,
  testimonials,
  avatar
}) {
  const [messageSent, setMessageSent] = useState(false);

  const requestCallbackFormApi = useDjangoGraphqlForm({
    mutation: CONTACT_EXPERT_MUTATION,
    mutationName: "contactExpertMutation",
    handleSuccess: () => {
      setMessageSent(true);
    },
    defaultValues: {
      consultationType: [],
      preferredMethodOfContact: [],
      phoneNumber: "",
      email: "",
      message: "",
      firstName: "",
      lastName: ""
    },
    transformer: data => ({
      ...data,
      consultationType: data.consultationType.join(","),
      preferredMethodOfContact: data.preferredMethodOfContact.join(","),
      expert: id
    })
  });

  const testimonialCarouselRef = useRef(null);

  const requestCallbackRef = useRef(null);

  let initials = null;
  if (fullName && fullName.split(" ")) {
    initials = fullName
      .split(" ")
      .map(name => name[0])
      .join("");
  }

  return (
    <Page height="100vh">
      <Stack height="100%" gap={0} bg="haze">
        <Box bg="partners" height={[195 + 80, 195 + 80, 340 + 95]} position="relative">
          <Avatar
            bg="white"
            size={[290, 290, 390]}
            placeholder={initials}
            src={avatar}
            position="absolute"
            left="50%"
            bottom={0}
            style={{
              transform: "translate(-50%, 50%)"
            }}
          />
        </Box>
        <Box
          pt={[290 / 2, 290 / 2, 390 / 2]}
          style={{
            flexGrow: 1
          }}
          px={20}
        >
          <Center stacked gap={40} pt={40}>
            <Center stacked gap={20}>
              <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]}>
                {fullName}
              </Box>
              {!!organisations?.length && (
                <Group gap={10}>
                  <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                    Organisation:
                  </Box>
                  <Box fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
                    {organisations?.join(", ")}
                  </Box>
                </Group>
              )}
              {!!practitionerTypes?.length && (
                <Group gap={10}>
                  <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                    Practitioner Type:
                  </Box>
                  <Box fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
                    {practitionerTypes.join(", ")}
                  </Box>
                </Group>
              )}
              {availability?.includes(EXPERT_AVAILABILITY_IN_PERSON) && (
                <Group gap={10}>
                  <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                    Location:
                  </Box>
                  <Box fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
                    {location}
                  </Box>
                </Group>
              )}
              {!!availability?.length && (
                <Group gap={10} flexWrap="wrap" alignItems="center">
                  <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
                    Availability:
                  </Box>
                  <Group
                    alignItems="center"
                    gap={10}
                    fontFamily="gilroyMedium"
                    fontSize={[15]}
                    flexWrap="wrap"
                  >
                    {availability?.map(a => (
                      <Badge bg="green" color="white" px={16} py="8px" key={a}>
                        {a}
                      </Badge>
                    ))}
                  </Group>
                </Group>
              )}
            </Center>
            <Center>
              <ButtonV2
                color="dark"
                rightIcon={<ChevronComponent />}
                onClick={() => {
                  if (requestCallbackRef.current) {
                    requestCallbackRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "center"
                    });
                  }
                }}
              >
                contact
              </ButtonV2>
            </Center>
          </Center>
        </Box>
        <Center px={20} pt={[50, 50, 80]} pb={[60, 60, 120]}>
          <Stack width="100%" maxWidth={980} gap={[20, 20, 40]}>
            <Box
              borderRadius={5}
              bg="white"
              pt={[20, 20, 40]}
              pb={[30, 30, 60]}
              px="calc(max(20px, 20px + ((100% - 760px) / 2)))"
            >
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Bio
              </Box>
              <Spacer py={2} />
              <CollapseableText fontSize={[14, 14, 16]}>{bio}</CollapseableText>
            </Box>
            {!!specialisms?.length && (
              <Box
                borderRadius={5}
                bg="white"
                pt={[20, 20, 40]}
                pb={[30, 30, 60]}
                px="calc(max(20px, 20px + ((100% - 760px) / 2)))"
              >
                <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  Specialisms
                </Box>
                <Spacer py={2} />
                <Accordion chevronVariant="circle">
                  {specialisms.map(spec => (
                    <>
                      <Accordion.Item value={spec.id}>
                        <Accordion.Control py={[2, 2, 15]}>
                          <Box fontFamily="gilroyBold" fontSize={[18]}>
                            {spec.name}
                          </Box>
                        </Accordion.Control>
                        <Accordion.Panel pb={[2, 2, 60]}>{spec.description}</Accordion.Panel>
                      </Accordion.Item>
                      <Divider />
                    </>
                  ))}
                </Accordion>
              </Box>
            )}
            {(!!qualifications.length || !!associations.length) && (
              <Stack
                gap={[20, 20, 40]}
                borderRadius={5}
                bg="white"
                pt={[20, 20, 40]}
                pb={[30, 30, 60]}
                px="calc(max(20px, 20px + ((100% - 760px) / 2)))"
              >
                {!!qualifications?.length && (
                  <Box>
                    <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                      Qualifications
                    </Box>
                    <Spacer py={2} />
                    <Stack
                      as="ul"
                      fontFamily="gilroyBold"
                      fontSize={18}
                      gap={20}
                      style={{
                        listStylePosition: "inside",
                        listStyleType: "disc"
                      }}
                    >
                      {qualifications.map(q => (
                        <li key={q}>{q}</li>
                      ))}
                    </Stack>
                  </Box>
                )}
                <Divider />
                {!!associations?.length && (
                  <Box>
                    <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                      Associations
                    </Box>
                    <Spacer py={2} />
                    <Stack
                      gap={20}
                      fontFamily="gilroyBold"
                      fontSize={18}
                      as="ul"
                      style={{
                        listStylePosition: "inside",
                        listStyleType: "disc"
                      }}
                    >
                      {associations.map(a => (
                        <li key={a}>{a}</li>
                      ))}
                    </Stack>
                  </Box>
                )}
              </Stack>
            )}
            {/* {!!supportedTests?.length && (
              <Stack
                gap={[20, 20, 40]}
                borderRadius={5}
                bg="white"
                pt={[20, 20, 40]}
                pb={[30, 30, 60]}
                px="calc(max(20px, 20px + ((100% - 760px) / 2)))"
              >
                <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  Supported tests
                </Box>
                <Group gap={10} flexWrap="wrap">
                  <Badge color="white" bg="green" py="8px" px={16}>
                    Supported test 1
                  </Badge>
                  <Badge color="white" bg="green" py="8px" px={16}>
                    Supported test 1
                  </Badge>
                  <Badge color="white" bg="green" py="8px" px={16}>
                    Supported test 1
                  </Badge>
                  <Badge color="white" bg="green" py="8px" px={16}>
                    Supported test 1
                  </Badge>
                  <Badge color="white" bg="green" py="8px" px={16}>
                    Supported test 1
                  </Badge>
                  <Badge color="white" bg="green" py="8px" px={16}>
                    Supported test 1
                  </Badge>
                </Group>
              </Stack>
            )} */}
            {(linkedin || instagram || websiteBlog || youtube) && (
              <Stack
                gap={[20, 20, 40]}
                borderRadius={5}
                bg="white"
                pt={[20, 20, 40]}
                pb={[30, 30, 60]}
                px="calc(max(20px, 20px + ((100% - 760px) / 2)))"
              >
                <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  Links
                </Box>
                <Group gap={30} flexWrap="wrap" alignItems="center">
                  {websiteBlog && (
                    <ButtonV2
                      color="dark"
                      rightIcon={<ChevronComponent />}
                      as={ExternalTextLink}
                      href={websiteBlog}
                      underline={false}
                    >
                      website
                    </ButtonV2>
                  )}
                  {linkedin && (
                    <ActionIcon as={ExternalTextLink} href={linkedin}>
                      <LinkedInIcon />
                    </ActionIcon>
                  )}
                  {instagram && (
                    <ActionIcon as={ExternalTextLink} href={instagram}>
                      <InstagramIcon />
                    </ActionIcon>
                  )}
                  {/*  TODO: check if we have x / twitter?  Don't think we do. */}
                  {/* <ActionIcon as={ExternalTextLink} href="https/:/www.google.com">
                      <XIcon />
                    </ActionIcon>
                   */}
                  {youtube && (
                    <ActionIcon as={ExternalTextLink} href={youtube}>
                      <YoutubeIcon />
                    </ActionIcon>
                  )}
                </Group>
              </Stack>
            )}
            {!messageSent ? (
              <FormProvider {...requestCallbackFormApi.methods}>
                <Stack
                  as="form"
                  gap={[40]}
                  borderRadius={5}
                  bg="blue"
                  pt={[20, 20, 40]}
                  pb={[30, 30, 60]}
                  px="calc(max(20px, 20px + ((100% - 760px) / 2)))"
                  color="white"
                  onSubmit={requestCallbackFormApi.methods.handleSubmit(
                    requestCallbackFormApi.onSubmit
                  )}
                  ref={requestCallbackRef}
                >
                  <Box color="inherit">
                    <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} color="inherit">
                      Request a call back
                    </Box>
                    <Spacer py={[2, 2, 15]} />
                    <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} color="inherit">
                      Reach out to {fullName} below and we’ll let them know that you are looking for
                      some support.
                    </Box>
                  </Box>
                  <FormControl
                    as="fieldset"
                    label={
                      <Box fontFamily="gilroyBold" fontSize={[18]} lineHeight="26px" color="white">
                        Preferred method of contact
                      </Box>
                    }
                    spaceAboveChildren={20}
                  >
                    <ControlledCheckboxGroup
                      Container={Group}
                      containerProps={{
                        gap: 20
                      }}
                      name="preferredMethodOfContact"
                      values={[
                        {
                          label: "Email",
                          value: "Email"
                        },
                        {
                          label: "Phone",
                          value: "Phone"
                        }
                      ]}
                      checkboxLabelProps={{
                        color: "white"
                      }}
                    />
                  </FormControl>
                  <FormControl
                    as="fieldset"
                    label={
                      <Box fontFamily="gilroyBold" fontSize={[18]} lineHeight="26px" color="white">
                        Consultation type
                      </Box>
                    }
                    spaceAboveChildren={20}
                  >
                    <ControlledCheckboxGroup
                      Container={Group}
                      containerProps={{
                        gap: 20
                      }}
                      name="consultationType"
                      values={[
                        {
                          label: "Online",
                          value: "Online"
                        },
                        {
                          label: "In person",
                          value: "In Person"
                        }
                      ]}
                      checkboxLabelProps={{
                        color: "white"
                      }}
                    />
                  </FormControl>
                  <Stack gap={20}>
                    <ControlledFormField
                      placeholder="First name"
                      Component={TextInput}
                      name="firstName"
                      rightIcon={<Pencil fill="black" width={16} />}
                      px={18}
                      py={20}
                      bg="white"
                      styles={{
                        input: {
                          border: "1px solid black",
                          pl: 2
                        }
                      }}
                    />
                    <ControlledFormField
                      placeholder="Last name"
                      Component={TextInput}
                      name="lastName"
                      rightIcon={<Pencil fill="black" width={16} />}
                      px={18}
                      py={20}
                      bg="white"
                      styles={{
                        input: {
                          border: "1px solid black",
                          pl: 2
                        }
                      }}
                    />
                    <EmailField />
                    <PhoneNumberField />
                    <ControlledFormField
                      Component={Textarea}
                      name="message"
                      rows={8}
                      placeholder="Include a message (optional)"
                      style={{
                        resize: "none",
                        padding: "10px",
                        width: "100%",
                        fontFamily: "Gilroy W05 Medium",
                        fontSize: 18,
                        border: "1px solid black"
                      }}
                    />
                  </Stack>
                  <Center>
                    <ButtonV2 color="green" rightIcon={<ChevronComponent />}>
                      submit
                    </ButtonV2>
                  </Center>
                </Stack>
              </FormProvider>
            ) : (
              <Box
                borderRadius={5}
                bg="blue"
                pt={[20, 20, 40]}
                pb={[30, 30, 60]}
                px="calc(max(20px, 20px + ((100% - 760px) / 2)))"
                color="white"
              >
                <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} color="inherit">
                  Message sent
                </Box>
                <Spacer py={[2, 2, 15]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} color="inherit">
                  Your request has been sent to {fullName}. They will be in touch directly via your
                  preferred contact method
                </Box>
              </Box>
            )}
          </Stack>
        </Center>
        {!!testimonials?.filter(t => t.name && t.detail)?.length && (
          <Box bg="supplements" pt={[50, 50, 100]} pb={[60, 60, 120]} px={0}>
            <Center fontFamily="gilroyBold" fontSize={[24, 24, 44]} mb={80}>
              Testimonials
            </Center>
            <Flicking
              ref={testimonialCarouselRef}
              circular
              style={{
                paddingLeft: 20,
                paddingRight: 20
              }}
            >
              {testimonials
                .filter(t => t.name && t.detail)
                .map(t => (
                  <Box
                    mx={30}
                    borderRadius={20}
                    width="100%"
                    maxWidth={760}
                    bg="white"
                    py={60}
                    px={[20, 20, 40]}
                    className="flicking-panel"
                    key={t.id}
                    fontFamily="gilroyRegular"
                    lineHeight="150%"
                  >
                    {t.detail}
                    <Box pt={30} fontFamily="gilroyBold" fontSize={18}>
                      {"- "}
                      {t.name}
                    </Box>
                  </Box>
                ))}
            </Flicking>
            <Center>
              <Group gap={10} width="100%" maxWidth={760} justifyContent="flex-end" mt={40}>
                <ActionIcon
                  bg="blue"
                  onClick={() => {
                    testimonialCarouselRef.current.prev().catch(err => {
                      console.log(err);

                      if (
                        err instanceof FlickingError &&
                        (err.code === ERROR_CODE.ANIMATION_ALREADY_PLAYING ||
                          err.code === ERROR_CODE.INDEX_OUT_OF_RANGE)
                      )
                        return;
                      throw err;
                    });
                  }}
                  variant="circle"
                >
                  <ChevronComponent
                    direction="left"
                    style={{
                      margin: 0
                    }}
                  />
                </ActionIcon>
                <ActionIcon
                  bg="blue"
                  onClick={() => {
                    testimonialCarouselRef.current.next().catch(err => {
                      if (
                        err instanceof FlickingError &&
                        (err.code === ERROR_CODE.ANIMATION_ALREADY_PLAYING ||
                          err.code === ERROR_CODE.INDEX_OUT_OF_RANGE)
                      )
                        return;
                      throw err;
                    });
                  }}
                  variant="circle"
                >
                  <ChevronComponent
                    direction="right"
                    style={{
                      margin: 0
                    }}
                  />
                </ActionIcon>
              </Group>
            </Center>
          </Box>
        )}
      </Stack>
    </Page>
  );
}

export default function ExpertDetailPage() {
  const { id } = useParams();

  return (
    <DataLoader
      query={GET_EXPERT}
      variables={{
        id
      }}
      render={({ expert }) => {
        return expert ? (
          <>
            <ExpertDetail
              id={expert.id}
              bio={expert.bio}
              fullName={expert.partnerUserProfile.user.fullName}
              availability={expert.consultationType}
              location={expert.location}
              organisations={expert.partnerUserProfile.organisationSet.map(o => o.name)}
              practitionerType={expert.partnerUserProfile.practitionerType}
              specialisms={expert.specialisms}
              qualifications={
                expert.partnerUserProfile.documents.map(d => d.qualificationType) || []
              }
              associations={expert.partnerUserProfile.professionalBodies}
              supportedTests={expert.supportedTests.map(st => st.name)}
              testimonials={expert.testimonials}
              avatar={expert.resizedAvatarUrl}
            />
            <GlobalStyle />
          </>
        ) : (
          <Redirect to={ERROR_404_URL} />
        );
      }}
    />
  );
}
