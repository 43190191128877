import DataLoader from "components/DataLoader";
import OnCmpLogin from "components/notifications/events/OnCmpLogin";
import LinkYourBankAccount from "components/notifications/ui/LinkYourBankAccount";
import NotificationModal from "components/notifications/ui/NotificationModal";
import OrganisationTabs from "components/organisations/OrganisationTabs";
import { useNotificationsContext } from "contexts/Notification";
import { PARTNER_USER_PROFILE_QUERY } from "graphql/accounts";

import ProfileForm from "./forms/ProfileForm";

export default function PractitionerProfile() {
  const api = useNotificationsContext();

  const userNotification = api.nextNotification(
    un => un.notification.type === "cmp.UserMissingLinkedBankAccount"
  );

  return (
    <>
      <OnCmpLogin />
      <>
        <OrganisationTabs selectedTab="profile" />
        <DataLoader
          query={PARTNER_USER_PROFILE_QUERY}
          fetchPolicy="network-only"
          nextFetchPolicy="network-only"
          render={({ partnerUserProfile }) =>
            partnerUserProfile ? <ProfileForm partnerUserProfile={partnerUserProfile} /> : null
          }
        />
      </>
      {userNotification && (
        <NotificationModal onClose={() => api.acknowledgeNotification(userNotification.id)}>
          <LinkYourBankAccount />
        </NotificationModal>
      )}
    </>
  );
}
