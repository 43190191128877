import gql from "graphql-tag";

import {
  ADDRESS_FIELDS,
  ORGANISATION_BASKET_FIELDS,
  ORGANISATION_FIELDS,
  ORGANISATION_PATIENT_FIELDS
} from "./shared";

export const CREATE_NEW_ORGANISATION_BASKET_MUTATION = gql`
  mutation CreateOrganisationBasketMutation($input: CreateBasketMutationInput!) {
    createBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const ADD_PRODUCT_TO_ORGANISATION_BASKET_MUTATION = gql`
  mutation AddProductToOrganisationBasketMutation($input: AddProductToBasketMutationInput!) {
    addProductToBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const SET_PRODUCT_QTY_IN_ORGANISATION_BASKET_MUTATION = gql`
  mutation SetProductQtyInOrganisationBasketMutation($input: SetProductQtyInBasketMutationInput!) {
    setProductQtyInBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const SET_SUPPLEMENT_QTY_IN_ORGANISATION_BASKET_MUTATION = gql`
  mutation SetSupplementQtyInOrganisationBasketMutation(
    $input: SetSupplementQtyInBasketMutationInput!
  ) {
    setSupplementQtyInBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const ADD_ON_TO_PRODUCT_MUTATION = gql`
  mutation AddOnToProductMutation($input: AddOnToProductMutationInput!) {
    addOnToProductMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const REMOVE_PRODUCT_FROM_ORGANISATION_BASKET_MUTATION = gql`
  mutation RemoveProductFromOrganisationBasketMutation(
    $input: RemoveProductFromBasketMutationInput!
  ) {
    removeProductFromBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const CLEAR_BASKET_MUTATION = gql`
  mutation ClearBasketMutation($input: ClearBasketMutationInput!) {
    clearBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const REMOVE_ADD_ON_FROM_PRODUCT_MUTATION = gql`
  mutation RemoveAddOnFromProductMutation($input: RemoveAddOnFromProductMutationInput!) {
    removeAddOnFromProductMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const ADD_SUPPLEMENT_TO_ORGANISATION_BASKET_MUTATION = gql`
  mutation AddSupplementToOrganisationBasketMutation($input: AddSupplementToBasketMutationInput!) {
    addSupplementToBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const REMOVE_SUPPLEMENT_FROM_ORGANISATION_BASKET_MUTATION = gql`
  mutation RemoveSupplementFromOrganisationBasketMutation(
    $input: RemoveSupplementFromBasketMutationInput!
  ) {
    removeSupplementFromBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const UPDATE_ORGANISATION_BASKET_DETAILS_MUTATION = gql`
  mutation UpdateOrganisationBasketDetailsMutation($input: UpdateBasketDetailsMutationInput!) {
    updateBasketDetailsMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const SAVE_PATIENT_MUTATION = gql`
  mutation SavePatientMutation($input: SavePatientMutationInput!) {
    savePatientMutation(input: $input) {
      patient {
        ...OrganisationPatientFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_PATIENT_FIELDS}
`;

export const UPDATE_OR_CREATE_SHIPPING_ADDRESS_MUTATION = gql`
  mutation UpdateOrCreateShippingAddressMutation(
    $input: UpdateOrCreateShippingAddressMutationInput!
  ) {
    updateOrCreateShippingAddressMutation(input: $input) {
      address {
        ...AddressFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ADDRESS_FIELDS}
`;

export const SAVE_SHIPPING_ADDRESS_AT_CHECKOUT_MUTATION = gql`
  mutation SaveShippingAddressAtCheckoutMutation(
    $input: SaveShippingAddressAtCheckoutMutationInput!
  ) {
    saveShippingAddressAtCheckoutMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      address {
        id
        name
        line1
        line2
        city
        postcode
        country {
          id
          name
          isoCode
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const SAVE_ORGANISATION_SHIPPING_ADDRESS_MUTATION = gql`
  mutation SaveOrganisationShippingAddressMutation(
    $input: SaveOrganisationShippingAddressMutationInput!
  ) {
    saveOrganisationShippingAddressMutation(input: $input) {
      organisation {
        ...OrganisationFields
      }
      address {
        id
        name
        line1
        line2
        city
        postcode
        country {
          id
          name
          isoCode
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_FIELDS}
`;

export const FINALISE_BASKET_MUTATION = gql`
  mutation FinaliseBasketMutation($input: FinaliseBasketMutationInput!) {
    finaliseBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      order {
        id
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const UPDATE_ORGANISATION_DETAILS_MUTATION = gql`
  mutation UpdateOrganisationDetailsMutation($input: UpdateOrganisationDetailsMutationInput!) {
    updateOrganisationDetailsMutation(input: $input) {
      organisation {
        ...OrganisationFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_FIELDS}
`;

export const UPDATE_AUTOMATIC_INVOICING_SETTING_MUTATION = gql`
  mutation UpdateAutomaticInvoicingSettingMutation(
    $input: UpdateAutomaticInvoicingSettingMutationInput!
  ) {
    updateAutomaticInvoicingSettingMutation(input: $input) {
      organisation {
        ...OrganisationFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ORGANISATION_FIELDS}
`;

export const CREATE_PAYMENT_INTENT_MUTATION = gql`
  mutation CreatePaymentIntentMutation($input: CreatePaymentIntentMutationInput!) {
    createPaymentIntentMutation(input: $input) {
      paymentIntent {
        id
        url
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION = gql`
  mutation CreatePaymentCheckoutSessionMutation(
    $input: CreatePaymentCheckoutSessionMutationInput!
  ) {
    createPaymentCheckoutSessionMutation(input: $input) {
      address {
        ...AddressFields
      }
      basket {
        ...OrganisationBasketFields
      }
      paymentCheckoutSession {
        id
        url
      }
      organisation {
        ...OrganisationFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ADDRESS_FIELDS}
  ${ORGANISATION_FIELDS}
  ${ORGANISATION_BASKET_FIELDS}
`;

export const CREATE_STOCK_PAYMENT_CHECKOUT_SESSION_MUTATION = gql`
  mutation CreateStockPaymentCheckoutSessionMutation(
    $input: CreateStockPaymentCheckoutSessionMutationInput!
  ) {
    createStockPaymentCheckoutSessionMutation(input: $input) {
      paymentCheckoutSession {
        id
        url
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const ADD_ON_TO_STOCK_MUTATION = gql`
  mutation AddOnToStockMutation($input: AddOnToStockMutationInput!) {
    addOnToStockMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const REMOVE_ADD_ON_FROM_STOCK_MUTATION = gql`
  mutation RemoveAddOnFromStockMutation($input: RemoveAddOnFromStockMutationInput!) {
    removeAddOnFromStockMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PAYMENT_INVOICE_MUTATION = gql`
  mutation CreatePaymentInvoiceMutation($input: CreatePaymentInvoiceMutationInput!) {
    createPaymentInvoiceMutation(input: $input) {
      address {
        ...AddressFields
      }
      basket {
        ...OrganisationBasketFields
      }
      paymentInvoice {
        id
        url
      }
      order {
        id
      }
      organisation {
        ...OrganisationFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ADDRESS_FIELDS}
  ${ORGANISATION_FIELDS}
  ${ORGANISATION_BASKET_FIELDS}
`;

export const CREATE_STOCK_PAYMENT_INVOICE_MUTATION = gql`
  mutation CreateStockPaymentInvoiceMutation($input: CreateStockPaymentInvoiceMutationInput!) {
    createStockPaymentInvoiceMutation(input: $input) {
      paymentInvoice {
        id
        url
      }
      stock {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION = gql`
  mutation CreatePatientOrderFromBasketMutation(
    $input: CreatePatientOrderFromBasketMutationInput!
  ) {
    createPatientOrderFromBasketMutation(input: $input) {
      address {
        ...AddressFields
      }
      basket {
        ...OrganisationBasketFields
      }
      order {
        id
      }
      organisation {
        ...OrganisationFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${ADDRESS_FIELDS}
  ${ORGANISATION_FIELDS}
  ${ORGANISATION_BASKET_FIELDS}
`;

export const INVITE_YOUR_PATIENT_MUTATION = gql`
  mutation InviteYourPatientMutation($input: InviteYourPatientMutationInput!) {
    inviteYourPatientMutation(input: $input) {
      patient {
        id
        platformUserProfile {
          id
          pk
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const RESEND_INVITE_MUTATION = gql`
  mutation ResendOrganisationInviteMutation($organisationId: ID!, $inviteId: ID!) {
    resendOrganisationInviteMutation(organisationId: $organisationId, inviteId: $inviteId) {
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_INVITE_MUTATION = gql`
  mutation DeleteOrganisationInviteMutation($organisationId: ID!, $inviteId: ID!) {
    deleteOrganisationInviteMutation(organisationId: $organisationId, inviteId: $inviteId) {
      errors {
        field
        messages
      }
    }
  }
`;

export const REQUEST_ACCESS_TO_RESULTS_MUTATION = gql`
  mutation RequestAccessToResultsMutation($input: RequestAccessToResultsMutationInput!) {
    requestAccessToResultsMutation(input: $input) {
      resultsAccessLink {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const RESEND_REQUEST_TO_ACCESS_RESULTS_MUTATION = gql`
  mutation ResendRequestToAccessResultsMutation(
    $input: ResendRequestToAccessResultsMutationInput!
  ) {
    resendRequestToAccessResultsMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_ORGANISATION_INVITE_MUTATION = gql`
  mutation AcceptOrganisationInviteMutation($input: AcceptOrganisationInviteMutationInput!) {
    acceptOrganisationInviteMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const SAVE_PATIENT_SHIPPING_ADDRESS_MUTATION = gql`
  mutation SavePatientShippingAddressMutation($input: SavePatientShippingAddressMutationInput!) {
    savePatientShippingAddressMutation(input: $input) {
      address {
        ...AddressFields
      }
      patient {
        id
        shippingAddress {
          ...AddressFields
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${ADDRESS_FIELDS}
`;
