import { useEffect } from "react";

import { useHistory, Route, useLocation } from "react-router-dom";

import { useAuthContext } from "components/AuthProvider";
import Loading from "components/Loading";
import { useUserProfileContext } from "contexts/UserProfileContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PARTNERS_DASHBOARD_URL, PARTNERS_SIGNIN_URL, PARTNERS_URL } from "core/urls";

export default function PartnerRoute({ permissionsRequired, ...props }) {
  const { user } = useAuthContext();
  const { isPartnerUser, loading: userLoading } = useUserProfileContext();
  const { organisation, permissions, loading } = useOrganisationContext();
  const history = useHistory();
  const location = useLocation();

  // Redirect to login page if the user is loaded but is in fact null
  useEffect(() => {
    if (!user) {
      history.push(`${PARTNERS_SIGNIN_URL}?next=${location.pathname}`);
    }
  }, [user, history, location]);

  useEffect(() => {
    if (userLoading) {
      return;
    }
    if (isPartnerUser === false) {
      history.push(PARTNERS_URL);
      return;
    }

    if (loading || !organisation) {
      return;
    }

    if (permissionsRequired?.length) {
      if (permissionsRequired.some(permission => permissions.includes(permission))) {
        return;
      } else {
        history.push(PARTNERS_DASHBOARD_URL);
      }
    }
  }, [
    permissionsRequired,
    permissions,
    organisation,
    history,
    loading,
    isPartnerUser,
    userLoading
  ]);

  if (loading) {
    return <Loading />;
  }
  return <Route {...props} />;
}
