import { Fragment } from "react";

import { useFormContext } from "react-hook-form";

import Box from "components/Box";
import Divider from "components/Divider";
import Loading from "components/Loading";
import { FileInputWrapper, UPLOADING_STATUS, useUploadFileToS3 } from "components/UploadFileToS3";
import { ReactComponent as FileIcon } from "images/file-icon.svg";
import Group from "tpo/Group";
import InputWrapper from "tpo/InputWrapper";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

function UploadQualification({ name }) {
  const { setValue, watch } = useFormContext();
  const documents = watch("documents");

  const { status, upload } = useUploadFileToS3({
    onChange: ({ key, file }) =>
      setValue("documents", [...documents, { key, name: file.name, type: name }])
  });

  const document = documents.find(d => d.type === name);

  return (
    <Stack gap={10}>
      <Box fontFamily="gilroyBold" fontSize={16}>
        Qualification - {name}
      </Box>
      <Group
        alignItems="center"
        justifyContent="space-between"
        gap={20}
        flexWrap="wrap"
        maxWidth="100%"
      >
        <Group
          gap={20}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden"
          }}
          alignItems="center"
        >
          {status === UPLOADING_STATUS ? (
            <Loading minHeight={40} minWidth={40} size={20} />
          ) : (
            <FileIcon />
          )}
          <Box
            fontFamily="gilroyBold"
            fontSize={12}
            letterSpacing="2.8px"
            textTransform="uppercase"
          >
            {`${document?.name || "Add a file"}`}
          </Box>
        </Group>
        {!document ? (
          <FileInputWrapper>
            <input type="file" onChange={upload} disabled={status === UPLOADING_STATUS} />
            <ButtonV2
              color="green"
              type="button"
              data-component-name={`addQualificationFileButton:${name}`}
              size="sm"
            >
              upload
            </ButtonV2>
          </FileInputWrapper>
        ) : (
          <ButtonV2
            color="red"
            size="sm"
            onClick={() =>
              setValue("documents", [...documents.filter(doc => doc.key !== document.key)])
            }
            data-component-name={`removeQualificationFileButton:${name}`}
          >
            remove
          </ButtonV2>
        )}
      </Group>
    </Stack>
  );
}

export default function ProveQualificationsField({ practitionerTypes }) {
  const { watch, getFieldState } = useFormContext();
  const practitionerTypeValue = watch("practitionerType");

  const { error } = getFieldState("documents");

  const qualificationTypes = practitionerTypes.find(({ id }) => id === practitionerTypeValue)
    ?.qualificationTypes;

  return (
    <>
      <InputWrapper
        error={error?.message}
        data-component-name="qualificationProofField"
        input={
          <Stack gap={20}>
            {!!qualificationTypes?.length &&
              qualificationTypes.map((qt, idx) => (
                <Fragment key={qt}>
                  <UploadQualification key={qt} name={qt} />
                  {idx !== qualificationTypes.length && <Divider />}
                </Fragment>
              ))}
          </Stack>
        }
      />

      {/* <Stack gap={20}>
        {documents.map(d => (
          <Group
            alignItems="center"
            justifyContent="space-between"
            key={d.key}
            gap={20}
            flexWrap="wrap"
            maxWidth="100%"
          >
            <Group
              gap={20}
              alignItems="center"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              <FileIcon />
              <Box fontFamily="gilroyBold" fontSize={12} letterSpacing="2.8px">
                {d.name}
              </Box>
            </Group>
            <ButtonV2
              disabled={false}
              color="red"
              px={20}
              py={2}
              onClick={() => setValue("documents", [...documents.filter(doc => doc.key !== d.key)])}
            >
              remove
            </ButtonV2>
          </Group>
        ))}
        <InputWrapper
          error={error?.message}
          input={
            <UploadFileToS3
              label="Upload certification"
              onChange={(key, file) =>
                setValue("documents", [...documents, { key, name: file.name }])
              }
            />
          }
          mr="auto"
        />
      </Stack> */}
    </>
  );
}
