import { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { Spinner } from "components/Spinner";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ORGANISATION_ONBOARDING_STATUS } from "graphql/organisations/shared";
import useBackoff from "hooks/use-backoff";

export default function OnboardingStatusChecker({ onChange = () => {} }) {
  const { organisation, refetchOrganisations } = useOrganisationContext();
  const [spinning, setSpinning] = useState(true);
  const [backoff, { next }] = useBackoff({
    initial: 1000,
    max: 8000
  });

  const [fetchStatus, { data, loading, error }] = useLazyQuery(ORGANISATION_ONBOARDING_STATUS, {
    variables: {
      organisation: organisation?.id
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (organisation) {
      setSpinning(true);
      fetchStatus();
    }
  }, [organisation]);

  useEffect(() => {
    if (data && !loading) {
      if (data.connectedAccount.onboardingStatus === "IN_PROGRESS") {
        let timeout = setTimeout(() => {
          setSpinning(true);
          fetchStatus();
        }, backoff);

        setSpinning(false);
        next();
        return () => {
          clearTimeout(timeout);
        };
      } else {
        refetchOrganisations();
        setSpinning(false);
      }
    }
  }, [data, loading]);

  useEffect(() => {
    onChange(spinning);
  }, [spinning, onChange]);

  if (spinning) {
    return <Spinner />;
  }

  return null;
}
