import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { REGENERUS_REGENCY } from "components/organisations/constants";
import { getAPIBase } from "core/config";
import ChevronComponent from "tpo/Chevron";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function DownloadRequisitionPanel({ stock: { orderTestItem, testProduct } }) {
  if (testProduct.providerApi === REGENERUS_REGENCY) {
    return (
      <Stack gap={[20, 20, 40]}>
        <Box>
          <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
            Thanks for registering
          </Box>
          <Spacer py={2} />
          <Box fontSize={[14, 14, 16]} fontFamily="gilroyMedium">
            Please download and print your lab requisition form and add it to the kit before giving
            it to your client. This is required for the test to be processed by the labs. Failure to
            do so will mean that your sample is unable to be processed.
          </Box>
          <Spacer py={2} />
        </Box>
        <ExternalTextLink
          href={`${getAPIBase()}/requisition/download/${orderTestItem.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonV2
            color="dark"
            rightIcon={<ChevronComponent />}
            mr="auto"
            size={["sm", "sm", "md"]}
          >
            Download
          </ButtonV2>
        </ExternalTextLink>
        <Box color="red" fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          !! Important: This kit can only be used by the patient it has been registered to.
        </Box>
      </Stack>
    );
  }

  return (
    <Stack gap={[20, 20, 40]}>
      <Box>
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
          Thanks for registering
        </Box>
        <Spacer py={2} />
        <Box fontSize={[14, 14, 16]} fontFamily="gilroyMedium">
          Please ensure this kit is passed on to the registered patient to ensure that the results
          are linked to their sample.
        </Box>
        <Spacer py={2} />
      </Box>
      <Box color="red" fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
        !! Important: This kit can only be used by the patient it has been registered to.
      </Box>
    </Stack>
  );
}
