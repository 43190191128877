import { useState } from "react";

import { theme } from "core/theme";
import FilterIcon from "images/Filter";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import { startCase } from "lodash";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import Drawer from "tpo/Drawer";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2, { ActionIcon, CloseButton } from "v2/Buttons";

import Box from "./Box";
import StyledCloseButton from "./CloseButton";

export default function ProductFilters({
  allFilters,
  filtersTitle,
  drawerBg,
  color,
  borderColor,
  validatedFilters,
  toggleFilter
}) {
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);

  return (
    <>
      <ButtonV2
        variant="pill"
        type="button"
        color="dark"
        onClick={() => setShowFiltersDrawer(!showFiltersDrawer)}
        leftIcon={<FilterIcon fill="white" size={20} />}
        size="md"
        sx={{
          display: ["none", "none", "inline-flex"]
        }}
      >
        Show filters
      </ButtonV2>
      <ActionIcon
        bg="dark"
        size="sm"
        variant="circle"
        onClick={() => setShowFiltersDrawer(!showFiltersDrawer)}
        sx={{
          display: ["block", "block", "none"]
        }}
      >
        <FilterIcon fill="white" size={20} />
      </ActionIcon>
      <Drawer.Provider
        position="top"
        opened={showFiltersDrawer}
        close={() => setShowFiltersDrawer(false)}
      >
        <Drawer.Root bg={drawerBg} overflow="auto">
          <PanelBoxV2
            maxWidth={1538}
            outer={{
              px: [20, 20, "5.5vw"],
              pt: 40,
              pb: [60, 60, 120]
            }}
          >
            <Group justifyContent="flex-end" py={[40, 40, 20]}>
              <StyledCloseButton
                color={theme.colors.dark}
                onClick={() => setShowFiltersDrawer(false)}
                bg="white"
              />
            </Group>
            <Stack flexDirection={["column", "row"]} gap={[20, 20, 40]}>
              <Group alignItems="center" gap={20}>
                <ActionIcon p={0}>
                  <FilterIcon fill={color} />
                </ActionIcon>
                <Box
                  as="h3"
                  fontSize={[28, 28, 36]}
                  fontFamily="gilroyBold"
                  lineHeight="130%"
                  color={color}
                >
                  {filtersTitle}
                </Box>
              </Group>
              <ButtonV2
                color="green"
                rightIcon={<ChevronComponent />}
                mr={["auto"]}
                onClick={() => setShowFiltersDrawer(false)}
                size={["sm", "sm", "md"]}
                type="button"
              >
                save
              </ButtonV2>
            </Stack>
            <Spacer py={[2, 2, 20]} />
            <Stack gap={[40, 40, 80]}>
              {Object.entries(allFilters).map(([k, values]) => (
                <Stack key={k} gap={[20, 20, 40]}>
                  <Box
                    fontFamily="gilroyBold"
                    fontSize={14}
                    letterSpacing="2.8px"
                    textTransform="uppercase"
                    color={color}
                  >
                    {startCase(k)}
                  </Box>
                  <Group flexWrap="wrap" gap={[10, 10, 20]}>
                    {values.map(val => (
                      <ButtonV2
                        key={val}
                        leftIcon={
                          validatedFilters[k].includes(val) ? (
                            <CrossIcon fill="white" width={10} />
                          ) : null
                        }
                        variant="pill"
                        size={["xs", "xs", "sm"]}
                        sx={{
                          color,
                          bg: validatedFilters[k].includes(val) ? "green" : "transparent",
                          borderStyle: "solid",
                          borderWidth: validatedFilters[k].includes(val) ? 0 : 2,
                          borderColor,
                          gap: 10
                        }}
                        onClick={() => {
                          toggleFilter(k, val);
                        }}
                        type="button"
                      >
                        {val}
                      </ButtonV2>
                    ))}
                  </Group>
                </Stack>
              ))}
            </Stack>
          </PanelBoxV2>
        </Drawer.Root>
      </Drawer.Provider>
    </>
  );
}

ProductFilters.defaultProps = {
  borderColor: "white",
  color: "white"
};
