import { useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import OnboardingStatusChecker from "components/notifications/ui/OnboardingStatusChecker";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import ChevronComponent from "tpo/Chevron";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import StripeCalloutBanner from "./StripeCalloutBanner";

export default function StripeConnectSetupPanel() {
  const { organisation } = useOrganisationContext();
  const [checkingStatus, setCheckingStatus] = useState(false);

  return (
    <>
      <Stack gap={30}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Link your bank account
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          Before you can add clients or receive commissions you’ll need to link a bank account for
          payment. Once completed you will automatically receive commission payouts to your bank
          account on a monthly basis as well as be able to order stock tests.
        </Box>
      </Stack>
      <Stack gap={40}>
        <Stack gap={30}>
          <Box fontFamily="gilroyBold" fontSize={18}>
            Payment provider
          </Box>
          <StripeCalloutBanner fontSize={[14, 14, 16]} />
        </Stack>
        <ButtonV2
          as={ExternalTextLink}
          href={organisation?.onboardingUrl}
          target="_self"
          rel="noopener noreferrer"
          color="green"
          rightIcon={<ChevronComponent opacity={checkingStatus ? 0 : 1} />}
          type="button"
          size={["sm", "sm", "md"]}
          underline={false}
          mx="auto"
        >
          Complete Set Up
          <OnboardingStatusChecker onChange={active => setCheckingStatus(active)} />
        </ButtonV2>
      </Stack>
    </>
  );
}
