import { createContext, useContext } from "react";

import Box from "components/Box";
import Circle from "components/Circle";

import Accordion from "./Accordion";
import Group from "./Group";

const NavLinkContext = createContext();

export default function NavLink({
  children,
  color,
  disabled,
  label,
  active,
  onClick,
  rightIcon,
  leftIcon,
  as,
  fontSize,
  ...rest
}) {
  const isNested = useContext(NavLinkContext);

  const innerContent = (
    <>
      {active && (
        <Circle
          backgroundColor="green"
          position="absolute"
          top="50%"
          size={5}
          transform="translate(-50%, -50%)"
          left="-10px"
          data-component-name="NavLink-active"
        />
      )}
      <Group
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        alignItems={["unset", "center"]}
        flexGrow={1}
        gap={10}
        onClick={disabled ? null : onClick}
        cursor={disabled ? "not-allowed" : "pointer"}
        as={as}
        {...rest}
      >
        <Group alignItems="center" gap={10}>
          {leftIcon}
          <Box as="span" color={disabled ? "rgba(255, 255, 255, 0.30)" : color}>
            {label}
          </Box>
        </Group>
        {rightIcon}
      </Group>
    </>
  );

  const conditionallyNestedProps = isNested
    ? {
        fontSize: fontSize || 16
      }
    : {
        fontSize: fontSize || 24,
        borderBottomColor: "rgba(255, 255, 255, 0.30)",
        borderBottomWidth: 1,
        borderBottomStyle: "solid"
      };

  if (children) {
    return (
      // navLink context is purely so we know if we are within a nested navlink
      <NavLinkContext.Provider value={true}>
        <Accordion color={color}>
          <Accordion.Item value={label} data-component-name="NavLink">
            <Accordion.Control
              bg="transparent"
              borderBottomColor="grey"
              borderBottomWidth={1}
              borderBottomStyle="solid"
              fontFamily="gilroyBold"
              borderRadius={0}
              py={[18, 18, 20]}
              expandable
              position="relative"
              fontSize={fontSize || 24}
            >
              {innerContent}
            </Accordion.Control>
            <Accordion.Panel bg="transparent" px={20} py={20}>
              {children}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </NavLinkContext.Provider>
    );
  } else {
    return (
      <Box
        position="relative"
        fontFamily="gilroyBold"
        data-component-name="NavLink"
        py={[18, 18, 20]}
        {...conditionallyNestedProps}
      >
        {innerContent}
      </Box>
    );
  }
}
