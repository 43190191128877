import { useContext, useMemo } from "react";

import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";

import Box from "../components/Box";
import { getTestProductQuestionnaireOutroUrl } from "../core/urls";
import { QUESTIONNAIRE_QUERY } from "../graphql/tpo/content/queries";
import { SUBMIT_USER_ANSWER_MUTATION } from "../graphql/tpo/results/mutations";
import Center from "./Center";
import { allQuestionsHaveBeenAnswered } from "./CompleteQuestionnaireQuestions";
import { HeadingExtraExtraSmall } from "./Headings";
import SectionProgressBar from "./QuestionSectionProgressBar";
import Answers from "./QuestionnaireAnswers";
import { SubmitButton } from "./QuestionnaireButtons";
import {
  AnswersContext,
  DownloadQuestionsContext,
  SubmissionContext
} from "./QuestionnaireContexts";
import { useDownloadQuestions, useSubmission, useTestProductSlug } from "./QuestionnaireHooks";
import Page from "./QuestionnairePages";
import { Meta } from "./QuestionnaireQuestion";
import Questions from "./QuestionnaireQuestions";
import { padQuestionNumber } from "./QuestionnaireUtilities";
import Spacer from "./Spacer";

function DownloadQuestionsForTestProductQuestionnaire({ children, query, variables }) {
  const { loading, questions, answers } = useDownloadQuestions({ query, variables });
  const submission = useContext(SubmissionContext);

  const api = useMemo(
    () => ({
      loading,
      questions,
      answers,
      submission
    }),
    [loading, questions, answers, submission]
  );

  return (
    <DownloadQuestionsContext.Provider value={api}>{children}</DownloadQuestionsContext.Provider>
  );
}

function TestProductQuestionnaireNavigation() {
  const submission = useSubmission();
  const { questions } = useContext(DownloadQuestionsContext);
  const { answered, setShowErrors } = useContext(AnswersContext);
  const testProductSlug = useTestProductSlug();
  const history = useHistory();

  // whereas the complete questionnaire submits answer one by one
  // we submit them all in one go at the end for this questionnaire because it can
  // be taken without user being logged in
  // i.e. so reduce rows created in DB if user maliciously answers questions
  const [submitAnswersMutation] = useMutation(SUBMIT_USER_ANSWER_MUTATION);

  return (
    <Box display="flex" alignItems="center" mt={4}>
      <SubmitButton
        text="Submit"
        onClick={() => {
          if (questions?.length && allQuestionsHaveBeenAnswered(questions, answered)) {
            setShowErrors(true);
            return;
          }
          submitAnswersMutation({
            variables: {
              input: {
                submission: submission.id,
                // Double check order does not matter
                // so long as each answer index corresponds to question at same index
                // in questions array
                question: Object.keys(answered)
                  .map(questionId => +questionId)
                  .join(","),
                answer: Object.values(answered)
                  .map(answer => +answer)
                  .join(","),
                partial: true
              }
            }
          })
            .then(() => {
              // We don't envisage any errors at app level
              setShowErrors(false);
              history.push({
                pathname: getTestProductQuestionnaireOutroUrl(testProductSlug, "symptoms"),
                state: {
                  fromSection: true
                }
              });
            })
            .catch(e => {
              console.log("Problem submitting answers", e);
            });
        }}
      />
    </Box>
  );
}

function TestProductQuestionsMeta({ questionIndex }) {
  const { questions } = useContext(DownloadQuestionsContext);

  return (
    <Meta
      rightText={`${padQuestionNumber(questionIndex + 1, questions.length)}/${questions.length}`}
    />
  );
}

function TestProductProgressBar() {
  const { answered } = useContext(AnswersContext);
  const { questions } = useContext(DownloadQuestionsContext);

  if (!questions?.length || !answered) return "...";

  return (
    <>
      <HeadingExtraExtraSmall fontSize={10} mb={2} textTransform="uppercase">{`progress - ${
        Object.keys(answered).length
      } / ${questions.length} questions`}</HeadingExtraExtraSmall>
      <SectionProgressBar />
    </>
  );
}

function TestProductQuestionnairePage() {
  const submission = useSubmission();
  const productSlug = useTestProductSlug();

  return (
    <SubmissionContext.Provider value={submission}>
      <DownloadQuestionsForTestProductQuestionnaire
        query={QUESTIONNAIRE_QUERY}
        variables={{
          productSlug,
          userSubmissionId: submission?.id
        }}
      >
        <Answers>
          <Page
            Main={
              <Questions
                navigation={<TestProductQuestionnaireNavigation />}
                partial
                QuestionMetaComponent={TestProductQuestionsMeta}
              />
            }
            Footer={
              <>
                <Spacer pt={62} />
                <Box bg="white" position="fixed" py={4} left={0} bottom={0} width="100vw">
                  <Center px={20}>
                    <Box maxWidth={1020} width="100%" pt={40}>
                      <TestProductProgressBar mx="auto" px={2} />
                    </Box>
                  </Center>
                </Box>
              </>
            }
          />
        </Answers>
      </DownloadQuestionsForTestProductQuestionnaire>
    </SubmissionContext.Provider>
  );
}

export default TestProductQuestionnairePage;
