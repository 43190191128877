export default function ThinSearchIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1008 19.005C15.4666 19.005 19.0058 15.4658 19.0058 11.1C19.0058 6.73419 15.4666 3.195 11.1008 3.195C6.73497 3.195 3.19578 6.73419 3.19578 11.1C3.19578 15.4658 6.73497 19.005 11.1008 19.005ZM11.1008 20.4C16.237 20.4 20.4008 16.2363 20.4008 11.1C20.4008 5.96375 16.237 1.8 11.1008 1.8C5.96453 1.8 1.80078 5.96375 1.80078 11.1C1.80078 16.2363 5.96453 20.4 11.1008 20.4Z"
        fill="#1A1A1A"
      />
      <rect
        x="17.2246"
        y="16.1358"
        width="7.03552"
        height="1.5406"
        rx="0.770301"
        transform="rotate(45 17.2246 16.1358)"
        fill="#1A1A1A"
      />
    </svg>
  );
}
