import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";

import BasketErrorModal from "./BasketErrorModal";

export default function StockPatientErrorModal() {
  const { hasStockPatientError } = useOrganisationBasketContext();

  return (
    <BasketErrorModal
      explanation={`Patient payment option is not applicable for stock items. If you want to return to a patient basket, please switch off the order as stock.`}
      show={hasStockPatientError}
    />
  );
}
