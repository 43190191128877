import { createContext } from "react";

import Box from "components/Box";
import Loading from "components/Loading";
import usePrevious from "hooks/usePrevious";

import Center from "./Center";

export const ListContext = createContext();

export function List({ items: newItems, loading, display, children }) {
  const previousItems = usePrevious(newItems);
  const items = newItems || previousItems;

  return (
    <Box display={display} position="relative">
      <ListContext.Provider value={items}>{children}</ListContext.Provider>
      {loading && (
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Center height="100%" position="relative">
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="haze"
              opacity={0.5}
            />
            <Loading />
          </Center>
        </Box>
      )}
    </Box>
  );
}
