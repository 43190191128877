import { FormProvider } from "react-hook-form";

import Box from "components/Box";
import { ACTIVATE_KIT_MUTATION } from "graphql/providers";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import { sortBy } from "lodash";
import Checkbox from "tpo/Checkbox";
import ControlledFormField from "tpo/ControlledFormField";
import ControlledRadioGroup from "tpo/ControlledRadioGroup";
import FormControl from "tpo/FormControl";
import { Error } from "tpo/InputWrapper";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function ProductSpecificQuestionsForm({
  confirmationField,
  registrableProduct,
  questions,
  testItemId,
  extraFormFields = {},
  setStep,
  onCancel
}) {
  const questionFormFields = questions.reduce(
    (acc, question) => ({ ...acc, [question.id]: "" }),
    {}
  );

  const activateKitFormApi = useDjangoGraphqlForm({
    mutation: ACTIVATE_KIT_MUTATION,
    mutationName: "activateTestItemMutation",
    defaultValues: {
      ...(questions?.length
        ? {
            questions: questionFormFields
          }
        : {}),
      testConsent: false,
      ...(confirmationField && confirmationField?.fullName && confirmationField?.dateOfBirth
        ? { confirmation: false }
        : {})
    },
    transformer: input => {
      return {
        ...(input.questions
          ? {
              questionsAndAnswers: Object.entries(input.questions).map(
                ([questionId, answerId]) => ({
                  question: questionId,
                  answer: answerId
                })
              )
            }
          : {}),
        testConsent: input.testConsent,
        testItemId,
        confirmTestItemId: testItemId,
        confirmation: input.confirmation,
        ...extraFormFields
      };
    },
    handleSuccess: () => {
      setStep(2);
    }
  });

  return (
    <>
      <Box maxWidth={760} width="100%">
        <Box fontFamily="gilroyMedium" fontSize={16} maxWidth={760} width="100%">
          {registrableProduct.questionsHeader}
        </Box>
      </Box>
      <Stack
        as="form"
        maxWidth={600}
        width="100%"
        gap={40}
        onSubmit={activateKitFormApi.methods.handleSubmit(activateKitFormApi.onSubmit)}
      >
        {activateKitFormApi.nonFieldError && (
          <Error data-testid="nonFieldError" error={activateKitFormApi.nonFieldError} />
        )}
        <FormProvider {...activateKitFormApi.methods}>
          {questions.map(q => (
            <ControlledRadioGroup
              key={q.id}
              label={q.question}
              labelProps={{
                mb: 4
              }}
              Container={Stack}
              containerProps={{
                gap: 20
              }}
              name={`questions.${q.id}`}
              values={sortBy(q.answers, ["order"]).map(a => ({ label: a.answer, value: a.id }))}
            />
          ))}
          <FormControl
            label={
              <Box fontFamily="gilroyMedium" fontSize={16} data-testid="finalInstructions">
                {registrableProduct.finalInstructions}
              </Box>
            }
            fields={
              <>
                <ControlledFormField
                  label={registrableProduct.consent}
                  Component={Checkbox}
                  name="testConsent"
                />
                {confirmationField &&
                  confirmationField?.fullName &&
                  confirmationField?.dateOfBirth && (
                    <ControlledFormField
                      label={`I confirm that ${confirmationField.fullName} with DOB ${confirmationField.dateOfBirth} is the person who will take the test`}
                      Component={Checkbox}
                      name="confirmation"
                    />
                  )}
              </>
            }
          />
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            gap={20}
            alignItems={["flex-start", "flex-start", null]}
          >
            <ButtonV2 color="red" onClick={onCancel} type="button" size={["sm", "sm", "md"]}>
              cancel
            </ButtonV2>
            <ButtonV2 color="green" type="submit" size={["sm", "sm", "md"]}>
              submit
            </ButtonV2>
          </Box>
        </FormProvider>
      </Stack>
    </>
  );
}
