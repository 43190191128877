import { CustomerAccountPage } from "components/CustomerPage";
import Text from "components/Text";
import useDocTitle from "hooks/use-doc-title";

export default function ResetAcknowledged({ partners }) {
  useDocTitle("Request password reset done");
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Password reset link sent",
        bg: partners ? "partners" : "purple",
        color: partners ? "dark" : "white"
      }}
    >
      <Text>A password reset link has been sent to the email address you provided.</Text>
      <Text pt={40}>Please check your inbox.</Text>
    </CustomerAccountPage>
  );
}
