import { useMemo, useState } from "react";

import { FormProvider } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import { theme } from "core/theme";
import { REGISTER_KIT_URL } from "core/urls";
import { VALIDATE_KIT_MUTATION } from "graphql/providers";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import Center from "tpo/Center";
import ControlledFormField from "tpo/ControlledFormField";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import ChooseProductSelect from "./ChooseProductSelect";
import ProductSpecificFields from "./ProductSpecificFields";
import ProductSpecificQuestionsForm from "./ProductSpecificQuestionsForm";

export default function RegisterKitPageContent({
  products,
  gender,
  userId,
  confirmationField,
  extraFormFields
}) {
  useDocTitle("Register test kit");

  const [step, setStep] = useState(0);

  const TEST_OPTIONS = useMemo(() => {
    return products.map(registrableProduct => ({
      value: registrableProduct.testProduct.id,
      content: registrableProduct.testProduct.truncatedName,
      testOption: registrableProduct
    }));
  }, [products]);

  const validateKitFormApi = useDjangoGraphqlForm({
    mutation: VALIDATE_KIT_MUTATION,
    mutationName: "validateTestItemMutation",
    defaultValues: {
      testItemId: "",
      confirmTestItemId: "",
      testProductId: ""
    },
    handleSuccess: () => {
      setStep(1);
    }
  });

  const testProductId = validateKitFormApi.methods.getValues("testProductId");
  const registrableProduct = products?.find(p => p.testProduct.id === testProductId);

  const location = useLocation();
  const history = useHistory();

  const registerAnotherTestOnClick = () => {
    return history.push(REGISTER_KIT_URL, {
      key: location.state?.key === undefined ? 1 : location.state.key + 1
    });
  };

  return (
    <CustomerPage
      jumbotronProps={{
        title: "Kit registration"
      }}
    >
      <Center
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        px={[20]}
        stacked
        gap={40}
      >
        {step === 0 && (
          <>
            <Box maxWidth={760} width="100%">
              <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Register your kit
              </Box>
              <Spacer py={[2, 2, 15]} />
              <Box fontFamily="gilroyMedium" fontSize={16} maxWidth={760} width="100%">
                Before you return your sample we need you to link your kit with your account. Please
                fill in your details below to continue.
              </Box>
            </Box>
            <Stack
              as="form"
              maxWidth={600}
              width="100%"
              gap={40}
              onSubmit={validateKitFormApi.methods.handleSubmit(validateKitFormApi.onSubmit)}
            >
              <FormProvider {...validateKitFormApi.methods}>
                <ControlledFormField
                  name="testProductId"
                  label="Select your test"
                  Component={ChooseProductSelect}
                  items={TEST_OPTIONS}
                />
                <ProductSpecificFields
                  testProducts={TEST_OPTIONS}
                  nonFieldError={validateKitFormApi.nonFieldError}
                  registrableProducts={products}
                />
              </FormProvider>
            </Stack>
          </>
        )}
        {step === 1 && (
          <ProductSpecificQuestionsForm
            registrableProduct={products.find(p => p.testProduct.id === testProductId)}
            questions={registrableProduct.questions.filter(q => !q.gender || q.gender === gender)}
            testItemId={validateKitFormApi.methods.getValues("testItemId")}
            confirmationField={confirmationField}
            extraFormFields={{
              userId: userId || "",
              testProductId,
              ...extraFormFields
            }}
            setStep={setStep}
            onCancel={registerAnotherTestOnClick}
          />
        )}
        {step === 2 && (
          <Stack maxWidth={760} width="100%" stacked gap={40}>
            <Box>
              <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                Complete
              </Box>
              <Spacer py={[2, 2, 15]} />
              <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                Congratulations! Your test is now registered and will be linked to your account.
                Please follow the instructions on how to take the test, post and return to us. You
                will be notified via email when your results are ready to be viewed.
              </Box>
            </Box>
            <ButtonV2
              color="green"
              mx="auto"
              onClick={registerAnotherTestOnClick}
              size={["sm", "sm", "md"]}
            >
              Register another test
            </ButtonV2>
          </Stack>
        )}
      </Center>
    </CustomerPage>
  );
}
