import Box from "components/Box";
import { PAYEE_PATIENT } from "components/organisations/constants";
import Currency from "tpo/Currency";
import Spacer from "tpo/Spacer";

export function BasketTotals({ basket, payee, showShipping = true }) {
  const TOTAL_FIELDS = [
    {
      label: "RRP",
      strikethrough: true,
      color: "midGrey",
      fontSize: 28,
      value: basket => basket?.basketRrpTotalPrice,
      symbol: basket => basket?.currencySymbol,
      visible: true
    },
    {
      label: "DISCOUNT",
      color: "red",
      fontSize: 28,
      value: basket => basket?.basketTotalDiscount,
      symbol: basket => basket?.currencySymbol,
      visible: basket => basket?.basketTotalDiscount > 0
    },
    {
      label: "SHIPPING",
      fontSize: 28,
      value: basket => basket?.basketShippingCost,
      symbol: basket => basket?.currencySymbol,
      visible: basket => basket?.basketShippingCost >= 0 && showShipping
    },
    {
      label: "TOTAL",
      color: "dark",
      fontSize: 44,
      value: basket =>
        showShipping
          ? basket?.basketTotalPrice
          : basket?.basketTotalPrice - basket?.basketShippingCost,
      symbol: basket => basket?.currencySymbol,
      visible: true
    },
    {
      label: "COMMISSION",
      color: "dark",
      fontSize: 28,
      value: basket => basket?.basketTotalCommission,
      symbol: basket => basket?.currencySymbol,
      visible: basket => basket?.payee === PAYEE_PATIENT
    }
  ];

  return (
    <>
      {TOTAL_FIELDS.map(field => {
        let visible = typeof field.visible === "function" ? field.visible(basket) : field.visible;
        if (visible) {
          return (
            <Box key={`total-${field.label.toLowerCase()}`}>
              <Currency
                symbol={field.symbol(basket)}
                prefix={field.label}
                value={field.value(basket)}
                strikethrough={field.strikethrough}
                color={field.color}
                fontSize={field.fontSize}
                data-testid={`total-${field.label.toLowerCase()}`}
              />
              <Spacer py={1} />
            </Box>
          );
        }
      })}

      {!showShipping && (
        <Box fontFamily="gilroyMedium" fontSize={16} mt={4} data-testid="shipping-total">
          Shipping: Calculated at checkout
        </Box>
      )}
    </>
  );
}
