import { useState } from "react";

import { useLocalesContext } from "contexts/Locale";
import Group from "tpo/Group";
import Menu, { Button } from "tpo/Menu";

function FlagIcon({ locale, icons }) {
  let icon = null;
  if (locale.flagIconUrl) {
    icon = <img src={locale.flagIconUrl} alt={locale.name} width={20} height={20} />;
  } else if (icons[locale.isoCode]) {
    const Icon = icons[locale.isoCode];
    icon = <Icon width={20} height={20} />;
  }

  return icon;
}

export default function LocaleSwitcher() {
  const { locales, locale, setLocale, icons } = useLocalesContext();

  const [showOrgMenu, setShowOrgMenu] = useState(false);
  const placeholder = "Shipping country";

  let buttonContent = placeholder;
  if (locale && locales.find(l => l.id === locale?.id)?.name) {
    const localInstance = locales.find(l => l.id === locale?.id);

    buttonContent = (
      <Group alignItems="center" gap={10} color="white">
        <FlagIcon locale={localInstance} icons={icons} />
        {localInstance.name}
      </Group>
    );
  }

  let orderedLocales = [...locales].sort((a, b) => {
    if (a.name === "United Kingdom") {
      return -1;
    }
    if (b.name === "United Kingdom") {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  return (
    <Menu opened={showOrgMenu} onChange={setShowOrgMenu} trigger="hover">
      <Menu.Target>
        <Button
          open={showOrgMenu}
          color="dark"
          data-component-name="OrganisationLocaleButton"
          sx={{
            color: "white",
            px: [20, 20, 40]
          }}
        >
          {buttonContent}
        </Button>
      </Menu.Target>
      <Menu.Dropdown
        mt={2}
        py={2}
        zIndex={2}
        minWidth={200}
        height={500}
        overflow="auto"
        bg="dark"
        data-component-name="OrganisationLocaleMenuDropdown"
      >
        {orderedLocales.map(l => {
          return (
            <Menu.Item
              onClick={() =>
                setLocale({
                  id: l.id,
                  isoCode: l.isoCode
                })
              }
              selected={l.id === locale?.id}
              key={l.id}
              fontSize={14}
              lineHeight={1.7}
              py={16}
              color="white"
              selectedColor="rgba(255, 255, 255, 0.10)"
              display="flex"
              gap={10}
              alignItems="center"
            >
              <FlagIcon locale={l} icons={icons} />
              {l.name}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}
