import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Center from "tpo/Center";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const UPDATE_ORGANISATION_NAME_MUTATION = gql`
  mutation UpdateOrganisationNameMutation($input: UpdateOrganisationNameMutationInput!) {
    updateOrganisationNameMutation(input: $input) {
      organisation {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`;

export default function UpdateOrganisationNameForm({ onSuccess }) {
  const { organisation } = useOrganisationContext();

  const api = useDjangoGraphqlForm({
    mutation: UPDATE_ORGANISATION_NAME_MUTATION,
    mutationName: "updateOrganisationNameMutation",
    handleSuccess: data => {
      onSuccess(data);
    },
    defaultValues: {
      id: organisation?.id,
      name: ""
    }
  });

  return (
    <FormProvider {...api.methods}>
      <Stack
        as="form"
        gap={40}
        onSubmit={api.methods.handleSubmit(api.onSubmit)}
        mx="auto"
        maxWidth={720}
        width="100%"
        pb={[60, 60, 80]}
        px={20}
      >
        <Stack gap={20}>
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Update organisation name
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            This name is used to identify your organisation within the platform and will appear to
            your patients in any communication.
          </Box>
        </Stack>
        <Stack gap={20}>
          <ControlledFormField
            name="name"
            Component={FloatingLabelInput}
            label="Organisation name"
          />
        </Stack>
        <Center gap={20}>
          <ButtonV2 size={["sm", "sm", "md"]} color="green">
            Update
          </ButtonV2>
          <ButtonV2
            type="button"
            size={["sm", "sm", "md"]}
            color="red"
            onClick={() => {
              api.methods.reset();
            }}
          >
            Cancel
          </ButtonV2>
        </Center>
      </Stack>
    </FormProvider>
  );
}
