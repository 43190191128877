import { forwardRef } from "react";

import Box from "components/Box";
import Badge from "tpo/Badge";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

import IsThisTestRightForYou from "./IsThisTestRightForYou";

const GridListingViewCard = forwardRef(
  (
    {
      rrpCurrentPrice,
      rrpFullPrice,
      imageBackgroundColor,
      name,
      slug,
      badge,
      stacked,
      productAudience,
      sampleTypes,
      categories,
      onClick,
      label,
      image
    },
    ref
  ) => {
    return (
      <Box
        borderRadius="5px"
        data-component-name="Test product card"
        display="flex"
        flexDirection={stacked ? "column" : "row"}
        overflow="hidden"
        onClick={onClick}
        cursor="pointer"
        ref={ref}
      >
        <Box
          bg={imageBackgroundColor}
          position="relative"
          width={stacked ? "100%" : "40%"}
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain"
          }}
          minHeight={300}
        >
          {!!badge && (
            <Box position="absolute" zIndex={1}>
              {badge}
            </Box>
          )}
        </Box>
        <Box
          flexGrow={1}
          backgroundColor="white"
          px={[20]}
          pb={[20, 20, 30, 40]}
          pt={[15, 15, 15, 30]}
          width={stacked ? "100%" : "60%"}
          height="100%"
        >
          {label}
          <Box as="h2" fontFamily="gilroyBold" color="dark" fontSize={[24, 24, 28]}>
            {name}
          </Box>
          <Spacer py={1} />
          <Group gap={10}>
            {rrpFullPrice !== undefined && rrpFullPrice > rrpCurrentPrice && (
              <Currency
                fontSize={44}
                value={rrpFullPrice}
                color="#e44c4b"
                strikethrough
                decimalFontSize={28}
              />
            )}
            <Currency fontSize={44} value={rrpCurrentPrice} decimalFontSize={28} />
          </Group>
          <Spacer py={20} />
          {productAudience === "B2C" && <IsThisTestRightForYou slug={slug} />}
          <Spacer py={1} />
          <ButtonV2 rightIcon={<ChevronComponent />} color="green" size={["sm", "sm", "md"]}>
            learn more
          </ButtonV2>
          <Spacer py={15} />
          <Group flexWrap="wrap" gap={2}>
            {sampleTypes.map(st => (
              <Badge bg="purple" color="white" size="xs" key={st}>
                {st}
              </Badge>
            ))}
            {categories.map(c => (
              <Badge bg="haze" color="dark" size="xs" key={c}>
                {c}
              </Badge>
            ))}
          </Group>
        </Box>
      </Box>
    );
  }
);

export default GridListingViewCard;
