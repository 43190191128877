import gql from "graphql-tag";

export const RESULT_DEFINITION_FIELDS = gql`
  fragment ResultDefinitionFields on ResultDefinitionType {
    id
    name
    category
    slug
    description
  }
`;

export const RESULT_DEFINITION_CORE_FIELDS = gql`
  fragment ResultDefinitionCoreFields on ResultDefinitionType {
    id
    name
    category
    slug
  }
`;

export const CONTENT_LINK_FIELDS = gql`
  fragment ContentLinkFields on ContentLinkType {
    id
    description
    symptoms {
      id
      name
    }
    thingsToDo {
      id
      name
      slug
      category
      recommendationType
    }
    thingsToAvoid {
      id
      name
      slug
      category
      recommendationType
    }
    thingsToTake {
      id
      name
      slug
      category
      recommendationType
    }
    peopleToSee {
      id
      name
      slug
      category
      recommendationType
    }
  }
  ${RESULT_DEFINITION_FIELDS}
`;

export const RESULT_CORE_FIELDS = gql`
  fragment ResultCoreFields on OldResultType {
    id
    value
    normal
    valueFormatted
    interpretation
    interpretationFormatted
    interpretationColor
    ranges
    singleDatapointResult
    isAbnormal
    resultDefinition {
      ...ResultDefinitionCoreFields
    }
  }
  ${RESULT_DEFINITION_CORE_FIELDS}
`;

export const RESULT_FIELDS = gql`
  fragment ResultFields on OldResultType {
    resultDefinition {
      ...ResultDefinitionFields
      contentLinkSet {
        ...ContentLinkFields
      }
    }
    ...ResultCoreFields
  }
  ${RESULT_CORE_FIELDS}
  ${RESULT_DEFINITION_FIELDS}
  ${CONTENT_LINK_FIELDS}
`;

export const TEST_DEFINITION_FIELDS = gql`
  fragment TestDefinitionFields on TestDefinitionType {
    id
    testType
    sector {
      id
      name
      slug
    }
  }
`;

export const DATAPOINT_FIELDS = gql`
  fragment DatapointFields on DatapointType {
    id
    value
    valueFormatted
    ranges
    units
    interpretation
    interpretationFormatted
    interpretationColor
    borrowed
    isAbnormal
    datapointDefinition {
      id
      name
      description
      category
    }
  }
`;

export const RECOMMENDATION_FIELDS = gql`
  fragment RecommendationFields on OldRecommendationType {
    id
    name
    slug
    recommendationType
    category
    description
    qualityGroups {
      id
      examples {
        id
        name
      }
    }
    contentlinkSet {
      id
      interpretation
      resultDefinition {
        id
        name
        slug
        testDefinitions {
          id
          sector {
            id
            name
            slug
          }
        }
        resultSet {
          id
          interpretation
        }
      }
    }
  }
`;

export const RECOMMENDATION_CORE_FIELDS = gql`
  fragment RecommendationCoreFields on OldRecommendationType {
    id
    name
    slug
  }
`;

export const RECOMMENDATION_QUERY = gql`
  query RecommendationQuery($userId: ID, $recommendationType: String, $slug: String) {
    recommendation(userId: $userId, recommendationType: $recommendationType, slug: $slug) {
      ...RecommendationFields
    }
  }
  ${RECOMMENDATION_FIELDS}
`;

export const RECOMMENDATIONS_QUERY = gql`
  query RecommendationsQuery($userId: ID, $recommendationType: String) {
    recommendations(userId: $userId, recommendationType: $recommendationType) {
      ...RecommendationCoreFields
    }
  }
  ${RECOMMENDATION_CORE_FIELDS}
`;
