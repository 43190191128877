import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";

import BusinessTemplate from "./ui/BusinessTemplate";
import StripeConnectDetailsPanel from "./ui/StripeConnectDetailsPanel";
import YourLinkedBankAccount from "./ui/YourLinkedBankAccount";

export default function Payment() {
  return (
    <BusinessTemplate active="payment">
      <PartnerDashboardBox>
        <YourLinkedBankAccount />
      </PartnerDashboardBox>
      <PartnerDashboardBox>
        <StripeConnectDetailsPanel />
      </PartnerDashboardBox>
    </BusinessTemplate>
  );
}
