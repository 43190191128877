import { isValidElement, useState } from "react";

import Box from "components/Box";
import BaseProductQtyInput from "components/ProductQtyInput";
import RichText from "components/RichText";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import Currency from "tpo/Currency";
import Stack from "tpo/Stack";
import AddToBasketButton from "tpo/shop/AddToBasketButton";
import ButtonV2 from "v2/Buttons";

function ProductQtyInput({ count: initialCount, disabled, setQty }) {
  const [count, setCount] = useState(initialCount);

  return (
    <BaseProductQtyInput
      value={count}
      disabled={disabled}
      onChange={n => {
        setCount(n);
      }}
      data-testid="add-to-basket-section:thermostat-button"
      onBlur={() => {
        if (count !== "") {
          if (count !== initialCount) {
            setQty(count);
          }
        } else {
          setCount(initialCount);
        }
      }}
    />
  );
}

function Option({
  children,
  name,
  description,
  price,
  previousPrice,
  practitionerSupportRequired,
  lastOption,
  hasClinics,
  onFindNearestClinic,
  onAddToBasket,
  setQty,
  status,
  disabled = false,
  disabledMessage = "",
  id: optionId
}) {
  const { selectedTestProductId } = useTestProductModalContext();

  const {
    basket,
    loading,
    removeProductFromBasket,
    getQtyForProduct,
    hasBasketGotProduct
  } = useOrganisationBasketContext();

  const qty = getQtyForProduct(selectedTestProductId, optionId);

  let button = null;

  if (basket?.purchaseAsStock) {
    button =
      hasClinics || !hasBasketGotProduct(selectedTestProductId, optionId) ? (
        <AddToBasketButton
          practitionerSupportRequired={practitionerSupportRequired}
          hasClinics={hasClinics}
          onFindNearestClinic={onFindNearestClinic}
          onAddToBasket={onAddToBasket}
          status={status}
          disabled={disabled}
          disabledMessage={disabledMessage}
        />
      ) : (
        <ProductQtyInput key={qty} count={qty} disabled={loading} setQty={setQty} />
      );
  } else if (hasBasketGotProduct(selectedTestProductId, optionId)) {
    const clinicLocationId = basket?.productLineItems.find(
      pli => pli.product.id === selectedTestProductId && pli.productOption.id === optionId
    )?.clinicLocation?.id;

    button = (
      <ButtonV2
        color="red"
        onClick={() => removeProductFromBasket(selectedTestProductId, optionId, clinicLocationId)}
      >
        remove
      </ButtonV2>
    );
  } else if (hasBasketGotProduct(selectedTestProductId)) {
    button = (
      <AddToBasketButton
        practitionerSupportRequired={practitionerSupportRequired}
        hasClinics={hasClinics}
        onFindNearestClinic={onFindNearestClinic}
        onAddToBasket={onAddToBasket}
        status={status}
        disabled
        disabledMessage={disabledMessage}
        color="midGrey"
      />
    );
  } else {
    button = (
      <AddToBasketButton
        practitionerSupportRequired={practitionerSupportRequired}
        hasClinics={hasClinics}
        onFindNearestClinic={onFindNearestClinic}
        onAddToBasket={onAddToBasket}
        status={status}
        disabled={disabled}
        disabledMessage={disabledMessage}
      />
    );
  }

  return (
    <Stack
      borderBottomColor="haze"
      borderBottomStyle={!lastOption ? "solid" : null}
      borderBottomWidth={1}
      gap={20}
      pb={20}
      data-component-name="TestProductOptions.Option"
    >
      <Stack gap={20}>
        <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} data-testid="name">
          {name}
        </Box>
        <RichText>{description}</RichText>
      </Stack>
      <Stack
        flexDirection={["column", "column", "row"]}
        justifyContent={[null, null, "space-between"]}
        alignItems={["flex-start"]}
      >
        <Stack>
          {isValidElement(price) ? (
            price
          ) : (
            <Currency
              fontSize={[24, 24, 44]}
              value={price}
              data-testid="price"
              symbol={basket?.currencySymbol}
            />
          )}
          {previousPrice !== null && previousPrice > price && (
            <Currency
              symbol={basket?.currencySymbol}
              color="midGrey"
              fontSize={18}
              value={previousPrice}
              prefix="RRP"
              data-testid="rrpprice"
            />
          )}
        </Stack>
        {button}
      </Stack>
      {children}
    </Stack>
  );
}

export default function TestProductOptions({ bg, children }) {
  return (
    <Stack gap={20} py={40} bg={bg} borderRadius={5} data-component-name="TestProductOptions">
      <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
        Purchase options
      </Box>
      {children}
    </Stack>
  );
}

TestProductOptions.defaultProps = {
  bg: "white"
};

TestProductOptions.Option = Option;
