import { useQuery } from "@apollo/client";
import { SHIPPING_COUNTRIES_QUERY } from "graphql/shop";

export default function useShippingCountries() {
  const { data: shippingData } = useQuery(SHIPPING_COUNTRIES_QUERY);

  const shippingCountries = shippingData
    ? [...shippingData.shippingCountries].sort((a, b) => {
        if (a.name === "United Kingdom") {
          return -1;
        }
        if (b.name === "United Kingdom") {
          return 1;
        }
        return a.name.localeCompare(b.name);
      })
    : [];

  return shippingCountries;
}
