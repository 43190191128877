import Box from "components/Box";

export default function Danger({ bg, color, size }) {
  return (
    <Box
      as="svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.13066 2C7.51556 1.33333 8.47781 1.33333 8.86271 2L15.3579 13.25C15.7428 13.9167 15.2617 14.75 14.4919 14.75H1.50149C0.731693 14.75 0.250566 13.9167 0.635466 13.25L7.13066 2Z"
        fill={bg}
      />
      <path
        d="M7.772 10.22L7.556 8.1V6.5H8.796V8.1L8.588 10.22H7.772ZM8.18 12.204C7.804 12.204 7.5 11.9 7.5 11.524C7.5 11.148 7.804 10.844 8.18 10.844C8.556 10.844 8.86 11.148 8.86 11.524C8.86 11.9 8.556 12.204 8.18 12.204Z"
        fill={color}
      />
    </Box>
  );
}

Danger.defaultProps = {
  bg: "#E44C4B",
  color: "white",
  size: 16
};
