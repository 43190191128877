import Group from "tpo/Group";
import {
  PAYMENT_STATUS_AWAITING_PAYMENT,
  PROGRESS_STATUS_ORDERED,
  STATUS_VARIANTS
} from "tpo/shop/constants";

import StatusBadge from "./StatusBadge";

export default function OrderStatusBadges({
  order,
  showPaymentStatus = true,
  badgeProps,
  derivedPaymentStatusOverrides = {},
  ...props
}) {
  let paymentStatus = null;
  let paymentVariant = null;
  let progressStatus = null;
  let progressVariant = null;

  if (!order || !order.id) {
    return null;
  }

  if (order.derivedPaymentStatus) {
    paymentStatus =
      derivedPaymentStatusOverrides[order.derivedPaymentStatus] || order.derivedPaymentStatus;
    paymentVariant = STATUS_VARIANTS[paymentStatus];
  } else if (order.sourceBasket) {
    paymentStatus = order.sourceBasket.paymentStatus;
    paymentVariant = STATUS_VARIANTS[order.sourceBasket.paymentStatus];
  } else {
    paymentStatus = PAYMENT_STATUS_AWAITING_PAYMENT;
    paymentVariant = STATUS_VARIANTS[PAYMENT_STATUS_AWAITING_PAYMENT];
  }

  if (order.derivedProgressStatus) {
    progressStatus = order.derivedProgressStatus;
    progressVariant = STATUS_VARIANTS[order.derivedProgressStatus];
  } else {
    progressStatus = PROGRESS_STATUS_ORDERED;
    progressVariant = STATUS_VARIANTS[PROGRESS_STATUS_ORDERED];
  }

  if (paymentStatus === null && progressStatus === null) {
    return null;
  }

  return (
    <Group gap={10} alignItems="center" alignSelf="flex-start" flexWrap="wrap" {...props}>
      {progressStatus && (
        <StatusBadge variant={progressVariant} {...badgeProps} data-testid="progressStatusBadge">
          {progressStatus}
        </StatusBadge>
      )}
      {showPaymentStatus && paymentStatus && (
        <StatusBadge variant={paymentVariant} {...badgeProps} data-testid="paymentStatusBadge">
          {paymentStatus}
        </StatusBadge>
      )}
    </Group>
  );
}
