import { forwardRef } from "react";

import Box from "components/Box";
import styled from "styled-components";
import { maxWidth } from "styled-system";

import InputWrapper from "./InputWrapper";

/**
 * Based on: https://blog.logrocket.com/creating-custom-select-dropdown-css/
 */

const NativeSelectWrapper = styled(Box)`
  select {
    appearance: none;
    /* safari */
    -webkit-appearance: none;
    /* other styles for aesthetics */
    width: 100%;
    padding: 0.675em 6em 0.675em 1em;
    background-color: #fff;
    border: 1px solid #caced1;
    border-radius: 0.25rem;
    color: #000;
    cursor: pointer;
  }

  select option {
    color: red;
  }

  position: relative;
  width: 100%;
  height: auto;

  &::before,
  &::after {
    position: absolute;
    content: "";
    right: 1rem;
    pointer-events: none;
  }

  &::before {
    border-left: ${props => props.size} solid transparent;
    border-right: ${props => props.size} solid transparent;
    border-bottom: ${props => props.size} solid black;
    top: 40%;
  }

  &::after {
    border-left: ${props => props.size} solid transparent;
    border-right: ${props => props.size} solid transparent;
    border-top: ${props => props.size} solid black;
    top: 55%;
  }
`;

NativeSelectWrapper.defaultProps = {
  size: "0.3rem",
  maxWidth: 350
};

const NativeSelect = forwardRef(
  (
    {
      label,
      error,
      children,
      onChange,
      value,
      styles,
      name,
      placeholder,
      readOnly,
      "data-component-name": dataComponentName
    },
    ref
  ) => (
    <InputWrapper
      label={label}
      error={error}
      input={
        <NativeSelectWrapper {...(styles?.wrapper || {})}>
          <Box
            as="select"
            name={name}
            ref={ref}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            fontSize="1.15rem"
            {...(styles?.select || {})}
            disabled={readOnly}
            readOnly={readOnly}
            data-component-name={dataComponentName}
          >
            {children}
          </Box>
        </NativeSelectWrapper>
      }
    />
  )
);

export default NativeSelect;
