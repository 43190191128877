import { useCallback, useMemo, useRef } from "react";

import { FormProvider } from "react-hook-form";

import { gql, useMutation } from "@apollo/client";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import DataLoader from "components/DataLoader";
import Grid from "components/Grid";
import { InternalTextLink } from "components/Links";
import { theme } from "core/theme";
import { UPLOAD_TEST } from "core/urls";
import { PLATFORM_USER_PROFILE_FIELDS } from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import styled from "styled-components";
import ToggleSwitch from "tpo/ToggleSwitch";
import ButtonV2 from "v2/Buttons";

import Asterisk from "./Asterisk";
import Badge from "./Badge";
import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import ChevronComponent from "./Chevron";
import ControlledCheckboxGroup from "./ControlledCheckboxGroup";
import ControlledFormField from "./ControlledFormField";
import ControlledRadioGroup from "./ControlledRadioGroup";
import DateInput from "./DateInput";
import EditFormButtons from "./EditFormButtons";
import FloatingLabelInput from "./FloatingLabelInput";
import FormControl from "./FormControl";
import Group from "./Group";
import Spacer from "./Spacer";
import Stack from "./Stack";
import Table from "./Table";

const FormButtonsContainer = styled(Box)`
  a,
  button {
    align-self: flex-start;
  }
`;

const RESULTS_ACCESS_LINK_MUTATION = gql`
  mutation ResultsAccessLinkMutation($input: ResultsAccessLinkMutationInput!) {
    resultsAccessLinkMutation(input: $input) {
      resultsAccessLink {
        id
        partnerUserProfile {
          id
          user {
            id
            fullName
          }
        }
        granted
      }
      errors {
        field
        messages
      }
    }
  }
`;

const YOUR_DETAILS_MUTATION = gql`
  mutation UserYourDetailsMutation($input: UserYourDetailsMutationInput!) {
    userYourDetailsMutation(input: $input) {
      platformUserProfile {
        ...PlatformUserProfileFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

function initializeYourDetails(platformUserProfile) {
  return {
    id: platformUserProfile.pk,
    firstName: platformUserProfile.user.firstName,
    lastName: platformUserProfile.user.lastName,
    height: platformUserProfile.height,
    weight: platformUserProfile.weight,
    dateOfBirth: platformUserProfile.dateOfBirth || "",
    gender: platformUserProfile.gender
  };
}

function YourDetails({ platformUserProfile }) {
  const submitButtonRef = useRef();

  const { methods, id, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: YOUR_DETAILS_MUTATION,
    mutationName: "userYourDetailsMutation",
    defaultValues: initializeYourDetails(platformUserProfile),
    transformer: input => ({
      ...input,
      dateOfBirth: input.dateOfBirth === "--" ? "" : input.dateOfBirth
    }),
    handleSuccess: ({ data, api: { reset } }) => {
      reset(initializeYourDetails(data.userYourDetailsMutation.platformUserProfile));
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
      }
    },
    handleFailure: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
    }
  });

  if (!id) {
    // this can't happen unless the field is accidentally
    // removed from the query
    // if id was missing however it would try to create a new instance
    // server side instead of editing the existing for this ID
    return null;
  }

  return (
    <FormProvider {...methods} submitButtonRef={submitButtonRef}>
      <PanelBoxV2
        maxWidth={1532}
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20],
          bg: "haze"
        }}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PanelBoxV2 maxWidth={1020}>
            <PanelBoxV2
              maxWidth={760}
              outer={{
                pt: [30, 30, 60],
                pb: [50, 50, 80],
                bg: "white",
                borderRadius: 5,
                px: 20
              }}
            >
              <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
                Your details
                <Asterisk />
              </Box>
              {nonFieldError && (
                <Box color="red" py={20}>
                  {nonFieldError}
                </Box>
              )}
              <Spacer py={[2, 2, 20]} />
              <Stack gap={[20, 20, 40]}>
                <FormControl
                  fields={
                    <>
                      <ControlledFormField
                        name="firstName"
                        label="First name"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="lastName"
                        label="Last name"
                        Component={FloatingLabelInput}
                      />
                    </>
                  }
                />
                <FormControl
                  as="fieldset"
                  label={
                    <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="26px">
                      Physical details
                      <Asterisk />
                    </Box>
                  }
                  fields={
                    <Stack gap={20}>
                      <ControlledFormField
                        name="height"
                        label="Height (cm)"
                        Component={FloatingLabelInput}
                      />
                      <ControlledFormField
                        name="weight"
                        label="Weight (kg)"
                        Component={FloatingLabelInput}
                      />
                    </Stack>
                  }
                />
                <FormControl
                  label={
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="36px">
                      Date of birth
                    </Box>
                  }
                >
                  <ControlledFormField
                    name="dateOfBirth"
                    Component={DateInput}
                    getDay={dateStr => dateStr.split("-")?.[2] || ""}
                    getMonth={dateStr => dateStr.split("-")?.[1] || ""}
                    getYear={dateStr => dateStr.split("-")?.[0] || ""}
                    constructDate={({ day, month, year }) => {
                      return `${year}-${month}-${day}`;
                    }}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="36px">
                      Gender
                    </Box>
                  }
                >
                  <ControlledRadioGroup
                    Container={Stack}
                    containerProps={{
                      gap: 20
                    }}
                    name="gender"
                    values={[
                      {
                        label: "Male",
                        value: "Male"
                      },
                      {
                        label: "Female",
                        value: "Female"
                      }
                    ]}
                  />
                </FormControl>
              </Stack>
            </PanelBoxV2>
            <Spacer py={[2, 2, 20]} />
            <FormButtonsContainer
              display="flex"
              flexDirection={["column", "column", "row"]}
              gap={20}
              justifyContent={["flex-start", "flex-start", "center"]}
            >
              <EditFormButtons />
            </FormButtonsContainer>
          </PanelBoxV2>
        </form>
      </PanelBoxV2>
    </FormProvider>
  );
}

const DIET_PREFERENCES_MUTATION = gql`
  mutation DietPreferencesMutation($input: DietPreferencesMutationInput!) {
    dietPreferencesMutation(input: $input) {
      platformUserProfile {
        id
        pk
        dietPreferences {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

function initializeDietPreferences(platformUserProfile) {
  return {
    id: platformUserProfile.pk,
    dietPreferences: platformUserProfile.dietPreferences.map(dietPreference => dietPreference.id)
  };
}

function DietPreferences({ platformUserProfile, dietPreferences }) {
  const submitButtonRef = useRef();

  const { methods, id, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: DIET_PREFERENCES_MUTATION,
    mutationName: "dietPreferencesMutation",
    defaultValues: initializeDietPreferences(platformUserProfile),
    handleSuccess: ({ data, api: { reset } }) => {
      reset(initializeDietPreferences(data.dietPreferencesMutation.platformUserProfile));
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
      }
    },
    handleFailure: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
    }
  });

  if (!id) {
    // this can't happen unless the field is accidentally
    // removed from the query
    // if id was missing however it would try to create a new instance
    // server side instead of editing the existing for this ID
    return null;
  }

  return (
    <FormProvider {...methods} submitButtonRef={submitButtonRef}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PanelBoxV2
          maxWidth={760}
          outer={{
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20],
            bg: "white"
          }}
          stacked
          gap={40}
        >
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
            Diet preferences
          </Box>
          {nonFieldError && (
            <Box color="red" py={20}>
              {nonFieldError}
            </Box>
          )}

          <ControlledCheckboxGroup
            Container={Grid}
            containerProps={{
              pl: [null, 80],
              gridTemplateColumns: ["1fr", "1fr 1fr"]
            }}
            name="dietPreferences"
            values={
              dietPreferences?.map(dietPreference => ({
                label: dietPreference.name,
                value: dietPreference.id
              })) || []
            }
          />

          <FormButtonsContainer
            display="flex"
            flexDirection={["column", "column", "row"]}
            gap={20}
            justifyContent={["flex-start", "flex-start", "center"]}
          >
            <EditFormButtons />
          </FormButtonsContainer>
        </PanelBoxV2>
      </form>
    </FormProvider>
  );
}

function GrantedToggle({ id, granted, accept, reject }) {
  return (
    <Group justifyContent="flex-end">
      <ToggleSwitch
        onChange={v => {
          if (granted) {
            reject(id);
          } else {
            accept(id);
          }
        }}
        value={granted}
        offBg="haze"
        onBg="haze"
        offSwitchBg="red"
        onSwitchBg="green"
        onSwitchColor="white"
        offSwitchColor="white"
        id={`results-access-toggle:${id}`}
      />
    </Group>
  );
}

function NewAccessRequestButtons({ id, reject, accept }) {
  return (
    <Group gap={20} justifyContent="flex-end" flexWrap="wrap">
      <ButtonV2
        color="red"
        onClick={() => reject(id)}
        rightIcon={<CrossIcon fill="white" width="5px" height="5px" />}
        size={["sm", "sm", "md"]}
      >
        reject
      </ButtonV2>
      <ButtonV2
        color="green"
        onClick={() => accept(id)}
        rightIcon={<ChevronComponent />}
        size={["sm", "sm", "md"]}
      >
        approved
      </ButtonV2>
    </Group>
  );
}

function ResultAccessRequests({ platformUserProfile }) {
  const [approveResultsAccessLinkMutation] = useMutation(RESULTS_ACCESS_LINK_MUTATION);

  const accept = useCallback(
    id => {
      approveResultsAccessLinkMutation({
        variables: {
          input: {
            id,
            granted: true
          }
        }
      }).catch(e => {
        console.log("An error occured accepting a result access link", e);
      });
    },
    [approveResultsAccessLinkMutation]
  );

  const reject = useCallback(
    id => {
      approveResultsAccessLinkMutation({
        variables: {
          input: {
            id,
            granted: false
          }
        }
      }).catch(e => {
        console.log("An error occured accepting a result access link", e);
      });
    },
    [approveResultsAccessLinkMutation]
  );

  const resultAccessRequestColumns = useMemo(
    () => [
      {
        id: "approvedPractitioners",
        label: "Approved Practitioners",
        verticalAlign: "middle",
        accessor: ({ organisation, partnerUserProfile }) => {
          let fullName;
          if (organisation) {
            fullName = organisation.name;
          } else {
            fullName = partnerUserProfile.user.fullName;
          }
          return (
            <Box textDecoration="underline" fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
              {fullName}
            </Box>
          );
        }
      },
      {
        id: "type",
        label: "",
        verticalAlign: "middle",
        accessor: ({ organisation, partnerUserProfile }) => {
          if (organisation) {
            return (
              <Badge bg="haze" withBorder>
                organisation
              </Badge>
            );
          }

          if (partnerUserProfile) {
            return (
              <Badge bg="haze" withBorder>
                partner
              </Badge>
            );
          }
        }
      },
      {
        id: "access",
        label: "Access",
        accessor: ({ id, granted }) => (
          <GrantedToggle id={id} granted={granted} accept={accept} reject={reject} />
        ),
        align: "right"
      }
    ],
    [accept, reject]
  );

  // i.e. filter out those where granted is null (unknown)
  const resultAccessRequestItems =
    platformUserProfile?.resultsAccessLinkSet.filter(
      link => link.granted === false || link.granted === true
    ) || [];

  const newAccessRequestColumns = useMemo(
    () => [
      {
        id: "name",
        label: "New access requests",
        verticalAlign: "middle",
        accessor: ({ organisation, partnerUserProfile }) => {
          let fullName;
          if (organisation) {
            fullName = organisation.name;
          } else {
            fullName = partnerUserProfile.user.fullName;
          }

          return (
            <Box textDecoration="underline" fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
              {fullName}
            </Box>
          );
        }
      },
      {
        id: "buttons",
        label: "",
        accessor: ({ id, granted }) => (
          <NewAccessRequestButtons id={id} reject={reject} accept={accept} />
        ),
        align: "right"
      }
    ],
    [reject, accept]
  );

  // i.e. filter out those where granted is null (unknown)
  const newAccessRequestItems =
    platformUserProfile?.resultsAccessLinkSet.filter(link => link.granted === null) || [];

  return (
    <PanelBoxV2
      maxWidth={1532}
      outer={{
        pt: theme.spacing.section.pt,
        pb: theme.spacing.section.pb,
        px: [20],
        bg: "haze"
      }}
    >
      <PanelBoxV2 maxWidth={1020}>
        <PanelBoxV2
          maxWidth={760}
          outer={{
            pt: [30, 30, 60],
            pb: [50, 50, 80],
            bg: "white",
            borderRadius: 5,
            px: 20
          }}
          stacked
          gap={40}
        >
          <Box>
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
              Results access requests
            </Box>
            <Spacer py={[2, 2, 20]} />
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} lineHeight="130%">
              Control access to your results below
            </Box>
          </Box>
          <Table
            emptyMessage="No approved practitioners"
            rows={resultAccessRequestItems}
            columns={resultAccessRequestColumns}
          />
          <Table
            emptyMessage="No new access requests"
            rows={newAccessRequestItems}
            columns={newAccessRequestColumns}
          />
        </PanelBoxV2>
      </PanelBoxV2>
    </PanelBoxV2>
  );
}

function YourData({
  platformUserProfile: {
    user: { email }
  }
}) {
  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        pt: theme.spacing.section.pt,
        pb: theme.spacing.section.pb,
        px: 20
      }}
    >
      <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
        Your data
      </Box>
      <Spacer py={[2, 2, 20]} />
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} lineHeight="150%">
        For privacy compliance you can request a copy of the data we hold on you by clicking on the
        "request data" button below.
        <br />
        <br />
        Please note your test results will be uploaded automatically to your dashboard and you will
        be notified when they are available. A copy of your raw data files will also appear above
        for you to download.
      </Box>
      <Spacer py={[2, 2, 20]} />
      <FormButtonsContainer
        display="flex"
        flexDirection={["column", "column", "row"]}
        gap={20}
        justifyContent={["flex-start", "flex-start", "center"]}
      >
        <ButtonV2
          as="a"
          href={`mailto:support@omnos.me?subject=Request my data&body=Please can I receive a copy of the data for the account in the name of ${email}`}
          color="green"
          rightIcon={<ChevronComponent />}
          size={["sm", "sm", "md"]}
        >
          request data
        </ButtonV2>
        <ButtonV2
          color="red"
          as="a"
          rightIcon={<ChevronComponent />}
          href={`mailto:support@omnos.me?subject=Delete my account&body=Please can I delete the account in the name of ${email}`}
          size={["sm", "sm", "md"]}
        >
          delete account
        </ButtonV2>
      </FormButtonsContainer>
    </PanelBoxV2>
  );
}

const INITIAL_QUERY = gql`
  query InitialQuery {
    dietPreferences {
      id
      name
    }
    platformUserProfile {
      ...PlatformUserProfileFields
      resultsAccessLinkSet {
        id
        organisation {
          id
          name
        }
        partnerUserProfile {
          id
          user {
            id
            fullName
          }
        }
        granted
      }
    }
  }
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

function UploadExistingTests() {
  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        bg: "partners",
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        px: 20
      }}
    >
      <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
        Upload existing test results
      </Box>
      <Spacer py={[2, 2, 20]} />
      <Box fontFamily="gilroyMedium" fontSize={[16]} lineHeight="130%">
        If you have an existing test result from one of our supported Labs you can now upload it and
        have it processed in the Omnos platform.
      </Box>
      <Spacer py={[2, 2, 20]} />
      <Center>
        <ButtonV2
          as={InternalTextLink}
          color="green"
          rightIcon={<ChevronComponent />}
          href={UPLOAD_TEST}
          underline={false}
        >
          upload my tests
        </ButtonV2>
      </Center>
    </PanelBoxV2>
  );
}

export default function SettingsPage() {
  useDocTitle("Settings");
  return (
    <CustomerPage
      jumbotronProps={{
        title: "Profile Settings"
      }}
    >
      <DataLoader
        query={INITIAL_QUERY}
        fetchPolicy="network-only"
        nextFetchPolicy="network-only"
        render={({ platformUserProfile, dietPreferences }) => (
          <>
            <YourDetails platformUserProfile={platformUserProfile} />
            <DietPreferences
              platformUserProfile={platformUserProfile}
              dietPreferences={dietPreferences}
            />
            <ResultAccessRequests platformUserProfile={platformUserProfile} />
            {/* <UploadExistingTests /> */}
            <YourData platformUserProfile={platformUserProfile} />
          </>
        )}
      />
    </CustomerPage>
  );
}
