import { theme } from "core/theme";

import Box from "../components/Box";
import { PanelBoxV2 } from "./Boxes";

export default function NothingToShow({ header, jumbotron }) {
  return (
    <>
      <PanelBoxV2
        outer={{
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        {header}
        {header && jumbotron && <Box py={[2, 2, 20]} />}
        <PanelBoxV2
          maxWidth={1020}
          inner={{
            bg: "white",
            pt: [30, 30, 60],
            pb: [30, 30, 60],
            px: 20
          }}
        >
          <PanelBoxV2 maxWidth={760}>{jumbotron}</PanelBoxV2>
        </PanelBoxV2>
      </PanelBoxV2>
    </>
  );
}
