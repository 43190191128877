export default function RegenerusLogoWithoutDots({ fill, style, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 35"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M3.94973 7.83593C5.05242 7.11812 6.45936 6.76099 8.16703 6.76099V8.3654H7.67988C5.94934 8.3654 4.51074 8.88422 3.36409 9.9183C2.21743 10.9541 1.6441 12.6439 1.6441 14.9892V25.6764H0.186157V7.13588H1.64586V10.9595C2.07849 9.59315 2.84704 8.55196 3.94973 7.83593Z"
        fill="white"
      />
      <path
        d="M28.5747 17.0022H13.3586C13.4026 18.6635 13.7473 20.0636 14.3963 21.2025C15.0452 22.3414 15.8999 23.1943 16.9587 23.7628C18.0174 24.3314 19.1763 24.6157 20.4303 24.6157C22.1608 24.6157 23.61 24.1661 24.7777 23.2671C25.9455 22.3681 26.6806 21.1456 26.9831 19.5963H28.5079C28.1614 21.4868 27.2751 23.0166 25.847 24.1892C24.419 25.3619 22.6128 25.9482 20.4285 25.9482C18.7842 25.9482 17.3139 25.5609 16.016 24.788C14.7181 24.0151 13.7016 22.9046 12.9665 21.4584C12.2313 20.0138 11.8638 18.3224 11.8638 16.3875C11.8638 14.4526 12.2261 12.7682 12.9506 11.3344C13.6752 9.9005 14.6864 8.80246 15.9843 8.04023C17.2823 7.278 18.7631 6.896 20.4285 6.896C22.094 6.896 23.5801 7.278 24.8252 8.04023C26.0686 8.80246 27.0148 9.81522 27.6637 11.0785C28.3127 12.3418 28.6363 13.7028 28.6363 15.1579C28.6363 15.977 28.6152 16.5918 28.5712 17.0022H28.5747ZM26.3517 11.5724C25.7345 10.4353 24.8973 9.58602 23.8368 9.02811C22.7764 8.47021 21.6315 8.19126 20.3969 8.19126C19.1623 8.19126 18.0227 8.47021 16.9745 9.02811C15.9246 9.58602 15.0716 10.4335 14.4121 11.5724C13.7526 12.7113 13.4009 14.099 13.3569 15.7372H27.1783C27.2434 14.099 26.9673 12.7096 26.3517 11.5724Z"
        fill="white"
      />
      <path
        d="M46.6135 8.31391C47.9114 9.25915 48.8188 10.4709 49.3394 11.9509V7.13591H50.7991V26.2574C50.7991 27.9649 50.4421 29.461 49.7281 30.7473C49.0141 32.0337 48.0521 33.0234 46.8403 33.7181C45.6286 34.4128 44.2779 34.7593 42.7848 34.7593C40.5795 34.7593 38.7504 34.2014 37.3013 33.0856C35.8521 31.9697 34.9007 30.4684 34.4469 28.5779H35.9067C36.3393 30.0579 37.15 31.2359 38.3407 32.1119C39.5295 32.9878 41.0121 33.4267 42.7848 33.4267C44.0177 33.4267 45.1309 33.1371 46.1263 32.5561C47.1217 31.9751 47.9043 31.1435 48.4794 30.0633C49.0528 28.9812 49.3394 27.7126 49.3394 26.2557V20.8259C48.8206 22.3059 47.9061 23.5283 46.5976 24.4967C45.2892 25.465 43.7152 25.9483 41.8774 25.9483C40.233 25.9483 38.768 25.5556 37.4807 24.7703C36.1933 23.985 35.1874 22.8692 34.4628 21.4246C33.7382 19.9801 33.3759 18.3011 33.3759 16.3893C33.3759 14.4775 33.7382 12.8038 34.4628 11.3699C35.1874 9.93609 36.1933 8.83272 37.4807 8.05805C38.768 7.28516 40.233 6.89783 41.8774 6.89783C43.738 6.89783 45.3173 7.37045 46.6135 8.31568V8.31391ZM48.3809 12.1037C47.7425 10.8636 46.872 9.90767 45.7693 9.23605C44.6666 8.56443 43.4338 8.22862 42.0708 8.22862C40.7078 8.22862 39.3888 8.55377 38.3072 9.20229C37.2257 9.85081 36.3815 10.7907 35.7765 12.0185C35.1715 13.248 34.8673 14.7049 34.8673 16.3893C34.8673 18.0737 35.1698 19.5306 35.7765 20.7601C36.3815 21.9897 37.2309 22.9349 38.3231 23.5941C39.4152 24.255 40.6639 24.5837 42.0708 24.5837C43.4778 24.5837 44.6719 24.2479 45.7851 23.5763C46.8984 22.9047 47.7689 21.9488 48.3968 20.7086C49.0246 19.4684 49.3377 18.0293 49.3377 16.3893C49.3377 14.7493 49.0176 13.3457 48.3809 12.1037Z"
        fill="white"
      />
      <path
        d="M73.1536 17.0022H57.9376C57.9815 18.6635 58.3262 20.0636 58.9752 21.2025C59.6241 22.3414 60.4789 23.1943 61.5376 23.7628C62.5963 24.3314 63.7553 24.6157 65.0092 24.6157C66.7397 24.6157 68.1889 24.1661 69.3567 23.2671C70.5244 22.3681 71.2595 21.1456 71.562 19.5963H73.0868C72.7403 21.4868 71.854 23.0166 70.4259 24.1892C68.9979 25.3619 67.1917 25.9482 65.0074 25.9482C63.3631 25.9482 61.8928 25.5609 60.5949 24.788C59.297 24.0151 58.2805 22.9046 57.5454 21.4584C56.8102 20.0138 56.4427 18.3224 56.4427 16.3875C56.4427 14.4526 56.805 12.7682 57.5295 11.3344C58.2541 9.9005 59.2654 8.80246 60.5633 8.04023C61.8612 7.278 63.342 6.896 65.0074 6.896C66.6729 6.896 68.159 7.278 69.4041 8.04023C70.6475 8.80246 71.5937 9.81522 72.2426 11.0785C72.8916 12.3418 73.2152 13.7028 73.2152 15.1579C73.2152 15.977 73.1941 16.5918 73.1501 17.0022H73.1536ZM70.9307 11.5724C70.3134 10.4353 69.4762 9.58602 68.4158 9.02811C67.3553 8.47021 66.2104 8.19126 64.9758 8.19126C63.7412 8.19126 62.6016 8.47021 61.5534 9.02811C60.5035 9.58602 59.6505 10.4335 58.991 11.5724C58.3315 12.7113 57.9798 14.099 57.9358 15.7372H71.7572C71.8223 14.099 71.5462 12.7096 70.9307 11.5724Z"
        fill="white"
      />
      <path
        d="M91.9381 8.79177C93.2571 10.1457 93.9166 12.1552 93.9166 14.8186V25.6764H92.4885V14.9216C92.4885 12.7149 91.9574 11.0234 90.8987 9.85072C89.8382 8.67806 88.3996 8.09173 86.5829 8.09173C84.7662 8.09173 83.1605 8.72959 82.0244 10.0035C80.8883 11.2792 80.3202 13.1679 80.3202 15.6714V25.6764H78.8605V7.13583H80.3202V11.0625C80.8179 9.67482 81.6445 8.61054 82.8017 7.86963C83.9589 7.1305 85.2938 6.75916 86.808 6.75916C88.9061 6.75916 90.6155 7.4361 91.9345 8.79L91.9381 8.79177Z"
        fill="white"
      />
      <path
        d="M116.109 17.0022H100.893C100.937 18.6635 101.282 20.0636 101.931 21.2025C102.58 22.3414 103.435 23.1943 104.493 23.7628C105.552 24.3314 106.711 24.6157 107.965 24.6157C109.695 24.6157 111.145 24.1661 112.312 23.2671C113.48 22.3681 114.215 21.1456 114.518 19.5963H116.042C115.696 21.4868 114.81 23.0166 113.382 24.1892C111.954 25.3619 110.147 25.9482 107.963 25.9482C106.319 25.9482 104.849 25.5609 103.551 24.788C102.253 24.0151 101.236 22.9046 100.501 21.4584C99.7659 20.0138 99.3984 18.3224 99.3984 16.3875C99.3984 14.4526 99.7607 12.7682 100.485 11.3344C101.21 9.9005 102.221 8.80246 103.519 8.04023C104.817 7.278 106.298 6.896 107.963 6.896C109.629 6.896 111.115 7.278 112.36 8.04023C113.603 8.80246 114.549 9.81522 115.198 11.0785C115.847 12.3418 116.171 13.7028 116.171 15.1579C116.171 15.977 116.15 16.5918 116.106 17.0022H116.109ZM113.886 11.5724C113.269 10.4353 112.432 9.58602 111.371 9.02811C110.311 8.47021 109.166 8.19126 107.931 8.19126C106.697 8.19126 105.557 8.47021 104.509 9.02811C103.459 9.58602 102.606 10.4335 101.947 11.5724C101.287 12.7113 100.935 14.099 100.891 15.7372H114.713C114.778 14.099 114.502 12.7096 113.886 11.5724Z"
        fill="white"
      />
      <path
        d="M125.583 7.83593C126.686 7.11812 128.093 6.76099 129.801 6.76099V8.3654H129.313C127.583 8.3654 126.144 8.88422 124.998 9.9183C123.851 10.9541 123.278 12.6439 123.278 14.9892V25.6764H121.818V7.13588H123.278V10.9595C123.71 9.59315 124.479 8.55196 125.582 7.83593H125.583Z"
        fill="white"
      />
      <path
        d="M149.301 7.13586V25.6764H147.841V21.6467C147.343 23.0592 146.515 24.1342 145.36 24.8733C144.202 25.6142 142.878 25.9838 141.385 25.9838C139.287 25.9838 137.572 25.3068 136.243 23.9529C134.913 22.599 134.247 20.5895 134.247 17.9262V7.13586H135.675V17.8231C135.675 20.0316 136.204 21.7213 137.264 22.894C138.323 24.0666 139.764 24.653 141.58 24.653C143.397 24.653 145.003 24.0169 146.139 22.7412C147.275 21.4672 147.843 19.5768 147.843 17.0733V7.13764H149.303L149.301 7.13586Z"
        fill="white"
      />
      <path
        d="M156.907 24.5837C155.664 23.674 154.966 22.377 154.814 20.6908H156.307C156.415 21.8973 156.941 22.8532 157.881 23.5585C158.822 24.2639 160.104 24.6175 161.726 24.6175C163.066 24.6175 164.132 24.2586 164.921 23.5426C165.711 22.8247 166.105 21.9435 166.105 20.897C166.105 20.1685 165.894 19.5715 165.472 19.1042C165.05 18.6387 164.526 18.2798 163.898 18.0293C163.27 17.7787 162.417 17.5051 161.335 17.2102C160.038 16.8459 158.988 16.487 158.187 16.1352C157.387 15.7834 156.705 15.2539 156.144 14.5468C155.581 13.8414 155.3 12.8962 155.3 11.7129C155.3 10.8476 155.548 10.0463 156.045 9.30534C156.543 8.56621 157.234 7.97988 158.122 7.54635C159.009 7.11459 160.015 6.89783 161.14 6.89783C162.978 6.89783 164.466 7.38111 165.602 8.34944C166.738 9.31778 167.348 10.6539 167.435 12.3614H165.975C165.91 11.1319 165.456 10.1315 164.612 9.35687C163.768 8.58398 162.6 8.19664 161.109 8.19664C159.855 8.19664 158.815 8.53778 157.994 9.22183C157.171 9.90411 156.761 10.7356 156.761 11.7146C156.761 12.5799 156.993 13.28 157.459 13.8148C157.924 14.3496 158.497 14.76 159.179 15.0443C159.86 15.3286 160.764 15.6306 161.888 15.9487C163.121 16.2898 164.111 16.6256 164.856 16.9561C165.602 17.2866 166.235 17.7645 166.754 18.3899C167.273 19.0171 167.533 19.8522 167.533 20.8987C167.533 21.8546 167.283 22.7199 166.787 23.4928C166.29 24.2675 165.597 24.8698 164.71 25.3033C163.824 25.7351 162.829 25.9518 161.726 25.9518C159.758 25.9518 158.152 25.497 156.907 24.5855V24.5837Z"
        fill="white"
      />
      <path d="M185.053 0.409058V25.6764H183.593V0.409058H185.053Z" fill="white" />
      <path
        d="M191.819 11.3699C192.543 9.93609 193.549 8.83272 194.836 8.05805C196.124 7.28516 197.589 6.89783 199.233 6.89783C201.094 6.89783 202.671 7.37578 203.969 8.33168C205.267 9.28757 206.175 10.5064 206.695 11.9847V7.13591H208.155V25.6764H206.695V20.7939C206.176 22.297 205.262 23.5319 203.953 24.4985C202.645 25.4668 201.071 25.9501 199.233 25.9501C197.589 25.9501 196.124 25.5574 194.836 24.7721C193.549 23.9867 192.543 22.8709 191.819 21.4264C191.094 19.9819 190.732 18.3029 190.732 16.3911C190.732 14.4793 191.094 12.8056 191.819 11.3717V11.3699ZM205.737 12.1037C205.098 10.8636 204.228 9.90767 203.125 9.23605C202.022 8.56443 200.79 8.22862 199.427 8.22862C198.064 8.22862 196.745 8.55377 195.663 9.20229C194.581 9.85081 193.737 10.7907 193.132 12.0185C192.526 13.248 192.223 14.7049 192.223 16.3893C192.223 18.0737 192.526 19.5306 193.132 20.7601C193.737 21.9897 194.587 22.9349 195.679 23.5941C196.771 24.255 198.02 24.5837 199.427 24.5837C200.834 24.5837 202.028 24.2479 203.141 23.5763C204.254 22.9047 205.125 21.9488 205.753 20.7086C206.38 19.4684 206.693 18.0293 206.693 16.3893C206.693 14.7493 206.373 13.3457 205.737 12.1037Z"
        fill="white"
      />
      <path
        d="M218.925 8.32987C220.223 7.37397 221.79 6.89602 223.63 6.89602C225.274 6.89602 226.739 7.28336 228.026 8.05625C229.314 8.83092 230.32 9.93429 231.044 11.3681C231.769 12.802 232.131 14.4757 232.131 16.3875C232.131 18.2993 231.769 19.9783 231.044 21.4228C230.32 22.8691 229.314 23.9832 228.026 24.7685C226.739 25.5538 225.274 25.9465 223.63 25.9465C221.79 25.9465 220.218 25.4685 218.909 24.5126C217.601 23.5567 216.686 22.3272 216.167 20.8259V25.6746H214.708V0.409058H216.167V12.0184C216.707 10.5153 217.627 9.28755 218.925 8.33165V8.32987ZM229.729 12.0167C229.124 10.7871 228.274 9.849 227.182 9.20049C226.09 8.55197 224.829 8.22682 223.403 8.22682C221.976 8.22682 220.807 8.56263 219.704 9.23424C218.601 9.90586 217.736 10.8618 217.108 12.1019C216.48 13.3421 216.167 14.7706 216.167 16.3875C216.167 18.0044 216.48 19.4666 217.108 20.7068C217.736 21.947 218.601 22.9047 219.704 23.5745C220.807 24.2461 222.04 24.5819 223.403 24.5819C224.766 24.5819 226.09 24.2515 227.182 23.5923C228.274 22.9331 229.124 21.9879 229.729 20.7583C230.334 19.5288 230.638 18.0719 230.638 16.3875C230.638 14.7031 230.335 13.2462 229.729 12.0167Z"
        fill="white"
      />
      <path
        d="M238.83 24.5837C237.586 23.674 236.888 22.377 236.737 20.6908H238.23C238.337 21.8973 238.863 22.8532 239.804 23.5585C240.745 24.2639 242.027 24.6175 243.649 24.6175C244.989 24.6175 246.054 24.2586 246.844 23.5426C247.634 22.8247 248.028 21.9435 248.028 20.897C248.028 20.1685 247.817 19.5715 247.395 19.1042C246.972 18.6387 246.448 18.2798 245.82 18.0293C245.193 17.7787 244.34 17.5051 243.258 17.2102C241.96 16.8459 240.91 16.487 240.11 16.1352C239.31 15.7834 238.628 15.2539 238.066 14.5468C237.504 13.8414 237.222 12.8962 237.222 11.7129C237.222 10.8476 237.47 10.0463 237.968 9.30534C238.466 8.56621 239.157 7.97988 240.045 7.54635C240.931 7.11459 241.937 6.89783 243.063 6.89783C244.901 6.89783 246.389 7.38111 247.525 8.34944C248.661 9.31778 249.271 10.6539 249.357 12.3614H247.897C247.832 11.1319 247.379 10.1315 246.535 9.35687C245.69 8.58398 244.523 8.19664 243.031 8.19664C241.777 8.19664 240.738 8.53778 239.917 9.22183C239.094 9.90411 238.684 10.7356 238.684 11.7146C238.684 12.5799 238.916 13.28 239.382 13.8148C239.846 14.3496 240.42 14.76 241.102 15.0443C241.783 15.3286 242.687 15.6306 243.81 15.9487C245.043 16.2898 246.033 16.6256 246.779 16.9561C247.525 17.2866 248.158 17.7645 248.677 18.3899C249.195 19.0171 249.456 19.8522 249.456 20.8987C249.456 21.8546 249.206 22.7199 248.71 23.4928C248.212 24.2675 247.519 24.8698 246.633 25.3033C245.747 25.7351 244.751 25.9518 243.649 25.9518C241.681 25.9518 240.075 25.497 238.83 24.5855V24.5837Z"
        fill="white"
      />
    </svg>
  );
}

RegenerusLogoWithoutDots.defaultProps = {
  height: 35,
  width: 250
};
