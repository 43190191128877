import { useEffect, useState } from "react";

import Box from "components/Box";
import { CMP_PERMISSION_ADMIN } from "components/organisations/constants";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import Modal from "tpo/Modal";
import SelectPatient from "tpo/SelectPatient";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

import SavePatientForm from "../forms/SavePatientForm";
import PatientDetails, { getSavePatientEditableFields } from "./PatientDetails";

export default function PatientSelection() {
  const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);
  const [showEditPatientModal, setShowEditPatientModal] = useState(false);

  const { organisation, permissions } = useOrganisationContext();
  const { basket, updateBasketDetails } = useOrganisationBasketContext();
  const [selectedPatientId, setSelectedPatientId] = useState("");

  useEffect(() => {
    if (!basket || !basket.patient || !organisation) {
      return;
    } else {
      let foundPatient = organisation.patients.find(
        patient => patient.platformUserProfile.pk === basket.patient.pk
      );
      setSelectedPatientId(foundPatient?.id || "");
    }
  }, [basket, organisation]);

  const handleSelectedPatient = value => {
    if (value === selectedPatientId || !organisation) {
      return;
    }
    setSelectedPatientId(value);
    let pup = organisation.patients.find(p => p.id === value)?.platformUserProfile;
    updateBasketDetails({
      patient: pup?.pk ?? "",
      usePatientAddress: true
    });
  };

  const patient = organisation?.patients.find(p => p.id === selectedPatientId);

  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        bg: "white",
        px: [20, 20, 40],
        pt: [20, 20, 40],
        pb: [50, 50, 80],
        borderRadius: 5
      }}
    >
      <Box fontFamily="gilroyBold" fontSize={28}>
        Assign this order to patient
      </Box>
      <Spacer py={2} />
      <Box fontFamily="gilroyMedium" fontSize={16}>
        Once you assign this order to a patient, the kit(s) will automatically be registered to them
        and can’t be changed.
      </Box>
      <Spacer py={15} />
      <SelectPatient
        patients={organisation?.patients || []}
        value={selectedPatientId}
        onChange={handleSelectedPatient}
      />
      <Spacer py={15} />
      {!!(permissions && permissions.includes(CMP_PERMISSION_ADMIN)) ? (
        <ButtonV2
          color="green"
          onClick={() => setShowCreatePatientModal(true)}
          rightIcon={<ChevronComponent />}
          size={["sm", "sm", "md"]}
        >
          create a new patient
        </ButtonV2>
      ) : (
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} color="midGrey">
          Please contact your team manager if you need to add additional patients
        </Box>
      )}
      <Spacer py={15} />
      {patient && (
        <PatientDetails
          patient={patient}
          permissions={permissions}
          onEdit={() => {
            setShowEditPatientModal(true);
          }}
        />
      )}{" "}
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [20, 20, 40]
        }}
        bg="white"
        show={showEditPatientModal}
        close={() => setShowEditPatientModal(false)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <Box px={[20]} maxWidth={760} mx="auto">
          {patient && (
            <SavePatientForm
              key="edit"
              title="Edit Patient Details"
              editableFields={getSavePatientEditableFields(patient)}
              defaultValues={{
                id: patient.id,
                firstName: patient.platformUserProfile.user.firstName,
                lastName: patient.platformUserProfile.user.lastName,
                email: patient.platformUserProfile.user.email,
                sex: patient.platformUserProfile.gender,
                dateOfBirth: patient.platformUserProfile.dateOfBirth || "",
                requiresGuardianConsent: patient.requiresGuardianConsent,
                guardianFirstName: patient.guardianFirstName,
                guardianLastName: patient.guardianLastName,
                guardianEmail: patient.guardianEmail
              }}
              handleSuccess={({ data }) => {
                setShowEditPatientModal(false);
              }}
            />
          )}
        </Box>
      </Modal>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [20, 20, 40]
        }}
        bg="white"
        show={showCreatePatientModal}
        close={() => setShowCreatePatientModal(false)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <Box px={[20]} maxWidth={760} mx="auto">
          <SavePatientForm
            key="create"
            handleSuccess={({ data }) => {
              setSelectedPatientId("");
              updateBasketDetails({
                patient: data.savePatientMutation.patient.platformUserProfile.pk,
                usePatientAddress: true
              });
              setShowCreatePatientModal(false);
            }}
          />
        </Box>
      </Modal>
    </PanelBoxV2>
  );
}
