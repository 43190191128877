export default function PageIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10025_6185)">
        <path
          d="M19.2004 1.404C19.7207 1.404 20.1604 1.83269 20.1604 2.34V16.9851L15.2262 21.996H4.80039C4.28007 21.996 3.84039 21.5673 3.84039 21.06V2.34C3.84039 1.83269 4.28007 1.404 4.80039 1.404H19.2004ZM19.2004 0H4.80039C3.48039 0 2.40039 1.053 2.40039 2.34V21.06C2.40039 22.347 3.48039 23.4 4.80039 23.4H15.8404L21.6004 17.55V2.34C21.6004 1.053 20.5204 0 19.2004 0Z"
          fill="#1A1A1A"
        />
        <path
          d="M14.8802 23.166C14.4827 23.166 14.1602 22.8515 14.1602 22.464V18.252C14.1602 17.22 15.0217 16.38 16.0802 16.38H20.1602C20.5576 16.38 20.8802 16.6944 20.8802 17.082C20.8802 17.4695 20.5576 17.784 20.1602 17.784H16.0802C15.8148 17.784 15.6002 17.9942 15.6002 18.252V22.464C15.6002 22.8515 15.2776 23.166 14.8802 23.166Z"
          fill="#1A1A1A"
        />
        <path
          d="M17.7598 5.14801H6.23977C5.97445 5.14801 5.75977 4.93869 5.75977 4.68001C5.75977 4.42133 5.97445 4.21201 6.23977 4.21201H17.7598C18.0251 4.21201 18.2398 4.42133 18.2398 4.68001C18.2398 4.93869 18.0251 5.14801 17.7598 5.14801Z"
          fill="#1A1A1A"
        />
        <path
          d="M17.7598 9.82801H6.23977C5.97445 9.82801 5.75977 9.61869 5.75977 9.36001C5.75977 9.10133 5.97445 8.89201 6.23977 8.89201H17.7598C18.0251 8.89201 18.2398 9.10133 18.2398 9.36001C18.2398 9.61869 18.0251 9.82801 17.7598 9.82801Z"
          fill="#1A1A1A"
        />
        <path
          d="M17.7598 14.508H6.23977C5.97445 14.508 5.75977 14.2987 5.75977 14.04C5.75977 13.7813 5.97445 13.572 6.23977 13.572H17.7598C18.0251 13.572 18.2398 13.7813 18.2398 14.04C18.2398 14.2987 18.0251 14.508 17.7598 14.508Z"
          fill="#1A1A1A"
        />
        <path
          d="M11.5198 19.188H6.23977C5.97445 19.188 5.75977 18.9787 5.75977 18.72C5.75977 18.4613 5.97445 18.252 6.23977 18.252H11.5198C11.7851 18.252 11.9998 18.4613 11.9998 18.72C11.9998 18.9787 11.7851 19.188 11.5198 19.188Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10025_6185">
          <rect width="24" height="23.4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
