import getClinicFees from "./getClinicFees";

export default function groupItemsByName(orderItems) {
  // for consumer shop only
  // create new one for practitioner shop

  let orderItemsByName = {};
  orderItems.forEach(
    ({
      id,
      clinicBooking,
      clinicLocation,
      groupBy,
      nameInBasket,
      price,
      product,
      productOption
    }) => {
      let summaryLineInBasket;
      if (groupBy in orderItemsByName) {
        summaryLineInBasket = orderItemsByName[groupBy];
        summaryLineInBasket["count"]++;
      } else {
        summaryLineInBasket = {
          compositeId: productOption ? productOption.compositeId : product.compositeId,
          clinicBookings: []
        };
        summaryLineInBasket["nameInBasket"] = nameInBasket;
        summaryLineInBasket["count"] = 1;
        summaryLineInBasket["linePrice"] = clinicLocation
          ? (price - getClinicFees([{ location: clinicLocation }])).toFixed(2)
          : price;
        summaryLineInBasket["rrpFullPrice"] = productOption ? undefined : product.rrpFullPrice;
        orderItemsByName[groupBy] = summaryLineInBasket;
      }
      if (clinicBooking) {
        summaryLineInBasket["clinicBookings"].push({
          ...clinicBooking,
          orderTestItemId: id,
          location: clinicLocation
        });
      } else if (clinicLocation) {
        summaryLineInBasket["clinicBookings"].push({
          status: "open",
          url: clinicLocation.bookingLink,
          orderTestItemId: id,
          location: clinicLocation
        });
      }
    }
  );
  return orderItemsByName;
}
