import Box from "components/Box";
import Circle from "components/Circle";
import { theme } from "core/theme";
import { ReactComponent as Tick } from "images/tick.svg";
import Center from "tpo/Center";
import Group from "tpo/Group";
import Stack from "tpo/Stack";

export default function TestRecommendationPanel({ userTestRecommendation }) {
  if (!userTestRecommendation) return null;

  if (userTestRecommendation?.rank <= 5) {
    return (
      <Stack gap={20} pt={[50]} pb={[70]} px={[40]} bg="blue" borderRadius={5}>
        <Center stacked gap={20}>
          <Circle bg="purple" size={114}>
            <Box color="white" fontFamily="gilroyBold" fontSize={44}>
              #{userTestRecommendation.rank}
            </Box>
          </Circle>
          <Box color="white" fontFamily="gilroyBold" fontSize={28}>
            Matched Test
          </Box>
        </Center>
        <Box fontFamily="gilroyMedium" fontSize={16} color="white">
          Based on your self assessment questionnaire this test should help identify possible causes
          for the following symptoms:
        </Box>
        <Stack gap={10}>
          <Box color="white" fontFamily="gilroyBold" fontSize={16}>
            Matched symptoms
          </Box>
          <Box
            display={["flex", "grid"]}
            flexDirection={["column", null]}
            gap={8}
            gridTemplateColumns={[null, "1fr 1fr 1fr"]}
            as="ul"
          >
            {userTestRecommendation.symptoms.slice(0, 5).map(symp => (
              <Group
                as="li"
                color="white"
                alignItems="center"
                gap={10}
                fontFamily="gilroyMedium"
                fontSize={16}
                key={symp.id}
              >
                <Tick
                  fill={theme.colors.green}
                  width={20}
                  height={15}
                  style={{ minWidth: "20px", minHeight: "15px" }}
                />
                {symp.name}
              </Group>
            ))}
          </Box>
        </Stack>
      </Stack>
    );
  }

  return null;
}
