import {
  bindValueToSegment,
  getChartRanges,
  getInterpretationColor,
  roundTimestampToDay
} from "utils/shared";

export function buildDiscreteGraphConfiguration({
  userDatapoints,
  userResults,
  chartConfiguration: {
    normalisedRange,
    chartRange: { range },
    terminology
  }
}) {
  if (userDatapoints && userResults) {
    throw new Error("Cannot use both userDatapoints and userResults");
  }
  if (userDatapoints && userResults) {
    throw new Error("Cannot use both userDatapoints and userResults");
  }

  const { fullRange } = getChartRanges({
    range,
    normalisedRange,
    precision: null,
    withLabels: false
  });

  let points = [];
  let tooltips = [];
  let colors = [];

  if (userDatapoints) {
    [points, tooltips, colors] = userDatapoints.reduce(
      ([points, tooltips, colors], userDatapoint, index) => {
        const { value } = userDatapoint.chartConfiguration.normalisedRange;
        let ySegment = 0;
        userDatapoint.chartConfiguration.normalisedRange.range.forEach((point, index) => {
          if (value >= point) {
            ySegment = index + 1;
          }
        });

        const y = bindValueToSegment((ySegment + 1) / (fullRange.length - 1), fullRange.length - 1);
        const xValue = new Date(userDatapoint.dateExtracted).getTime();

        points.push([xValue, y]);

        const stack = [
          `<div class='interpretation' style='color: ${getInterpretationColor(
            userDatapoint.chartConfiguration.interpretation
          )};'>${userDatapoint.chartConfiguration.interpretation}</div>`,
          `<div class='date'>${new Date(xValue).toLocaleDateString(undefined, {
            day: "numeric",
            month: "long",
            year: "numeric"
          })}</div>`
        ];

        if (
          userDatapoints.length - 1 === index &&
          userDatapoint.dateExpired &&
          new Date(userDatapoint.dateExpired) <= new Date()
        ) {
          stack.push("<div class='retest'>(Due for retest)</div>");
        }

        tooltips.push(stack);

        colors.push(
          new Date(userDatapoint.dateExpired) <= new Date()
            ? "#818181"
            : getInterpretationColor(userDatapoint.chartConfiguration.interpretation)
        );

        return [points, tooltips, colors];
      },
      [[], [], []]
    );
  } else if (userResults) {
    [points, tooltips, colors] = userResults.reduce(
      ([points, tooltips, colors], userResult, index) => {
        const {
          userSnapshot: { snapshotDate },
          chartConfiguration: {
            normalisedRange: { value }
          }
        } = userResult;
        let ySegment = 0;
        userResult.chartConfiguration.normalisedRange.range.forEach((point, index) => {
          if (value >= point) {
            ySegment = index + 1;
          }
        });

        const y = bindValueToSegment((ySegment + 1) / (fullRange.length - 1), fullRange.length - 1);
        const xValue = roundTimestampToDay(snapshotDate);

        points.push([xValue, y]);

        const stack = [
          `<div class='interpretation' style='color: ${getInterpretationColor(
            userResult.chartConfiguration.interpretation
          )};'>${userResult.chartConfiguration.interpretation}</div>`,
          `<div class='date'>${new Date(xValue).toLocaleDateString(undefined, {
            day: "numeric",
            month: "long",
            year: "numeric"
          })}</div>`
        ];

        tooltips.push(stack);

        colors.push(
          new Date(userResult.dateExpired) <= new Date()
            ? "#818181"
            : getInterpretationColor(userResult.chartConfiguration.interpretation)
        );

        return [points, tooltips, colors];
      },
      [[], [], []]
    );
  }

  return {
    fullRange,
    points,
    userDatapoint: userDatapoints ? userDatapoints.slice(-1)[0] : undefined,
    userResult: userResults ? userResults.slice(-1)[0] : undefined,
    tooltips,
    colors
  };
}
