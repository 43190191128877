import { createContext } from "react";

import { ChartConfigurationProvider } from "contexts/ChartConfiguration";

import Box from "../components/Box";
import { ExternalTextLink } from "../components/Links";
import { DiscreteChartCard, SegementedProgressChartCard, UnknownChartCard } from "./ChartCards";

export const CardListContext = createContext();

export function ResultCharts({ children, result }) {
  if (result.chartConfiguration?.chartType === "DISCRETE_CHART") {
    return (
      <ChartConfigurationProvider value={result.chartConfiguration}>
        <DiscreteChartCard result={result}>{children}</DiscreteChartCard>
      </ChartConfigurationProvider>
    );
  } else if (result.chartConfiguration?.chartType === "SEGMENTED_PROGRESS_CHART") {
    return (
      <ChartConfigurationProvider value={result.chartConfiguration}>
        <SegementedProgressChartCard result={result}>{children}</SegementedProgressChartCard>
      </ChartConfigurationProvider>
    );
  }

  return (
    <ChartConfigurationProvider value={result.chartConfiguration}>
      <UnknownChartCard result={result}>{children}</UnknownChartCard>
    </ChartConfigurationProvider>
  );
}

export function Explanation({ display }) {
  return (
    <Box display={display} fontSize={[14, 14, 16]} textAlign="center">
      Your Omnos results are based on optimal biomarker ranges which differ from standard lab ranges{" "}
      {
        <ExternalTextLink href="https://omnos.me/articles/understanding-your-omnos-results">
          learn more
        </ExternalTextLink>
      }
    </Box>
  );
}
