import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import { PLATFORM_USER_PROFILE_FIELDS } from "../graphql/accounts";
import { STORE_INCOMING_DISCOUNT_CODE } from "../graphql/shop";
import { useDataLoader } from "../hooks/useDataLoader";
import { useAuthContext } from "./AuthProvider";

export const AppStateContext = React.createContext();
export const useAppState = () => useContext(AppStateContext);

function useWatchDiscountCode() {
  const params = new URLSearchParams(window.location.search);
  const discountCode = params.get("discountcode");
  const [storeIncomingDiscountCode] = useMutation(STORE_INCOMING_DISCOUNT_CODE);

  useEffect(() => {
    if (discountCode) {
      storeIncomingDiscountCode({ variables: { discountCode } });
    }
  }, [discountCode, storeIncomingDiscountCode]);
}

function usePathnameMonitor() {
  // Watches for changes in the pathname, trigger GA calls and resets the scroll unless the state says not to
  const { pathname, state } = useLocation();
  const prevPathnameRef = useRef(window.location.pathname);

  useEffect(() => {
    if (pathname !== prevPathnameRef.current) {
      if (state?.resetScroll !== false) {
        window.scrollTo(0, 0);
      }

      if (prevPathnameRef.current !== null) {
        if (typeof window.gtag !== typeof undefined) {
          window.gtag("config", "UA-156447102-1", { page_path: pathname });
        }
      }
      prevPathnameRef.current = pathname;
    }
  }, [pathname, state]);
}

export function isOnBoardingFlow(path) {
  if (path === "/profile/welcome") {
    return true;
  } else if (path === "/profile") {
    return true;
  } else if (path === "/profile/goals") {
    return true;
  } else if (path === "/profile/diet-preferences") {
    return true;
  }
}

const USER_INFO_QUERY = gql`
  query UserInfoQuery {
    user {
      id
      snapshotStatus
      snapshotQueued
      email
      pageAvailability {
        testResultsAvailable
        wellnessScoresAvailable
        personalisedRecommendationsAvailable
        foodListAvailable
      }
      partnerUserProfile {
        id
        tier
      }
      platformUserProfile {
        ...PlatformUserProfileFields
      }
    }
    userSubmissions {
      id
      complete
    }
  }
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

function AppStateProvider({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [consumerBasketOpen, setConsumerBasketOpen] = useState(false);
  const [practitionerBasketOpen, setPractitionerBasketOpen] = useState(false);

  const [questionnaireProcessing, setQuestionnaireProcessing] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthContext();
  const { data: userInfo, refetch } = useDataLoader({
    query: USER_INFO_QUERY,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache"
  });

  usePathnameMonitor();
  useWatchDiscountCode();

  const refetchPlatformAndRedirect = useCallback(
    callback => {
      refetch().then(res => callback(res, history));
    },
    [refetch, history]
  );

  useEffect(() => {
    if (menuOpen && (consumerBasketOpen || practitionerBasketOpen)) {
      if (consumerBasketOpen) {
        setConsumerBasketOpen(false);
      }
      if (practitionerBasketOpen) {
        setPractitionerBasketOpen(false);
      }
    }
    // eslint-disable-next-line
  }, [menuOpen, consumerBasketOpen, practitionerBasketOpen]);

  useEffect(() => {
    if (
      user &&
      userInfo?.user?.platformUserProfile &&
      !userInfo.user.platformUserProfile.isComplete &&
      !isOnBoardingFlow(location.pathname) &&
      !userInfo?.user?.partnerUserProfile
    ) {
      return history.push("/profile/welcome");
    }
  }, [userInfo, location, user, history]);

  const api = useMemo(
    () => ({
      menuOpen,
      setMenuOpen,
      basketOpen: consumerBasketOpen,
      setBasketOpen: setConsumerBasketOpen,
      questionnaireProcessing,
      setQuestionnaireProcessing,
      refetchPlatformAndRedirect,
      userInfo,
      practitionerBasketOpen,
      setPractitionerBasketOpen
    }),
    [
      menuOpen,
      setMenuOpen,
      consumerBasketOpen,
      setConsumerBasketOpen,
      questionnaireProcessing,
      setQuestionnaireProcessing,
      refetchPlatformAndRedirect,
      userInfo,
      practitionerBasketOpen,
      setPractitionerBasketOpen
    ]
  );

  return <AppStateContext.Provider value={api}>{children}</AppStateContext.Provider>;
}

export default AppStateProvider;
