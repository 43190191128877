import { useAppState } from "components/AppStateProvider";
import { useAuthContext } from "components/AuthProvider";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import CustomerTabs from "components/CustomerTabs";
import DataLoader from "components/DataLoader";
import Loading from "components/Loading";
import { useBasketContext } from "contexts/BasketContext";
import { SupplementsProvider, useSupplementsContext } from "contexts/SupplementContext";
import { theme } from "core/theme";
import { SHOP_SUPPLEMENTS_URL, SHOP_TESTS_URL } from "core/urls";
import { SHOP_LISTING_FILTERS_QUERY, SUPPLEMENT_DETAIL_QUERY } from "graphql/shop";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import Jumbotron from "tpo/Jumbotron";
import Modal from "tpo/Modal";
import SortMenu from "tpo/SortMenu";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";

import ListPageTemplate from "./ListPageTemplate";
import OmnosHeader from "./OmnosHeader";
import SupplementCard from "./SupplementCard";
import { SupplementDetailContent } from "./SupplementDetailPage";

export function SupplementControls() {
  const { controls, location, history, menu, search, selectedFilters } = useSupplementsContext();

  return (
    <Box display="flex" flexDirection={["column"]} gap={20} flexWrap="wrap" pb={[20, 20, 40]}>
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        gap={20}
        justifyContent={[null, null, "space-between"]}
        flexWrap="wrap"
      >
        <Group gap={10}>{menu}</Group>
        <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
          <Search
            value={search}
            onChange={s => {
              const searchParams = new URLSearchParams(location.search);
              searchParams.set("search", s);
              history.push({
                path: location.path,
                search: searchParams.toString()
              });
            }}
            maxWidth={[null, 350]}
            minWidth={310}
            width="100%"
          />
          <Box display="flex" justifyContent="flex-end">
            <SortMenu
              value={controls.sort}
              setValue={controls.setSort}
              open={controls.sortMenuOpen}
              setOpen={controls.setSortMenuOpen}
              options={controls.sortOptions}
              placeholder="Sort By"
            />
          </Box>
        </Box>
      </Box>
      {selectedFilters}
    </Box>
  );
}

export function SupplementList({ onAddToBasket }) {
  const {
    hasMore,
    loading,
    endCursor,
    fetchMore,
    supplements,
    setSelectedSupplement
  } = useSupplementsContext();

  return (
    <InfiniteScroll
      loader={<Loading />}
      hasMore={hasMore}
      loading={loading}
      next={() => {
        if (endCursor) {
          fetchMore({
            variables: {
              after: endCursor,
              first: 10
            }
          });
        }
      }}
      items={supplements}
    >
      <Stack>
        <InfiniteScrollContext.Consumer>
          {({ itemsList, setBottomElement }) => (
            <>
              {itemsList.map((supplement, idx) => (
                <SupplementCard
                  title={supplement.name}
                  addText="Add"
                  status={supplement.status}
                  subtitle={
                    <>
                      <Box color="anchorBlue" fontFamily="gilroyMedium" fontSize={[16, 16, 18]}>
                        {supplement.brandName}
                      </Box>
                      {!!supplement.size && (
                        <Box color="midGrey" fontFamily="gilroyMedium" fontSize={14}>
                          Size: {supplement.size}
                        </Box>
                      )}
                    </>
                  }
                  pills={
                    <>
                      {!!supplement.doseType && (
                        <Badge bg="purple" color="white" size="xs">
                          {supplement.doseType}
                        </Badge>
                      )}
                      {supplement.dietryRestrictions.map(dr => (
                        <Badge bg="haze" color="dark" size="xs" key={dr.id}>
                          {dr.name}
                        </Badge>
                      ))}
                    </>
                  }
                  price={supplement.price}
                  key={supplement.id}
                  onAdd={e => {
                    e.onAdd = true;
                    onAddToBasket({ supplement });
                  }}
                  onSelect={e => {
                    if (e.onAdd) return;
                    setSelectedSupplement(supplement.slug);
                  }}
                  ref={itemsList.length - 1 === idx ? setBottomElement : null}
                />
              ))}
            </>
          )}
        </InfiniteScrollContext.Consumer>
      </Stack>
    </InfiniteScroll>
  );
}

export function SupplementDetailModal({ onAddToBasket }) {
  const { selectedSupplement, setSelectedSupplement } = useSupplementsContext();

  return (
    <Modal
      maxWidth={1020}
      closeButton
      headerProps={{
        p: [20, 20, 40]
      }}
      bg="haze"
      show={!!selectedSupplement}
      close={() => setSelectedSupplement()}
      mode={["fullScreen", "fullScreen", "centered"]}
      data-component-name="SupplementDetailModal"
    >
      {selectedSupplement && (
        <DataLoader
          query={SUPPLEMENT_DETAIL_QUERY}
          variables={{
            slug: selectedSupplement
          }}
          render={({ supplement }) => (
            <SupplementDetailContent
              onAddToBasket={() => {
                setSelectedSupplement();
                onAddToBasket({ supplement });
              }}
              description={supplement.description}
              name={supplement.name}
              productImage={supplement.productImage}
              dietryRestrictions={supplement.dietryRestrictions}
              price={supplement.price}
              directions={supplement.directions}
              relatedUserResults={supplement.relatedUserResults}
              dimensions={{
                columnWidth: 600
              }}
              status={supplement.status}
            />
          )}
        />
      )}
    </Modal>
  );
}

export function SupplementListContent({
  bg,
  dietryRestrictions,
  types,
  brands,
  header,
  urlMap,
  title,
  subtitle,
  onAddToBasket,
  productFiltersDrawerBg = "blue",
  pb
}) {
  return (
    <SupplementsProvider
      dietryRestrictions={dietryRestrictions}
      types={types}
      brands={brands}
      productFiltersDrawerBg={productFiltersDrawerBg}
    >
      <ListPageTemplate
        bg={bg}
        urlMap={urlMap}
        header={header}
        tab="supplements"
        title={title}
        subtitle={subtitle}
        pb={pb}
      >
        <PanelBoxV2
          maxWidth={1220}
          outer={{
            bg: "haze",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, "5.5vw"]
          }}
        >
          <SupplementControls />
          <SupplementList onAddToBasket={onAddToBasket} />
        </PanelBoxV2>
        <SupplementDetailModal onAddToBasket={onAddToBasket} />
      </ListPageTemplate>
    </SupplementsProvider>
  );
}

export default function SupplementListPage() {
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();
  const { user } = useAuthContext();

  return (
    <CustomerPage
      jumbotronProps={{
        title: "Shop"
      }}
    >
      {user && <CustomerTabs selectedTab="/shop" />}
      <DataLoader
        query={SHOP_LISTING_FILTERS_QUERY}
        render={({ dietryRestrictions, types, brands }) => (
          <SupplementListContent
            brands={brands}
            dietryRestrictions={dietryRestrictions}
            types={types}
            urlMap={{
              supplements: SHOP_SUPPLEMENTS_URL,
              tests: SHOP_TESTS_URL
            }}
            bg="white"
            title="Supplements are important"
            subtitle="We can get a huge amount of benefit from small changes to our diets. Alongside our nutritional advice we offer more specific, targeted support, in the form of carefully selected supplements. These supplements have been designed to be specific rather than general."
            onAddToBasket={({ supplement }) => {
              addItemToBasket({
                compositeId: supplement.compositeId
              });
              setBasketOpen(true);
            }}
          />
        )}
      />
    </CustomerPage>
  );
}
