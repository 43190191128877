async function copyTextToClipboard(text) {
  // NB - i think this will only work if the page is loaded on a secure origin
  // i.e. https
  // ( so expect this to not work locally )
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export default copyTextToClipboard;
