import { useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { useHeaderContext } from "contexts/HeaderContext";
import {
  CONTACT_US_URL,
  HOW_IT_WORKS_URL,
  LOGIN_URL,
  MEET_THE_TEAM_URL,
  OUR_MISSION_URL,
  PARTNERS_PRACTITIONER_URL,
  SIGNUP_URL,
  THE_SCIENCE_URL
} from "core/urls";
import ChevronComponent from "tpo/Chevron";
import LinkWrapper from "tpo/LinkWrapper";
import Menu from "tpo/Menu";
import ButtonV2 from "v2/Buttons";

export default function LoggedOutMenuItems({ bg }) {
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [partnersDropdownOpen, setPartnersDropdownOpen] = useState(false);
  const { onClick } = useHeaderContext();

  return (
    <>
      <Menu
        opened={aboutDropdownOpen}
        onChange={internalVal => {
          if (internalVal && partnersDropdownOpen) {
            setPartnersDropdownOpen(false);
          }
          setAboutDropdownOpen(internalVal);
        }}
        trigger="hover"
        xOffset={60}
        yOffset={30}
      >
        <Menu.Target>
          <ButtonV2
            display={["none", "none", "inline-flex"]}
            px={0}
            py={0}
            letterSpacing="unset"
            textTransform="unset"
            fontSize={16}
            gap={10}
            rightIcon={<ChevronComponent direction={aboutDropdownOpen ? "top" : "bottom"} />}
            size={null}
          >
            About
          </ButtonV2>
        </Menu.Target>
        <Menu.Dropdown
          bg={bg}
          top={45}
          minWidth={240}
          left={-30}
          borderRadius={5}
          py={40}
          boxShadow="5px 5px 0px 0px rgba(0, 0, 0, 0.20)"
        >
          <Menu.Item
            selectedColor="rgba(255, 255, 255, 0.10)"
            color="white"
            fontFamily="gilroyBold"
            fontSize={14}
            py={16}
            px={30}
            as={ExternalTextLink}
            href={HOW_IT_WORKS_URL}
            display="block"
            underline={false}
          >
            How it works
          </Menu.Item>
          <Menu.Item
            selectedColor="rgba(255, 255, 255, 0.10)"
            color="white"
            fontFamily="gilroyBold"
            fontSize={14}
            py={16}
            px={30}
            as={ExternalTextLink}
            href={OUR_MISSION_URL}
            display="block"
            underline={false}
          >
            Our Mission
          </Menu.Item>
          <Menu.Item
            selectedColor="rgba(255, 255, 255, 0.10)"
            color="white"
            fontFamily="gilroyBold"
            fontSize={14}
            py={16}
            px={30}
            as={ExternalTextLink}
            href={THE_SCIENCE_URL}
            display="block"
            underline={false}
          >
            The science
          </Menu.Item>
          <Menu.Item
            selectedColor="rgba(255, 255, 255, 0.10)"
            color="white"
            fontFamily="gilroyBold"
            fontSize={14}
            py={16}
            px={30}
            as={ExternalTextLink}
            href={MEET_THE_TEAM_URL}
            display="block"
            underline={false}
          >
            Meet the team
          </Menu.Item>
          <Menu.Item
            selectedColor="rgba(255, 255, 255, 0.10)"
            color="white"
            fontFamily="gilroyBold"
            fontSize={14}
            py={16}
            px={30}
            as={ExternalTextLink}
            href={CONTACT_US_URL}
            display="block"
            underline={false}
          >
            Contact us
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Menu
        opened={partnersDropdownOpen}
        onChange={internalVal => {
          if (internalVal && aboutDropdownOpen) {
            setAboutDropdownOpen(false);
          }
          setPartnersDropdownOpen(internalVal);
        }}
        trigger="hover"
        xOffset={60}
        yOffset={30}
      >
        <Menu.Target>
          <ButtonV2
            display={["none", "none", "inline-flex"]}
            px={0}
            py={0}
            letterSpacing="unset"
            textTransform="unset"
            fontSize={16}
            gap={10}
            rightIcon={<ChevronComponent direction={partnersDropdownOpen ? "top" : "bottom"} />}
            size={null}
          >
            Partners
          </ButtonV2>
        </Menu.Target>
        <Menu.Dropdown
          bg={bg}
          top={45}
          minWidth={240}
          left={-30}
          borderRadius={5}
          py={40}
          boxShadow="5px 5px 0px 0px rgba(0, 0, 0, 0.20)"
        >
          <Menu.Item
            selectedColor="rgba(255, 255, 255, 0.10)"
            color="white"
            fontFamily="gilroyBold"
            fontSize={14}
            py={16}
            px={30}
            as={ExternalTextLink}
            href="https://www.regeneruslabs.me/practitioner-portal"
            display="block"
            underline={false}
            onClick={onClick}
          >
            Omnos Partner programme
          </Menu.Item>
          <Menu.Item
            selectedColor="rgba(255, 255, 255, 0.10)"
            color="white"
            fontFamily="gilroyBold"
            fontSize={14}
            py={16}
            px={30}
            as={LinkWrapper}
            to={PARTNERS_PRACTITIONER_URL}
            display="block"
            underline={false}
            onClick={onClick}
          >
            Practitioner application
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Box
        as={LinkWrapper}
        color="green"
        fontFamily="gilroyBold"
        fontSize={16}
        to={SIGNUP_URL}
        textDecoration="underline"
        display={["none", "none", "inline"]}
        onClick={onClick}
      >
        Join now
      </Box>
      <ButtonV2
        variant="pill"
        as={LinkWrapper}
        color="green"
        to={LOGIN_URL}
        py={2}
        px={20}
        onClick={onClick}
        size={null}
      >
        sign in
      </ButtonV2>
    </>
  );
}
