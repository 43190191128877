import AspectRatio from "components/AspectRatio";
import BackgroundImage from "components/BackgroundImage";
import Box from "components/Box";
import RichText from "components/RichText";
import Stack from "tpo/Stack";

function VideoOrImage({ imageUrl, videoUrl }) {
  if (videoUrl) {
    return (
      <AspectRatio value={9 / 16} flex={1}>
        <iframe
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          src={`https://www.youtube.com/embed/${videoUrl}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatio>
    );
  }
  if (imageUrl) {
    return (
      <AspectRatio value={9 / 16} flex={1}>
        <BackgroundImage backgroundImage={imageUrl} width="100%" height="100%" />
      </AspectRatio>
    );
  }

  return null;
}

export default function HowItWorksPanel({
  title,
  body,
  collectingYourSampleImage,
  collectingYourSampleVideo
}) {
  return (
    <>
      <Stack gap={[20, 20, 40]} alignItems="flex-start">
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          {title}
        </Box>
        <RichText
          style={{
            whiteSpace: "unset"
          }}
        >
          {body}
        </RichText>
      </Stack>
      <Stack
        gap={[20, 20, 40]}
        style={{
          flexGrow: 1
        }}
      >
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Collecting your sample
        </Box>
        <VideoOrImage imageUrl={collectingYourSampleImage} videoUrl={collectingYourSampleVideo} />
      </Stack>
    </>
  );
}
