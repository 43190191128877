import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useAuthContext } from "components/AuthProvider";
import { CustomerAccountPage } from "components/CustomerPage";
import DataLoader from "components/DataLoader";
import { DASHBOARD_HOME_URL } from "core/urls";
import {
  ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION,
  RESULTS_ACCESS_REQUEST_QUERY
} from "graphql/accounts";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import useDocTitle from "hooks/use-doc-title";
import Center from "tpo/Center";
import Checkbox from "tpo/Checkbox";
import ControlledFormField from "tpo/ControlledFormField";
import { Error } from "tpo/InputWrapper";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import Box from "./Box";

const textProps = {
  fontFamily: "gilroyMedium",
  fontSize: [14, 14, 16]
};

function CannotFindAccessRequest() {
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Results access request not found"
      }}
    >
      <Box {...textProps} pt={40}>
        I'm sorry, we can't seem to find that access request. Please contact support@omnos.me.
      </Box>
    </CustomerAccountPage>
  );
}

function AccessRequestFound({ requestId, identifier }) {
  const history = useHistory();

  const { user } = useAuthContext();

  const api = useDjangoGraphqlForm({
    mutation: ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION,
    mutationName: "acceptResultsAccessRequestMutation",
    handleSuccess: () => {
      history.push(DASHBOARD_HOME_URL);
    },
    defaultValues: {
      resultsAccessGranted: false
    },
    transformer: data => ({
      ...data,
      requestId
    })
  });

  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Results access request"
      }}
    >
      <Box {...textProps}>
        You've been invited to share your results with an Omnos practitioner.
      </Box>
      <Box pt={user ? 20 : 40}>
        <FormProvider {...api.methods}>
          <Stack
            mx="auto"
            maxWidth={760}
            as="form"
            onSubmit={api.methods.handleSubmit(api.onSubmit)}
            gap={20}
          >
            <ControlledFormField
              Component={Checkbox}
              name="resultsAccessGranted"
              label={`I'm happy for ${identifier} to have access to my results`}
              styles={{
                inputWrapperProps: {
                  mt: 20
                }
              }}
            />
            <Center mt={20} stacked gap={20}>
              <ButtonV2 color="green" sizes={["sm", "sm", "md"]}>
                submit
              </ButtonV2>
              {!!api.nonFieldError && (
                <Error error={api.nonFieldError} data-testid="nonFieldError" />
              )}
            </Center>
          </Stack>
        </FormProvider>
      </Box>
    </CustomerAccountPage>
  );
}

export default function AcceptResultsAccessLink(props) {
  useDocTitle("Accept Results Access Link");

  return (
    <DataLoader
      query={RESULTS_ACCESS_REQUEST_QUERY}
      variables={{ id: props.match.params.requestId }}
      render={data => {
        if (data.resultsAccessLink === null) {
          return <CannotFindAccessRequest />;
        }

        return (
          <AccessRequestFound
            requestId={props.match.params.requestId}
            identifier={
              data.resultsAccessLink.partnerUserProfile
                ? data.resultsAccessLink.partnerUserProfile.user.identifier
                : data.resultsAccessLink.organisation.name
            }
          />
        );
      }}
    />
  );
}
