import { useLocation } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import useProductFilters from "tpo/shop/hooks/useProductFilters";

import useFilters from "./useFilters";

export default function useList({ connectionName, query, defaultSort, sortOptions: _sortOptions }) {
  const { testingServices, sampleTypes, categories } = useFilters();

  const { toggleFilter, allFilters, selectedFilters, validatedFilters } = useProductFilters({
    defaultFilters: {
      categories: [],
      sampleTypes: [],
      testingServices: []
    },
    allFilters: {
      testingServices: [...testingServices].sort(),
      categories: [...categories].sort(),
      sampleTypes: [...sampleTypes].sort()
    }
  });

  const location = useLocation();
  const search = new URLSearchParams(location.search).get("search") || "";

  const sortOptions = _sortOptions || [
    {
      label: "Title asc",
      value: "title"
    },
    {
      label: "Title desc",
      value: "-title"
    },
    {
      label: "Start time asc",
      value: "start_time"
    },
    {
      label: "Start time desc",
      value: "-start_time"
    }
  ];

  const controls = useListControls({
    defaultSort,
    sortOptions
  });

  const debouncedSearch = useDebounce(search, 200);

  const { data, loading, fetchMore } = useQuery(query, {
    variables: {
      first: 10,
      orderBy: controls.sort,
      search: debouncedSearch,
      testingServices: validatedFilters.testingServices.join(","),
      areasOfInterest: validatedFilters.categories.join(","),
      sampleTypes: validatedFilters.sampleTypes.join(",")
    }
  });

  const hasMore = data?.[connectionName].pageInfo.hasNextPage;
  const endCursor = data?.[connectionName].pageInfo.endCursor;
  const objects = data?.[connectionName].edges.map(edge => edge.node);

  return {
    search,
    loading,
    fetchMore,
    hasMore,
    endCursor,
    objects,
    controls,
    toggleFilter,
    allFilters,
    selectedFilters,
    validatedFilters,
    location
  };
}
