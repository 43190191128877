import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import { theme } from "core/theme";
import Stack from "tpo/Stack";

export default function OrderDetailTemplate({ title, children, footer, gap }) {
  return (
    <CustomerPage
      bg="haze"
      jumbotronProps={{
        title
      }}
    >
      <Box pt={theme.spacing.section.pt} pb={theme.spacing.section.pb} px={20}>
        <Box
          bg="white"
          borderRadius={5}
          maxWidth={1020}
          mx="auto"
          pt={[30, 30, 60]}
          pb={[50, 50, 80]}
          px={[20, 20, 40]}
        >
          <Stack gap={gap} maxWidth={760} mx="auto">
            {children}
          </Stack>
        </Box>
      </Box>
      {footer}
    </CustomerPage>
  );
}

OrderDetailTemplate.defaultProps = {
  gap: [20, 20, 40]
};
