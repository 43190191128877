import AspectRatio from "components/AspectRatio";
import BackgroundImage from "components/BackgroundImage";
import Box from "components/Box";
import Spacer from "tpo/Spacer";

function Title({ maxWidth, width, mx }) {
  return (
    <Box maxWidth={maxWidth} mx={mx} width={width}>
      <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
        Your results
      </Box>
      <Spacer py={[2, 2, 15]} />
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        Once you’ve taken your test they will be interpreted and made available on your dashboard
      </Box>
    </Box>
  );
}

function Card({ image, title, children, maxHeight, gridColumnStart, gridColumnEnd }) {
  return (
    <Box
      borderRadius="5px"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      gridColumnStart={gridColumnStart}
      gridColumnEnd={gridColumnEnd}
    >
      <AspectRatio value={9 / 16} flex={0} maxHeight={maxHeight}>
        <BackgroundImage backgroundImage={image} width="100%" height="100%" />
      </AspectRatio>
      <Box bg="white" flex={1} pt={40} pb={50} px={20}>
        <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
          {title}
        </Box>
        <Spacer py={2} />
        {children}
      </Box>
    </Box>
  );
}

function ResultsCard({ maxHeight }) {
  return (
    <Card maxHeight={maxHeight} title="Results" image="/test-images/test-page/results.jpg">
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        Discover and learn how each tested biomarker is influencing your body and your overall
        wellness.
      </Box>
    </Card>
  );
}

function RecommendationsCard({ maxHeight }) {
  return (
    <Card
      maxHeight={maxHeight}
      title="Suggestions"
      image="/test-images/test-page/recommendations.jpg"
    >
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        Get personalised and actionable suggestions to help you optimise your health.
      </Box>
    </Card>
  );
}

function SupportCard({ maxHeight, gridColumnStart, gridColumnEnd }) {
  return (
    <Card
      maxHeight={maxHeight}
      title="Support"
      image="/test-images/test-page/support.jpg"
      gridColumnStart={gridColumnStart}
      gridColumnEnd={gridColumnEnd}
    >
      <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
        Our team of experts are here should you have any questions about the test, the results or
        seek professional support. Please email support@omnos.me
      </Box>
    </Card>
  );
}

export default Object.assign(
  {},
  {
    Title,
    ResultsCard,
    RecommendationsCard,
    SupportCard
  }
);
