import { gql } from "@apollo/client";
import { PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS } from "graphql/shop";

export const SUPPORTING_DOCUMENT_FIELDS = gql`
  fragment SupportingDocumentFields on SupportingDocumentType {
    id
    title
    displayName
    url
    contentType
    description
    filename
  }
`;

export const VIDEO_FIELDS = gql`
  fragment VideoFields on VideoType {
    id
    url
    thumbnailUrl
    userStarted
    userEnded
  }
`;

export const COURSE_VIDEO_FIELDS = gql`
  fragment CourseVideoFields on CourseVideoType {
    id
    title
    video {
      ...VideoFields
    }
  }
  ${VIDEO_FIELDS}
`;

export const COURSE_FIELDS = gql`
  fragment CourseFields on CourseType {
    id
    title
    subtitle
    slug
    description
    shortDescription
    hasExclusiveAccess
    exclusiveAccessStartTime
    exclusiveAccessEndTime
    videos {
      ...CourseVideoFields
    }
    documents {
      ...SupportingDocumentFields
    }
    cpdPoints
    imageUrl
    backgroundColor
    testProducts {
      ...PractitionerTestProductConnectionFields
      laboratory {
        id
        name
        logoUrl
      }
    }
  }
  ${COURSE_VIDEO_FIELDS}
  ${PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS}
  ${SUPPORTING_DOCUMENT_FIELDS}
`;

export const WEBINAR_FIELDS = gql`
  fragment WebinarFields on WebinarType {
    id
    title
    slug
    shortDescription
    description
    startTime
    endTime
    registerNowUrl
    video {
      ...VideoFields
    }
    testProducts {
      ...PractitionerTestProductConnectionFields
      laboratory {
        id
        name
        logoUrl
      }
    }
    host {
      id
      firstName
      lastName
      fullName
      avatarUrl
      title
      occupation
    }
    documents {
      ...SupportingDocumentFields
    }
  }
  ${PRACTITIONER_TEST_PRODUCT_CONNECTION_FIELDS}
  ${SUPPORTING_DOCUMENT_FIELDS}
  ${VIDEO_FIELDS}
`;

export const EVENT_FIELDS = gql`
  fragment EventFields on EventType {
    id
    title
    startTime
    endTime
    description
    shortDescription
    location
    type
    cpdPoints
    slug
    registerNowUrl
  }
`;

export const EVENT_QUERY = gql`
  query EventQuery($id: ID, $slug: String) {
    event(id: $id, slug: $slug) {
      ...EventFields
    }
  }
  ${EVENT_FIELDS}
`;

export const COURSE_QUERY = gql`
  query CourseQuery($id: ID, $slug: String) {
    course(id: $id, slug: $slug) {
      ...CourseFields
    }
  }
  ${COURSE_FIELDS}
`;

export const WEBINAR_QUERY = gql`
  query WebinarQuery($id: ID, $slug: String) {
    webinar(id: $id, slug: $slug) {
      ...WebinarFields
    }
  }
  ${WEBINAR_FIELDS}
`;

export const COURSE_CONNECTIONS_QUERY = gql`
  query CourseConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
  ) {
    courseConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
    ) {
      edges {
        node {
          ...CourseFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${COURSE_FIELDS}
`;

export const HOMEPAGE_QUERY = gql`
  query HomepageQuery {
    learningHubHomepage {
      webinars {
        ...WebinarFields
      }
      courses {
        ...CourseFields
      }
      events {
        ...EventFields
      }
    }
  }
  ${COURSE_FIELDS}
  ${EVENT_FIELDS}
  ${WEBINAR_FIELDS}
`;

export const WEBINAR_CONNECTIONS_QUERY = gql`
  query WebinarConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $testingServices: String
    $areasOfInterest: String
    $sampleTypes: String
    $upcoming: Boolean
    $exclude: [ID!]
  ) {
    webinarConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      testingServices: $testingServices
      areasOfInterest: $areasOfInterest
      sampleTypes: $sampleTypes
      upcoming: $upcoming
      exclude: $exclude
    ) {
      edges {
        node {
          ...WebinarFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${WEBINAR_FIELDS}
`;

export const SUPPORT_DOCUMENT_CONNECTIONS_QUERY = gql`
  query SupportDocumentConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
  ) {
    documentConnections(first: $first, after: $after, orderBy: $orderBy, search: $search) {
      edges {
        node {
          ...SupportingDocumentFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SUPPORTING_DOCUMENT_FIELDS}
`;

export const EVENT_CONNECTIONS_QUERY = gql`
  query EventConnectionsQuery(
    $first: Int!
    $after: String
    $orderBy: String
    $search: String
    $types: [String!]
  ) {
    eventConnections(
      first: $first
      after: $after
      orderBy: $orderBy
      search: $search
      types: $types
    ) {
      edges {
        node {
          ...EventFields
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${EVENT_FIELDS}
`;
