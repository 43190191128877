import { CustomerAccountPage } from "components/CustomerPage";
import { InternalTextLink } from "components/Links";
import { LOGIN_URL, PARTNERS_SIGNIN_URL } from "core/urls";
import useDocTitle from "hooks/use-doc-title";

function ResetCompletePage({ partners }) {
  useDocTitle("Reset password complete");
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Password reset complete",
        bg: partners ? "partners" : "purple",
        color: partners ? "dark" : "white"
      }}
    >
      Your password has been successfully reset. Please click{" "}
      <InternalTextLink href={partners ? PARTNERS_SIGNIN_URL : LOGIN_URL}>here</InternalTextLink> to
      login.
    </CustomerAccountPage>
  );
}

export default ResetCompletePage;
