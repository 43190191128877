import { createContext, useContext, useMemo } from "react";

import { gql, useQuery } from "@apollo/client";
import { ReactComponent as AustraliaIcon } from "images/country/australia.svg";
import { ReactComponent as AustriaIcon } from "images/country/austria.svg";
import { ReactComponent as BelarusIcon } from "images/country/belarus.svg";
import { ReactComponent as BelgiumIcon } from "images/country/belgium.svg";
import { ReactComponent as BermudaIcon } from "images/country/bermuda.svg";
import { ReactComponent as BulgariaIcon } from "images/country/bulgaria.svg";
import { ReactComponent as CanadaIcon } from "images/country/canada.svg";
import { ReactComponent as CanaryIslandsIcon } from "images/country/canary-islands.svg";
import { ReactComponent as CyprusIcon } from "images/country/cyprus.svg";
import { ReactComponent as CzechRepublicIcon } from "images/country/czech-republic.svg";
import { ReactComponent as DenmarkIcon } from "images/country/denmark.svg";
import { ReactComponent as EstoniaIcon } from "images/country/estonia.svg";
import { ReactComponent as FinlandIcon } from "images/country/finland.svg";
import { ReactComponent as FranceIcon } from "images/country/france.svg";
import { ReactComponent as GermanyIcon } from "images/country/germany.svg";
import { ReactComponent as GibraltarIcon } from "images/country/gibraltar.svg";
import { ReactComponent as GreeceIcon } from "images/country/greece.svg";
import { ReactComponent as GuernseyIcon } from "images/country/guernsey.svg";
import { ReactComponent as HungaryIcon } from "images/country/hungary.svg";
import { ReactComponent as IcelandIcon } from "images/country/iceland.svg";
import { ReactComponent as IndiaIcon } from "images/country/india.svg";
import { ReactComponent as IrelandIcon } from "images/country/ireland.svg";
import { ReactComponent as IsleOfManIcon } from "images/country/isle-of-man.svg";
import { ReactComponent as ItalyIcon } from "images/country/italy.svg";
import { ReactComponent as JerseyIcon } from "images/country/jersey.svg";
import { ReactComponent as LatviaIcon } from "images/country/latvia.svg";
import { ReactComponent as LiechtensteinIcon } from "images/country/liechtenstein.svg";
import { ReactComponent as LithuaniaIcon } from "images/country/lithuania.svg";
import { ReactComponent as LuxembourgIcon } from "images/country/luxembourg.svg";
import { ReactComponent as MaltaIcon } from "images/country/malta.svg";
import { ReactComponent as NetherlandsIcon } from "images/country/netherlands.svg";
import { ReactComponent as NewZealandIcon } from "images/country/new-zealand.svg";
import { ReactComponent as NorwayIcon } from "images/country/norway.svg";
import { ReactComponent as PolandIcon } from "images/country/poland.svg";
import { ReactComponent as PortugalIcon } from "images/country/portugal.svg";
import { ReactComponent as RomaniaIcon } from "images/country/romania.svg";
import { ReactComponent as SingaporeIcon } from "images/country/singapore.svg";
import { ReactComponent as SlovakiaIcon } from "images/country/slovakia.svg";
import { ReactComponent as SloveniaIcon } from "images/country/slovenia.svg";
import { ReactComponent as SpainIcon } from "images/country/spain.svg";
import { ReactComponent as SwedenIcon } from "images/country/sweden.svg";
import { ReactComponent as SwitzerlandIcon } from "images/country/switzerland.svg";
import { ReactComponent as UnitedKingdomIcon } from "images/country/united-kingdom.svg";

import { usePersistentState } from "./LocalStorage";

export const ICONS = {
  AU: AustraliaIcon,
  AT: AustriaIcon,
  BY: BelarusIcon,
  BE: BelgiumIcon,
  BM: BermudaIcon,
  BG: BulgariaIcon,
  CA: CanadaIcon,
  IC: CanaryIslandsIcon,
  CY: CyprusIcon,
  CZ: CzechRepublicIcon,
  DK: DenmarkIcon,
  EE: EstoniaIcon,
  FI: FinlandIcon,
  FR: FranceIcon,
  DE: GermanyIcon,
  GI: GibraltarIcon,
  GR: GreeceIcon,
  GG: GuernseyIcon,
  HU: HungaryIcon,
  IS: IcelandIcon,
  IN: IndiaIcon,
  IE: IrelandIcon,
  IM: IsleOfManIcon,
  IT: ItalyIcon,
  JE: JerseyIcon,
  LV: LatviaIcon,
  LI: LiechtensteinIcon,
  LT: LithuaniaIcon,
  LU: LuxembourgIcon,
  MT: MaltaIcon,
  NL: NetherlandsIcon,
  NZ: NewZealandIcon,
  NO: NorwayIcon,
  PL: PolandIcon,
  PT: PortugalIcon,
  RO: RomaniaIcon,
  SG: SingaporeIcon,
  SK: SlovakiaIcon,
  SI: SloveniaIcon,
  ES: SpainIcon,
  SE: SwedenIcon,
  CH: SwitzerlandIcon,
  GB: UnitedKingdomIcon
};

const LOCALE_FIELDS = gql`
  fragment LocaleFields on LocaleType {
    id
    name
    isoCode
    flagIconUrl
  }
`;

const GET_LOCALES = gql`
  query GetLocales {
    locales {
      ...LocaleFields
    }
  }
  ${LOCALE_FIELDS}
`;

const LocaleContext = createContext();

function useLocales() {
  const [locale, setLocale] = usePersistentState("selectedLocale", [undefined, undefined]);
  const { data = { locales: [] }, loading } = useQuery(GET_LOCALES);

  const api = useMemo(
    () => ({
      locales: data.locales,
      locale,
      setLocale,
      loading,
      icons: ICONS
    }),
    [data.locales, locale, setLocale, loading]
  );

  return api;
}

export function useLocalesContext() {
  return useContext(LocaleContext);
}

export function LocalesProvider({ children }) {
  const api = useLocales();

  return <LocaleContext.Provider value={api}>{children}</LocaleContext.Provider>;
}
