import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";

import BasketErrorModal from "./BasketErrorModal";

export default function ProductNotSoldInLocaleErrorModal() {
  const {
    hasProductNotSoldInLocaleErrorModal,
    setAcknowledgedBasketInvalidShippingRestrictionModal,
    acknowledgedBasketInvalidShippingRestrictionModal
  } = useOrganisationBasketContext();

  return (
    <BasketErrorModal
      explanation={`An item in your basket is not available in your shipping destination. Please remove the item from your basket in order to checkout.`}
      show={
        hasProductNotSoldInLocaleErrorModal && !acknowledgedBasketInvalidShippingRestrictionModal
      }
      onClose={() => {
        setAcknowledgedBasketInvalidShippingRestrictionModal(true);
      }}
    />
  );
}
