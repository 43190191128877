import Box from "components/Box";
import Asterisk from "tpo/Asterisk";
import Group from "tpo/Group";

export default function RequiredFieldsNotice() {
  return (
    <Group fontFamily="gilroyMedium" fontSize={16} justifyContent="flex-end">
      <Box as="span" fontFamily="inherit" fontSize="inherit">
        (
      </Box>
      <Asterisk />
      <Box as="span" fontFamily="inherit" fontSize="inherit">
        Indicates required fields
      </Box>
      <Box as="span" fontFamily="inherit" fontSize="inherit">
        )
      </Box>
    </Group>
  );
}
