import React from "react";

import { useLocation } from "react-router";

import useDocTitle from "../hooks/use-doc-title";
import { CustomerAccountPage } from "./CustomerPage";
import Text, { SpanText } from "./Text";

function SignupDonePage() {
  useDocTitle("Activation email sent");
  const { state } = useLocation();

  let message;
  if (state?.createdUserEmail) {
    message = (
      <>
        We've sent an activation email to{" "}
        <SpanText color="purple">{state.createdUserEmail}</SpanText>, please check your inbox and
        click on the confirmation link to complete your sign-up
      </>
    );
  } else {
    message =
      "We've sent you an activation email, please check your inbox and click on the confirmation link to complete your sign-up";
  }
  return (
    <CustomerAccountPage
      jumbotronProps={{
        title: "Success"
      }}
    >
      <Text>{message}</Text>
    </CustomerAccountPage>
  );
}

export default SignupDonePage;
