import { useRef, useState } from "react";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { DELETE_INVITE_MUTATION, RESEND_INVITE_MUTATION } from "graphql/organisations/mutations";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Modal from "tpo/Modal";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2, { IndicativeButton } from "v2/Buttons";

export function PractitionerInviteControls({ status, invite, onSuccess = () => {} }) {
  const resendButtonRef = useRef();
  const deleteButtonRef = useRef();
  const [showDeleteInviteConfirmation, setShowDeleteInviteConfirmation] = useState(false);

  const [resendInviteMutation] = useMutation(RESEND_INVITE_MUTATION, {
    onCompleted: () => {
      resendButtonRef.current.setSuccessful(true);
      resendButtonRef.current.setPending(false);
      onSuccess();
    },
    onError: () => {
      resendButtonRef.current.setSuccessful(false);
      resendButtonRef.current.setPending(false);
    }
  });
  const [deleteInviteMutation] = useMutation(DELETE_INVITE_MUTATION, {
    onCompleted: onSuccess
  });

  const { organisation } = useOrganisationContext();

  return (
    <Stack ml={[0, "auto"]} gap={10} justifyContent="flex-end">
      {status === "Invited" && (
        <IndicativeButton
          ref={resendButtonRef}
          defaultColor="dark"
          pendingColor="darkgrey"
          successColor="#2ecc71"
          failureColor="error"
          rightIcon={<ChevronComponent />}
          sx={{
            color: "white"
          }}
          type="button"
          onClick={() => {
            resendInviteMutation({
              variables: {
                organisationId: parseInt(organisation?.id),
                inviteId: invite?.id
              }
            });
          }}
          size="sm"
        >
          resend
        </IndicativeButton>
      )}
      <ButtonV2
        ref={deleteButtonRef}
        color="red"
        disabled={!invite}
        onClick={() => {
          setShowDeleteInviteConfirmation(invite?.id);
        }}
        type="button"
        size="sm"
      >
        delete
      </ButtonV2>
      <Modal
        maxWidth={600}
        headerProps={{
          p: [20, 20, 40]
        }}
        bg="white"
        show={showDeleteInviteConfirmation}
        close={() => setShowDeleteInviteConfirmation(null)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <Box maxWidth={520} mx="auto">
          <Box pt={6} pb={6} px={20}>
            <Box fontFamily="gilroyBold" fontSize={28}>
              Are you sure?
            </Box>
            <Spacer py={15} />
            <Box fontFamily="gilroyMedium" fontSize={16}>
              You are about to remove a practitioner from your organisation.
            </Box>
            <Spacer py={15} />
            <Group gap={20} flexDirection="row" flexWrap="wrap">
              <IndicativeButton
                ref={deleteButtonRef}
                defaultColor="red"
                pendingColor="darkgrey"
                successColor="#2ecc71"
                failureColor="error"
                onClick={() => {
                  deleteInviteMutation({
                    variables: {
                      organisationId: parseInt(organisation?.id),
                      inviteId: invite?.id
                    }
                  });
                }}
              >
                delete
              </IndicativeButton>
              <ButtonV2
                color="dark"
                onClick={() => {
                  setShowDeleteInviteConfirmation(null);
                }}
              >
                cancel
              </ButtonV2>
            </Group>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
}
