export default function GridIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1_3192)">
        <path
          d="M11.0825 0.5C10.7923 0.598945 10.5114 0.721799 10.2425 0.867234C9.67802 1.23246 9.45023 1.80025 9.44493 2.45301C9.43497 3.67025 9.43497 4.88673 9.44493 6.10274C9.44493 6.90161 9.80353 7.51852 10.5347 7.86054V7.86121C10.8116 7.98074 11.1104 8.04316 11.4119 8.04316C12.6138 8.06374 13.8171 8.05644 15.0191 8.04316C16.0239 8.04316 16.7191 7.48069 16.9588 6.50319L16.9595 6.50253C16.9714 6.47198 16.9847 6.4421 17 6.41288V2.14758C16.9814 2.1084 16.9661 2.06922 16.9555 2.03004C16.8413 1.56586 16.6374 1.1475 16.2211 0.891179H16.2217C15.9395 0.740436 15.6473 0.609625 15.3478 0.500043L11.0825 0.5Z"
          fill="inherit"
        />
        <path
          d="M17 11.5653C16.8878 11.2658 16.751 10.9763 16.5903 10.7C16.2808 10.2319 15.7529 9.95362 15.1911 9.96424C13.8955 9.95096 12.602 9.95561 11.3062 9.96424H11.3069C10.8022 9.96358 10.3194 10.1674 9.96749 10.528C9.61488 10.8893 9.42363 11.3774 9.4369 11.8814C9.42827 13.1119 9.42163 14.3443 9.4369 15.5755C9.45218 16.6785 10.1999 17.4608 11.2969 17.4859C12.5699 17.5152 13.8469 17.5132 15.1185 17.4859C16.0701 17.4627 16.7109 16.9102 16.954 15.9905C16.9639 15.9493 16.9812 15.9121 16.9998 15.8749V11.5651L17 11.5653Z"
          fill="inherit"
        />
        <path
          d="M1.66276 0.500022C1.31811 0.570413 0.992718 0.715174 0.709158 0.923033C0.298761 1.23315 0.0490818 1.71061 0.0291576 2.22392C0.00525236 3.27116 0.0138836 4.31968 0.0105624 5.36892V6.17112H0.00989866C0.0105624 6.68046 0.217758 7.16788 0.583649 7.52182C0.948877 7.87576 1.44295 8.06701 1.95162 8.05175C3.16886 8.05175 4.38602 8.06171 5.60322 8.05175C6.71154 8.0378 7.50382 7.30002 7.53102 6.20691C7.56356 4.91995 7.56024 3.63175 7.53102 2.34468C7.51243 1.4057 6.94066 0.76155 6.02294 0.537065C5.99106 0.527104 5.95985 0.514488 5.9293 0.499878L1.66276 0.500022Z"
          fill="inherit"
        />
        <path
          d="M0.017252 13.7196C0.017252 14.3996 -0.00665379 15.0796 0.017252 15.7463V15.7456C0.0497913 16.6846 0.803503 17.4377 1.74241 17.4696C3.10241 17.5015 4.46241 17.5015 5.82241 17.4696C6.75077 17.4337 7.49385 16.686 7.52445 15.7577C7.555 14.3977 7.555 13.0377 7.52445 11.6777C7.4959 10.7487 6.75347 9.99961 5.82445 9.96444C4.46445 9.93389 3.09986 9.93389 1.73119 9.96444H1.73053C0.810131 10.0069 0.0736909 10.7434 0.0305276 11.6644C-1.95503e-05 12.3444 0.0305276 13.0244 0.0305276 13.7044L0.017252 13.7196Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3192">
          <rect width="17" height="17" fill="white" transform="matrix(-1 0 0 1 17 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
