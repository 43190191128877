import { useEffect, useRef, useState } from "react";

const DEFAULT_OPTIONS = {
  rootMargin: "0px",
  once: false
};

export const useOnScreen = (ref, options) => {
  const [isVisible, setIsVisible] = useState(false);
  const { rootMargin, once } = { ...DEFAULT_OPTIONS, ...options };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (once && entry.isIntersecting) {
          observer.unobserve(entry.target);
        }
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );

    const currentElement = ref?.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      try {
        if (currentElement) {
          observer.unobserve(currentElement);
        }
      } catch (e) {
        console.warn(e);
      }
    };
  }, []);

  return isVisible;
};

export const useOnScreenBoundingRect = (ref, options) => {
  const [isVisible, setIsVisible] = useState(false);
  options = { ...DEFAULT_OPTIONS, ...options };

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;

      const { top, bottom } = ref.current.getBoundingClientRect();
      const visible = top < window.innerHeight && bottom >= 0;
      if (visible || !options.once) {
        setIsVisible(visible);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return isVisible;
};

export const useOnScreenBoundingRectAnchor = options => {
  const ref = useRef();
  const isVisible = useOnScreenBoundingRect(ref, options);

  const Anchor = () => (
    <div
      ref={ref}
      style={{
        width: 0,
        height: 0,
        overflow: "hidden",
        opacity: 0
      }}
    />
  );

  return [isVisible, Anchor];
};
