import { useHistory } from "react-router-dom";

import { getPartnerTestResultsUrl, getTestResultsUrl } from "../core/urls";
import { RESULTS_AVAILABLE, CONSULT_NEEDED } from "../core/utils";
import { TestStatusesLoader } from "./TestStatuses";

export default function UserTestsLoader({ isPartnerView, clientId }) {
  const history = useHistory();
  return (
    <TestStatusesLoader
      isPartnerView={isPartnerView}
      clientId={clientId}
      handleSelect={test => {
        /**
         * This dropdown is a list which merges two groups -
         *  - user tests
         *  - orders in progress
         *
         * Only the first group should have related results.
         *
         * Items in the list will only have a slug property
         * if the item belongs to the user tests group.
         *
         * All items regardless have a status field showing
         * in the dropdown.
         */
        if (test.slug && (test.status === RESULTS_AVAILABLE || test.status === CONSULT_NEEDED)) {
          history.push(
            isPartnerView ? getPartnerTestResultsUrl(clientId, test.id) : getTestResultsUrl(test.id)
          );
        }
      }}
      heading="Your tests"
      defaultOpen={false}
      selectProps={{
        maxWidth: 500,
        maxHeight: 500,
        px: 20,
        py: 25
      }}
    />
  );
}
