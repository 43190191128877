import { useOrganisationContext } from "contexts/organisations/OrganisationContext";

import { CMP_PERMISSION_ADMIN } from "../constants";
import ConnectedUsers from "./ui/ConnectedUsers";
import InviteYourPatients from "./ui/InviteYourPatients";
import PatientsTemplate from "./ui/Template";

export default function ManagePatients() {
  const { permissions } = useOrganisationContext();

  return (
    <PatientsTemplate active="manage_patients">
      {!!(permissions && permissions.includes(CMP_PERMISSION_ADMIN)) && <InviteYourPatients />}
      <ConnectedUsers />
    </PatientsTemplate>
  );
}
