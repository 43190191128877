import { cloneElement, useCallback, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import { useAuthContext } from "components/AuthProvider";
import Box from "components/Box";
import CustomerPage from "components/CustomerPage";
import CustomerTabs from "components/CustomerTabs";
import DataLoader from "components/DataLoader";
import Grid from "components/Grid";
import Loading from "components/Loading";
import ProductFilters from "components/ProductFilters";
import {
  TestProductModalProvider,
  useTestProductModalContext
} from "contexts/TestProductModalContext";
import { TestProductOptionProvider } from "contexts/TestProductOptionContext";
import { theme } from "core/theme";
import { SHOP_SUPPLEMENTS_URL, SHOP_TESTS_URL, getQuestionnaireIntroUrl } from "core/urls";
import {
  CONSUMER_TEST_PRODUCT_CONNECTIONS_QUERY,
  CONSUMER_TEST_PRODUCT_DETAIL_QUERY,
  CONSUMER_TEST_PRODUCT_OPTION_FIELDS,
  SHOP_LISTING_FILTERS_QUERY
} from "graphql/shop";
import useWindowSize from "hooks/use-window-size";
import { useDebounce } from "hooks/useDebounce";
import useListControls from "hooks/useListControls";
import { ReactComponent as Pencil } from "images/pencil.svg";
import PractitionerOnlyImage from "images/shot1-practitioner.webp";
import Badge from "tpo/Badge";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import GridIcon from "tpo/GridIcon";
import Group from "tpo/Group";
import InfiniteScroll, { InfiniteScrollContext } from "tpo/InfiniteScroll";
import Jumbotron from "tpo/Jumbotron";
import LinkWrapper from "tpo/LinkWrapper";
import ListIcon from "tpo/ListIcon";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { Search } from "tpo/TextInput";
import ToggleSwitch from "tpo/ToggleSwitch";
import useTestProducts from "tpo/shop/hooks/useTestProducts";
import ButtonV2, { ActionIcon } from "v2/Buttons";

import GridListingViewCard from "./GridListingViewCard";
import ListPageTemplate from "./ListPageTemplate";
import OmnosHeader from "./OmnosHeader";
import StackedListingViewCard from "./StackedListingViewCard";
import TestProductModal from "./TestProductModal";
import TestRecommendationBadge from "./TestRecommendationBadge";
import useProductFilters from "./hooks/useProductFilters";

function SelfAssessmentCard({ stacked }) {
  return (
    <Box display="flex" flexDirection={stacked ? "column" : "row"} borderRadius={5}>
      <Box
        bg="white"
        borderTopLeftRadius={5}
        borderTopRightRadius={5}
        height={stacked ? "300px" : "100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={stacked ? "100%" : "40%"}
        minWidth={stacked ? "100%" : "40%"}
        minHeight={!stacked ? "300px" : undefined}
      >
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box
            borderRadius="33%"
            borderColor="dark"
            borderStyle="solid"
            size={[100]}
            borderWidth={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box width={[23, 36]}>
              <Pencil fill={theme.colors.dark} width="100%" />
            </Box>
          </Box>
          <Box fontSize={18} fontFamily="gilroyBold" mt={30}>
            Free self assessment
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="blue"
        p={[4, 4, 5, 6]}
        flexGrow={1}
      >
        <Box color="white" fontSize={stacked ? 18 : 28} fontFamily="gilroyBold">
          Which test is right for you?
        </Box>
        <Box color="white" fontSize={[14, 14, 16]} my={23} pb={50} fontFamily="gilroyMedium">
          For a comprehensive assessment, take our FREE, in-depth questionnaire, which ranks all
          tests to reveal your ultimate match. Start exploring now and unlock a healthier you!
        </Box>
        <ButtonV2
          color="green"
          as={LinkWrapper}
          to={getQuestionnaireIntroUrl("symptoms")}
          mt="auto"
          mr={stacked ? null : "auto"}
          size={["sm", "sm", "md"]}
        >
          <Box component="span" color="inherit" display={["inline-block", "inline-block", "none"]}>
            symptom questionnaire
          </Box>
          <Box component="span" color="inherit" display={["none", "none", "inline-block"]}>
            free symptom questionnaire
          </Box>
        </ButtonV2>
      </Box>
    </Box>
  );
}

function ListView({ products, setBottomElement }) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;

  const { selectTestProductId } = useTestProductModalContext();

  // IMPORTANT - the order of the products from the server must have the products
  // ordered such that the following filtering still preserves the ordering
  // i.e. consumer products first atm
  let consumerProducts = products.filter(p => p.productAudience === "B2C");
  let businessProducts = products.filter(p => p.productAudience === "B2B");

  const allProducts = [
    ...consumerProducts.map(tp => [
      tp,
      <StackedListingViewCard
        key={tp.id}
        imageBackgroundColor={theme.colors.purple}
        name={tp.name}
        priceElement={
          <>
            {tp.rrpFullPrice && tp.rrpFullPrice !== tp.rrpCurrentPrice && (
              <Currency
                fontSize={44}
                decimalFontSize={28}
                value={tp.rrpFullPrice}
                color="#e44c4b"
                strikethrough
              />
            )}
            <Currency value={tp.rrpCurrentPrice} fontSize={44} decimalFontSize={28} />
          </>
        }
        description={tp.content.shortDescription}
        numOfBiomarkersTested={tp.content?.numOfBiomarkersTested}
        slug={tp.slug}
        image={tp.content.image1}
        badge={
          tp.userTestRecommendationRank !== null && tp.userTestRecommendationRank < 6 ? (
            <TestRecommendationBadge bg="white" color="dark" rank={tp.userTestRecommendationRank} />
          ) : null
        }
        matchedSymptoms={tp.matchedSymptoms.slice(0, 5)}
        stacked={isMobile}
        categories={tp.content.categories.map(cat => cat.name)}
        testType={tp.content.testType}
        productAudience={tp.productAudience}
        onClick={() => selectTestProductId(tp.id)}
      />
    ]),
    ...businessProducts.map(tp => [
      tp,
      <StackedListingViewCard
        key={tp.id}
        imageBackgroundColor={theme.colors.partners}
        name={tp.name}
        priceElement={
          <>
            {tp.rrpFullPrice && tp.rrpFullPrice !== tp.rrpCurrentPrice && (
              <Currency
                fontSize={44}
                decimalFontSize={28}
                value={tp.rrpFullPrice}
                color="#e44c4b"
                strikethrough
              />
            )}
            <Currency value={tp.rrpCurrentPrice} fontSize={44} decimalFontSize={28} />
          </>
        }
        description={tp.content?.shortDescription}
        numOfBiomarkersTested={tp.content?.numOfBiomarkersTested}
        slug={tp.slug}
        image={PractitionerOnlyImage}
        label={
          <Badge bg="partners" color="dark" mr="auto">
            practitioner support required
          </Badge>
        }
        badge={
          tp.userTestRecommendationRank !== null && tp.userTestRecommendationRank < 6 ? (
            <TestRecommendationBadge bg="white" color="dark" rank={tp.userTestRecommendationRank} />
          ) : null
        }
        matchedSymptoms={tp.matchedSymptoms.slice(0, 5)}
        stacked={isMobile}
        categories={tp.content.categories.map(cat => cat.name)}
        sampleTypes={tp.sampleTypes}
        productAudience={tp.productAudience}
        onClick={() => selectTestProductId(tp.id)}
      />
    ])
  ].map(([tp, elem], idx, arr) =>
    arr.length - 1 === idx ? [tp, cloneElement(elem, { ref: setBottomElement })] : [tp, elem]
  );

  consumerProducts = allProducts
    .filter(([tp]) => tp.productAudience === "B2C")
    .map(([, elem]) => elem);
  businessProducts = allProducts
    .filter(([tp]) => tp.productAudience === "B2B")
    .map(([, elem]) => elem);

  return (
    <Grid>
      <SelfAssessmentCard stacked={isMobile} />
      {consumerProducts.map(elem => elem)}
      {!!businessProducts.length && (
        <Box gridColumnStart="1" gridColumnEnd="2">
          <Spacer py={[2, 2, 20]} />
          <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
            Practitioner Supported Tests
          </Box>
        </Box>
      )}
      {businessProducts.map(elem => elem)}
    </Grid>
  );
}

function GridView({ products, setBottomElement }) {
  // IMPORTANT - the order of the products from the server must have the products
  // ordered such that the following filtering still preserves the ordering
  // i.e. consumer products first atm

  const { selectTestProductId } = useTestProductModalContext();

  let consumerProducts = products.filter(p => p.productAudience === "B2C");
  let businessProducts = products.filter(p => p.productAudience === "B2B");

  const allProducts = [
    ...consumerProducts.map(tp => [
      tp,
      <GridListingViewCard
        key={tp.id}
        name={tp.name}
        rrpCurrentPrice={tp.rrpCurrentPrice}
        rrpFullPrice={tp.rrpFullPrice}
        overview={tp.content.shortDescription}
        numOfBiomarkersTested={tp.content?.numOfBiomarkersTested}
        productFamily={tp.productFamily}
        slug={tp.slug}
        image={tp.content.image1}
        imageBackgroundColor={theme.colors.purple}
        badge={
          tp.userTestRecommendationRank !== null && tp.userTestRecommendationRank < 6 ? (
            <TestRecommendationBadge bg="white" color="dark" rank={tp.userTestRecommendationRank} />
          ) : null
        }
        matchedSymptoms={tp.matchedSymptoms.slice(0, 5)}
        stacked
        categories={tp.content.categories.map(cat => cat.name)}
        sampleTypes={tp.sampleTypes}
        productAudience={tp.productAudience}
        onClick={() => selectTestProductId(tp.id)}
      />
    ]),
    ...businessProducts.map(tp => [
      tp,
      <GridListingViewCard
        key={tp.id}
        imageBackgroundColor={theme.colors.partners}
        name={tp.name}
        rrpCurrentPrice={tp.rrpCurrentPrice}
        rrpFullPrice={tp.rrpFullPrice}
        overview={tp.content?.shortDescription}
        numOfBiomarkersTested={tp.content?.numOfBiomarkersTested}
        productFamily={tp.productFamily}
        slug={tp.slug}
        image={PractitionerOnlyImage}
        label={
          <>
            <Badge bg="partners" color="dark" mr="auto">
              practitioner support required
            </Badge>
            <Spacer py={1} />
          </>
        }
        badge={
          tp.userTestRecommendationRank !== null && tp.userTestRecommendationRank < 6 ? (
            <TestRecommendationBadge bg="white" color="dark" rank={tp.userTestRecommendationRank} />
          ) : null
        }
        matchedSymptoms={tp.matchedSymptoms.slice(0, 5)}
        stacked
        categories={tp.content.categories.map(cat => cat.name)}
        sampleTypes={tp.sampleTypes}
        productAudience={tp.productAudience}
        onClick={() => selectTestProductId(tp.id)}
      />
    ])
  ].map(([tp, elem], idx, arr) =>
    arr.length - 1 === idx ? [tp, cloneElement(elem, { ref: setBottomElement })] : [tp, elem]
  );

  consumerProducts = allProducts
    .filter(([tp]) => tp.productAudience === "B2C")
    .map(([, elem]) => elem);
  businessProducts = allProducts
    .filter(([tp]) => tp.productAudience === "B2B")
    .map(([, elem]) => elem);

  return (
    <Grid
      gridTemplateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
      gap={10}
    >
      <SelfAssessmentCard stacked />
      {consumerProducts.map(elem => elem)}
      {!!businessProducts.length && (
        <Box gridColumnStart={"1"} gridColumnEnd={["2", "3", "3", "4"]}>
          <Spacer py={[2, 2, 20]} />
          <Box fontFamily="gilroyBold" fontSize={[18, 18, 28]}>
            Practitioner Supported Tests
          </Box>
        </Box>
      )}
      {businessProducts.map(elem => elem)}
    </Grid>
  );
}

function TestProductListContent({ sampleTypes, testingServices, categories }) {
  const [view, setView] = useState("grid");

  const [includePractitionerSupportedTests, setIncludePractitionerSupportedTests] = useState(false);
  const toggleIncludePractitionerSupportedTests = useCallback(() => {
    setIncludePractitionerSupportedTests(!includePractitionerSupportedTests);
  }, [includePractitionerSupportedTests, setIncludePractitionerSupportedTests]);

  const history = useHistory();
  const location = useLocation();

  const search = new URLSearchParams(location.search).get("search") || "";

  const debouncedSearch = useDebounce(search, 200);

  const controls = useListControls({
    sortOptions: [
      {
        label: "Recommended",
        value: "-product_audience,sort_order"
      },
      {
        label: "A - Z",
        value: "-product_audience,name"
      },
      {
        label: "Z - A",
        value: "-product_audience,-name"
      },
      {
        label: "Price Asc",
        value: "-product_audience,rrp_current_price"
      },
      {
        label: "Price Desc",
        value: "-product_audience,-rrp_current_price"
      }
    ]
  });

  const { allFilters, validatedFilters, toggleFilter, selectedFilters } = useProductFilters({
    defaultFilters: {
      gender: "all",
      categories: [],
      sampleTypes: [],
      testingServices: []
    },
    allFilters: {
      gender: ["all", "female", "male"],
      testingServices: [...testingServices].sort(),
      categories: [...categories].sort(),
      sampleTypes: [...sampleTypes].sort()
    },
    filterSelected: {
      gender: ["male", "female"]
    }
  });

  const {
    products: { items: products, hasMore, endCursor },
    loading,
    fetchMore
  } = useTestProducts({
    query: CONSUMER_TEST_PRODUCT_CONNECTIONS_QUERY,
    connectionsFieldName: "consumerTestProductConnections",
    search: debouncedSearch,
    sort: controls.sort,
    validatedFilters,
    queryVariables: {
      productAudience: includePractitionerSupportedTests ? undefined : "B2C"
    }
  });

  return (
    <>
      <TestProductOptionProvider
        testProductOptionFragment={CONSUMER_TEST_PRODUCT_OPTION_FIELDS}
        testProductOptionFragmentName="ConsumerTestProductOptionFields"
        testProductOptionFragmentType="TestProductOptionType"
      >
        <TestProductModalProvider
          testProductQuery={CONSUMER_TEST_PRODUCT_DETAIL_QUERY}
          testProductFieldName="consumerTestProduct"
        >
          <ListPageTemplate
            bg="white"
            urlMap={{
              supplements: SHOP_SUPPLEMENTS_URL,
              tests: SHOP_TESTS_URL
            }}
            tab="tests"
            title="Discover the right test for you"
            subtitle={`Easily navigate through our selection by using our convenient filter options. Tailor your
          search based on gender, sample collection type, or specific areas of interest.

          Not sure where to start? Each individual test has its own symptom questionnaire for an extra level
          of reassurance. Or take our self assessment to reveal what tests from our whole range best
          match your symptoms. Start exploring now and unlock a healthier you!`}
          >
            <PanelBoxV2
              maxWidth={1538}
              outer={{
                pt: theme.spacing.section.pt,
                pb: theme.spacing.section.pb,
                px: [20, 20, "5.5vw"],
                bg: "haze"
              }}
            >
              <Stack gap={[20, 20, 20]}>
                <Box
                  display="flex"
                  flexDirection={["column", "column", "row"]}
                  gap={20}
                  justifyContent={[null, null, "space-between"]}
                  flexWrap="wrap"
                >
                  <Group gap={10}>
                    <ProductFilters
                      allFilters={allFilters}
                      toggleFilter={toggleFilter}
                      drawerBg="blue"
                      validatedFilters={validatedFilters}
                      filtersTitle="Product Filters"
                    />
                    <ActionIcon
                      bg={view === "grid" ? "dark" : "white"}
                      size="md"
                      variant="circle"
                      onClick={() => setView("grid")}
                      data-component-name="GridView"
                    >
                      <GridIcon fill={view === "grid" ? "white" : "dark"} />
                    </ActionIcon>
                    <ActionIcon
                      bg={view === "list" ? "dark" : "white"}
                      size="md"
                      variant="circle"
                      onClick={() => setView("list")}
                      data-component-name="StackedView"
                    >
                      <ListIcon fill={view === "list" ? "white" : "dark"} />
                    </ActionIcon>
                  </Group>
                  <Box display="flex" flexDirection={["column", "column", "row"]} gap={10}>
                    <Search
                      value={search}
                      onChange={s => {
                        const searchParams = new URLSearchParams(location.search);
                        searchParams.set("search", s);
                        history.push({
                          path: location.path,
                          search: searchParams.toString()
                        });
                      }}
                      maxWidth={[null, null, 350]}
                      minWidth={310}
                      width="100%"
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <SortMenu
                        value={controls.sort}
                        setValue={controls.setSort}
                        open={controls.sortMenuOpen}
                        setOpen={controls.setSortMenuOpen}
                        options={controls.sortOptions}
                        placeholder="Sort By"
                      />
                    </Box>
                  </Box>
                </Box>
                {selectedFilters}
                <Group alignItems="center" gap={10} ml="auto">
                  <Box fontFamily="gilroyBold" fontSize={10} textTransform="uppercase">
                    include practitioner supported tests
                  </Box>
                  <ToggleSwitch
                    onChange={toggleIncludePractitionerSupportedTests}
                    value={includePractitionerSupportedTests}
                    data-testid="include-practitioner-supported-tests-toggle"
                    id="include-practitioner-supported-tests-toggle"
                  />
                </Group>
              </Stack>
              <Spacer py={[2, 2, 20]} />
              <InfiniteScroll
                loader={<Loading />}
                hasMore={hasMore}
                loading={loading}
                next={() => {
                  if (endCursor) {
                    fetchMore({
                      variables: {
                        after: endCursor,
                        first: 10
                      }
                    });
                  }
                }}
                items={products}
              >
                <InfiniteScrollContext.Consumer>
                  {({ itemsList, setBottomElement }) => (
                    <>
                      {view === "grid" && itemsList && (
                        <GridView products={itemsList} setBottomElement={setBottomElement} />
                      )}
                      {view === "list" && itemsList && (
                        <ListView products={itemsList} setBottomElement={setBottomElement} />
                      )}
                    </>
                  )}
                </InfiniteScrollContext.Consumer>
              </InfiniteScroll>
              <TestProductModal bg="white" />
            </PanelBoxV2>
          </ListPageTemplate>
        </TestProductModalProvider>
      </TestProductOptionProvider>
    </>
  );
}

export default function TestProductListPage() {
  const { user } = useAuthContext();

  return (
    <CustomerPage
      jumbotronProps={{
        title: "Shop"
      }}
    >
      {user && <CustomerTabs selectedTab="/shop" />}
      <DataLoader
        query={SHOP_LISTING_FILTERS_QUERY}
        variables={{
          includeFilters: ["Both", "B2C"]
        }}
        render={({ sampleTypes, categories, testingServices, userTestRecommendations }) => {
          return (
            <TestProductListContent
              sampleTypes={sampleTypes}
              categories={categories.map(cat => cat.name)}
              testingServices={testingServices}
              userTestRecommendations={userTestRecommendations}
            />
          );
        }}
      />
    </CustomerPage>
  );
}
