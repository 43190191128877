import useQueryParams from "hooks/useQueryParams";
import { ReactComponent as CrossIcon } from "images/cross.svg";
import Group from "tpo/Group";
import ButtonV2 from "v2/Buttons";

export default function useProductFilters({ defaultFilters, allFilters, filterSelected }) {
  const { removeFilter, validatedFilters, resetFilters, toggleFilter } = useQueryParams({
    DEFAULT_FILTERS: defaultFilters,
    ALL_FILTERS: allFilters
  });

  const hasSelectedFilters = Object.entries(validatedFilters).some(([groupName, values]) => {
    const filteredValues =
      filterSelected && groupName in filterSelected
        ? values.filter(val => filterSelected[groupName].includes(val))
        : values;
    return filteredValues.length;
  });

  const selectedFilters = hasSelectedFilters ? (
    <Group alignItems="center" flexWrap="wrap" gap={10}>
      <Group flexWrap="wrap" gap={10}>
        {Object.entries(validatedFilters).map(([groupName, values]) => {
          return values
            .filter(val => {
              if (filterSelected && groupName in filterSelected) {
                return filterSelected[groupName].includes(val);
              }
              return true;
            })
            .map(val => (
              <ButtonV2
                leftIcon={<CrossIcon fill="white" width={10} />}
                color="green"
                key={`${groupName}:${val}`}
                onClick={() => removeFilter(groupName, val)}
                gap={10}
                variant="pill"
                size={["xs", "xs", "sm"]}
                type="button"
              >
                {val}
              </ButtonV2>
            ));
        })}
      </Group>
      {hasSelectedFilters && (
        <ButtonV2
          onClick={() => resetFilters()}
          variant="transparent"
          type="button"
          sx={{
            letterSpacing: null,
            fontSize: 16,
            textTransform: "unset",
            fontFamily: "gilroyRegular",
            textDecoration: "underline",
            p: 0
          }}
        >
          Clear all filters
        </ButtonV2>
      )}
    </Group>
  ) : null;

  return {
    toggleFilter,
    selectedFilters,
    validatedFilters,
    allFilters
  };
}
