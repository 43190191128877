import { useContext } from "react";

import DataLoader from "components/DataLoader";
import { SUPPLEMENT_DETAIL_QUERY } from "graphql/shop";
import { ViewerContext } from "tpo/Viewer";
import { SupplementDetailContent } from "tpo/shop/SupplementDetailPage";

export default function SupplementModalContent({ slug, onViewInShop }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;
  return (
    <DataLoader
      query={SUPPLEMENT_DETAIL_QUERY}
      variables={{
        slug,
        userId: clientId
      }}
      render={({ supplement }) =>
        supplement ? (
          <SupplementDetailContent
            description={supplement.description}
            name={supplement.name}
            productImage={supplement.productImage}
            dietryRestrictions={supplement.dietryRestrictions}
            price={supplement.price}
            directions={supplement.directions}
            relatedUserResults={supplement.relatedUserResults}
            dimensions={{
              columnWidth: 760
            }}
            status={supplement.status}
            onViewInShop={onViewInShop}
          />
        ) : null
      }
    />
  );
}
