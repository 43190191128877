import { forwardRef } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import { getCourseUrl } from "core/urls";
import CpdIcon from "images/CpdIcon";
import PaperStackIcon from "images/PaperStackIcon";
import Center from "tpo/Center";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const CourseCard = forwardRef(
  (
    { id, bg, slug, imageUrl, cpdPoints, title, shortDescription, subtitle, backgroundColor },
    ref
  ) => {
    const history = useHistory();

    return (
      <Stack
        bg={bg}
        ref={ref}
        gap={0}
        borderRadius={5}
        style={{
          cursor: "pointer",
          overflow: "hidden"
        }}
        onClick={() => {
          history.push(getCourseUrl(slug));
        }}
      >
        {imageUrl ? (
          <Box
            minHeight={300}
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
          />
        ) : (
          <Center
            stacked
            minHeight={300}
            style={{
              backgroundColor: backgroundColor
            }}
          >
            <PaperStackIcon size={80} fill={backgroundColor === "#1A1A1A" ? "white" : "black"} />
          </Center>
        )}

        <Stack bg={bg} gap={40} padding={[20, 20, 28]} flexGrow={1}>
          <Stack gap={8}>
            <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
              {title}
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              {subtitle}
            </Box>
          </Stack>
          <Group justifyContent="space-between" mt="auto">
            <Group gap={10}>
              <CpdIcon />
              <Box fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
                {`${cpdPoints}pts`}
              </Box>
            </Group>
            <ButtonV2
              color="dark"
              size="sm"
              sx={{
                py: 2
              }}
            >
              learn more
            </ButtonV2>
          </Group>
        </Stack>
      </Stack>
    );
  }
);

CourseCard.defaultProps = {
  bg: "white"
};

export default CourseCard;
