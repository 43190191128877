export default function getResponsiveProps(customProps, propValue) {
  /**
   * Hitherto I was using useBreakPointBasedProp to get the value of the prop
   * for the given breakpoint but this uses the useWindowSize hook which i think might
   * be slowing things down.
   *
   * This approach avoids adding the resize event to the window.
   */

  const propsPerBreakpoint = (Array.isArray(propValue) ? propValue : [propValue]).map(s =>
    s ? customProps[s] : null
  );

  const props = [
    ...new Set(
      propsPerBreakpoint
        .map(breakpointProps => (breakpointProps ? Object.keys(breakpointProps) : null))
        .filter(bp => bp !== null)
        .reduce((allProps, props) => {
          return [...allProps, ...props];
        }, [])
    )
  ];

  return props.reduce((propValues, prop) => {
    return {
      ...propValues,
      [prop]: propsPerBreakpoint.map(bp => (bp ? bp[prop] || null : null))
    };
  }, {});
}
