import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import DownloadRelatedTestProductFiles from "components/DownloadRelatedTestProductFiles";
import { useBasketContext } from "contexts/BasketContext";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { theme } from "core/theme";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import BiomarkersAndSymptomsList from "tpo/shop/BiomarkersAndSymptomsList";
import Carousel from "tpo/shop/Carousel";
import MainDetails from "tpo/shop/MainDetails";
import RelatedPdfs from "tpo/shop/RelatedFiles";
import TestProductInformationPanels from "tpo/shop/TestProductInformationPanels";
import TestProductOptions from "tpo/shop/TestProductOptions";
import { DISPLAY_CONTEXT_ALWAYS_VISIBLE, DISPLAY_CONTEXT_B2B_ONLY } from "tpo/shop/constants";

import RelatedFileClasses from "../../RelatedFiles.module.css";
import getCarouselImages from "../utils/getCarouselImages";

export default function Truncated({ testProduct }) {
  const { selectTestProductOptionId } = useTestProductModalContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  const carouselImages = getCarouselImages(testProduct);

  const testDataFilesWithSamples = testProduct.testDataFiles
    .filter(tdf => tdf.sampleFile)
    .map(tdf => ({
      id: tdf.id,
      name: tdf.name,
      url: tdf.sampleFile,
      extension: tdf.fileType.extension,
      contentType: tdf.fileType.contentType
    }));

  const sampleReports = testDataFilesWithSamples.length
    ? testDataFilesWithSamples
    : [
        {
          id: "reportPdf",
          name: "Report Pdf",
          url: testProduct.reportPdf,
          extension: "pdf",
          contentType: "application/pdf"
        }
      ];

  const practitionerSupportRequired = testProduct.productAudience === "B2B";
  const options = testProduct.options.length ? (
    <TestProductOptions>
      {testProduct.options.map((option, index) => {
        const hasClinics = !!option.consumerClinicsInfo?.clinics?.length;
        return (
          <TestProductOptions.Option
            status={testProduct.status}
            key={option.id}
            id={option.id}
            compositeId={option.compositeId}
            name={option.testProductOptionFamily.name}
            description={option.testProductOptionFamily.description}
            price={
              hasClinics ? (
                <Stack gap={0}>
                  <Currency
                    prefix="FROM"
                    prefixFontFamily="gilroyBold"
                    prefixFontSize={16}
                    value={option.consumerClinicsInfo.fromPrice}
                    stacked
                    fontSize={44}
                  />
                  <Box fontFamily="gilroyMedium" fontSize={14}>
                    Includes clinic fee
                  </Box>
                </Stack>
              ) : (
                option.rrpCurrentPrice
              )
            }
            practitionerSupportRequired={testProduct.productAudience === "B2B"}
            lastOption={index === testProduct.options.length - 1}
            hasClinics={hasClinics}
            onFindNearestClinic={() => {
              selectTestProductOptionId({
                id: option.id,
                paymentIsoCode: option.paymentIsoCode,
                organisation: option.organisation
              });
            }}
            onAddToBasket={() => {
              setBasketOpen(true);
              addItemToBasket({
                compositeId: option.compositeId
              });
            }}
          />
        );
      })}
    </TestProductOptions>
  ) : null;

  const informationPanels = testProduct.content.informationPanels.filter(
    panel =>
      panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
      panel.displayContext === DISPLAY_CONTEXT_B2B_ONLY
  );

  return (
    <>
      <Box bg="white" pt={94} />
      <Box display="none" data-component-name="TruncatedProductDetail" />
      {!!carouselImages.length && (
        <PanelBoxV2
          maxWidth={1024}
          outer={{
            bg: "white",
            pt: theme.spacing.section.pt,
            px: [20, 20, "5.5vw"]
          }}
        >
          <Box bg="haze" p={20}>
            <Carousel images={carouselImages} bg="haze" />
          </Box>
        </PanelBoxV2>
      )}
      <PanelBoxV2
        maxWidth={768}
        outer={{
          bg: "white",
          pt: theme.spacing.section.pt,
          pb: theme.spacing.section.pb,
          px: [20, 20, "5.5vw"]
        }}
      >
        <Box>
          <MainDetails
            status={testProduct.status}
            practitionerSupportRequired={practitionerSupportRequired}
            name={testProduct.name}
            description={testProduct.content.description}
            categories={testProduct.content.categories.map(cat => cat.name)}
            sampleTypes={testProduct.sampleTypes}
            numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
            processingTime={testProduct.content.processingTime}
            options={options}
            priceElement={
              <Group gap={10}>
                {testProduct.rrpFullPrice !== undefined &&
                  testProduct.rrpFullPrice > testProduct.rrpCurrentPrice && (
                    <Currency
                      fontSize={[44]}
                      value={testProduct.rrpFullPrice}
                      color="red"
                      strikethrough
                    />
                  )}
                <Currency fontSize={[44]} value={testProduct.rrpCurrentPrice} />
              </Group>
            }
            productOptionCardStackBg="white"
            testType={testProduct.content.testType}
            onAddToBasket={() => {
              addItemToBasket({
                compositeId: testProduct.compositeId
              });
              setBasketOpen(true);
            }}
          />
        </Box>
      </PanelBoxV2>
      {!!(
        testProduct.content.relatedSymptoms?.length || testProduct.content.biomarkersTested?.length
      ) && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "partners",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, "5.5vw"]
          }}
        >
          <BiomarkersAndSymptomsList
            relatedSymptoms={testProduct.content.relatedSymptoms}
            biomarkersTested={testProduct.content.biomarkersTested}
          />
        </PanelBoxV2>
      )}
      {!!informationPanels?.length && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "haze",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, "5.5vw"]
          }}
          stacked
          gap={40}
        >
          <TestProductInformationPanels panels={informationPanels} />
        </PanelBoxV2>
      )}
      {(!!testProduct.instructions?.length || !!testProduct.sampleLabReports?.length) && (
        <PanelBoxV2
          maxWidth={768}
          outer={{
            bg: "white",
            pt: theme.spacing.section.pt,
            pb: theme.spacing.section.pb,
            px: [20, 20, "5.5vw"],
            className: RelatedFileClasses.container
          }}
        >
          <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Download files
          </Box>
          {!!testProduct.sampleLabReports?.length && (
            <RelatedPdfs sectionName="Sample Reports" pdfs={testProduct.sampleLabReports} />
          )}
          {!!testProduct.instructions?.length && (
            <RelatedPdfs sectionName="Instructions" pdfs={testProduct.instructions} />
          )}
        </PanelBoxV2>
      )}
    </>
  );
}
