import { forwardRef } from "react";

import { fontSize } from "styled-system";
import ButtonV2 from "v2/Buttons";

const Tab = forwardRef(
  (
    {
      tab,
      selectedTab,
      tabs,
      onClick,
      borderRadiusTop,
      borderRadiusBottom,
      px,
      py,
      withBorder,
      disabled,
      "data-component-name": dataComponentName,
      height,
      fontSize
    },
    ref
  ) => {
    let radiusProps = {};
    if (borderRadiusTop) {
      radiusProps = {
        ...radiusProps,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
      };
    }
    if (borderRadiusBottom) {
      radiusProps = {
        ...radiusProps,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
      };
    }

    let borderProps = {};

    if (borderRadiusTop && withBorder) {
      borderProps = {
        ...borderProps,
        borderBottomStyle: "solid",
        borderBottomColor: "haze",
        borderBottomWidth: 1
      };
    }

    if (borderRadiusBottom && withBorder) {
      borderProps = {
        ...borderProps,
        borderTopStyle: "solid",
        borderTopColor: "haze",
        borderTopWidth: 1
      };
    }

    return (
      <ButtonV2
        bg={tab.slug === selectedTab ? "dark" : "white"}
        fontSize={[14]}
        py={py}
        px={px}
        borderWidth={1}
        display="inline"
        ml={tabs?.[0]?.slug === tab.slug ? "auto" : null}
        mr={tabs.slice(-1)?.[0]?.slug === tab.slug ? "auto" : null}
        whiteSpace="nowrap"
        onClick={onClick}
        disabled={disabled}
        data-component-name={dataComponentName}
        sx={{
          borderRadius: 0,
          color: tab.slug === selectedTab ? "white" : disabled ? "midGrey" : "dark",
          ...radiusProps,
          borderRightStyle: tabs.slice(-1)?.[0]?.slug !== tab.slug ? "solid" : null,
          borderRightColor: tab.slug === selectedTab ? "dark" : "haze",
          opacity: 1,
          fontSize,
          ...borderProps
        }}
        ref={ref}
        size={null}
        height={height}
      >
        {tab.label}
      </ButtonV2>
    );
  }
);

Tab.defaultProps = {
  borderRadiusTop: true,
  borderRadiusBottom: false,
  px: 40,
  py: 20,
  fontSize: 14
};

export default Tab;
