import { useContext, useMemo } from "react";

import { useHistory } from "react-router-dom";

import Box from "components/Box";
import DataLoader from "components/DataLoader";
import SupplementModalContent from "components/SupplementModelContent";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import { getPartnerShopTabUrl } from "core/urls";
import { PanelBoxV2 } from "tpo/Boxes";
import Modal from "tpo/Modal";
import NothingToShow from "tpo/NothingToShow";
import {
  PriorityRecommendations,
  RecommendationModalContent,
  USER_SUBSECTORS_QUERY
} from "tpo/Recommendations";
import { CardListContext } from "tpo/UserResults";
import { useCardList } from "tpo/UserSubsector";
import { ViewerContext } from "tpo/Viewer";

import Template from "./shared/Template";

function RecommendationsTab() {
  const viewerContext = useContext(ViewerContext);

  const { viewCard, closeCard, cardOpen } = useCardList();
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  const cardListApi = useMemo(
    () => ({
      viewCard,
      closeCard
    }),
    [viewCard, closeCard]
  );

  const history = useHistory();

  return (
    <DataLoader
      query={USER_SUBSECTORS_QUERY}
      variables={{
        excludeEmptyRecommendations: true,
        includeThingsToDoCount: true,
        includeThingsToAvoidCount: true,
        includeThingsToTakeCount: true,
        includePeopleToSeeCount: true,
        userId: viewerContext?.userId,
        suppressed: false,
        fresh: !includeExpiredMarkers
      }}
      render={({ userSubsectors, userTestRecommendations }) =>
        userSubsectors?.length ? (
          <>
            <CardListContext.Provider value={cardListApi}>
              <PriorityRecommendations
                header="Patient Prioritised Suggestion List"
                userSubsectors={userSubsectors}
                bodyCopy=""
              />
            </CardListContext.Provider>
            <Modal
              mode={["fullScreen", "fullScreen", "centered"]}
              bg={cardOpen?.bg}
              close={closeCard}
              show={!!cardOpen}
              maxWidth={1020}
              closeButton
              headerProps={{
                p: [20, 20, 40]
              }}
              data-component-name={
                cardOpen?.type === "recommendations" ? "RecommendationModal" : "SupplementModal"
              }
            >
              {cardOpen?.type === "recommendations" && cardOpen?.item?.id && (
                <RecommendationModalContent recommendationId={cardOpen.item.id} />
              )}
              {cardOpen?.type === "supplements" && cardOpen?.item?.id && (
                <SupplementModalContent
                  slug={cardOpen.item.slug}
                  onViewInShop={() => {
                    history.push(
                      `${getPartnerShopTabUrl("supplements")}?slug=${cardOpen.item.slug}`
                    );
                  }}
                />
              )}
            </Modal>
          </>
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  {PriorityRecommendations.defaultProps.header}
                </Box>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
                  No suggestions available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

export default function RecommendationsRoute() {
  return (
    <Template selectedTab="suggestions" docTitle="Patient's Suggestions">
      <RecommendationsTab />
    </Template>
  );
}
